<template>
  <v-form>
    <v-card id="container_card" class="ma-4">
      <v-card-text ref="templateContainer" class="mx-3 d-flex flex-column flex-grow-1 overflow-y-auto" style="overscroll-behavior: contain;max-height:calc(100vh - 170px);min-width:65vw;">
            <template v-if="formPhase==1">
              <v-container>
                <v-sheet class="flex-grow-1" color="transparent">
                    <p v-html="$t('view.sheelon1.intro').replace('.  ','.<br/>')"></p>
                </v-sheet>
                <v-select v-model="form.project"
                  @update:model-value="form.project=$event; {form.schoolCode=null;if ($refs.schoolCode) {$refs.schoolCode.resetValidation();}}"
                  :label="$t('view.sheelon1.Qs.project.label')"
                  xpersistent-hint
                  xplaceholder="$t('view.sheelon1.Qs.project.label')"
                  xhint="$t('view.sheelon1.Qs.project.label')"
                  :items="$tm('view.sheelon1.itemSets.ProjectItems')"></v-select>
                <v-text-field v-if="form.project==1 || form.project==2"
                  v-model="form.schoolCode" ref="schoolCode"
                  :label="$t('view.sheelon1.Qs.schoolCode.label_' + form.project)"
                  type="number" :validate-on="validationMode.schoolCode"
                  @focus="if(!$refs.schoolCode.isValid && $refs.schoolCode.value!='') {validationMode.schoolCode='';$refs.schoolCode.resetValidation(); }"
                  :rules="[schoolCodeRule]"></v-text-field>
              </v-container>
            </template>

            <template v-if="formPhase==2">
            <v-container>
              <v-text-field
                v-model="form.age" ref="age"
                :label="$t('view.sheelon1.Qs.age.label')"
                type="number" :validate-on="validationMode.age"
              @focus="if(!$refs.age.isValid && $refs.age.value!='') {validationMode.age='';$refs.age.resetValidation(); }"
              :rules="[ageRuleOutOfRange]" ></v-text-field>
              <v-select v-model="form.gender" :label="$t('view.sheelon1.Qs.gender.label')" :items="$tm('view.sheelon1.Qs.gender.items')"></v-select>
              <!--v-select v-model="form.israel" :label="$t('view.sheelon1.Qs.israel.label')" :items="$tm('view.sheelon1.itemSets.NoYesItems')"></v-select-->
              <v-select v-model="form.evacuated" :label="$t('view.sheelon1.Qs.evacuated.label')" :items="$tm('view.sheelon1.itemSets.NoYesItems')"></v-select>
              <v-select v-if="isAdult"
                  v-model="form.residenceChildhood" :label="$t('view.sheelon1.Qs.residenceChildhood.label')" :items="$tm('view.sheelon1.itemSets.residence')"
              ></v-select>
              <!-- https://data.gov.il/dataset/unified/resource/e9701dcb-9f1c-43bb-bd44-eb380ade542f
                https://data.gov.il/api/3/action/datastore_search?resource_id=e9701dcb-9f1c-43bb-bd44-eb380ade542f
              -->

              <v-autocomplete v-model="form.residenceName"
                v-if="isKehila || isNonEvacuatedTalmid"
                :label="$t('view.sheelon1.Qs.residenceName.label' + (form.evacuated?'_evacuated':''))"
                :items="$tm('general.vilages')"
                no-data-text="view.sheelon1.Qs.residenceName.errors.noDataText"
                :custom-filter="customFilter"
                auto-select-first></v-autocomplete>

              <v-select v-if="isNonEvacuatedTalmid"
                  v-model="form.residencePermanent"

                  :label="$t('view.sheelon1.Qs.residencePermanent.label')"
                  xpersistent-hint
                  xplaceholder="$t('view.sheelon1.Qs.residencePermanent.label')"
                  xhint="$t('view.sheelon1.Qs.residencePermanent.label')"
                  :items="$tm('view.sheelon1.itemSets.residence')"
              ></v-select>
              <v-select v-if="isEvacuatedTalmid"
                  v-model="form.residenceBefore" :label="$t('view.sheelon1.Qs.residenceBefore.label')" :items="$tm('view.sheelon1.itemSets.residence')"
              ></v-select>
              <v-select v-if="isEvacuatedTalmid"
                  v-model="form.residenceAfter" :label="$t('view.sheelon1.Qs.residenceAfter.label')" :items="$tm('view.sheelon1.itemSets.residence')"
              ></v-select>

              <v-select v-if="isNonEvacuatedTalmid || isKehila"
                  v-model="form.houseNow" :label="$t('view.sheelon1.Qs.houseNow.label')" :items="$tm('view.sheelon1.itemSets.house')"
                  @update:modelValue="if(!hasFloors(form.houseNow)) form.houseNowFloor=null;"
              ></v-select><!--([20,30,40].indexOf(form.houseNow) >=0) -->
              <v-text-field v-if="(isNonEvacuatedTalmid || isKehila) && hasFloors(form.houseNow)"
                v-model="form.houseNowFloor" :label="$t('view.sheelon1.Qs.floor.label')" type="number" :rules="[floorRuleOutOfRange]"
              ></v-text-field>

                <v-select v-if="isEvacuatedTalmid"
                  v-model="form.houseBefore" :label="$t('view.sheelon1.Qs.houseBefore.label')" :items="$tm('view.sheelon1.itemSets.house')"
                  @update:modelValue="if(!hasFloors(form.houseBefore)) form.houseBeforeFloor=null;"
              ></v-select>
              <v-text-field v-if="isEvacuatedTalmid && hasFloors(form.houseBefore)"
                v-model="form.houseBeforeFloor" :label="$t('view.sheelon1.Qs.floor.label')" type="number" :rules="[floorRuleOutOfRange]"
              ></v-text-field>

                <v-select v-if="isEvacuatedTalmid"
                  v-model="form.houseAfter" :label="$t('view.sheelon1.Qs.houseAfter.label')" :items="$tm('view.sheelon1.itemSets.house')"
                  @update:modelValue="if(!hasFloors(form.houseAfter)) form.houseAfterFloor=null;"
              ></v-select>
              {{ $tm('view.sheelon1.itemSets.house')[form.houseAfter]?.hasFloors }}
              <v-text-field v-if="isEvacuatedTalmid && hasFloors(form.houseAfter)"
                v-model="form.houseAfterFloor" :label="$t('view.sheelon1.Qs.floor.label')" type="number" :rules="[floorRuleOutOfRange]"
              ></v-text-field>

              <v-select v-if="!isTalmid"
              v-model="form.educationLevel" :items="$tm('view.sheelon1.Qs.educationLevel.items')" :label="$t('view.sheelon1.Qs.educationLevel.label')"></v-select>
            </v-container>
            </template>

            <template v-if="formPhase==3"> <!-- NR - nature relatedness -->
            <v-container>
              <div class="d-flex flex-column flex-grow-1">
                <div class="mb-3" v-html="$t('view.sheelon1.NR_title').replace('.  ','.<br/>') + ' ' + $t('view.sheelon1.agreeScaleText') "></div>
                <div>
                  <v-card :key="Q.key+'card'" v-for="(Q,i) in NR_Qs"
                  class="pt-1 pb-0 mt-0 mb-4" variant="tonal" elevation="3">
                      <v-card-text class="pt-0 pb-7">{{$t('view.sheelon1.Qs.'+ Q.key+'.label')}}</v-card-text>
                      <v-card-text  class="pt-0 pb-2 my-0 overflow-y-hide" xstyle="height:30px">
                        <IMZSlider class="mx-1 ps-3" :name="'slider_' +i " v-model="form[Q.key]" :min="1" :max="5" :step="1"></IMZSlider>
                      </v-card-text>
                  </v-card>
                </div>
              </div>
            </v-container>
            </template>

            <template v-if="formPhase==4"> <!-- PA - place attachment -->
            <v-container>
              <div class="d-flex flex-column flex-grow-1">
                <div class="mb-3" v-html="$t('view.sheelon1.PA_title').replace('.  ','.<br/>' + ' ' + $t('view.sheelon1.agreeScaleText') )"></div>
                <div>
                  <v-card :key="Q.key+'card'" v-for="(Q,i) in PA_Qs_1"
                  class="pt-1 pb-0 mt-0 mb-4" variant="tonal" elevation="3">
                      <v-card-text :key="Q.key+'_label'" class="pt-0 pb-7">{{$t('view.sheelon1.Qs.' + Q.key+ '.label')}} </v-card-text>
                      <v-card-text class="py-0 pb-2 my-0 overflow-y-hide" xstyle="height:30px">
                        <IMZSlider class="mx-1 ps-3" :name="'slider_' +i " v-model="form[Q.key]" :min="1" :max="5" :step="1"></IMZSlider>
                      </v-card-text>
                  </v-card>
                </div>
              </div>
            </v-container>
            </template>

            <template v-if="formPhase==4.5"> <!-- PA - place attachment -->
            <v-container>
              <div class="d-flex flex-column flex-grow-1">
                <div class="mb-3" v-html="$t('view.sheelon1.PA_title').replace('.  ','.<br/>' + ' ' + $t('view.sheelon1.agreeScaleText') )"></div>
                <div>
                  <v-card :key="Q.key+'card'" v-for="(Q,i) in PA_Qs_2"
                  class="pt-1 pb-0 mt-0 mb-4" variant="tonal" elevation="3">
                      <v-card-text :key="Q.key+'_label'" class="pt-0 pb-7">{{$t('view.sheelon1.Qs.' + Q.key+ '.label')}} </v-card-text>
                      <v-card-text class="py-0 pb-2 my-0 overflow-y-hide" xstyle="height:30px">
                        <IMZSlider class="mx-1 ps-3" :name="'slider_' +i " v-model="form[Q.key]" :min="1" :max="5" :step="1"></IMZSlider>
                      </v-card-text>
                  </v-card>
                </div>
              </div>
            </v-container>
            </template>


            <template v-if="formPhase==5"> <!-- WHO - Q's by the World Health Organization-->
              <v-container>
                <div class="d-flex flex-column flex-grow-1">
                  <div class="mb-0" v-html="$t('view.sheelon1.WHO_title').replace('.  ','.<br/>')" ></div>
                  <div class="mb-3">(<span v-for="i in 6" :key="i">{{ i - 1}}- {{ $t('view.sheelon1.Qs.WHO_Headers_' + (i-1)) }} <span v-if="i<6">, </span></span> )</div>
                  <div>
                    <v-card :key="Q.key" v-for="(Q,i) in WHO_Qs" class="pt-1 pb-0 mt-0 mb-3" variant="tonal" elevation="3">
                        <v-card-text class="pt-0 pb-7"> <b> {{ $t('view.sheelon1.Qs.' + Q.key+ '.label')}}</b></v-card-text>
                        <v-card-text class="py-0 my-0 overflow-y-hide" xstyle="height:60px">
                          <IMZSlider class="mx-1 ps-3" :name="'slider_who_' +i " v-model="form[Q.key]" :min="0" :max="5" :step="1"></IMZSlider>
                        </v-card-text>
                    </v-card>
                  </div>
                </div>
              </v-container>
            </template>

            <template v-if="formPhase==5.5"> <!-- WHO - Q's by the World Health Organization-->
            <v-container>
              <p class="mb-2" v-html="$t('view.sheelon1.WHO_title').replace('.  ','.<br/>')"></p>

              <v-card class="pt-1 pb-0 mt-0 mb-1" variant="tonal" elevation="3">
              <v-row no-gutters class="pa-0" xstyle="border:1px solid blue">
                <template :key="'t_who_'+'i_'+i" v-for="i in [5,4,3,2,1,0]">
                  <v-col class="pa-0" xstyle="border:1px solid yellow">
                  </v-col>
                  <v-col class="pa-0" xstyle="border:1px solid yellow">
                    <div class="text-center py-0" xstyle="min-height:48px;">{{ $t('view.sheelon1.Qs.WHO_Headers_' + i) }}</div>
                  </v-col>
                </template>
              </v-row>
              </v-card>

              <v-card :key="Q.key+'card'" v-for="Q in WHO_Qs"
              class="pt-1 pb-0 mt-0 mb-1" variant="tonal" elevation="3">
                  <v-card-text :key="Q.key+'_label'" class="pt-0 pb-1">{{$t('view.sheelon1.Qs.' + Q.key+ '.label')}}</v-card-text>
                  <v-card-text :key="Q.key+'_text'" class="py-0 my-0 overflow-y-hide" xstyle="height:150px;">

                      <v-radio-group v-model="form[Q.key]" inline class="flex-grow pa-0 ma-0" :hide-details="true" xstyle="border:1px solid cyan">
                        <v-container class="pa-0" xstyle="border:1px solid red">
                            <v-row no-gutters class="pa-0" xstyle="border:1px solid blue">
                                  <v-col class="pa-0" xstyle="border:1px solid yellow">
                                    {{$t('view.sheelon1.Qs.' + Q.key+ '.label')}}
                                  </v-col>
                                  <template :key="'t_'+Q.key+'i'+i" v-for="i in [5,4,3,2,1,0]">
                                    <v-col class="pa-0" xstyle="border:1px solid yellow">
                                       <!--div class="text-center py-0" style="min-height:48px;">{{ $t('view.sheelon1.Qs.WHO_Headers_' + i) }}</div-->
                                       <div class="text-center py-0"><v-radio density="compact" :key="'k'+i"  label="" :value="i"></v-radio></div>
                                       <div class="text-center py-0">{{ i }}</div>
                                    </v-col>
                                  </template>

                            </v-row>
                        </v-container>
                      </v-radio-group>
                  </v-card-text>
              </v-card>
            </v-container>
            </template>

      </v-card-text>
      <v-spacer></v-spacer>
      <v-divider></v-divider>
      <v-card-actions class="d-flex flex-row justify-space-between">
        <v-btn :disabled="!prevIsEnabled" :style="{ visibility: (formPhase>1) ? 'visible' : 'hidden' }"
                 v-ripple=true variant="flat" class="ma-2 ms-6" @click="onPrev">{{$t('general.buttons.previous')}}
        </v-btn>
        <!--v-btn :disabled="!prevIsEnabled" :style="{ visibility: (formPhase>1) ? 'visible' : 'hidden' }"
                 v-ripple=true variant="flat" class="ma-2 ms-6" @click="onPrev();">{{$t('general.buttons.previous')}}
        </v-btn-->
          <!-- 'flat' | 'text' | 'elevated' | 'tonal' | 'outlined' | 'plain'-->
          <!--v-btn @click="submitForm">Submit</v-btn-->
        <v-btn v-if="nextIsEnabled" :disabled="!nextIsEnabled"
             v-ripple=true variant="flat" class="ma-2 me-6" @click="onNext">{{$t('general.buttons.next')}}
        </v-btn>
      </v-card-actions>
      <!--v-locale-provider class="text-start" :rtl="false"></v-locale-provider-->
    </v-card>
  </v-form>
</template>

<script setup>
// @ is an alias to /src
  import {ref, computed, nextTick} from 'vue';
  import { useRouter } from 'vue-router';
  import { useI18n } from 'vue-i18n';
  import IMZSlider from '@/components/IMZSlider.vue';

// Assuming you have already set up vue-i18n in your application
  const { t,tm } = useI18n();
  const router = useRouter();

  const templateContainer = ref(null); // This will be used to reference the DOM element for scrolling it up

  const validationMode={
    schoolCode:'blur',
    age:'blur'
  }

  const NR_Qs=ref([
    {key:'NR_1',wasClicked:false},
    {key:'NR_2',wasClicked:false},
    {key:'NR_3',wasClicked:false},
    {key:'NR_4',wasClicked:false},
    {key:'NR_5',wasClicked:false},
    {key:'NR_6',wasClicked:false}
  ]);

  const PA_Qs_1=ref([
    {key:'PA_nature1',wasClicked:false},
    {key:'PA_people1',wasClicked:false},
    {key:'PA_identity1',wasClicked:false},

    {key:'PA_nature2',wasClicked:false},
    {key:'PA_people2',wasClicked:false},
    {key:'PA_identity2',wasClicked:false}
  ]);

  const PA_Qs_2=ref([
    {key:'PA_nature3',wasClicked:false},
    {key:'PA_people3',wasClicked:false},
    {key:'PA_identity3',wasClicked:false},

    {key:'PA_nature4',wasClicked:false},
    {key:'PA_people4',wasClicked:false},
    {key:'PA_identity4',wasClicked:false}
  ]);

  const WHO_Qs=ref([
    {key:'WHO_1',wasClicked:false},
    {key:'WHO_2',wasClicked:false},
    {key:'WHO_3',wasClicked:false},
    {key:'WHO_4',wasClicked:false},
    {key:'WHO_5',wasClicked:false},
  ]);

  const form = ref({
      //phase 1
      project: null,
      schoolCode:null,
      //phase 2
      age: null,
      gender: null,
      israel: null,
      evacuated : null,
      residenceChildhood: null,
      residenceName:null,

      residencePermanent:null,
      residenceBefore:null,
      residenceAfter:null,

      houseNow:null,
      houseNowFloor:null,
      houseBefore:null,
      houseBeforeFloor:null,
      houseAfter:null,
      houseAfterFloor:null,

      educationLevel: null,
    });

    for (let Qidx in NR_Qs.value ) {
      form.value[NR_Qs.value[Qidx].key]=null;
    }
    for (let Qidx in PA_Qs_1.value ) {
      form.value[PA_Qs_1.value[Qidx].key]=null;
    }
    for (let Qidx in PA_Qs_2.value ) {
      form.value[PA_Qs_2.value[Qidx].key]=null;
    }
    for (let Qidx in WHO_Qs.value ) {
      form.value[WHO_Qs.value[Qidx].key]=null;
    }

    const formPhase=ref(1);
    //alert('formPhase=ref(5)');


/*        satisfactionEmojis: [
          //{{ satisfactionEmojis[modelValue] }}
        '😭','☹️','😐','🙂','😍',
      ],
      satisfactionText: [
        'ממש לא מסכים','לא מסכים','אדיש','מסכים','ממש מסכים'
      ],
      satisfactionEmojis_10: [
          //{{ satisfactionEmojis[Math.min(Math.floor(modelValue / 10), 9)] }}
        '😭','😢','☹️','🙁','😐','🙂','😊','😁','😄','😍',
      ],
    };
  },
*/
/*
  const handleSliderMouseDown= (Q,form) => {
    let key=Q.key;
    Q.wasClicked = true;
    form[key]=(null==form[key])?1:form[key];
  }
*/

  const customFilter= (itemTitle, queryText, item) => {
    const searchText = queryText.trim().toLowerCase()
    var res= ((''+item.raw.search_key).indexOf(searchText) > -1);
    //console.log(res, item.raw.search_key.indexOf(searchText) , itemTitle, queryText, ''+ item.raw.search_key );
    return res;
  };

  const schoolCodeRule=(v)=>{ //isValid
    if (v==null) return false; // not valid no error
    if (''==v.trim()) return false; // only spaces
    if (!isNaN(parseFloat(v)) && v >= 99999 && v <= 999999) return true; // itsvalid
    return t('view.sheelon1.Qs.schoolCode.errors.schoolCodeRuleOutOfRange_' + form.value.project);
  };

  const ageRuleOutOfRange= (v) => {
    if (!isNaN(parseFloat(v)) && v >= 6 && v <= 99) return true;
    return t('view.sheelon1.Qs.age.errors.ageRuleOutOfRange')
  };

  const floorRuleOutOfRange = (v)=> {
    if (!isNaN(parseFloat(v)) && v >= 0) return true;
    return false
  };

  const ScrollToTop=(el)=> {
    nextTick(() => {
      if (el && el.$el) { // For Vuetify components
      el.$el.scrollTop = 0;
    } else if (el) {
      el.scrollTop = 0; // For native HTML elements
    }
    });
  };


  const onNext= ()=>{
    if (formPhase.value==3 && !isTalmid.value) {
      formPhase.value=5;
      ScrollToTop(templateContainer.value);
      return;
    }
    if (formPhase.value<4) {
      formPhase.value++;
      ScrollToTop(templateContainer.value);
      return;
    }
    if (formPhase.value==4) {
      formPhase.value=4.5;
      ScrollToTop(templateContainer.value);
      return;
    }
    if (formPhase.value==4.5) {
      formPhase.value=5;
      ScrollToTop(templateContainer.value);
      return;
    }
    else { // on form phase 5
      submitForm();
    }
  };

  const onPrev=()=>{
    if (formPhase.value==4.5) {
      formPhase.value=4;
      ScrollToTop(templateContainer.value);
      return;
    }
    if (formPhase.value==5 && !isTalmid.value) {
      formPhase.value=3;
      ScrollToTop(templateContainer.value);
      return;
    }
    if (formPhase.value>1) {
      formPhase.value--;
      ScrollToTop(templateContainer.value);
      return;
    }
  };

  const hasFloors=(house_value)=>{
    return tm('view.sheelon1.itemSets.house').find((house) => (house.value==house_value))?.hasFloors;
  }

  const submitForm= ()=> {
      /*
      const now = new Date(); // Get current date and time
      const nowAsString = now.toISOString(); // Convert to string in ISO 8601 format
      localStorage.setItem('currentDateTime', nowAsString); // Save to localStorage
      //to read the date and time:
      const savedDateTimeString = localStorage.getItem('currentDateTime'); // Retrieve from localStorage
      const savedDateTime = new Date(savedDateTimeString); // Convert string back to Date object
      console.log(savedDateTime); // Use the Date object
      */

      localStorage.setItem('formData', JSON.stringify(form.value));
      console.log("Form data saved to local storage.");
      router.replace('/sh2');
      //this.$router.replace('/takePhoto');
  };
/*
  const populateFormData = ()=>{
      const storedFormData = localStorage.getItem('formData');
      if (storedFormData) {
          form = JSON.parse(storedFormData);
      }
  };
*/
  const allQsAreValid=(Qset)=>{
    const allFieldsValid = Qset.every(Q => {
        //console.log('testing ', Q.key, (null != form.value[Q.key]));
        return null != form.value[Q.key]; // Return true if the condition is met, otherwise false
      });
      return allFieldsValid; // This will return false if any field is null, true otherwise
  };

  const phase1Valid=computed(()=>{
    if (null == form.value.project) return false;
    if (!form.value.project) return true;
    if (null == form.value.schoolCode) return false;
    return true == schoolCodeRule(form.value.schoolCode)
  });

  const phase2Valid=computed(()=>{
      if (null == form.value.age) return false;
      if (form.value.age < 6 || 99 < form.value.age) return false;

      //if (null == form.value.gender) return false;
      //if (isAdult.value && (null == form.value.residenceChildhood)) return false;
      //if (null == form.value.israel) return false;
      if (null == form.value.evacuated) return false;
      // if ((isNonEvacuatedTalmid.value) && (null==form.value.residencePermanent)) return false;
      // if ((isEvacuatedTalmid.value) && (null==form.value.residenceBefore)) return false;
      // if ((isEvacuatedTalmid.value) && (null==form.value.residenceAfter)) return false;
      // if ((isNonEvacuatedTalmid.value || isKehila.value) && (null==form.value.houseNow)) return false;
      // if ((isNonEvacuatedTalmid.value || isKehila.value) && hasFloors(form.value.houseNow) && (null==form.value.houseNowFloor)) return false;
      // if ((isEvacuatedTalmid.value) && (null==form.value.houseBefore)) return false;
      // if ((isEvacuatedTalmid.value) && hasFloors(form.value.houseBefore) && (null==form.value.houseBeforeFloor)) return false;
      // if ((isEvacuatedTalmid.value) && (null==form.value.houseAfter)) return false;
      // if ((isEvacuatedTalmid.value) && hasFloors(form.value.houseAfter) && (null==form.value.houseAfterFloor)) return false;
      // if ((!isTalmid.value) && (null == form.value.educationLevel)) return false;
      return true;
  });

  const phase3Valid=computed(()=> {
    return allQsAreValid(NR_Qs.value);
  });

  const phase4Valid=computed(()=> {
    return allQsAreValid(PA_Qs_1.value);
  });

  const phase4_5Valid=computed(()=> {
    return allQsAreValid(PA_Qs_2.value);
  });


  const phase5Valid=computed(()=> {
    return allQsAreValid(WHO_Qs.value);
  });

  const nextIsEnabled=computed(()=> {
    if (formPhase.value==1) { return phase1Valid.value; }
    if (formPhase.value==2) { return phase2Valid.value; }
    if (formPhase.value==3) { return phase3Valid.value; }
    if (formPhase.value==4) { return phase4Valid.value; }
    if (formPhase.value==4.5) { return phase4_5Valid.value; }
    if (formPhase.value==5) { return phase5Valid.value; }
    return true;
  });

  const prevIsEnabled=computed(()=>{
    return (formPhase.value>1);
  });

  const isTalmid=computed(()=>{
    return form.value.project==1;
  });
  const isKehila=computed(()=>{
    return (form.value.project==0 || form.value.project==2);
  });
  const isEvacuatedTalmid=computed(()=>{
    return isTalmid.value && form.value.evacuated
  });
  const isNonEvacuatedTalmid=computed(()=>{
    return isTalmid.value && !form.value.evacuated
  });
  const isAdult=computed(()=>{
    return form.value.age && parseInt(form.value.age)>18
  });

    </script>

  <style scoped>
  /* Add your styles here */

  .v-card ,
  .v-field{
   background-color: rgba(255, 255, 255, 1) !important;
   border-color: white !important;
 }

 .v-card .v-card {
  background-color: transparent !important;
 }

  </style>

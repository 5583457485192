<template>
  <v-container v-bind="$attrs">
    <v-row class="mb-2"><v-btn @click="readLSKey" variant="flat" color="blue">Read Local Storage</v-btn></v-row>
    <!--div><v-text-field v-model="debugLSKey" label="key name"></v-text-field></div-->
    <v-row><v-select v-model="debugLSKey" label="key name" :items="lskeys" :list-props="{fontSize:'5px',xmaxWidth:'250px'}"></v-select></v-row>
  </v-container>
</template>
<script setup>

  import {ref,computed} from'vue';

  const lskeys=computed( ()=> Object.keys(localStorage));
  const debugLSKey=ref(lskeys.value[0]);
  const readLSKey = () => {
      alert(JSON.stringify(localStorage.getItem(debugLSKey.value)));
  };
</script>
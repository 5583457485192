import { computed,reactive, watch } from 'vue';
import { usePermission } from '@vueuse/core';
import { useGeolocation } from "@vueuse/core";
//import {useConditionalAlerts} from '@/composables/useConditionalAlerts';
//const { c_alert  , set_alerts } = useConditionalAlerts();
//set_alerts(true);

// we will first maintain the state of the permission
//const geoPermissionInternalState = usePermission('geolocation');

// I have an assumption here that I should not call useGeolocation when I do not have location permissions.
// so:
//function NOP(logMessage) {
//  return () => {
//    console.log('NOP: logMessage=', logMessage);
//  }
//}


const internal=reactive({
  currentPermission: usePermission('geolocation'), // usePermission('geolocation'),
  currentGeolocation: useGeolocation({immediate:false,maximumAge:10*60*1000, timeout:27*1000}),
  state:'unknown'
})

const gpsService = reactive({
  permission:computed(() => internal.currentPermission),
  latitude:  computed(() => internal.currentGeolocation?.coords?.latitude || null),
  longitude: computed(() => internal.currentGeolocation?.coords?.longitude || null),
  accuracy:  computed(() => internal.currentGeolocation?.coords?.accuracy || null),
  locatedAt: computed(() => internal.currentGeolocation?.locatedAt || null),
  error:     computed(() => internal.currentGeolocation?.error || null),
  state:     computed(() => internal.state),
  resume:    ()=> {
    if (internal.currentGeolocation?.resume) {
      console.log('resume called');
      internal.currentGeolocation.resume();
      internal.state='resumed';
      }
  },
  pause:    ()=> {
    if (internal.currentGeolocation?.pause) {
      console.log('pause called');
      internal.currentGeolocation.pause();
      internal.state='paused';
      }
  }
})


// it is important to watch the gpsService and not the internal object becuase
// if internal.currentPermission is re-assigned than the watch gets lost.
watch(() => gpsService.permission, (newPermission,oldPermission) => {
  console.log('internal.currentPermission as reported by watching gpsService.permission : newPermission,oldPermission=',newPermission,oldPermission);
  if (newPermission=='denied') {
    internal.state='denied';
    // remove access to old location data from before the permission was denied
    internal.currentGeolocation = useGeolocation({immediate:false,maximumAge:10*60*1000, timeout:27*1000});
  }
  else if (newPermission=='prompt') {
      internal.state='prompt';
    // remove access to old location data from before the permission was changed to prompt
    internal.currentGeolocation = useGeolocation({immediate:false,maximumAge:10*60*1000, timeout:27*1000});
  }
  else if (newPermission=='granted') {
      internal.state='granted';
      // if the permissions where just resolved to granted then we can call useGeolocation with immediate:true
      internal.currentGeolocation = useGeolocation({immediate:true,maximumAge:10*60*1000, timeout:27*1000});
  }
}, { immediate: false });


// we want to watch the internal object for changes in the permission
watch(
  () => internal.currentPermission,
  (newPermission, oldPermission) => {
    console.log('Internal Permission object changed:', newPermission, oldPermission);
  },
  { immediate: false }
);

watch(() => gpsService.permission, (newPermission) => {
  console.log('Permission state changed:', newPermission);
}, { immediate: false });

console.log('internal', internal)
console.log('gpsService',gpsService)
window.internal=internal;
window.gpsService=gpsService;

//console.log('assigning internal.currentPermission');
//internal.currentPermission = usePermission('geolocation');



export function useGpsService(){
  return gpsService;
}

// permission:null,
// coords:null, //{latitude:null,longitude:null,accuracy:null}
// locatedAt:null,
// error:null,
// state:null,
// resume:NOP('NOP for gpsService.resume'),
// pause:NOP('NOP for gpsService.pause')
// requestPermission
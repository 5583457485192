<template>
      <v-card class="my-10 d-flex flex-column" style="width:80vw;height:calc(100vh-80px);">
        <v-card-title></v-card-title>
        <v-card-text class="overflow-y-auto overflow-x-none">


          <!-- two weeks have not passed yet-->
          <!--nextReportingWindowIndex : {{ ReportsManager.nextReportingWindowIndex  }} - {{ ReportsManager.nextReportingWindowIndex!=-1 }} <br/>
          reportingPeriodEnded : {{  reportingPeriodEnded }}
          FeedbackStats.n_reportsSoFar :  {{ FeedbackStats.n_reportsSoFar }} <br/>-->
          <div v-if="!reportingPeriodEnded"
               class="d-flex flex-grow-0 flex-wrap ga-5 justify-center text-center" >
            <!-- and not 12 reports yet-->
            <template v-if="FeedbackStats.n_reportsSoFar<required_reports_for_final_feedback">
              <div>{{  $t('view.get_feedback.data_will_be_here',{end_date:toDDMMYYYYHHMM(endDate).replaceAll(' ','&lrm; &lrm;') + '&lrm;'}) }}</div>
              <div><DiagonallyCutImage imageUrl="final_sample_he.png" :imageFullHeight="697" :imageWidth="192" :cutHeight="90" :leftTopEdge="100" :rightTopEdge="292" :width="91"></DiagonallyCutImage></div>
              <div>{{  $t('view.get_feedback.data_N_required',{n_req:required_reports_for_final_feedback}) }}</div><br/>
              <div>{{  $t('view.get_feedback.data_requirements',{n:required_reports_for_final_feedback-FeedbackStats.n_reportsSoFar}) }}</div>
              <div>
                <!-- chart showing progress of reports -->
                <apexchart
                  :type="pie"
                  :series="[100.0 * Math.min(1.0*required_reports_for_final_feedback,FeedbackStats.n_reportsSoFar) / required_reports_for_final_feedback]"
                  :width="progressChartSetting.options.chart.width"
                  :height="progressChartSetting.options.chart.height"
                  :options="progressChartSetting.options"
                  >
                </apexchart>
              </div>
            </template>
            <template v-else>
                <!-- two weeks have not passed yet but did 12 reports already-->
                <div>{{  $t('view.get_feedback.did_N_reports',{n_req:required_reports_for_final_feedback, end_date:toDDMMYYYYHHMM(endDate).replaceAll(' ','&lrm; &lrm;') + '&lrm;'}) }}</div>
                <div>{{  $t('view.get_feedback.did_N_continue_reporting',{n_req:required_reports_for_final_feedback}) }}</div>
            </template>
          </div>
          <div v-else><!-- two weeks have passed and a -->
            <template v-if="FeedbackStats.n_reportsSoFar<required_reports_for_final_feedback">
              <div>{{$t('view.get_feedback.ended_without_enough_reports')}}</div>
            </template>
            <template v-else>
              {{ $t('view.get_feedback.we_created') }} {{ $t('view.get_feedback.indicators') }}<br/><br/>

                {{ $t('view.get_feedback.averages') }}<br/>
                <ul>
                  <li class="ms-5">{{ $t('view.get_feedback.averages_feelings') }}</li>
                  <li class="ms-5">{{ $t('view.get_feedback.averages_stress') }}</li>
                  <li class="ms-5">{{ $t('view.get_feedback.limits_note') }}</li>
                </ul>

                <div class="d-flex flex-column flex-grow-1 mt-5">
                  <!--div class="justify-start ps-2">{{ $t('view.get_feedback.???') }}</div-->
                  {{$t('view.get_feedback.final.positive_and_negative_feelings')}}
                  <div>
                      <apexchart
                        :type="feelingsHorizontalChart.options.chart.type"
                        :series="feelingsHorizontalChart.series"
                        :width="feelingsHorizontalChart.options.chart.width"
                        :height="feelingsHorizontalChart.options.chart.height"
                        :options="feelingsHorizontalChart.options"
                        >
                      </apexchart>
                  </div>
                </div>

                <div class="d-flex flex-column flex-grow-1 mt-5">
                  <!--div class="justify-start ps-2">{{ $t('view.get_feedback.???') }}</div-->
                  {{$t('view.get_feedback.final.stress')}}
                  <div>
                      <apexchart
                        :type="stressHorizontalChart.options.chart.type"
                        :series="stressHorizontalChart.series"
                        :width="stressHorizontalChart.options.chart.width"
                        :height="stressHorizontalChart.options.chart.height"
                        :options="stressHorizontalChart.options"
                        >
                      </apexchart>
                  </div>
                </div>




            </template>
          </div>
<!--

  <v-list>

              <v-list-item>
                {{ $t('view.get_feedback.inside') }} : {{  n_reports_inside }}<br/>
                {{ $t('view.get_feedback.outside') }} : {{ n_reports_outside }}<br/>
                {{ $t('view.get_feedback.sea_or_lake_view') }} : {{ n_g_blue_1 }}<br/>
                {{ $t('view.get_feedback.green_view') }} : {{ n_g_green_1 }}<br/>
                {{ $t('view.get_feedback.alone') }} : {{ n_g_company_1 }}<br/>
                {{ $t('view.get_feedback.with_friends') }} : {{ n_g_company_8 }}<br/>
                {{ $t('view.get_feedback.with_family') }} : {{ n_g_company_2 }}<br/>
                {{ $t('view.sheelon2.Qs.stai_1.label') }} : {{ avg_stai_1 }}<br/>
                {{ $t('view.sheelon2.Qs.stai_2.label') }} : {{ avg_stai_2 }}<br/>
                {{ $t('view.sheelon2.Qs.stai_3.label') }} : {{ avg_stai_3 }}<br/>
              </v-list-item>

              <v-expansion-panels>
                <v-expansion-panel key="1"
                  title="Apex chart debug data">
                  <v-expansion-panel-text>
                    <pre dir="ltr">{{ JSOn_stringify(apexRadar1, null, 2) }}</pre>
                  </v-expansion-panel-text>
                  </v-expansion-panel>
              </v-expansion-panels>

              <apexchart
                :type="apexRadar1.type"
                :series="apexRadar1.series"
                width="350px"
                height="350px"
                :options="apexRadar1.options"
              >

              </apexchart>

              <div dir="ltr">
                <hr/>
                <pre>{{ JSON.stringify(reportsGroupAverages, null, 2) }}</pre>
                <hr/>
                {{reportsGroupAverages}}
              </div>

              <v-list-item>
              <GChart
                :settings="{ packages: ['bar', 'corechart', 'table'] , bars: 'horizontal' , locale:'he'}"
                type="BarChart"
                :data="chart1Data"
                :options="chart1Options"
              />
              </v-list-item>

            </v-list>
          -->
          <!--pre v-if="true">
              {{ rga }}
          </pre-->
        </v-card-text>
      </v-card>
</template>
<style>
</style>
<script setup>

  //import {computed} from 'vue'

  import { useReportingWindowsManager } from '@/services/ReportingWidnowsManager';
  const { loadSetup, reportingEndDate } = useReportingWindowsManager();
  loadSetup();
  const endDate=reportingEndDate.value;

  import {useReportsManager} from '@/services/ReportsManager';
  const {reportingPeriodEnded} = useReportsManager();

  import {useFeedbackStats} from '@/services/FeedbackStats';
  const FeedbackStats=useFeedbackStats();

  import { useTimeUtils } from '@/services/TimeUtils';
  const {toDDMMYYYYHHMM} = useTimeUtils();

  import DiagonallyCutImage from '@/components/DiagonallyCutImage';

  const required_reports_for_final_feedback=12;

  import { useI18n } from 'vue-i18n';
  const { t } = useI18n();

  //const reportingPeriodEnded= computed(()=>ReportsManager.nextReportingWindowIndex.value==-1)

  // displays the circular progress bar showing how many out of 20 reports were reported
  var progressChartSetting = {
        options: {
            chart: {
              height: 250,
              width:250,
              type: 'radialBar',
              offsetY: -10
            },
            plotOptions: {
              radialBar: {
                startAngle: -135,
                endAngle: 135,
                dataLabels: {
                  name: {
                    fontSize: '16px',
                    color: undefined,
                    offsetY: 80 //120
                  },
                  value: {
                    offsetY: -10, //76,
                    fontSize: '22px',
                    color: undefined,
                    formatter: function (val) {
                      return "" +  (val/100.0 * 12 ) + "/12" ;
                    }
                  }
                },
                track: {
                  background: '#ddd',/*
                  strokeWidth: '67%',
                  margin: 0, // margin is in pixels
                  dropShadow: {
                    enabled: true,
                    top: -3,
                    left: 0,
                    blur: 4,
                    opacity: 0.35
                  }*/
                },

              },
            },
            fill: {
              type: 'gradient',
              gradient: {
                  shade: 'dark',
                  shadeIntensity: 0.15,
                  inverseColors: false,
                  opacityFrom: 1,
                  opacityTo: 1,
                  stops: [0, 50, 65, 91]
              },
            },
            stroke: {
              dashArray: 4
            },

            labels: [t('view.get_feedback.your_reporting_progress')],
          }
        };



// Example usage
var reports_group = [
  {name:'g_in_out_1',reports:FeedbackStats.g_in_out_1,key:''},//בחוץ
  {name:'g_in_out_0',reports:FeedbackStats.g_in_out_0,key:''},//בפנים
  {name:'blue_1',reports:FeedbackStats.g_blue_1,key:''},//נוף של ים או אגם
  {name:'g_green_1',reports:FeedbackStats.g_green_1,key:''},//נוף ירוק
  {name:'g_birds_1',reports:FeedbackStats.g_birds_1,key:''},//ציפורים
  {name:'g_company_1',reports:FeedbackStats.g_company_1,key:''},//לבד
  {name:'g_company_2',reports:FeedbackStats.g_company_2,key:''},//עם חברים
  {name:'g_company_3',reports:FeedbackStats.g_company_3,key:''},//עם משפחה
  {name:'g_company_4',reports:FeedbackStats.g_company_4,key:''},//עם הכלב
  {name:'g_trees_1',reports:FeedbackStats.g_trees_1,key:''},//כשאין עצים
  {name:'g_trees_2',reports:FeedbackStats.g_trees_2,key:''},//1-3 עצים
  {name:'g_trees_3',reports:FeedbackStats.g_trees_3,key:''},//4-10 עצים
  {name:'g_trees_4',reports:FeedbackStats.g_trees_4,key:''},//11-20 עצים
  {name:'g_carsd_1',reports:FeedbackStats.g_carsd_1,key:''},//כשאין רכבים נוסעים
  {name:'g_carsd_2',reports:FeedbackStats.g_carsd_2,key:''},//1-3 רכבים נוסעים
  {name:'g_carsd_3',reports:FeedbackStats.g_carsd_3,key:''},//4-10 רכבים נוסעים
  {name:'g_carsd_4',reports:FeedbackStats.g_carsd_4,key:''},//11-20 רכבים נוסעים
];

//reportsGroupAverages
const rga = FeedbackStats.calculateAverages(reports_group);

const pos_data = []
const neg_data = []
const stress_data = []
const xcategories = []
console.log(rga);
for (const report_group of reports_group) {
  console.log('report_group.name:',report_group.name);
  let stats=rga[report_group.name];
  console.log('stats.name:',stats);
  pos_data.push(stats.pos_feel.toFixed(2))
  neg_data.push(stats.neg_feel.toFixed(2))
  stress_data.push(stats.stress.toFixed(2))
  xcategories.push(t('view.get_feedback.final.categories.'+ report_group.name));
  if (report_group.name=='g_in_out_0' ||
      report_group.name=='g_green_1' ||
      report_group.name=='g_birds_1' ||
      report_group.name=='g_company_4' ||
      report_group.name=='g_trees_4' ) {
    pos_data.push([])
    neg_data.push([])
    stress_data.push([])
    xcategories.push('');
  }
}

console.log('pos_data',pos_data);
console.log('neg_data',neg_data);
console.log('xcategories', xcategories);


var feelingsHorizontalChart={
    series: [{
            name:'רגשות חיוביים',
            data: pos_data
          } , {
            name:'רגשות שליליים',
            data: neg_data
          }],
    options: {
      chart: {
        type: 'bar',
        height: 850,
        width: 260,
        toolbar: {
          show:false,
        },
      },
      colors: ['#06c','#f00'],
      plotOptions: {
        bar: {
          horizontal: true,
        }
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '12px',
          colors: ['#000']
        },
        formatter: function(value,opts) {
          if (isNaN(value)) return '';

          if (value==0) {
            if (opts.seriesIndex==0) {
              console.log(opts)
              return t('view.get_feedback.no_information_word_1');
            }
            else {
              return t('view.get_feedback.no_information_word_2');
            }
          }
          return value;
        }
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['#fff']
      },
      menu: {
        show:false,
      },
      tooltip: {
        show:false,
        shared: true,
        intersect: false
      },
      xaxis: {
        categories: xcategories,
        labels: {
          show:true
        },
        min:0,
        max:5,
        title:{
          text:'ממוצע רגשות',
          fontFamily: 'Helvetica, Arial',
          fontSize: '14px',

        }

      },
      yaxis: {
        show:true,
        labels: {
          offsetX: -100
        },
        //reversed: true,
      },/*
      responsive: [{
            breakpoint: 480,
            options: {
              chart: { width: 300, height: 200 },
              legend: { position: 'bottom' },
            }
          }] */
    },

  }

  var stressHorizontalChart={
    series: [{
            //name:'לחץ',
            data: stress_data
          }],
    options: {
      chart: {
        type: 'bar',
        height: 550,
        width: 260,
        toolbar: {
          show:false,
        },
      },
      fill: {
        colors: [ '#f00'/*,
      function({ value / *, seriesIndex, w * /}) {
            if (value < 1.5) {
                return '#0f0'
            }
            else if (value < 2.25) {
                return '#2a0';
            }
            else if (value < 3) {
                return '#770';
            }
            else if (value < 3.5) {
                return '#a20';
            }
            else if (value < 4) {
                return '#f00';
            }
            else {
              return "#00a"
            }
          }*/
        ],
      },

      plotOptions: {
        bar: {
          horizontal: true,
        }
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '12px',
          colors: ['#000']
        },
        formatter: function(value) {
          if (isNaN(value)) return '';
          if (value==0) {
              return t('view.get_feedback.no_information_word_1') + ' ' + t('view.get_feedback.no_information_word_2');
          }
          return value;
        }
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['#fff']
      },
      menu: {
        show:false,
      },
      tooltip: {
        show:false,
        shared: true,
        intersect: false
      },
      // title:{
      //     align:'center',
      //     text:'לחץ',
      //     style:{
      //        fontSize: '14px',
      //        fontFamily: 'Helvetica, Arial',
      //     }
      // },
      xaxis: {
        categories: xcategories,
        labels: {
          show:true
        },
        min:0,
        max:4,
        title:{
          text:'ממוצע תחושות לחץ',
          fontFamily: 'Helvetica, Arial',
          fontSize: '14px',
        }
      },
      yaxis: {
        show:true,
        labels: {
          offsetX: -100
        },
        //reversed: true,
      },    },

  }


//var allStats = ref(stats_array([g_in_out_0.value,g_in_out_1.value]));

</script>
<template>
  <v-form>
    <v-card id="container_card" class="ma-4">
      <v-card-text ref="templateContainer" class="py-1 mx-3 d-flex flex-column flex-grow-1 overflow-y-auto"  style="overscroll-behavior: contain;max-height:calc(100vh - 170px);">
        <template v-if="formPhase==0">
          <v-container class="d-flex flex-column flex-grow-1 px-0 py-0 pt-1 ma-0">
            <div>
              <!--
              {{internalLog}}
              <v-btn @click="internalLog.addToLog('hello ' + Date.now());">addToLog</v-btn>
              -->
              <template v-if="nextReportingWindowIndex==-1">
                    {{$t('view.sheelon2.phase0.thanksForParticipating')}}<br/><br/>
                    <div class="text-center">
                      <v-btn to="/final-feedback">{{$t('view.sheelon2.phase0.showReports')}}</v-btn>
                    </div>
              </template>
              <template v-else>
                <template v-if="nextReportingWindowIndex==0"> <!--also implies there is no Current window-->
                  {{$t('view.sheelon2.phase0.thanksForJoining')}}<br/>
                  {{$t('view.sheelon2.phase0.thrilledYourWithUs')}}<br/>
                  <div v-if="isJoinDate">
                      {{$t('view.sheelon2.phase0.youStartTommorow')}}<br/>
                  </div>
                </template>
                <template v-if="currentReportingWindowIndex>-1 && !submitedCurrentReport">
                    {{$t('view.sheelon2.phase0.goodToSeeYouAgain')}}<br/>
                    {{$t('view.sheelon2.phase0.readyLetsGo')}}<br/>
                </template>
                <template v-else>
                  <template v-if="submitedCurrentOrPreviousReport">
                      <div v-if="submitedCurrentReport" class="text-center">
                         <!--img :src="require('@/assets/clappingHands.webm')" /-->
                         <video :src="clappingHands" autoplay muted loop playsinline>
                          <!-- It's good practice to specify a type for source elements -->
                          <source :src="clappingHands" type="video/webm">
                          <!--Your browser does not support the video tag.-->
                        </video>
                      </div>
                      {{$t('view.sheelon2.phase0.thanksForYouRecentReport')}}<br/>
                  </template>
                  <template v-else-if="missedPreviousReport && !submitedCurrentReport">
                      {{$t('view.sheelon2.phase0.oopsYouMissed')}}<br/>
                  </template>
                  <div>
                      {{ $t('view.sheelon2.phase0.nextReportWillBeAvailable',
                            {hour:nextReportWindowHour}
                          )
                      }}<br/>

                      <SuggestPushNotifications v-model="userWantsPushNotifications">
                        <template v-slot:label>{{$t('view.sheelon2.phase0.reminders.display_reminder_notifications')}}</template>
                        <template v-slot:explainPermissions>{{$t('view.sheelon2.phase0.reminders.explain_permissions')}}</template>
                        <template v-slot:permissionsDenied>{{$t('view.sheelon2.phase0.reminders.permissions_denied')}}</template>
                      </SuggestPushNotifications>
                  </div>
                </template>
              </template>
            </div>
          </v-container>
        </template>
        <template v-if="formPhase==1" >
          <v-container class="d-flex flex-column flex-grow-1 px-0 py-0 pt-1 ma-0">
            <template v-if="gpsService.permission=='prompt' && !gpsService.error" >
              <p class="mb-2 ext-subtitle-1 text-h6">{{$t('view.sheelon2.locationPermissions.title')}}</p>
              <div class="text-start px-6" >
                <template v-for="n in 2" v-bind:key="n">
                  <p class="mb-2" v-html="$t('view.sheelon2.locationPermissions.para' + n)"></p>
                </template>
                <div class="text-center">
                  <v-btn v-ripple=true variant="flat" class="ma-2 ms-6"
                    @click="reqGeoLocationPermission()">{{$t('general.buttons.next')}}
                  </v-btn>
                </div>
              </div>
            </template>
            <template v-else-if="gpsService.permission=='denied'">
              <p class="mb-2 ext-subtitle-1 text-h6">{{$t('view.sheelon2.locationPermissions.title')}}</p>
              <div class="text-start px-6" >
                <p class="mb-2" v-html="$t('view.sheelon2.locationPermissions.para1')"></p>
                <p class="mb-2" v-html="$t('view.sheelon2.locationPermissions.denied')"></p>
              </div>
            </template>
            <template v-else>
              <v-sheet class="flex-grow-1" color="transparent"></v-sheet>
              <v-select :modelValue="form.in_out" persistent-hint class="mb-4"
                @update:model-value="form.in_out=$event; if ($event==1) {form.location=null}"
                xxplaceholder="$t('view.sheelon2.Qs.in_out.label')"
                xxint="$t('view.sheelon2.Qs.in_out.label')"
                :label="$t('view.sheelon2.Qs.in_out.label')"
                :items="$tm('view.sheelon2.Qs.in_out.items')"
              ></v-select>

              <v-select v-model="form.company" persistent-hint class="mb-4"
                :label="$t('view.sheelon2.Qs.company.label')"
                :items="$tm('view.sheelon2.Qs.company.items')"
              ></v-select>

              <v-select v-model="form.activity" persistent-hint class="mb-4"
                xxmenu-props="{ maxHeight: '480' }"
                :label="$t('view.sheelon2.Qs.activity.label')"
                :items="$tm('view.sheelon2.Qs.activity.items')"
              ></v-select>

              <!-- TODO: Q's from here need to be visible if form.in_out = 1 i.e. outside -->

              <v-select v-model="form.location" v-if="form.in_out == 1 /*out*/"

                :label="$t('view.sheelon2.Qs.location.label')"
                :items="$tm('view.sheelon2.Qs.location.items')">
              </v-select>

              <div v-if="gpsService.error">{{ gpsService.error?.message }} ({{gpsService.error?.code}})</div>

            </template>
        </v-container>
        </template>
        <template v-if="formPhase==2"> <!-- near by-->
          <v-container class="d-flex flex-column flex-grow-1 px-0 py-0 pt-1 ma-0">
            <p class="mb-2" v-html="$t('view.sheelon2.nearBy').replace('.  ','.<br/>')"></p>

            <v-select v-model="form.trees"
              :label="$t('view.sheelon2.Qs.trees.label')"
              :items="$tm('view.sheelon2.Qs.trees.items')">
            </v-select>

            <v-select v-model="form.bushes"
              :label="$t('view.sheelon2.Qs.bushes.label')"
              :items="$tm('view.sheelon2.Qs.bushes.items')">
            </v-select>

            <v-select v-model="form.carsp"
              :label="$t('view.sheelon2.Qs.carsp.label')"
              :items="$tm('view.sheelon2.Qs.carsp.items')">
            </v-select>

            <v-select v-model="form.carsd"
              :label="$t('view.sheelon2.Qs.carsd.label')"
              :items="$tm('view.sheelon2.Qs.carsd.items')">
            </v-select>

            <v-select v-model="form.grass"
              :label="$t('view.sheelon2.Qs.grass.label')"
              :items="$tm('view.sheelon2.Qs.grass.items')">
            </v-select>

            <v-select v-model="form.birds"
              :label="$t('view.sheelon2.Qs.birds.label')"
              :items="$tm('view.sheelon2.Qs.birds.items')">
            </v-select>
          </v-container>
        </template>
        <template v-if="formPhase==3"> <!-- at the distance -->
          <v-container class="d-flex flex-column flex-grow-1 px-0 py-0 pt-1 ma-0">
            <p class="mb-2" v-html="$t('view.sheelon2.atTheDistance').replace('.  ','.<br/>')"></p>

            <v-select v-model="form.blue"
              :label="$t('view.sheelon2.Qs.blue.label')"
              :items="$tm('view.sheelon2.Qs.blue.items')">
            </v-select>

            <v-select v-model="form.green"
              :label="$t('view.sheelon2.Qs.green.label')"
              :items="$tm('view.sheelon2.Qs.green.items')">
            </v-select>
          </v-container>
        </template>
        <template v-if="formPhase==4"> <!--stai -->
          <v-container class="d-flex flex-column flex-grow-1 px-0 py-0 pt-1 ma-0">
            <!--div class="d-flex flex-column flex-grow-1"-->
              <div class="mb-2">{{ $t('view.sheelon2.Qscales.stai_1_4' )}}</div>
              <div>
                <v-card :key="Q.key" v-for="(Q,i) in staiQs" class="pt-0 pb-0 mt-0 mb-2" variant="tonal" elevation="3">
                    <v-card-text class="py-0"> <b> {{ $t('view.sheelon2.Qs.stai_' + (i+1) + '.label')}}</b></v-card-text>
                    <v-card-text class="py-0 my-0 xoverflow-y-hide" xstyle="max-height:36px">
                      <IMZSlider class="mt-n2 mx-1 ps-3" :name="'slider_stai_' +i " v-model="form[Q.key]" :min="1" :max="4" :step="1"></IMZSlider>
                    </v-card-text>
                </v-card>
              </div>
            <!--/div-->
          </v-container>
        </template>
        <template v-if="formPhase==5"> <!--panas -->
          <v-container class="px-0 py-0 pt-1 ma-0">
              <div class="d-flex flex-column flex-grow-1">
                <div class="mb-2">{{ $t('view.sheelon2.Qscales.panas_1_5' )}}</div>
                <div>
                  <v-card :key="Q.key" v-for="(Q,i) in panasQs" class="pt-0 pb-0 mt-0 mb-3" variant="tonal" elevation="3">
                      <v-card-text class="py-0"><b> {{ $t('view.sheelon2.Qs.panas_' + (i+1) + '.label') }}</b></v-card-text>
                      <v-card-text class="py-0 my-0 overflow-y-hide" xstyle="max-height:36px">
                        <IMZSlider class="mt-n2 mx-1 ps-3" :name="'slider_panas_' +i " v-model="form[Q.key]" :min="1" :max="5" :step="1"></IMZSlider>
                      </v-card-text>
                  </v-card>
                </div>
              </div>
          </v-container>
        </template>
        <template v-if="formPhase==6"> <!--Audio-->
          <v-container>
            <p class="mb-2" v-html="$t('view.sheelon2.recording_prompt_v2').replace('.  ','.<br/>')"></p>
            <AudioRecorder ref="audioRecorderRef" v-model:audioUrl="audioUrl" v-model:rms="form.audio_rms" v-model:dB="form.audio_dB" >
              <template v-slot:start>
                  {{ $t('view.sheelon2.start_recording') }}
              </template>
              <!--template v-slot:stop>
                  {{ $t('view.sheelon2.stop_recording') }}
              </template-->
              <template v-slot:audioRecorded>
                {{ $t('view.sheelon2.recording_has_ended') }}
              </template>
              <template v-slot:permissions>{{ $t('view.sheelon2.microphone_permissions_are_needed') }}</template>
            </AudioRecorder><br/><br/><br/>

            <template v-if="form.audio_dB!=null">
                <v-select v-model="form.sound"
                  :label="$t('view.sheelon2.Qs.sound.label')"
                  :items="$tm('view.sheelon2.Qs.sound.items')">
                </v-select>
            </template>

          </v-container>
        </template>
        <template v-if="formPhase==7"> <!--Camera pos-->
          <v-container>
            <p class="text-center mb-6" v-html="$t('view.sheelon2.photo_pos_prompt').replace('.  ','.<br/>')"></p>
            <PhotoBooth v-model:capturedImageDataUrl="photos.pos_photo">
              <template v-slot:permissions>{{ $t('view.sheelon2.camera_permissions_are_needed') }}</template>
              <template v-slot:openCamera>{{ $t('view.sheelon2.open_camera') }}</template>
              <template v-slot:takePicture>{{ $t('view.sheelon2.take_picture') }}</template>
              <template v-slot:removeImage>{{ $t('view.sheelon2.dont_use_this_image') }}</template>
            </PhotoBooth>
          </v-container>
        </template>
        <template v-if="formPhase==8"> <!--Camera neg-->
          <v-container>
            <p class="mb-6" v-html="$t('view.sheelon2.photo_neg_prompt').replace('.  ','.<br/>')"></p>
            <PhotoBooth v-model:capturedImageDataUrl="photos.neg_photo">
              <template v-slot:permissions>{{ $t('view.sheelon2.camera_permissions_are_needed') }}</template>
              <template v-slot:openCamera>{{ $t('view.sheelon2.open_camera') }}</template>
              <template v-slot:takePicture>{{ $t('view.sheelon2.take_picture') }}</template>
              <template v-slot:removeImage>{{ $t('view.sheelon2.dont_use_this_image') }}</template>
            </PhotoBooth>
          </v-container>
        </template>
        <template v-if="formPhase==9"> <!--done -->
          <v-container>
              <p class="mb-2">
                {{ $t('view.sheelon2.form_filled_press_send')}}
              </p>
          </v-container>
        </template>
      </v-card-text>
      <v-spacer></v-spacer>
      <v-divider v-if="!(formPhase==0 && ((nextReportingWindowIndex==-1 && currentReportingWindowIndex==-1) || nextReportingWindowIndex==0))"></v-divider >

      <v-card-actions class="d-flex flex-row justify-space-between pa-1" v-if="!(formPhase==0 && ((nextReportingWindowIndex==-1 && currentReportingWindowIndex==-1) || nextReportingWindowIndex==0))">
        <v-btn :disabled="!prevIsEnabled" :style="{ visibility: (formPhase>1) ? 'visible' : 'hidden' }"
                v-ripple=true variant="flat" class="ma-2 ms-6" @click="onPrev">{{$t('general.buttons.previous')}}
        </v-btn>
        <div v-if="timeLeftToSubmitCurrentReport>0 && timeLeftToSubmitCurrentReport<120">{{ $t('view.sheelon2.phase0.timeLeft',{time:timeLeftToSubmitCurrentReport}) }}</div>
          <!-- 'flat' | 'text' | 'elevated' | 'tonal' | 'outlined' | 'plain'-->
          <!--v-btn @click="submitForm">Submit</v-btn-->
        <v-btn v-if="formPhase==9"
                v-ripple=true variant="flat" class="ma-2 ms-6" @click="onSubmit();">{{$t('general.buttons.sendReport')}}
        </v-btn>
        <v-btn v-if="formPhase<9 && nextIsEnabled" :disabled="!nextIsEnabled"
            v-ripple=true variant="flat" class="ma-2 me-6" @click="onNext">{{$t('general.buttons.next')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script setup>

  // @ is an alias to /src
  import {ref, computed,
    watch,
    inject,
     nextTick, onMounted
  } from 'vue';

  import IMZSlider from '@/components/IMZSlider';
  // Import the video file
  import clappingHands from '@/assets/clappingHands.webm';

  //import {useSlowReactiveTime } from '@/composables/useSlowReactiveTime';
  //const {now:slowCurrentTime} = useSlowReactiveTime(); // Initial current time

  // import { useGeolocationService } from '@/composables/useGeolocationService';
  // const {
  // geolocationPermissionState,
  // coords,
  // locatedAt,
  // geolocationError,
  // //locationServicesState,
  // resumeLocationService,
  // pauseLocationService,
  // reqGeoLocationPermission
  // } = useGeolocationService();

  import { useGpsService } from '@/composables/useGpsService';
  const gpsService= useGpsService();

  if (null==localStorage.getItem('userWantsPusNotifications')) {
    localStorage.setItem('userWantsPusNotifications',JSON.stringify(false));
  }
  const userWantsPushNotifications=ref(JSON.parse(localStorage.getItem('userWantsPusNotifications')));
  watch(userWantsPushNotifications, (newValue) => {
    localStorage.setItem('userWantsPusNotifications',JSON.stringify(newValue));
  });

  import AudioRecorder from '@/components/AudioRecorder.vue'
  import PhotoBooth from '@/components/PhotoBooth.vue';

  import {useTimeUtils} from '@/services/TimeUtils';
  const { enhanceWithLocalTime } = useTimeUtils();

  import { useReportingWindowsManager } from '@/services/ReportingWidnowsManager';
  const {
    nextReportWindowHour,
    currentReportingWindowIndex,
    nextReportingWindowIndex,
    isJoinDate
  } = useReportingWindowsManager();

  const reloadRouterView = inject('reloadRouterView');
  const internalLog = inject('internalLog');
  console.log('internalLog=',internalLog)

  import SuggestPushNotifications from '@/components/SuggestPushNotifications';
  import { useReportsManager } from '@/services/ReportsManager.js'
  const {
    dependsOnLS,
    getReport,
//    getReports,
    addReport,
    timeLeftToSubmitCurrentReport,
    missedPreviousReport,
    submitedCurrentReport,
    submitedCurrentOrPreviousReport
  } = useReportsManager();

  import { useSyncController  } from '@/controllers/SyncController.js'
  const {
    // isParticipantAndSetupSynchronized,
    // markParticipantAndSetupSynchronized,
    // isReportSynchronized,
    // markReportSynchronized,
    tryToSync,
  } = useSyncController()

  // import { useCSAPI } from '@/services/CSAPI';
  // const api = useCSAPI();


  //import { useRouter,useRoute } from 'vue-router';
  // import uuidController from '@/controllers/UUIDController';
  //const router = useRouter();
  //const route = useRoute();

  const templateContainer = ref(null); // This will be used to reference the DOM element
  //const panels=ref([]);

  const staiQs= [
      {key:'stai_1',wasClicked:false},
      {key:'stai_2',wasClicked:false},
      {key:'stai_3',wasClicked:false},
    ];
  const panasQs=[
      {key:'panas_1',wasClicked:false},
      {key:'panas_2',wasClicked:false},
      {key:'panas_3',wasClicked:false},
      {key:'panas_4',wasClicked:false},
      {key:'panas_5',wasClicked:false},
      {key:'panas_6',wasClicked:false},
    ];
  const audioUrl=ref(null);
  const form = ref({
      in_out:null,
      company:null,
      activity:null,
      location:null,
      trees:null,
      bushes:null,
      carsp:null,
      carsd:null,
      grass:null,
      birds:null,
      blue:null,
      green:null,
      stai_1:null,
      stai_2:null,
      stai_3:null,
      panas_1:null,
      panas_2:null,
      panas_3:null,
      panas_4:null,
      panas_5:null,
      panas_6:null,
      audio_rms:null,
      audio_dB:null,
      sound:null,
      longitude:null,
      latitude:null,
      accuracy:null,
      geo_age_minutes:null
  });

  const photos = ref({
    pos_photo:null,
    neg_photo:null
  })

  const isSchoolUser=ref(false);
  const formPhase=ref(0);
  if (formPhase.value!=0) alert('formPhase=ref(' + formPhase.value + ')');

  onMounted(async () => {
    //this.populateFormData();
    isSchoolUser.value=(JSON.parse(localStorage.getItem('formData')).project==1);
    tryToSync(internalLog,false);
    // let puuid=uuidController.getUUID();
    // //make sure everything is in sync
    // console.log('isParticipantAndSetupSynchronized():' , isParticipantAndSetupSynchronized());
    // if (!isParticipantAndSetupSynchronized()) {
    //   console.log('!isParticipantAndSetupSynchronized => calling api.addParticipant ');
    //   let pdata=JSON.parse(localStorage.getItem('formData'));
    //   let sdata=JSON.parse(localStorage.getItem('reportingScheduleSetup'));
    //   try {
    //     await api.addParticipantAndSetup(puuid,pdata,sdata);
    //     markParticipantAndSetupSynchronized(new Date());
    //   }
    //   catch (error) {
    //       console.log('an error occured while calling api.addParticipant')
    //   }
    // }

    // if (isParticipantAndSetupSynchronized()) {
    //   console.log('checking for unSyncronizied Reports');
    //   var reports=getReports();
    //   for (let i=0; i<reports.length ; i++){
    //     if (reports[i] !==null) {
    //       console.log('report ', i , ' exists');
    //       if (!isReportSynchronized(i)) {
    //         try {
    //           console.log('report ', i , ' is not inSync');

    //           let res = await api.addReport(puuid,reports[i]);
    //           console.log('result of callin api.addReport ' , res);
    //           if (res!==undefined) {
    //             console.log('checking there are photos in this report ');
    //             if (reports[i].pos_photo_uuid) {
    //               console.log('report['+i+'] has pos photo:' + reports[i].pos_photo_uuid );
    //               let pos_photo_data=JSON.parse(localStorage.getItem('photo_pos_' + reports[i].pos_photo_uuid));
    //               if (pos_photo_data) {
    //                   console.log('calling add photo for pos photo ' + reports[i].pos_photo_uuid  );
    //                   let res = await api.addPhoto(puuid,reports[i].ruuid,reports[i].pos_photo_uuid,1,pos_photo_data);
    //                   console.log(res);
    //               }//puuid,ruuid,photo_uuid,is_pos,photo_data
    //             }
    //             if (reports[i].neg_photo_uuid) {
    //               console.log('report['+i+'] has neg photo:' + reports[i].neg_photo_uuid );
    //               let neg_photo_data=JSON.parse(localStorage.getItem('photo_neg_' + reports[i].neg_photo_uuid));
    //               if (neg_photo_data) {
    //                   let res = await api.addPhoto(puuid,reports[i].ruuid,reports[i].neg_photo_uuid,0,neg_photo_data);
    //                   console.log(res);
    //               }
    //             }
    //             let res = await api.addReport(puuid,reports[i]);
    //             console.log(res);
    //             markReportSynchronized(i,new Date());
    //           }
    //         }
    //         catch (error) {
    //           console.log('Error sending a report or a photo in sheelon2View' , error);
    //         }
    //       } else {
    //         console.log('report ', i , ' is inSync');
    //       }
    //     }
    //   }
    // }
  });

  const phase0Valid=computed(()=>{
    if (currentReportingWindowIndex.value == -1) return false;
      if (submitedCurrentReport.value) return false;
      return true;
  });

  const phase1Valid=computed(()=>{
    if (null == form.value.in_out) return false;
    if (null == form.value.company) return false;
    if (null == form.value.activity) return false;
    if ((form.value.in_out == 1) && (null == form.value.location)) return false;
    return true;
  });

  const phase2Valid=computed(()=>{
    if (null == form.value.trees) return false;
    if (null == form.value.bushes) return false;
    if (null == form.value.carsp) return false;
    if (null == form.value.carsd) return false;
    if (null == form.value.grass) return false;
    if (null == form.value.birds) return false;
    return true;
  });

  const phase3Valid=computed(()=>{
    if (null == form.value.blue) return false;
    if (null == form.value.green) return false;
      return true;
  });

  const phase4Valid=computed(()=>{
    if (null == form.value.stai_1) return false;
    if (null == form.value.stai_2) return false;
    if (null == form.value.stai_3) return false;
      return true;
  });

  const phase5Valid=computed(()=>{
    if (null == form.value.panas_1) return false;
    if (null == form.value.panas_2) return false;
    if (null == form.value.panas_3) return false;
    if (null == form.value.panas_4) return false;
    if (null == form.value.panas_5) return false;
    if (null == form.value.panas_6) return false;
      return true;
  });

  const audioRecorderRef = ref(null); // template ref
  const phase6Valid=computed(()=>{
    if (null !== audioRecorderRef.value) {
      return !audioRecorderRef.value.isRecording;
    }
    return true;
  });

  const nextIsEnabled=computed(()=> {
    if (formPhase.value==0) { return phase0Valid.value; }
    if (formPhase.value==1) { return phase1Valid.value; }
    if (formPhase.value==2) { return phase2Valid.value; }
    if (formPhase.value==3) { return phase3Valid.value; }
    if (formPhase.value==4) { return phase4Valid.value; }
    if (formPhase.value==5) { return phase5Valid.value; }
    if (formPhase.value==6) { return phase6Valid.value; } // audio
    // all phases after 5 are optional
    //if (formPhase.value >5) { return true;}
    return true;
  });

  const prevIsEnabled=computed(()=>{
    if (formPhase.value>1) return true;
    return false;
  });

  const ScrollToTop=(el)=> {
    nextTick(() => {
      if (el && el.$el) { // For Vuetify components
        el.$el.scrollTop = 0;
      } else if (el) {
        el.scrollTop = 0; // For native HTML elements
      }
    });
  };

  const onNext=()=>{
    if (formPhase.value==2) {
        if (gpsService.permission=='granted') { // if we are on prompt or denied it will be handled by the the content of phase 1.
          gpsService.resume(); // turn GPS on again if we are here after it was turned off on form submission
        }
    }
    if (formPhase.value==6) { // when next clicked on audio phase
      //video is only for comunity and not schools.
      if (isSchoolUser.value) {
        formPhase.value=9;
        ScrollToTop(templateContainer.value);
        return;
      }
    }
    if (formPhase.value<9) {
      formPhase.value++;
      ScrollToTop(templateContainer.value);
      return;
    }
  };

  const onPrev=()=> {
    if (formPhase.value==9) { // when next clicked on final phase
      //video is only for comunity and not schools so skip back to audio
      if (isSchoolUser.value) {
        formPhase.value=6;
        ScrollToTop(templateContainer.value);
        return;
      }
    }
    if (formPhase.value>1) {
      formPhase.value--;
      ScrollToTop(templateContainer.value);
      return;
    }
  };


  const onSubmit= ()=>{
    console.log('onSubmit Pressed');
    internalLog.addToLog('onSubmit Pressed');
    try {

      internalLog.addToLog('adding coordinates to form');
      internalLog.addToLog(form);

      internalLog.addToLog('gpsService.latitude');
      internalLog.addToLog(gpsService.latitude);
      internalLog.addToLog('gpsService.longitude');
      internalLog.addToLog(gpsService.longitude);
      internalLog.addToLog('gpsService.accuracy');
      internalLog.addToLog(gpsService.accuracy);
      internalLog.addToLog('gpsService.locatedAt');
      internalLog.addToLog(gpsService.locatedAt);

      form.value.latitude=gpsService.latitude;
      form.value.longitude=gpsService.longitude;
      form.value.accuracy=gpsService.accuracy;


      internalLog.addToLog('adding geo_age_minutes to form');
      if (null != gpsService.locatedAt) {
        form.value.geo_age_minutes=(new Date().now - gpsService.locatedAt) / 1000.0 / 60.0;
      }
      else {
        form.value.geo_age_minutes=null;
      }

      var ridx=currentReportingWindowIndex.value;
      internalLog.addToLog('current report idx is ' + ridx);
      console.log('report idx' , ridx);

      if (ridx!=-1) {
        internalLog.addToLog('enchancing form with local time');
        enhanceWithLocalTime(form.value);
        if (getReport(ridx,dependsOnLS.value)==null) { // just for consistancy - dependsOnLS does not realy do anything here as no dependancy is needed
          internalLog.addToLog('calling add report');
          addReport(ridx,form.value,photos.value); // adds the report to the local storage.
        }
        else {
          internalLog.addToLog('a report for index ' + ridx + ' already exists');
        }

      }
      //alert('navigating to start');
      // synchronization to the server will be in the next onMount
      internalLog.addToLog('trying to refresh router view');

      internalLog.addToLog('calling pause on GPS');

      gpsService.pause(); // stop the GPS // will turn back on next time we enter formphase==2

      reloadRouterView();
    } catch (error) {
      internalLog.addToLog('an error in on submit');
      internalLog.addToLog(error);
    }

  };

  /*
  const submitForm=()=>{
        localStorage.setItem('formData', JSON.stringify(form));
        console.log("Form data saved to local storage.");
        this.$router.replace('/takePhoto');
  };

  const populateFormData=()=>{
      const storedFormData = localStorage.getItem('formData');
      if (storedFormData) {
          // form = JSON.parse(storedFormData);
      }
  }
  */

</script>

<style scoped>

/* Add your styles here */
#container_card .v-container {
  min-width:calc(100vw - 120px);
}
.v-card ,
  .v-field{
   background-color: rgba(255, 255, 255, 1) !important;
   border-color: white !important;
  }

  .v-card .v-card {
    background-color: transparent !important;
  }

</style>

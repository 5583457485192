<template>
  <v-container dir="ltr" fluid class="pa-0 ma-0">
    Visualizing {{ count }} of {{ array.length }} Array Items.
    <v-row dir="ltr" dense class="pa-0 ma-0">
      <v-col cols="12" class="pa-0 ma-0" v-for="(chunk,chunkidx) in chunkedArray" :key="chunkidx">
        <div class="d-flex flex-wrap">
          <v-sheet
            v-for="(item, index) in chunk"
            :key="index"
            :color="item !== null ? 'green' : 'red'"
            :title="(chunkidx + ',' + index)"
            height="10px"
            width="10px"
            class="ma-1"
          ></v-sheet>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  array: {
    type: Array,
    required: true
  },
  count: {
    type: Number,
    required: true
  }
});

const chunkArray = (array, size) => {
  let result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
};

// Adjust 'numCols' to increase or decrease the number of cells per row
const numCols = 3; // This can be changed to fit more or fewer cells in each row
const chunkedArray = computed(() => {
  return chunkArray(props.array, numCols);
});
</script>

<style scoped>
/* Adjust margins and padding as needed to make the grid denser */
.ma-1 {
  margin: 1px !important;
}
.pa-1 {
  padding: 1px !important;
}
</style>
import { useGlobalCircularLog } from '@/composables/useGlobalCircularLog';
import uuidController from '@/controllers/UUIDController';
import languageController from '@/controllers/LanguageController';
const gLog=useGlobalCircularLog(20);
import { useServiceWorker } from '@/services/useServiceWorker'
const {swLifecycleEvent} = useServiceWorker();


function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

const unSubscribeToPushNotifications = async() => {
  const puuid=uuidController.getUUID();

  await fetch('/api/push/register', {
    method: 'post',
    headers: {
      'Content-type': 'application/json'
    },
    body: JSON.stringify({
      puuid:puuid,
      subscription: null,
      lang:languageController.getLanguage(),
      user_time_zone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone
    }),
  });
}

const subscribeToPushNotifications = async () => {
  //same as the commented block above but using await.
  try {
    const registration = await navigator.serviceWorker.ready;
    gLog.addToLog('ready resolved');
    let subscription = await registration.pushManager.getSubscription();
    gLog.addToLog('getSubscription resolved');
    if (!subscription) {
      gLog.addToLog('no subscription');
      const response = await fetch('/api/push/vapidPublicKey');
      gLog.addToLog('fetched /api/push/vapidPublicKey');
      const vapidPublicKey = await response.text();
      gLog.addToLog('got key');
      const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);
      gLog.addToLog('converted to base64');
      subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: convertedVapidKey
      });
      gLog.addToLog('called subscribe');
      //localStorage.setItem('push_vapidPublicKey',vapidPublicKey);
    }
    gLog.addToLog(JSON.stringify(subscription)); // {endpoint:'...',expirationTime:null,keys:{p256dh:"...",auth:"..."}}
    //localStorage.setItem('push_subscription',JSON.stringify(subscription));
    gLog.addToLog('getting puuid');
    const puuid=uuidController.getUUID();
    gLog.addToLog('puuid:' + puuid);
    if (null != puuid) {
      gLog.addToLog('calling api/push/register');
      await fetch('/api/push/register', {
        method: 'post',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({
          puuid:puuid,
          subscription: subscription,
          lang:languageController.getLanguage(),
          user_time_zone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone
        }),
      });
    }
  } catch (error) {
    gLog.addToLog('lastError:'+error.toString());
    localStorage.setItem('lastError',error.toString());
    swLifecycleEvent("error");
    console.error('Error during service worker registration or push subscription:', error.toString());
  }
  gLog.addToLog('done');
};

export function useSubscribeToPushNotifications() {
  return {
    subscribeToPushNotifications,
    unSubscribeToPushNotifications
  }
}

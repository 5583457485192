// Define the debugging function and attach it to the window object
// window.dbg = (thing) => {
//   // Retrieve the existing debug log from localStorage, or initialize a new array if none exists
//   const debugLog = JSON.parse(localStorage.getItem('dbg')) || [];

//   // Add the new item to the end of the array
//   debugLog.push({ timestamp: Date.now(), message: thing });

//   // Ensure the array doesn't grow beyond 10 items
//   if (debugLog.length > 10) {
//     debugLog.shift(); // Remove the oldest item to keep the length to 10
//   }

//   // Serialize the updated array and store it back in localStorage
//   localStorage.setItem('dbg', JSON.stringify(debugLog));
// };
import {useConditionalAlerts} from '@/composables/useConditionalAlerts';
const { c_alert /* , set_alerts */} = useConditionalAlerts();

window.onerror = function(message, source, lineno, colno, error) {
  // Prepare an error object or message
  const errorDetails = {
    message: message,
    source: source,
    line: lineno,
    column: colno,
    errorStack: error ? error.stack : null,
  };
  // Log the error to the console or send it to a server
  console.error("Caught an unhandled error:", errorDetails);
  // Optionally, use your dbg function to log errors to localStorage
  c_alert(JSON.stringify(errorDetails));
  // Return true to prevent the default handling of the error
  return true;
};

//window.dbg('Init');


import { createApp } from 'vue'
import App from './App.vue'

import './registerServiceWorker'
import router from './router'
//import RedirectStack from './router/RedirectStack'
//RedirectStack.reset();// clean up any previously saved routes
import store from './store'
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18nPlugin';  // i18n is the instance reqired for the app.use statement
                                          // and also for createVueI18nAdapter in the vuetify plugin

// for importing t & friends in components use:
//import {useI18n} from 'vue-i18n'
//const { locale/*, t*/ } = useI18n();

/*
Taken from: https://vue-i18n.intlify.dev/guide/advanced/composition.html

You must call useI18n at top of the setup.
The useI18n returns a Composer instance. The Composer instance provides a translation API such as the t function, as well as properties such as locale and fallbackLocale, just like the VueI18n instance. For more information on the Composer instance, see the API Reference.
In the above example, there are no options for useI18n, so it returns a Composer instance that works with the global scope. As such, it returns a Composer instance that works with the global scope, which means that the localized message referenced by the spread t function here is the one specified in createI18n.
By returning t as render context in the setup, you can use t in the components template:

see also : https://vue-i18n.intlify.dev/api/composition.html#composer

comment by Eyal Peleg
don't forget that locale is reactive so .value is needed when use in script
so locale.value = ....

*/

//import { LoremIpsum } from "lorem-ipsum";
import VueApexCharts from "vue3-apexcharts";

// const LoremIpsum = require("lorem-ipsum").LoremIpsum;
const app = createApp(App)
app.config.devtools = true;

app.use(store).use(router).use(i18n).use(vuetify).use(VueApexCharts)

// app.use((app /*,options*/)=> {  //middleware to add lorem-ipsum support  https://www.npmjs.com/package/lorem-ipsum
//   const lorem = new LoremIpsum({
//     /*format: "html",
//     suffix:'<br/>',
//     sentencesPerParagraph: {
//       max: 4,
//       min: 2
//     },
//     wordsPerSentence: {
//       max: 16,
//       min: 4
//     }*/
//   });
//   app.config.globalProperties.$lorem = lorem;
// })

app.mount('#app');


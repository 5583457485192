// either import specific items or the object that contains both of them
//import { createI18n, useI18n } from 'vue-i18n'
import {createI18n} from 'vue-i18n';

import languageController from '@/controllers/LanguageController';
import {vilages} from './VilagesInIsrael';
import {en} from './lang/en.js'
import {he} from './lang/he.js'
import {ar} from './lang/ar.js'
import {ru} from './lang/ru.js'

//TODO: move messages to a different folder
const messages = {
    en ,
    he,
    ru,
    ar
  };

//Patch messages with vilages in israel
const languages = ['en', 'ru', 'he', 'ar'];
for (const lang of languages) {
      var name_lang='name_' + lang;
      var mapped=vilages.map(record => ({
        value:record.village_id,
        title:record[name_lang],
        search_key:(record['name_en'] + ' ' + record['name_he'] + ' ' + record['name_ru'] + ' ' + record['name_ar']).toLowerCase()
    })).sort((a, b) => a.title.localeCompare(b.title));
    messages[lang]['general']['vilages']=mapped;
}


  // 2. Create i18n instance with options
const i18n = createI18n({
    legacy: false, /// without this RTL is ignored.
    locale: languageController.getLanguage(), // set locale
    fallbackLocale: ['en','he'], // set fallback locale
    //fallbackLocale: ['en'], // set fallback locale
    messages, // set locale messages
    warnHtmlMessage: false
    // If you need to specify other options, you can set other options
    // ...
  })

export default i18n;



<template>
  <template v-if="(!isInstalled) && canTriggerInstallPrompt && (operatingSystem!='Windows')">
      <div class="text-center pa-2"><v-btn color="green" ref="installButton" @click="TriggerDefferedInstallEvent">לחץ כאן להתחלת התקנה </v-btn></div>
  </template><v-expansion-panels v-else-if="!isInstalled">
    <v-expansion-panel>
      <v-expansion-panel-title static :tile="true"> <!--  v-slot="{ expanded  }" -->
        {{$t('install.guidelines')}}
      </v-expansion-panel-title>
      <v-expansion-panel-text class="mx-1" style="background-color:#33cc44;">
          <v-tabs style="min-height:28px;border-bottom:1px solid rgb(var(--v-theme-primary));"
            v-model="activeTab"

            fixed-tabs
            density="compact"
          >
            <v-tab value="Android"  text="Android"></v-tab>
            <v-tab value="iOS" class="px-1" style="min-width:0px;">iOS</v-tab>
            <v-tab value="Windows">Windows</v-tab>
            <v-tab value="Other">Other</v-tab>
          </v-tabs>
          {{$t('install.your_os_is',{operatingSystem})}}<br/>
          <v-window v-model="activeTab" >
              <v-window-item value="Android" class="text-wrap">
                <p v-for="(key) in keysFor('Android')" :key="key">&bullet;&nbsp;{{ $t(key) }}</p>
              </v-window-item>


              <v-window-item value="iOS" class="text-wrap">
                <i18n-t v-for="(key) in keysFor('iOS')" :key="key" :keypath="key" tag="p">
                  <template #share_icon>
                    <v-icon :icon="mdiExportVariant" />
                  </template>
                  <template #add_to_home_screen_icon>
                    <v-icon :icon="mdiPlusBoxOutline" />
                  </template>
                </i18n-t>
              </v-window-item>

              <v-window-item value="Windows" class="text-wrap">
                <p v-for="(key) in keysFor('Windows')" :key="key">&bullet;&nbsp;{{ $t(key) }}</p>
              </v-window-item>

              <v-window-item value="Other" class="text-wrap">
                <i18n-t v-for="(key) in keysFor('Other')" :key="key" :keypath="key" tag="p">
                  <template #userAgent>
                    {{ userAgent }}
                  </template>
                  <template #email>
                    <a href="mailto:intomyzone24@gmail.com"> intomyzone24@gmail.com</a>
                  </template>
                </i18n-t>
              </v-window-item>
          </v-window>
      </v-expansion-panel-text>
    </v-expansion-panel>
</v-expansion-panels>

</template>

<script setup>

//const version=process.env.VUE_APP_VERSION;
import { ref, onMounted } from 'vue';
import { useI18n} from 'vue-i18n'

const { te } = useI18n() // te tests if a Translation Exists

//import faSquareWithArrow from '@vuetify'
//import {mdiClose} from '@mdi/js';
import { mdiExportVariant, mdiPlusBoxOutline } from '@mdi/js';

const operatingSystem = ref('Other');
const activeTab = ref('Other');

import {usePWAIsInstalled} from '@/composables/usePWAIsInstalled'
const { isInstalled /*, setInstalled, resetInstalled*/ } = usePWAIsInstalled();

/*  const isInstalled=ref('unknown');*/
// This variable will save the event for later use.
const deferredInstallPrompt = ref(null);
const deferredInstallPromptOutcome = ref(null);
const canTriggerInstallPrompt = ref(false);
const userAgent=ref('');

const installButton=ref(null);
// Gather the data from your custom install UI event listener

const TriggerDefferedInstallEvent= async ()=>{
  if (deferredInstallPrompt.value==null) {
    console.log('There was no deferredInstallPrompt to trigger');
    return
  }
  else {
    console.log('Triggering deferredInstallPrompt');
  }
  // deferredPrompt is a global variable we've been using in the sample to capture the `beforeinstallevent`
  deferredInstallPrompt.value.prompt();
  // Find out whether the user confirmed the installation or not
  deferredInstallPromptOutcome.value = await deferredInstallPrompt.value.userChoice;
  // The deferredPrompt can only be used once.
  deferredInstallPrompt.value = null;
  // Act on the user's choice
  if (deferredInstallPromptOutcome.value === 'accepted') {
    console.log('User accepted the install prompt.');
  } else if (deferredInstallPromptOutcome.value === 'dismissed') {
    console.log('User dismissed the install prompt');
  }
}

onMounted(() => {

  userAgent.value = window.navigator.userAgent;
  if (/android/i.test(userAgent.value)) {
    operatingSystem.value = 'Android';
  } else if (/iPad|iPhone|iPod/.test(userAgent.value) && !window.MSStream) {
    operatingSystem.value = 'iOS';
  } else if (/Windows NT/.test(userAgent.value)) {
    operatingSystem.value = 'Windows';
  }

  activeTab.value=operatingSystem.value;
/*
  if (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true) {
      isInstalled.value = true;
  }
  else {
    isInstalled.value = false;
  }
*/
  window.addEventListener('beforeinstallprompt', (e) => {
    // Prevents the default mini-infobar or install dialog from appearing on mobile
    e.preventDefault();
    // Save the event because you'll need to trigger it later.
    deferredInstallPrompt.value = e;
    // Show your customized install prompt for your PWA
    // Your own UI doesn't have to be a single element, you
    // can have buttons in different locations, or wait to prompt
    // as part of a critical journey.
    //showInAppInstallPromotion();
    canTriggerInstallPrompt.value = true;
    console.log('canTriggerInstallPrompt');
    //alert('canTriggerInstallPrompt manually set to false');

    //if (operatingSystem.value == 'Windows') canTriggerInstallPrompt.value = false;
  });

});

const keysFor= (os) => {
  let arr=[];
  let i=1;
  while (i<10) {
    let key='install.' + os + '.para_' + i;
    if (te(key)) {
      arr.push(key);
    }

    i++;
  }
  return arr;
};

</script>

<style scoped>
.v-tab {
 text-transform:none;
 padding-inline:2px;
 min-width:0px;
}
</style>

<style>
.v-expansion-panel-text__wrapper {
  font-size:14px;
  padding:8px 8px 16px 8px !important;
  /*padding-left:4px !important;
  padding-right:4px !important;
  padding-inline:4px !important;*/
}
</style>

<template>
  <v-container v-bind="$attrs"  >
    <v-row>Reporting Windows:</v-row>
    <v-row>P:{{previousReportingWindowIndex }}</v-row>
    <v-row>C:{{currentReportingWindowIndex }} submited:{{ submitedCurrentReport }}</v-row>
    <v-row>N:{{nextReportingWindowIndex}} @{{ nextReportWindowHour }} </v-row>
  </v-container>
</template>
<script setup>
  import { useReportingWindowsManager } from '@/services/ReportingWidnowsManager';
  import {useReportsManager} from '@/services/ReportsManager';
  const {
    previousReportingWindowIndex,
    currentReportingWindowIndex,
    nextReportingWindowIndex,
    nextReportWindowHour,
  } = useReportingWindowsManager();
  const {submitedCurrentReport} = useReportsManager();

</script>
<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="6">
        <v-row v-if="cameraPermission=='denied'" >
          <slot name="permissions">To allow taking pictures you need to grant the "camera" permission in your device.</slot>
        </v-row>
        <template v-else>
          <v-row justify="center" class="justify-space-around  mb-6">
            <video :style="{ transform: isFlipped ? 'scaleX(-1)' : '' }" v-show="isCapturing" ref="video" width="320" height="240"></video>
            <canvas v-show="false && !isCapturing && capturedImageDataUrl" ref="canvas" width="320" height="240"></canvas>
            <img v-if="capturedImageDataUrl" :src="capturedImageDataUrl" alt="Captured Image" />
          </v-row>
          <v-row justify="end" class="justify-space-around  mb-6">
            <v-btn v-if="cameras.length>1 && isCapturing" density="default" :icon="mdiCameraFlip" @click="switchCamera"></v-btn>
          </v-row>

          <v-row justify="center" class="justify-space-around  mb-6">
              <v-btn v-if="!isCapturing && !capturedImageDataUrl" @click="startCapturing();toggleCapturing();"><slot name="openCamera">Open Camera</slot></v-btn>
              <v-btn v-if="isCapturing" @click="capturePhoto();toggleCapturing();"><slot name="takePicture">Take Picture</slot></v-btn>
              <v-btn v-if="capturedImageDataUrl" @click="clearCapturedImage();"><slot name="removeImage">Don't use this image</slot></v-btn>
          </v-row>

        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>

  import { ref, computed, /* watch,onMounted*/ } from 'vue';
  import { useToggle } from '@vueuse/core'
  import { mdiCameraFlip } from '@mdi/js';
  import { usePermission } from '@vueuse/core';
  import { inject } from 'vue';
  const cameraPermission = usePermission('camera');

  const internalLog = inject('internalLog'); //{ addToLog, clearLog, logs }

  const [isCapturing, toggleCapturing] = useToggle();
  toggleCapturing(false);

  // Define the events your component can emit
  const props = defineProps({
    capturedImageDataUrl: String
  });

  const emit = defineEmits(['update:audioUrl','update:rms','update:dB'])
  const capturedImageDataUrl = computed({
      get() {
        return props.capturedImageDataUrl;
      },
      set(value) {
        // Emitting update back to parent component
        emit('update:capturedImageDataUrl', value);
      }
    });


  //const capturedImageDataUrl= ref(null); // Property to hold the captured image data URL

  const [isFlipped, toggleFlipping] = useToggle();
  toggleFlipping(false);

  const cameras = ref([]);
  const selectedCameraId = ref(null);
  let stream=null;

  const video=ref(null); // the video player object in the template
  const canvas=ref(null); // the canvas in the template

  const getCameras = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      internalLog.addToLog('Number Of Devices Found:' + devices?.length );
      for (let i=0;i<devices.length;i++) {
        internalLog.addToLog(JSON.stringify(devices[i]));
      }
      cameras.value = devices.filter(device => device.kind === 'videoinput');

      internalLog.addToLog('Number Of Cameras Found:' + cameras.value?.length );
      for (let i=0;i<cameras.value.length;i++) {
        internalLog.addToLog(JSON.stringify(cameras.value[i]));
      }
      if (cameras.value.length > 0) {
        selectedCameraId.value = cameras.value[cameras.value.length-1].deviceId; // Default to the first camera
      }
    } catch (error) {
      console.error('Error accessing media devices:', error);
    }
  };

  const getStream = async () => {
    if (selectedCameraId.value) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { deviceId: { exact: selectedCameraId.value } }
        });
        return stream;
        // video.value.srcObject = stream;
        // video.value.play();
      } catch (error) {
        console.error('Error getting user media:', error);
      }
    }
  };

  const switchCamera = async () => {
    const currentCameraIndex = cameras.value.findIndex(camera => camera.deviceId === selectedCameraId.value);
    if (currentCameraIndex !== -1 && cameras.value.length > 1) {
      const nextCameraIndex = (currentCameraIndex + 1) % cameras.value.length;
      selectedCameraId.value = cameras.value[nextCameraIndex].deviceId;
      stream = await getStream(); // Restart the stream with the new camera
    }
  };

  const startCapturing = (async () => {
    if (cameraPermission.value=='prompt') {
      await navigator.mediaDevices.getUserMedia({ video: true }); // trigger premissions request.
    }
    console.log(cameraPermission.value);
    if (cameraPermission.value!='granted') {
      internalLog.addToLog('Camera permission not granted');
      console.log('Camera permission not granted');
      return;
    }
    if (!navigator.mediaDevices) {
        console.log('No navigator.mediaDevices');
        return;
    }
      await getCameras();
      stream = await getStream();
      video.value.srcObject = stream;
      video.value.play();
      // Wait for the video to start playing
      await new Promise(resolve => video.value.addEventListener('play', resolve));
  });

  const capturePhoto = async () => {
      try {
        // Draw the video frame to the canvas
        if (isFlipped) {
          canvas.value.getContext('2d').drawImage(video.value, 0, 0, 320, 240);
        }
        else {
          canvas.value.getContext('2d').drawImage(video.value, 320, 240, 0, 0);
        }


        // Convert canvas to Data URL
        capturedImageDataUrl.value = canvas.value.toDataURL('image/png');
        //emit('update:ImageDataUrl', capturedImageDataUrl.value);
        //const photoData = this.$refs.canvas.toDataURL('image/png');
        //this.capturedImageDataUrl = photoData;

        // Stop the video stream
        stream.getTracks().forEach(track => track.stop());

        // Store in local storage
        //localStorage.setItem('capturedPhoto', photoData);

        console.log('Photo captured and stored in local storage');

      } catch (err) {
        console.error('Error capturing photo:', err);
      }
  };

const clearCapturedImage = ()=> {
  isCapturing.value=false;
  capturedImageDataUrl.value=null;
  //  emit('update:ImageDataUrl', capturedImageDataUrl.value);

}


</script>

<style>
/* Add your styles here */
</style>

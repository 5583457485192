import { ref, computed } from 'vue';

// those are always either serviceWorkers or null;
const installingSW = ref(null);
const waitingSW = ref(null);
const activeSW = ref(null);
const state = ref("");
const lastError = ref(null);

const isInstalling = computed( () => installingSW.value != null); // is there a service worker installing
const isActive = computed( () => activeSW.value != null);
const isWaiting = computed( () => waitingSW.value != null);

const isUpdateAvailable = computed( () => waitingSW.value != null);

const updateServiceWorkerNow = () => {
  if (process.env.NODE_ENV === 'production') {
    if (navigator && navigator.serviceWorker) {
      navigator.serviceWorker.getRegistration().then(registration => {
        if (registration && registration.waiting) {
          navigator.serviceWorker.addEventListener('controllerchange', () => {
            window.location.reload();
          });
          registration.waiting.postMessage({ type: 'SKIP_WAITING' });
          //updateAvailable.value = false;
          //window.location.reload(); //
        }
      });
    }
  }
};
// see docs in:
// https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration/showNotification

const showNotification = (title,options/*,delay=0*/) => {
  if (process.env.NODE_ENV === 'production') {
    if (navigator && navigator.serviceWorker) {
      //setTimeout(()=>{
        navigator.serviceWorker.getRegistration().then(
            registration => {
            if (registration && registration.active) {

                registration.active.showNotification(title,options);
              }
            }
        )
      //},delay);
    }
  }
}
const swLifecycleEvent= (new_state,error = null)=>{
  if (process.env.NODE_ENV === 'production') {
    state.value=new_state; //ready,registered,cached,updatefound,updated,offline,error
    if (error) {
      lastError.value=error;
    }
    navigator.serviceWorker.getRegistration().then(registration => {
      if (registration) {
        installingSW.value=registration.installing;
        waitingSW.value=registration.waiting;
        activeSW.value=registration.active;
      }
    });
  }
}

export function useServiceWorker() {
  return {
    swLifecycleEvent, // for internal use

    // informational
    isInstalling,
    isWaiting,
    isActive,
    state,
    lastError,

    // actual API
    isUpdateAvailable,
    updateServiceWorkerNow,
    showNotification

  }
}
/* Instructions for translators:
1. Only translate the texts with single quotes  (i.e. alwyas on the right side of :)
2. Do not add,remove or change the order of lines.
3. if a text include within it tags like <b></b> (like para4 of the consentForm ) keep the tags in the translation and
   make sure the segment inside them has the same meaning as in the original.
4. if a text include some variable within {} ( like the reported_so_far key for example)
   it means that it will be replaced with an appropriate replacement -
   keep the {} and their content un translated)
5. In case of doubt of any Q's please call Eyal Peleg : 054-4638212

*/
const he = {
    $vuetify :{
      open:'פתח',
      close:'סגור',
      noDataText:'לא נמצא מידע'
    },
    general: {
      buttons: {
        understood:'הבנתי',
        continue:'המשך',
        next:'הבא',
        previous:'הקודם',
        close:'סגור',
        sendReport:'דיווח'
      }
    },
    app: {
      title:'IntoMyZone.Com', // do not translate the title.
      menu:{
        choose_language:'בחר שפה',
        continue_reporting:'המשך לדווח',
        ongoing_feedback:'משוב ביניים',
        final_feedback:'משוב מסכם',
        contact:'יצירת קשר',
      }
    },
    install: {
          please_install_to_continue:'אנא פעל/י לפי הנחיות ההתקנה שלמעלה.',
          after_install:'בסיום ההתקנה פתח/י את האפליקציה המותקנת להמשך.',
          guidelines:'הנחיות התקנה',
          your_os_is:'סביבת העבודה שלך היא {operatingSystem}.',
          Android:{
            para_1:'במכשירי Android, ודא/י שמותקנת אצלך גירסה עדכנית של Google Chrome.',
            para_2:'השתמש/י ב google chrome ופתח/י את https://app.intomyzone.com',
            para_3:'בתפריט של Google Chrome חפש/י את האפשרות install App',
            para_4:'תתבקש/י לאשר את ביצוע ההתקנה.',
            para_5:'אחרי האישור תוכל/י להוסיף את האפליקציה מרשימת האפליקציות המותקנות אצלך באופן המקובל במכשירך.',
          },
          iOS:{
            para_1:'במכשירי iPhone or iPad, פתח/י את Safari.',
            para_2:'לך/י אל הכתובת https://app.intomyzone.com',
            para_3:'בתחתית המסך לחץ/י על הכפתור {share_icon}.',
            para_4:'מצא/י את ולחץ/י על "Add to home Screen" {add_to_home_screen_icon}',
            para_5:'אשר/י את פרטי האתר ולחץ/י על "Add".',
            para_6:'קישור להפעלת האפליקציה יצורף למסך הבית שלך.',
          },
          Windows: {
            para_1:'אפליקציה זו לא תוכננה לפעול בסביבת Microsoft Windows.',
          },
          Other: {
            para_1:'את/ה משתמש/ת ב: "{userAgent}"',
            para_2:'סביבה זו אינה מוכרת לנו.',
            para_3:'אנא צלמ/י תמונת מסך של מסך זה ושלח/י אותה אלינו לכתובת {email} על מנת שנוכל לסייע.',
          }
    },
    view: {
      generalInfo: {
        title:'מידע כללי',
        head1:'IntoMyZone',
        para1:'IntoMyZone היא אפליקציה שמזמינה אותך להתבונן בסביבתך בעירנות ובהנאה, ולהתמסר לתהליך של מדידת חוויות רגעיות בסביבות עירוניות או טבעיות',
      },
      contact: {
        can_contact_via_email:'ניתן ליצור איתנו קשר בכתובת {email}'
      },
      consentForm: {
        title:'טופס הסכמה מדעת',
        para1:'בקשה להשתתפותך במחקר העוסק בחקירת מערכות הקשרים בין הסביבה הפיסית לבין רווחה נפשית בסביבות עירוניות וטבעיות – IntoMyZone',
        para2:'שלום רב, ',
        para3:'אנו פונים אליך במסגרת מחקר שעוסק בחקירת מערכות הקשרים בין הסביבה הפיסית ובין רווחה נפשית בסביבות עירוניות וטבעיות. המחקר נערך בישראל ומנוהל ע"י צוות חוקרים מהפקולטה לחינוך באוניברסיטת מחיפה. מחקר זה מאושר על ידי ועדת האתיקה של אוניברסיטת חיפה.',
        para4:'<b>מטרת המחקר</b> היא להבין את ההשפעה הרגעית והמצטברת שיש לסביבות שונות על הרווחה הנפשית של האדם. ממצאי המחקר יתרמו להבנה מעמיקה אודות הערך השיקומי שיש לתנאים סביבתיים שונים הן מבחינת קידום הבריאות והן מנקודת מבט אקולוגית עבור הכלל.',
        para5:'לצורך מתן הסכמתך, יש לקרוא את תנאי השימוש ואת כתב ההסכמה שיוצג שלהלן ולסמן את האפשרות "אני מסכימ/ה להשתתף במחקר". בבחירה ב"אני מסכימ/ה להשתתף במחקר" את/ה מאשרת שאת/ה מסכימ/ה להשתתף במחקר. תהיה באפשרותך לגשת למידע המוצג לעיל שוב ואף לגרוע את הסכמתך דרך תפריט ההגדרות של האפליקציה.',
        termsAndConditions: {
          buttonCaption:'הצג תנאי שימוש',
          title:'תנאי שימוש',
          head1:'אופן ההשתתפות.',
          para1:'לצורך ההשתתפות במחקר עליך למלא שאלונים מקוונים באמצעות האפליקציה ‘IntoMyZone’, באתרים שונים לבחירתך. אם תחליט/י להשתתף במחקר, תחילה תתבקש/י לענות על שאלון ראשוני לגבי גיל, מגדר, עיסוק, מקום מגורים, אורח חיים, ומידת הזיקה לטבע. לאחר מכן, האפליקציה תנחה אותך לדווח על מאפייני הסביבה שלך ותחושתיך, 3 פעמים ביום, בתוך תקופה של שבועיים. כמו כן, מיקומך הגיאוגרפי יירשם באמצעות חיישן ה-GPS בטלפון הנייד שלך.',
          head2:'',
          para2:'אנו נבקש את הסכמתך לספק את המיקום הגיאוגרפי כאשר תשתמש/י לראשונה באפליקציה. בנוסף, בתום כל דיווח, תעמוד בפניך אפשרות לצלם משהו בסביבתך שגורם לך להרגשה חיובית ושלילית, ולהקליט את צליל הסביבה שבה את/ה נמצא/ת. השאלון הראשוני לוקח בערך 5 דקות להשלים, והדוחות השוטפים לוקחים בערך 2 דקות להשלים.',
          head3:'',
          para3:'במהלך השבועיים התראות יישלחו לטלפון הנייד שלך בזמנים מוגדרים מראש. לאחר כל התראה, חלון זמנים של שעתיים יעמוד לרשותך לצורך השלמת הדיווח. אם אין באפשרותך לדווח בטווח זה, תוכל/י לדלג על דיווח זה ולהמתין להתראה הבאה. נבקשך להשלים כמה שיותר דיווחים, שכן זה יאפשר קבלת תמונת מצב מהימנה ומקיפה יותר.',
          head4:'סודיות.',
          para4:'ההשתתפות במחקר היא אנונימית לחלוטין. לא תתבקש/י לספק פרטים אישיים ו/או מזהים מכל סוג בשום עת. הנתונים שייאספו ישמשו למטרות מחקר בלבד וידווחו בצורה מצרפית (לא ידווחו נתונים בודדים). תגובותיך לשאלונים יאוחסנו ללא הגבלת זמן ויאובטחו במחשבים מוגני סיסמה, או על גבי דיסקים נפרדים. התצלומים וקטעי האודיו ינותחו למטרות מחקר בלבד ויתכן שיוצגו במדיה חברתית וישמשו לתערוכות אמנות דיגיטלית לקידום הפרויקט.',
          head5:'ניתוח הנתונים.',
          para5:'הנתונים שייאספו עשויים להיות מוצגים כדו"חות מדעיים ולהתפרסם בכתבי עת מדעיים. בנוסף, הנתונים עשויים להיות זמינים לחוקרים אחרים ברוח תנועת המדע הפתוח. כל מידע אשר יוצג בדוחות או בפרסומים אפשריים יהיה אנונימי לחלוטין ובלתי ניתן לזיהוי.',
          head6:'התועלת מהשתתפות במחקר.',
          para6:'להשתתפות במחקר תועלת אישית וציבורית. ברמה האישית, אצל משתתף/ת שימלא מעל 20 דיווחים לאורך תקופת ההשתתפות יתקבל בסוף התקופה דוח אישי שמתאר את האופן בו סביבות שונות קשורות למצבים רגשיים שונים אצלו. דוח זה יוכל לשמש לתכנון אישי לפיתוח מצבים התורמים לרווחה נפשית. ברמה הציבורית, ממצאי המחקר יתרמו להבנה מעמיקה אודות הערך השיקומי שיש לתנאים סביבתיים שונים לקידום הבריאות הנפשית והגופנית ובכך יתרמו לכלל האוכלוסיה. בנוסף, בהתבסס על הממצאים ניתן יהיה להמליץ על תכנון עירוני מאוזן ומועיל עבור תושבים בעתיד.',
          head7:'מידע נוסף.',
          para7:'במידה והינך מעוניינ/ת לקבל מידע נוסף אודות המחקר אנו מזמינים אותך ליצור קשר עם צוות המחקר בכתובת המייל: intomyzone24@gmail.com.',
          head8:'',
          para8:'נודה לך מאוד אם תסכימ/י להשתתף במחקר. חשוב לציין כי ההשתתפות תתאפשר רק אם תינתן על ידך הסכמה. הסכמה זו איננה מחייבת ותוכל/י להפסיק את השתתפותך במחקר בכל עת ללא כל פגיעה בך. כמו כן, באפשרותך למחוק נתונים שייאספו דרך תפריט ההגדרות של האפליקציה בלחיצה על &apos;מחיקת כל הרישומים&apos;. בנוסף, הינך רשאי/ת למחוק את האפליקציה בכל עת. עם זאת, לאחר מכן, לא תוכל/י למחוק את הרישומים ולא תוכל/י לחזור מהסכמתך להשתתף במחקר. זאת מכיוון שאיננו יכולים לזהות רישומים פרטניים שכן אנו לא אוספים שום מידע מזהה. ',

          },
        bullet1:'אני מצהיר/ה בזה כי אני מסכימ/ה להשתתף במחקר בנושא בחקירת מערכות הקשרים בין הסביבה הפיסית ובין רווחה נפשית בסביבות עירוניות וטבעיות.',
        bullet2:'ידוע לי שאני חופשי/ה לבחור שלא להשתתף במחקר ואני חופשי/ה להפסיק בכל עת את השתתפותי, מבלי לפגוע בזכויותיי, מבלי שיאונה לי כל רע, ומבלי שתינקט נגדי סנקציה כלשהי.',
        bullet3:'ידוע לי שמובטחת לי סודיות באשר לזהותי האישית בכל שלב במחקר, כולל פרסומים מדעיים. ',
        bullet4:'אני מצהיר/ה בזה כי את הסכמתי נתתי מרצוני החופשי וכי הבנתי את כל האמור לעיל.',
        bullet5:'בכל שאלות לגבי המחקר ניתן לפנות אל צוות המחקר בכתובת המייל: intomyzone24@gmail.com.',
        iagree:'אני מסכים/ה להשתתף במחקר'
      },
      sheelon1:{
        title:'מידע סטטיסטי',
        intro:'לשם ניתוח סטטיסטי נבקשך לענות על מספר שאלות שלהלן לפני תחילת השימוש באפליקציה.  כל המידע הוא לצורכי מחקר בלבד, ובכל מקרה לא נאסף מידע פרטי כגון שמות המשתתפים.',
        NR_title:'באיזו מידה את/ה מסכימ/ה עם האמירות הבאות?',
        PA_title:'לפניך היגדים שמתארים את הקשר למקום מגורים. אנא השב/י בהתייחס למקום שאת/ה מתגוררת בו כעת. באיזו מידה הינך מסכימ/ה עם ההיגדים הבאים (1 = \'מאוד לא מסכים/ה\' עד 5 = \'מסכים/ה מאוד\')?',
        WHO_title:'בהתיחס לשבועיים האחרונים, באיזו תדירות הרגשתך תאמה את כל אחד מחמשת ההיגדים הבאים:',
        agreeScaleText:'(1 = מאוד לא מסכים/ה עד 5 = מסכים/ה מאוד)',
        satisfactionTexts:[
          'ממש לא מסכים','לא מסכים','אדיש','מסכים','ממש מסכים'
        ],
        itemSets:{
          ProjectItems:[
            {value:0, title:'ללא שייכות לפרוייקט מסויים'},
            {value:1, title:'במסגרת פרוייקט בית ספרי'},
            {value:2, title:'במסגרת פרוייקט קהילתי'},
          ],


          YesNoItems:[
            {value:1, title:'כן'},
            {value:0, title:'לא'},
          ],
          NoYesItems:[
            {value:0, title:'לא'},
            {value:1, title:'כן'}
          ],
          residence:[
            {value:1, title:'בעיר'},
            {value:2, title:'בישוב עירוני עם פחות מ-20,000 איש'},
            {value:3, title:'באיזור כפרי (למשל קיבוץ)'},
            {value:4, title:'במספר מקומות'},
            {value:5, title:'אחר'},
          ],
          house:[
            {value:10 , title:'בית פרטי' ,hasFloors:false},
            {value:20 , title:'דירה בבנין' , hasFloors:true},
            {value:30 , title:'מלון' , hasFloors:true},
            {value:40 , title:'מעון סטודנט' , hasFloors:true },
            {value:90 , title:'אף אחד מהאמור לעיל' ,hasFloors:false}
          ]
      },
      Qs: {
          //phase 1
          project:
            {
              label:'השתתפותך במחקר היא:',
              //items: was @:view.sheelon1.itemSets.YesNoItems
              // changed to @:view.sheelon1.itemSets.ProjectItems
            },
          schoolCode:{
            label_1:'הכנס/י קוד בית ספר בן 6 ספרות',
            label_2:'הכנס/י קוד קהילה בן 6 ספרות',
            errors: {
              schoolCodeRuleOutOfRange_1:'קוד בית ספר חייב להיות בן 6 ספרות',
              schoolCodeRuleOutOfRange_2:'קוד קהילה חייב להיות בן 6 ספרות'
            }
          },
          //phase 2
          age: {
            label:'מה גילך?',
            errors:{
              ageRuleOutOfRange:'גיל חייב להיות בין 6 ל 99'
            }
          },
          gender:
          {
            label:'מה המיגדר שלך?',
            items:[
              {value:1, title:'נקבה'},
              {value:0, title:'זכר'},
              {value:2, title:'לא בינארי'},
              {value:3, title:'לא רוצה לומר'},
            ]
          },
          israel:{
            label: "האם את/ה יליד הארץ?",
            //items: @:view.sheelon1.itemSets.NoYesItems
          },

          evacuated:{
            label: "בעקבות המלחמה שונה מקום מגורי:",
            //items: @:view.sheelon1.itemSets.NoYesItems

          },
          residenceChildhood:{
            label: "באיזה סוג ישוב גדלת?",
          },

          residenceName:{
            label: "מה מקום המגורים הקבוע שלך עכשיו?",
            label_evacuated: "מה מקום המגורים בו גרת לפני הפינוי?",
            //list of vilages is injected automatically
            errors:{
              noDataText:'לא נמצא ישוב כזה'
            }
          },
          //phase 3
          residencePermanent:{
            label:'מה סוג הישוב שאת/ה גר/ה בו באופן קבוע (ביתך הקבוע)?'
          },
          residenceBefore:{
            label:'מה סוג הישוב שבו גרת לפני הפינוי (ביתך הקבוע)?'
          },
          residenceAfter:{
            label:'מה סוג הישוב שאת/ה גר/ה בו היום?'
          },

          houseNow:{
            label: "באיזה סוג מגורים את/ה גר/ה כיום?",
          },
          houseBefore:{
            label: "מהו סוג הבית שבו התגוררת לפני המעבר?",
          },
          houseAfter:{
            label: "מהו סוג הבית שהינך מתגורר/ת בו היום?",
          },
          floor: { // used for all house* Q's where the  hasFloors is true for the selected answer
            label: "באיזו קומה?"
          },

          educationLevel:{
            label: "רמת השכלה",
            items:[
              {value:1, title:'חטיבת ביניים'},
              {value:2, title:'תיכונית ללא תעודת בגרות'},
              {value:3, title:'תיכונית עם תעודת בגרות'},
              {value:4, title:'על תיכונית מקצועית'},
              {value:5, title:'תואר ראשון'},
              {value:6, title:'תואר שני ומעלה'},
            ]
          },

          //phase 3
          NR_1:{label: "מקום החופשה האידיאלי עבורי הוא מקום רחוק בטבע"},
          NR_2:{label:'אני תמיד חושב/ת על האופן שבו הפעולות שלי משפיעות על הסביבה',},
          NR_3:{label:'הקשר שלי לטבע ולסביבה הוא חלק מהרוחניות שלי',},
          NR_4:{label:'אני שמ/ה לב לטבע בכל מקום שאני נמצא/ת',},
          NR_5:{label:'היחס שלי לטבע הוא חלק חשוב ממי שאני ',},
          NR_6:{label:'אני מרגיש/ה מאוד מחובר/ת לכל היצורים החיים ולכדור הארץ',},

          //phase 4
          PA_nature1:{label:'אני קשור/ה לטבע שנמצא במקום הזה'},
          PA_nature2:{label:'כשאני נמצא/ת רחוק מכאן אני מתגעגע/ת לטבע שכאן'},
          PA_nature3:{label:'הטבע שבמקום הזה גורם לי לתחושת שייכות למקום הזה'},
          PA_nature4:{label:'הטבע שנמצא במקום הזה משקף את מי שאני'},
          PA_people1:{label:'אני חש/ה קשורה לא.נשים במקום הזה'},
          PA_people2:{label:'כשאני נמצא/ת רחוק מכאן אני מתגעגע/ת לא.נשים שכאן'},
          PA_people3:{label:'הא.נשים שכאן גורמ/ים לי לתחושת שייכות למקום הזה'},
          PA_people4:{label:'הקשרים שיש לי עם אנשים במקום הזה, גורמים לי להרגיש שייכ/ת אליו'},
          PA_identity1:{label:'המקום הזה משקף את מי שאני'},
          PA_identity2:{label:'אני חש/ה קשר רגשי למקום הזה'},
          PA_identity3:{label:'אני חש/ה שאני שייכת למקום הזה'},
          PA_identity4:{label:'כשאני נמצא/ת רחוק אני חשה געגועים למקום הזה'},

          //phase 5
          WHO_1:{label:'הרגשתי עליז/ה וברוח טובה'},
          WHO_2:{label:'הרגשתי רגוע/ה ונינוח/ה'},
          WHO_3:{label:'הרגשתי פעיל/ה ונמרץ/צת'},
          WHO_4:{label:'התעוררתי בהרגשה רעננה'},
          WHO_5:{label:'חיי היומיום שלי מלאים בדברים שמעניינים אותי'},

          WHO_Headers_5: 'כל הזמן',
          WHO_Headers_4: 'רוב הזמן',
          WHO_Headers_3: 'יותר ממחצית הזמן',
          WHO_Headers_2: 'פחות ממחצית הזמן',
          WHO_Headers_1: 'חלק מהזמן',
          WHO_Headers_0: 'באף זמן שהוא'
        }
      },
      sheelon2:{
          phase0: {
            goodToSeeYouAgain:'טוב לראות אותך שוב.',
            readyLetsGo:'מוכן/ה למילוי של דיווח חדש? קדימה, נצא לדרך!',
            thanksForJoining:'תודה שהצטרפת אלינו.',
            thrilledYourWithUs:'מתרגשים שאת/ה איתנו.',
            youStartTommorow:'הדיווחים שלך יחלו מחר.',
            thanksForYouRecentReport:'תודה על הדיווח הקודם שמסרת.',
            oopsYouMissed:'אוופס, פספסת את חלון הדיווח הקודם',
            nextReportWillBeAvailable:'הדיווח הבא יהיה פתוח בפניך בשעה {hour}',
            thanksForParticipating:'תקופת הדיווחים הסתיימה, תודה על השתתפותך בפרוייקט,',
            //youCanSeeYourReports:'תוכל לראות את הדוחות המתארים את הדיווחים שלך כאן.',
            showReports:'למשוב מסכם',
            timeLeft:'זמן נותר: {time} דקות',
            reminders:{
              display_reminder_notifications:'מאשר/ת הצגת תזכורות לדיווחים',
              explain_permissions:'בחירה בהצגת תזכורות תציג בקשה למתן הרשאות מתאימות.',
              permission_denied:'סירבת לתת את ההרשאה ולכן לא יוצגו הודעות. יש לתת את ההרשאות המתאימות במכשיר שלך.',
            }
          },

          agreeExtent:'באיזו מידה את/ה מסכימ/ה עם האמירות הבאות?',

          nearBy:'כרגע, באזור הקרוב אלי:',
          atTheDistance:'בנוף הרחוק (מעל כ-100 מטרים)',

          microphone_permissions_are_needed:'על מנת לאפשר הקלטת אודיו יש לתת את ההרשאות המתאימות במכשיר שלך',
          recording_prompt_v2:'כעת נבקשך להקליט 30 שניות של הקולות שמסביבך. (התיעוד שישמר הוא של עוצמת הקול בלבד)',
          start_recording:'התחל הקלטה',
          stop_recording:'סיים הקלטה',
          recording_has_ended:'תודה, ההקלטה הסתיימה',

          camera_permissions_are_needed:'על מנת לאפשר צילום תמונות יש לתת את ההרשאות המתאימות במכשיר שלך',
          photo_pos_prompt:'צלמ/י בבקשה תמונה של משהו שאת/ה רואה סביבך שגורם לך להרגשה חיובית. נא לא לצלם אנשים.',
          photo_neg_prompt:'צלם/י בבקשה תמונה של משהו שאת/ה רואה סביבך שגורם לך להרגשה שלילית. נא לא לצלם אנשים.',
          open_camera:'הפעלת המצלמה',
          take_picture:'צילום',
          dont_use_this_image:'לא לשמור תמונה זו',

          form_filled_press_send:'סיימת למלא את הטופס. לחץ/י על הכפתור "דיווח" למטה להגשת הדיווח',

          Qscales: {
            stai_1_4:"כעת, הייתי מתאר/ת את מצבי כ- (בין 1='כלל לא' ל-4='מאוד')",
            panas_1_5:"כעת, אני מרגיש/ה (בין 1='כלל לא' ל-5='מאוד')"
          },
          Qs: {
            //phase 1
            in_out:
              {
                label:'כרגע אני נמצא/ת:',
                items:[{value:0, title:'בפנים'},
                       {value:1, title:'בחוץ'},]
              },
            company:{
              label:'כרגע אני נמצא/ת עם:',
              items:[
                {value:1, title:'אף אחד'},
                {value:2, title:'משפחה'},
                {value:3, title:'חיית מחמד'},
                {value:4, title:'חברים'},
                {value:5, title:'אחר'},
              ]
            },
            //phase 2
            activity:
            {
              label:'אני עוסק/ת כעת ב:',
              items:[
                {value:1, title:'עבודה'},
                {value:2, title:'לימודים'},
                {value:3, title:'קניות'},
                {value:4, title:'עבודות בית'},
                {value:5, title:'פעילות פנאי'},
                {value:6, title:'מנוחה'},
                {value:7, title:'בדרכי למקום כלשהו'},
                {value:9, title:'פעילות ספורטיבית'},
                {value:8, title:'אחר'},
              ]
            },
            /// Those are for people Outside...
            location: {
              label:'אני נמצא/ת עכשיו ב:',
              items:[
                {value:1, title:'חצר או גינה של בית'},
                {value:2, title:'רחוב'},
                {value:3, title:'חוף ים'},
                {value:4, title:'גינה עירונית'},
                {value:9, title:'גינה קהילתית'},
                {value:5, title:'מרכז קניות'},
                {value:6, title:'פארק'},
                {value:7, title:'איזור טבעי'},
                {value:8, title:'אחר'},
              ]
            },
            //nearBy
            trees:{
              label: "אני רואה עצים:",
              items:[
                {value:1, title:'כלל לא'},
                {value:2, title:'בין 1-3'},
                {value:3, title:'בין 4-10'},
                {value:4, title:'בין 11-20'},
                {value:5, title:'מעל 20'},
              ]
            },
            bushes:{
              label: "אני רואה פרחים:",
              items:[
                {value:1, title:'כלל לא'},
                {value:2, title:'בין 1-3'},
                {value:3, title:'בין 4-10'},
                {value:4, title:'בין 11-20'},
                {value:5, title:'מעל 20'},
              ]
            },
            carsp:{
              label: "אני רואה מכוניות חונות:",
              items:[
                {value:1, title:'אין מכוניות חונות'},
                {value:2, title:'בין 1-3'},
                {value:3, title:'בין 4-10'},
                {value:4, title:'בין 11-20'},
                {value:5, title:'מעל 20'},
              ]
            },
            carsd:{
              label: "אני רואה מכוניות נוסעות:",
              items:[
                {value:1, title:'אין מכוניות נוסעות'},
                {value:2, title:'בין 1-3'},
                {value:3, title:'בין 4-10'},
                {value:4, title:'בין 11-20'},
                {value:5, title:'מעל 20'},
              ]
            },
            grass:{
              label: "אני רואה דשא:",
              items:[
                {value:1, title:'כן'},
                {value:2, title:'לא'},
                {value:3, title:'לא בטוח/ה'},
              ]
            },
            birds:{
              label: "אני רואה או שומע/ת ציפורים :",
              items:[
                {value:1, title:'כן'},
                {value:2, title:'לא'},
                {value:3, title:'לא בטוח/ה'},
              ]
            },
            // phase
            // בנוף הרחוק (מעל כ-100 מטרים)
            blue:{
              label: "אני רואה ים או אגם:",
              items:[
                {value:1, title:'כן'},
                {value:2, title:'לא'},
                {value:3, title:'לא בטוח/ה'},
              ]
            },
            green:{
              label: "אני רואה נוף ירוק :",
              items:[
                {value:1, title:'כן'},
                {value:2, title:'לא'},
                {value:3, title:'לא בטוח/ה'},
              ]
            },
            //phase 3
            stai_1:{label: "מתוח/ה"},
            stai_2:{label: "שבע/ת רצון"},
            stai_3:{label: "רגוע/ה"},
            panas_1: {label: "מלא/ת השראה"},
            panas_2: {label: "עוינ/ת"},
            panas_3: {label: "חזק/ה"},
            panas_4: {label: "במצוקה"},
            panas_5: {label: "נחוש/ה"},
            panas_6: {label: "עצבני/ת"},

            //phase 6 - audio
            sound:{
              label:'מהו המקור העיקרי של הצליל שהקלטת?',
              items:[
                {value:1, title:'אנשים מדברים'},
                {value:2, title:'כלבים נובחים'},
                {value:3, title:'תנועת רכבים'},
                {value:4, title:'מוזיקה'},
                {value:6, title:'קולות ציפורים'},
                {value:5, title:'אחר'},
              ]
            },

          },

          locationPermissions: {
            title: 'הרשאות מיקום',
            para1:'לצורך חקירת הקשר בין מיקום פיסי לבין תחושות רגשיות, אנו נרצה לגשת למיקום שלך בעת השימוש באפליקציה, ובזמנים אלו בלבד. ',
            para2:'לאחר שתלחץ/י על "הבא" נבקש ממך לתת את ההרשאה,אנא אשר/י שהינך מסכים/ה לכך. ללא מתן הרשאה זו לא ניתן להשתתף בפעילות',
            denied:'סירבת לתת את ההרשאה ולכן לא ניתן להשתתף בפעילות. יש לתת את ההרשאות המתאימות במכשיר שלך.',
          },

      },
      get_feedback: {
        reported_so_far:'עד כה דיווחת {n} פעמים.',
        where_were_you:'איפה היית כשדיווחת:',
        outside:'בחוץ',
        inside:'בפנים',
        sea_or_lake_view:'נוף של ים או אגם',
        green_view:'נוף ירוק',

        who_were_you_with:'עם מי היית כשדיווחת:',
        alone:'לבד',
        with_pet:'עם חיית מחמד',
        with_friends:'עם חברים',
        with_family:'עם משפחה',
        other:'אחר',

        trees_seen:'מספר העצים שראית:',
        carsd_seen:'מספר המכוניות בתנועה שראית:',
        range_none:'כלל לא',
        range_1_3:'בין 1-3',
        range_4_10:'בין 4-10',
        range_11_20:'בין 11-20',
        range_over_20:'מעל 20',
        number_of_times_you_reported_of:'מספר הפעמים שדיווחת על:',
        reported_feeling_avg:'ממוצע הרגשות שדיווחת (בטווח שבין 1-4) הינו:',
        no_reports_yet:'כאן יופיע משוב מתמשך על הדיווחים שלך. יש לדווח לפחות פעם אחת על מנת לקבל את המשוב המתמשך.',

        data_will_be_here:'כאן יופיע החל מ {end_date} (בתום שבועיים של השתתפות) משוב אישי מסכם אודות האופן בו אתרים שונים וחוויות שונות קשורים אצלך למצב ריגשי.',
        data_N_required:'לצורך כך צריך להגיע לפחות ל {n_req} דיווחים.',
        data_requirements:'חסרים לך {n} דיווחים כדי לקבל משוב מסכם בתום שבועיים.',
        your_reporting_progress:'התקדמותך בדיווחים',

        did_N_reports:'מזל טוב! השלמת {n_req} דיווחים. משוב מסכם יוצג החל מ {end_date} (בתום שבועיים של השתתפות)',
        did_N_continue_reporting: 'בכל מקרה, בכדי שהמשוב יהיה מדוייק ככל האפשר מומלץ להמשיך למלא דיווחים.',

        ended_without_enough_reports:'תודה רבה על השתתפותך. מאחר שלא התקבלו  {n_req} דיווחים לא ניתן להפיק משוב מסכם אישי. באפשרותך עדיין לצפות במשוב הביניים שלך ולדעת מה המראות העיקריים שראית ומה היתה רמת הרגשות שדיווחת.',

        we_created:'מתוך הנתונים שמילאת יצרנו מדדים לשלושה סוגי רגשות:',
        indicators:'רגשות חיוביים, רגשות שליליים, ותחושת לחץ.',
        averages:'להלן הממוצעים של הרגשות שאת/ה חווה במצבים שונים.',
        averages_feelings:'הממוצעים של רגשות חיוביים ושליליים מופיעים בטווח שבין 1-5.',
        averages_stress:'הממוצעים של לחץ מופיעים בטווח שבין 1-4.',
        limits_note:'לתשומת לבך: הגרפים כוללים מבחר מתוך המשתנים שנאספו אך לא את כל משתני המחקר.',

        final:{
          positive_and_negative_feelings:'רגשות חיוביים ושליליים:',
          stress:'לחץ:',
          categories: {
            g_in_out_1:'בחוץ',
            g_in_out_0:'בפנים',
            blue_1:'נוף של ים או אגם',
            g_green_1:'נוף ירוק',
            g_birds_1:'ציפורים',
            g_company_1:'לבד',
            g_company_2:'עם חברים',
            g_company_3:'עם משפחה',
            g_company_4:'עם הכלב',
            g_trees_1:'כשאין עצים',
            g_trees_2:'1-3 עצים',
            g_trees_3:'4-10 עצים',
            g_trees_4:'11-20 עצים',
            g_carsd_1:'כשאין רכבים נוסעים',
            g_carsd_2:'1-3 רכבים נוסעים',
            g_carsd_3:'4-10 רכבים נוסעים',
            g_carsd_4:'11-20 רכבים נוסעים',
          }
        },
        no_information_word_1:'אין דיווחים',
        no_information_word_2:'בקטגוריה זו',

      },
    }
  };

export {he};
<template>
  <div>
    <v-btn @click="showDialog = true">
      <slot name="buttonText">Show Log</slot>
    </v-btn>
    <v-dialog v-model="showDialog" persistent max-width="500px">
      <v-card dir="ltr">
        <v-card-title>
          Log Messages
          <v-spacer></v-spacer>
          <v-btn :icon="mdiClose" @click="showDialog = false"></v-btn>
        </v-card-title>
        <v-card-text>
          <v-list dense>
            <v-list-item v-for="(log, index) in logs" :key="index">
              {{index}} : {{ log }}
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="emitClearLog">Clear Log</v-btn>
          <v-btn color="primary" text @click="showDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
/*

  Usage:
    <template>
      <div>
         <LogDialog :logs="logs1" @clearLog="clearLog1" />
         <LogDialog :logs="logs2" @clearLog="clearLog2" />

        <v-btn @click="addToFirstLog">Add Entry to First Log</v-btn>
        <v-btn @click="addToSecondLog">Add Entry to Second Log</v-btn>
      </div>
    </template>

    <script setup>
    import { ref } from 'vue';
    import LogDialog from './LogDialog.vue';

    import { useCircularLog } from '@/composables/useCircularLog';
    const { addToLog:addToLog1, clearLog:clearLog1, logs:logs1 } = useCircularLog(45);
    const { addToLog:addToLog2, clearLog:clearLog2, logs:logs2 } = useCircularLog(45);
    provide('addToLog1',addToLog1);

    /script
*/

import { ref, defineProps} from 'vue';
import {mdiClose} from '@mdi/js';

defineProps({
  logs: Array
});
const emit = defineEmits(['clearLog']);

const showDialog = ref(false);

function emitClearLog() {
  emit('clearLog')
}

</script>

<template>
      <v-card class="my-10 d-flex flex-column" style="width:80vw;height:calc(100vh-80px);">
        <v-card-title></v-card-title>
        <v-card-text class="overflow-y-auto overflow-x-none">

            <v-list v-if="false">
              <v-list-item>

                {{ $t('view.get_feedback.inside') }} : {{  FeedbackStats.n_reports_inside }}<br/>
                {{ $t('view.get_feedback.outside') }} : {{ FeedbackStats.n_reports_outside }}<br/>
                {{ $t('view.get_feedback.sea_or_lake_view') }} : {{ FeedbackStats.n_g_blue_1 }}<br/>
                {{ $t('view.get_feedback.green_view') }} : {{ FeedbackStats.n_g_green_1 }}<br/>
                {{ $t('view.get_feedback.alone') }} : {{ FeedbackStats.n_g_company_1 }}<br/>
                {{ $t('view.get_feedback.with_family') }} : {{ FeedbackStats.n_g_company_2 }}<br/>
                {{ $t('view.get_feedback.with_pet') }} : {{ FeedbackStats.n_g_company_4 }}<br/>
                {{ $t('view.get_feedback.with_friends') }} : {{ FeedbackStats.n_g_company_8 }}<br/>

                {{ $t('view.sheelon2.Qs.stai_1.label') }} : {{ FeedbackStats.avg_stai_1 }}<br/>
                {{ $t('view.sheelon2.Qs.stai_2.label') }} : {{ FeedbackStats.avg_stai_2 }}<br/>
                {{ $t('view.sheelon2.Qs.stai_3.label') }} : {{ FeedbackStats.avg_stai_3 }}<br/>
              </v-list-item>
              </v-list>

              <div class="d-flex flex-grow-0 flex-wrap ga-5 justify-center text-center" v-if="FeedbackStats.n_reportsSoFar==0">
                {{$t('view.get_feedback.no_reports_yet')}}<br/><br/>
                {{ $t('view.get_feedback.reported_so_far',{n:FeedbackStats.n_reportsSoFar}) }} <br/>
              </div>
              <template v-else>
                <div class="d-flex flex-grow-0 flex-wrap ga-5 justify-center" style="width:100%;">
                  <v-sheet class="">
                    {{ $t('view.get_feedback.reported_so_far',{n:FeedbackStats.n_reportsSoFar}) }} <br/>
                  </v-sheet>

                  <template v-for="chart in [inOutPieChart,companyPieChart,treesPieChart,carsdPieChart]" :key="chart">
                    <div class="d-flex flex-column flex-grow-1">
                      <div class="justify-start  ps-3" v-if="chart.titleKey">{{ $t(chart.titleKey) }}</div>
                      <apexchart
                        :type="chart.options.chart.type"
                        :series="chart.series"
                        :width="chart.options.chart.width"
                        :height="chart.options.chart.height"
                        :options="chart.options"
                      ></apexchart>
                    </div>
                  </template>
                </div>
                <div class="d-flex flex-column flex-grow-1 mt-5">
                  <div class="justify-start ps-2">{{ $t('view.get_feedback.number_of_times_you_reported_of') }}</div>
                  <div>
                      <apexchart
                        :type="blueGreenBirdsHorizontalChart.options.chart.type"
                        :series="blueGreenBirdsHorizontalChart.series"
                        :width="blueGreenBirdsHorizontalChart.options.chart.width"
                        :height="blueGreenBirdsHorizontalChart.options.chart.height"
                        :options="blueGreenBirdsHorizontalChart.options"
                        >
                      </apexchart>
                  </div>
                </div>
                <div class="d-flex flex-column flex-grow-1 mt-5">
                  <div class="justify-start">{{ $t('view.get_feedback.reported_feeling_avg') }}</div>
                  <apexchart
                    :type="ApexFeelingsBarChart.type"
                    :series="ApexFeelingsBarChart.series"
                    width="240"
                    height="260"
                    :options="ApexFeelingsBarChart.options"
                  >
                  </apexchart>
                </div>
                <div dir="ltr" v-if="false">
                  <hr/>
                  <pre>{{ JSOn_stringify(reportsGroupAverages, null, 2) }}</pre>
                  <hr/>
                  {{reportsGroupAverages}}
                </div>
              </template>
        </v-card-text>
      </v-card>
</template>
<style>
</style>
<script setup>
  //import ReportsManager from '@/services/ReportsManager';
  import {useFeedbackStats} from '@/services/FeedbackStats';
  const FeedbackStats=useFeedbackStats();
  import { useI18n } from 'vue-i18n';

  //import {  } from '@mdi/js';

  // Use `useI18n` to access translation functions
  const { t } = useI18n();
  console.log('In OngingFeedback');
  console.log('FeedbackStats' , FeedbackStats)
  console.log('FeedbackStats.Avg' , FeedbackStats.Avg)

  function pieChartSetttings(series,labels,colors,customizer){
    var settings={

      series,

      options:{
          labels,
          colors,
          chart: {
            width:300,
            height:300,
            type:'pie',
            horizontalAlignment:'center'
          },
          dataLabels: {
            formatter: function (val, {seriesIndex, w }) { //seriesIndex, dataPointIndex, w
                //console.log(val, {seriesIndex, w })
                //return [w.config.series[seriesIndex] + " (" +(val.toFixed(1))+'%)' , w.config.labels[seriesIndex]]
                return [w.config.series[seriesIndex] , " (" +(val.toFixed(1))+'%)']
            },
            style: {
              colors: ['000']
            },
            dropShadow: {
              enabled: false,
              left: 2,
              top: 2,
              opacity: 0.5
            },
          },
          legend: {
            position: 'bottom',
            horizontalAlignment:'center'
            //width:280
          },
          plotOptions:{
            pie: {
              dataLabels: {
                offset: -5
              }
            }
          }/*,
          responsive: [{
            breakpoint: 480,
            options: {
              chart: { width: 280 },
              legend: { width:280, position: 'bottom', horizontalAlign: 'center' },
              plotOptions: {
                pie: {
                  dataLabels: {
                    offset: -25,
                  },
                }
              },
            }
          }]*/
        }
      };
      if (customizer) {
        console.log('in customizer' ,settings );
        settings  = customizer(settings);
        console.log('in customizer' ,settings );
      }
      return settings;
  }

  var inOutPieChart= pieChartSetttings(
    [FeedbackStats.n_reports_inside, FeedbackStats.n_reports_outside],
    [
          t('view.get_feedback.inside'),
          t('view.get_feedback.outside'),
    ],undefined/*,
    ['#332299','#992233']*/,(s)=>{s.titleKey='view.get_feedback.where_were_you';return s}


  );
  var companyPieChart=pieChartSetttings(
    [FeedbackStats.n_g_company_1, FeedbackStats.n_g_company_2,FeedbackStats.n_g_company_3,FeedbackStats.n_g_company_4,FeedbackStats.n_g_company_5],
    [
          t('view.get_feedback.alone'),
          t('view.get_feedback.with_family'),
          t('view.get_feedback.with_pet'),
          t('view.get_feedback.with_friends'),
          t('view.get_feedback.other')
    ],undefined,(s)=>{s.titleKey='view.get_feedback.who_were_you_with';return s}
  );

  var treesPieChart=pieChartSetttings( /*trees*/
    [FeedbackStats.n_g_trees_1, FeedbackStats.n_g_trees_2,FeedbackStats.n_g_trees_3,FeedbackStats.n_g_trees_4,FeedbackStats.n_g_trees_5],
    [
          t('view.get_feedback.range_none'),
          t('view.get_feedback.range_1_3'),
          t('view.get_feedback.range_4_10'),
          t('view.get_feedback.range_11_20'),
          t('view.get_feedback.range_over_20')
    ],undefined,(s)=>{s.titleKey='view.get_feedback.trees_seen';return s}
  );

  var carsdPieChart=pieChartSetttings( /*cars driving*/
    [FeedbackStats.n_g_carsd_1, FeedbackStats.n_g_carsd_2,FeedbackStats.n_g_carsd_3,FeedbackStats.n_g_carsd_4,FeedbackStats.n_g_carsd_5],
    [
          t('view.get_feedback.range_none'),
          t('view.get_feedback.range_1_3'),
          t('view.get_feedback.range_4_10'),
          t('view.get_feedback.range_11_20'),
          t('view.get_feedback.range_over_20')
    ],undefined, (s)=>{s.titleKey='view.get_feedback.carsd_seen';return s}
  );

  var blueGreenBirdsHorizontalChart={
    series: [{
            //name:'כן',
            data: [FeedbackStats.n_g_blue_1.toFixed(1), FeedbackStats.n_g_green_1.toFixed(1), FeedbackStats.n_g_birds_1.toFixed(1)]
          } /*, {
            name:'לא',
            data: [FeedbackStats.n_g_blue_2.toFixed(1), FeedbackStats.n_g_green_2.toFixed(1), FeedbackStats.n_g_birds_2.toFixed(1)]
          }*/],
    options: {
      chart: {
        type: 'bar',
        height: 250,
        width: 260,
        toolbar: {
          show:false,
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
          /*
          dataLabels: {
            position: 'left',
          },
          */
        }
      },
      dataLabels: {
        enabled: true,
        /*offsetX: 25,*/
        style: {
          fontSize: '12px',
          colors: ['#000']
        }
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['#fff']
      },
      menu: {
        show:false,
      },
      tooltip: {
        show:false,
        shared: true,
        intersect: false
      },
      xaxis: {
        categories: ['נוף כחול', 'נוף ירוק', 'ציפורים'],
        labels: {
          show:false
        }
      },
      yaxis: {
        labels: {
          offsetX: -30
        }
      },/*
      responsive: [{
            breakpoint: 480,
            options: {
              chart: { width: 300, height: 200 },
              legend: { position: 'bottom' },
            }
          }] */
    },

  }

  var ApexFeelingsBarChart={
    type:'bar',
    series: [
      {
        name: 'רגש',
        data: [FeedbackStats.avg_stai_1.toFixed(1),FeedbackStats.avg_stai_2.toFixed(1),FeedbackStats.avg_stai_3.toFixed(1)]
      },
    ],
    options:{
      chart:{
        toolbar: {
            show: false
        },
      },
      dataLabels: {
        style: {
          colors:['#000']
        }
      },
      xaxis: {
        show:true,
        categories: [
            t('view.sheelon2.Qs.stai_1.label'),
            t('view.sheelon2.Qs.stai_2.label'),
            t('view.sheelon2.Qs.stai_3.label')
        ]
      },
/*      markers: { size: 5,
        hover: { size: 10 }
      },*/
      //stroke: { show: false, width: 2, colors: ['#000000'], dashArray: 0 },
      yaxis: { show:true, tickAmount: 4, min:0 , max:4, decimalsInFloat:0,  }
    }
  };
  console.log('In OngingFeedback 2');
/*
var chartData_insideOutside = ref(
  [
  ['Inside/Outside', 'N'],
  [t('view.sheelon2.Qs.in_out.items.0.title'), n_reports_inside.value*1],
  [t('view.sheelon2.Qs.in_out.items.1.title'), n_reports_outside.value*1],
]
);
var chartOptions_insideOutside = ref(
  {
  title: 'ממוצע רגשות מדווחים',
    subtitle: '',
    width:'50%',
    height:'150px'
  }
);
*/
// // eslint-disable-next-line no-unused-vars
// const stress = (report)=> {
//   return (report.stai_1 + (5 - report.stai_2) + (5 - report.stai_3)) / 3;
// }
// // eslint-disable-next-line no-unused-vars
// const pos_feel = (report)=> {
//   return (report.panas_1 + report.panas_3 + report.panas_5) / 3;
// }
// // eslint-disable-next-line no-unused-vars
// const neg_feel = (report)=> {
//   return (report.panas_2 + report.panas_4 + report.panas_6) / 3;
// }

console.log('In OngingFeedback 3');

// const calculateAverages = (reportsGroup) => {
//   console.log('In OngingFeedback:calculateAverages for reportsGroup:', reportsGroup  );
//   return reportsGroup.map(group => {
//     console.log('In OngingFeedback:calculateAverages working on group:' , group);
//     const {name, reports} = group;

//     // Initialize sums
//     let stressSum = 0;
//     let posFeelSum = 0;
//     let negFeelSum = 0;

//     // Calculate sums
//     reports.forEach(report => {
//       stressSum += stress(report);
//       posFeelSum += pos_feel(report);
//       negFeelSum += neg_feel(report);
//     });

//     // Calculate averages
//     const reportCount = reports.length;
//     const averages = {
//       N:reportCount,
//       stress: reportCount > 0 ? stressSum / reportCount : 0,
//       pos_feel: reportCount > 0 ? posFeelSum / reportCount : 0,
//       neg_feel: reportCount > 0 ? negFeelSum / reportCount : 0
//     };

//     // Return an object with the group name and the averages
//     var item = {};
//     item[name] = averages;
//     return item;
//   });
// };

console.log('In OngingFeedback 4');

// Example usage
//console.log('FeedbackStats.g_in_out_0',FeedbackStats.g_in_out_0);
//var reports_group = [
//  {name:'g_in_out_0',reports:FeedbackStats.g_in_out_0},
//  {name:'g_in_out_1',reports:FeedbackStats.g_in_out_1}
//];

//console.log('In OngingFeedback: calling calculateAverages ' , reports_group);
//const reportsGroupAverages = FeedbackStats.calculateAverages(reports_group);

//var allStats = ref(stats_array([g_in_out_0,g_in_out_1]));

</script>

<style>
/* manually offset all labels of pie charts 15px upwards*/
.apexcharts-pie .apexcharts-datalabels {
  transform:translateY(-5px);
}

</style>
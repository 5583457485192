<template>
    <v-container >
      <v-row justify="center">
        <v-col cols="12" sm="6">
          <v-card class="text-center" color=green a="#4caf50" dark>
            <v-card-title>Select a language</v-card-title>
            <v-card-text>
              <v-list>
                <!--v-list-item-group color="green"-->
                  <v-list-item v-for="language in languages" :key="language.code">
                    <v-btn color=green v-ripple=true class="ma-2" @click="selectLanguage(language.code);">
                        {{ language.name }}
                    </v-btn>
                  </v-list-item>
                <!--/v-list-item-group-->
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>

<script setup>
import {useI18n} from 'vue-i18n'
const { locale/*, t*/ } = useI18n();

import RedirectStack from '@/router/RedirectStack';
import laguageController from '../controllers/LanguageController.js';
import vuetify from '@/plugins/vuetify'

import {useRouter} from 'vue-router';
const router = useRouter();

const languages= [
        { name: "עברית", code: "he"},
        { name: "عربي", code: "ar" },
        { name: "English", code: "en" },
        { name: "Русский", code: "ru" }
      ];

const selectLanguage=(code)=> {


      console.log("Selected language:", code);
      laguageController.setLanguage(code);
      code = laguageController.getLanguage();
      if (code == '') {
        code = 'en';
      }
      //eslint ignoe-next-line no-unused-vars
      locale.value = code;
      vuetify.rtl = (code=='ar' || code=='he');
      console.log("Language configured to:" , code)

      if (RedirectStack.isEmpty()) {
        router.replace('/info'); // was go
      }
      else {
        var path=RedirectStack.pop();
        console.log('about to go to:' + path);
        router.replace(path);
      }
};
</script>

<style scoped>
  div { color: darkgreen; }
/* Additional styling if needed */
</style>@/plugins/i18nMessages.js
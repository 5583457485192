const allowedLanguageCodes = ["en", "he", "ar", "ru"];
function setLanguage(code) {
    console.log("Selected language:", code);
    //const storedLang = localStorage.getItem('langCode');
    if (allowedLanguageCodes.includes(code)) {
        localStorage.setItem('langCode', code);
    }

    // console.log("was RTL:", this.$vuetify.rtl);
    // this.$vuetify.rtl = (code=='ar' || code=='he');
    // console.log("is RTL:", this.$vuetify.rtl);
    //this.$router.replace({name: 'RouteName', params: {}});
    //this.$router.replace('/path-to-route');

    //this.$router.replace({name: 'RouteName', params: {}});
    //this.$router.replace('/path-to-route');



    // Implement the logic to change the language in your app
}
function getLanguage() {
    const storedLangCode = localStorage.getItem('langCode');
    if (allowedLanguageCodes.includes(storedLangCode)) {
        return storedLangCode;
    }
    else {
        localStorage.removeItem('langCode');
        return '';
    }

    // console.log("was RTL:", this.$vuetify.rtl);
    // this.$vuetify.rtl = (code=='ar' || code=='he');
    // console.log("is RTL:", this.$vuetify.rtl);
    //this.$router.replace({name: 'RouteName', params: {}});
    //this.$router.replace('/path-to-route');

    //this.$router.replace({name: 'RouteName', params: {}});
    //this.$router.replace('/path-to-route');



    // Implement the logic to change the language in your app
}

const languageController={setLanguage,getLanguage};
export default languageController
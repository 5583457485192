import { ref, toValue } from 'vue';

export function useCircularLog(maxRows) {
    const logs = ref([]);
    const maxLogRows = ref(maxRows);

    const addToLog = (message) => {
        // Use toValue here to unwrap the ref's value, if it is a ref
        logs.value.push(toValue(message));
        if (logs.value.length > maxLogRows.value) {
            // Remove the oldest log if we exceed max rows
            logs.value.shift();
        }
    };

    const clearLog = () => {
        logs.value = [];
    };

    return { addToLog, clearLog, logs };
}
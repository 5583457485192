
function createUUID() {
  let dt = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
  return uuid;
}

function isValidUUID(uuid) {
    const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return regex.test(uuid);
}

function getUUID() {
    const storedUUID = localStorage.getItem('uuid');
    return storedUUID
}

function setUUID(uuid) {
    if (!isValidUUID(uuid)) {
       localStorage.removeItem('uuid');
       return false;
    }
    else {
        localStorage.setItem('uuid',uuid);
        return true;
    }
}

const uuidController={createUUID,isValidUUID,getUUID,setUUID};
export default uuidController
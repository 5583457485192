const vilages = [
  {
    "village_id": 7,
    "name_en": "Shahar ",
    "name_ar": "شاحَر",
    "name_ru": "Shahar ",
    "name_he": "שחר ",
    "x": 173946.344,
    "y": 614167.1864
  },
  {
    "village_id": 10,
    "name_en": "Tirosh ",
    "name_ar": "تيروش",
    "name_ru": "Tirosh ",
    "name_he": "תירוש ",
    "x": 189539.8843,
    "y": 628489.6115
  },
  {
    "village_id": 11,
    "name_en": "Nir hen ",
    "name_ar": "نير حين",
    "name_ru": "Nir hen ",
    "name_he": "ניר ח\"ן ",
    "x": 172992.2744,
    "y": 613096.7801
  },
  {
    "village_id": 13,
    "name_en": "Hazeva ",
    "name_ar": "حتْسِڤا",
    "name_ru": "Hazeva ",
    "name_he": "חצבה ",
    "x": 226695.9368,
    "y": 519702.6827
  },
  {
    "village_id": 15,
    "name_en": "No'am ",
    "name_ar": "نوعَم",
    "name_ru": "No'am ",
    "name_he": "נועם ",
    "x": 179977.3314,
    "y": 608413.7751
  },
  {
    "village_id": 16,
    "name_en": "Bet nir ",
    "name_ar": "بيت نير",
    "name_ru": "Bet nir ",
    "name_he": "בית ניר ",
    "x": 188089.6537,
    "y": 617337.5469
  },
  {
    "village_id": 18,
    "name_en": "Sede moshe ",
    "name_ar": "سْدي مُشي",
    "name_ru": "Сде-Моше",
    "name_he": "שדה משה ",
    "x": 181244.5493,
    "y": 613270.5717
  },
  {
    "village_id": 21,
    "name_en": "Be'er ora ",
    "name_ar": "بِئير أورا",
    "name_ru": "Be'er ora ",
    "name_he": "באר אורה ",
    "x": 198402.3557,
    "y": 402578.0006
  },
  {
    "village_id": 22,
    "name_en": "Miqwe yisra'el ",
    "name_ar": "مِكْڤي يِسرَئيل",
    "name_ru": "Микве Исраэль",
    "name_he": "מקווה ישראל ",
    "x": 179701.4346,
    "y": 659613.5361
  },
  {
    "village_id": 23,
    "name_en": "Amazya ",
    "name_ar": "أمَتْسيا",
    "name_ru": "Amazya ",
    "name_he": "אמציה ",
    "x": 191761.3595,
    "y": 604792.8876
  },
  {
    "village_id": 24,
    "name_en": "Lakhish ",
    "name_ar": "لخيش",
    "name_ru": "Lakhish ",
    "name_he": "לכיש ",
    "x": 184953.5296,
    "y": 607989.9074
  },
  {
    "village_id": 26,
    "name_en": "Rosh pinna ",
    "name_ar": "روش پِنا",
    "name_ru": "Рош-Пина",
    "name_he": "ראש פינה ",
    "x": 251056.6139,
    "y": 763501.3077
  },
  {
    "village_id": 27,
    "name_en": "Sedot mikha ",
    "name_ar": "سْدوت ميخا",
    "name_ru": "Sedot mikha ",
    "name_he": "שדות מיכה ",
    "x": 192565.2798,
    "y": 625419.2368
  },
  {
    "village_id": 28,
    "name_en": "Mazkeret batya ",
    "name_ar": "مزكيرِت بَتيا",
    "name_ru": "Мазкерет-Батья",
    "name_he": "מזכרת בתיה ",
    "x": 185249.3083,
    "y": 639415.0948
  },
  {
    "village_id": 29,
    "name_en": "Yesud hama'ala ",
    "name_ar": "يِسود همعلا",
    "name_ru": "Йесуд-ха-Маала",
    "name_he": "יסוד המעלה ",
    "x": 256469.8189,
    "y": 773459.0239
  },
  {
    "village_id": 31,
    "name_en": "Ofaqim ",
    "name_ar": "أُفَكيم",
    "name_ru": "Офаким",
    "name_he": "אופקים ",
    "x": 163796.0529,
    "y": 579871.3594
  },
  {
    "village_id": 32,
    "name_en": "Ozem ",
    "name_ar": "عوتْسِم",
    "name_ru": "Ozem ",
    "name_he": "עוצם ",
    "x": 172016.5586,
    "y": 616165.1895
  },
  {
    "village_id": 33,
    "name_en": "Bat shelomo ",
    "name_ar": "بات شلُمو",
    "name_ru": "Бат-Соломон",
    "name_he": "בת שלמה ",
    "x": 200582.5955,
    "y": 722918.6258
  },
  {
    "village_id": 35,
    "name_en": "Gadot ",
    "name_ar": "چَدوت",
    "name_ru": "Gadot ",
    "name_he": "גדות ",
    "x": 258282.24,
    "y": 769257.4873
  },
  {
    "village_id": 36,
    "name_en": "Sede dawid ",
    "name_ar": "سْدي دَڤيد",
    "name_ru": "Сде-Давид",
    "name_he": "שדה דוד ",
    "x": 169989.0264,
    "y": 609387.2164
  },
  {
    "village_id": 37,
    "name_en": "Etan ",
    "name_ar": "إيتان",
    "name_ru": "Etan ",
    "name_he": "איתן ",
    "x": 176120.2789,
    "y": 608991.4829
  },
  {
    "village_id": 38,
    "name_en": "Kare deshe ",
    "name_ar": "كفار تپووَح",
    "name_ru": "Kare deshe ",
    "name_he": "כרי דשא ",
    "x": 254305.1451,
    "y": 759440.6194
  },
  {
    "village_id": 39,
    "name_en": "Gefen ",
    "name_ar": "چيفِن",
    "name_ru": "Гефен",
    "name_he": "גפן ",
    "x": 188566.9384,
    "y": 627629.2154
  },
  {
    "village_id": 41,
    "name_en": "Elyakhin ",
    "name_ar": "إليَخين",
    "name_ru": "Эльяхин",
    "name_he": "אליכין ",
    "x": 193152.9395,
    "y": 701564.1042
  },
  {
    "village_id": 43,
    "name_en": "Metula ",
    "name_ar": "مِتولا",
    "name_ru": "Метула",
    "name_he": "מטולה ",
    "x": 254242.9107,
    "y": 798163.9721
  },
  {
    "village_id": 44,
    "name_en": "Zohar ",
    "name_ar": "زوهَر",
    "name_ru": "Zohar ",
    "name_he": "זוהר ",
    "x": 170876.7462,
    "y": 611601.896
  },
  {
    "village_id": 46,
    "name_en": "Yavne'el ",
    "name_ar": "يَڤنِئيل",
    "name_ru": "Yavne'el ",
    "name_he": "יבנאל ",
    "x": 247188.876,
    "y": 735024.8881
  },
  {
    "village_id": 47,
    "name_en": "Kefar tavor ",
    "name_ar": "كفار شمرياهو",
    "name_ru": "Кфар-Тавор",
    "name_he": "כפר תבור ",
    "x": 239740.2996,
    "y": 732715.7005
  },
  {
    "village_id": 48,
    "name_en": "Menahemya ",
    "name_ar": "مِنَحِمْيا ",
    "name_ru": "Menahemya ",
    "name_he": "מנחמיה ",
    "x": 252396.276,
    "y": 730287.2635
  },
  {
    "village_id": 49,
    "name_en": "Ilaniyya ",
    "name_ar": "إيلَنيا",
    "name_ru": "Илания",
    "name_he": "אילניה ",
    "x": 238389.7067,
    "y": 740021.9327
  },
  {
    "village_id": 52,
    "name_en": "Luzit ",
    "name_ar": "لوزيت",
    "name_ru": "Luzit ",
    "name_he": "לוזית ",
    "x": 189302.9582,
    "y": 621683.8096
  },
  {
    "village_id": 53,
    "name_en": "Atlit ",
    "name_ar": "عتليت",
    "name_ru": "Atlit ",
    "name_he": "עתלית ",
    "x": 194965.9749,
    "y": 733200.043
  },
  {
    "village_id": 55,
    "name_en": "Nogah ",
    "name_ar": "نوچَه",
    "name_ru": "Nogah ",
    "name_he": "נוגה ",
    "x": 171101.7999,
    "y": 614779.5779
  },
  {
    "village_id": 57,
    "name_en": "Kinneret(qevuza) ",
    "name_ar": "كِنيرِت (كڤوتسا)",
    "name_ru": "Квуцат-Кинерет",
    "name_he": "כנרת (קבוצה) ",
    "x": 253324.3507,
    "y": 735495.0435
  },
  {
    "village_id": 58,
    "name_en": "Mizpa ",
    "name_ar": "مِتْسپا",
    "name_ru": "Mizpa ",
    "name_he": "מצפה ",
    "x": 248016.7687,
    "y": 743997.5907
  },
  {
    "village_id": 59,
    "name_en": "Nehusha ",
    "name_ar": "نِحوشا",
    "name_ru": "Nehusha ",
    "name_he": "נחושה ",
    "x": 195456.6959,
    "y": 614916.0997
  },
  {
    "village_id": 62,
    "name_en": "Deganya alef ",
    "name_ar": "دچنيا ألف",
    "name_ru": "Дгания",
    "name_he": "דגניה א' ",
    "x": 254186.343,
    "y": 734852.5077
  },
  {
    "village_id": 63,
    "name_en": "Kinneret(moshava) ",
    "name_ar": "كِنيرِت (موشَڤا)",
    "name_ru": "Кинерет (мошава)",
    "name_he": "כנרת (מושבה) ",
    "x": 253158.2891,
    "y": 736469.7251
  },
  {
    "village_id": 64,
    "name_en": "Yad rambam ",
    "name_ar": "ياد رَمبام",
    "name_ru": "ЯД-РАМБА́М",
    "name_he": "יד רמב\"ם ",
    "x": 190662.9684,
    "y": 645269.8758
  },
  {
    "village_id": 65,
    "name_en": "Migdal ",
    "name_ar": "مِچدال",
    "name_ru": "Migdal ",
    "name_he": "מגדל ",
    "x": 248342.934,
    "y": 748677.1852
  },
  {
    "village_id": 66,
    "name_en": "Merhavya(qibbuz) ",
    "name_ar": "مِرحَڤيا (كِبوتس)",
    "name_ru": "Merhavya(qibbuz) ",
    "name_he": "מרחביה (קיבוץ) ",
    "x": 229166.5842,
    "y": 723534.8289
  },
  {
    "village_id": 67,
    "name_en": "Or haner ",
    "name_ar": "أور هنير",
    "name_ru": "Or haner ",
    "name_he": "אור הנר ",
    "x": 162152.8194,
    "y": 607313.8745
  },
  {
    "village_id": 69,
    "name_en": "Nir oz ",
    "name_ar": "نير عوز",
    "name_ru": "Nir oz ",
    "name_he": "ניר עוז ",
    "x": 143127.3665,
    "y": 580384.6522
  },
  {
    "village_id": 70,
    "name_en": "Ashdod ",
    "name_ar": "أشدود",
    "name_ru": "Ашдод",
    "name_he": "אשדוד ",
    "x": 167582.8805,
    "y": 635675.818
  },
  {
    "village_id": 71,
    "name_en": "Eshbol ",
    "name_ar": "إشبول",
    "name_ru": "Eshbol ",
    "name_he": "אשבול ",
    "x": 168286.7364,
    "y": 595140.8607
  },
  {
    "village_id": 72,
    "name_en": "Gan shemu'el ",
    "name_ar": "چان نير",
    "name_ru": "Ган-Шмуэль",
    "name_he": "גן שמואל ",
    "x": 195621.3311,
    "y": 706413.418
  },
  {
    "village_id": 74,
    "name_en": "En hod ",
    "name_ar": "عين هود",
    "name_ru": "En hod ",
    "name_he": "עין הוד ",
    "x": 198660.8386,
    "y": 733931.3237
  },
  {
    "village_id": 76,
    "name_en": "Kefar gil'adi ",
    "name_ar": "كفار چِلعدي",
    "name_ru": "Кфар-Гилади",
    "name_he": "כפר גלעדי ",
    "x": 254054.005,
    "y": 794221.2453
  },
  {
    "village_id": 77,
    "name_en": "Ayyelet hashahar ",
    "name_ar": "أييلِت هشاحَر",
    "name_ru": "Айелет-ха-Шахар",
    "name_he": "איילת השחר ",
    "x": 254387.5863,
    "y": 769777.7859
  },
  {
    "village_id": 78,
    "name_en": "Qiryat anavim ",
    "name_ar": "كِريات عنڤيم",
    "name_ru": "Кирьят-Аневим",
    "name_he": "קרית ענבים ",
    "x": 211358.3965,
    "y": 635273.0848
  },
  {
    "village_id": 79,
    "name_en": "Deganya bet ",
    "name_ar": "دچنيا بيت",
    "name_ru": "Дгания",
    "name_he": "דגניה ב' ",
    "x": 254252.0643,
    "y": 734006.2181
  },
  {
    "village_id": 80,
    "name_en": "Nahalal ",
    "name_ar": "نهلال",
    "name_ru": "Nahalal ",
    "name_he": "נהלל ",
    "x": 218489.25,
    "y": 732866.3108
  },
  {
    "village_id": 82,
    "name_en": "En harod(me'uhad) ",
    "name_ar": "عين حَرود (مِؤُحاد)",
    "name_ru": "En harod(me'uhad) ",
    "name_he": "עין חרוד (מאוחד) ",
    "x": 237056.6648,
    "y": 718128.5892
  },
  {
    "village_id": 84,
    "name_en": "Tel yosef ",
    "name_ar": "تيل يوسيف",
    "name_ru": "Тель-Йосеф",
    "name_he": "תל יוסף ",
    "x": 237768.6862,
    "y": 717896.2948
  },
  {
    "village_id": 85,
    "name_en": "Kefar yehezqel ",
    "name_ar": "كفار يِحِزكيل",
    "name_ru": "Kefar yehezqel ",
    "name_he": "כפר יחזקאל ",
    "x": 233937.7974,
    "y": 719161.1229
  },
  {
    "village_id": 86,
    "name_en": "Geva ",
    "name_ar": "چيڤع",
    "name_ru": "Гева",
    "name_he": "גבע ",
    "x": 235300.9235,
    "y": 719143.1458
  },
  {
    "village_id": 88,
    "name_en": "Kerem ben shemen ",
    "name_ar": "كيرِم بين شيمِن",
    "name_ru": "Kerem ben shemen ",
    "name_he": "כרם בן שמן ",
    "x": 193887.112,
    "y": 651738.8069
  },
  {
    "village_id": 89,
    "name_en": "En harod (ihud) ",
    "name_ar": "عين حَرود (إحود)",
    "name_ru": "En harod (ihud) ",
    "name_he": "עין חרוד (איחוד) ",
    "x": 236948.7962,
    "y": 718969.446
  },
  {
    "village_id": 90,
    "name_en": "Hefzi-bah ",
    "name_ar": "حِفْتْسي־ڤاه",
    "name_ru": "Hefzi-bah ",
    "name_he": "חפצי-בה ",
    "x": 240137.2362,
    "y": 713940.9268
  },
  {
    "village_id": 92,
    "name_en": "Ginnegar ",
    "name_ar": "چِنيچار",
    "name_ru": "Ginnegar ",
    "name_he": "גיניגר ",
    "x": 224514.7193,
    "y": 729928.759
  },
  {
    "village_id": 94,
    "name_en": "Balfuriyya ",
    "name_ar": "بلفوريا",
    "name_ru": "Balfuriyya ",
    "name_he": "בלפוריה ",
    "x": 228116.4746,
    "y": 726203.1768
  },
  {
    "village_id": 95,
    "name_en": "Bet alfa ",
    "name_ar": "بيت ألفا",
    "name_ru": "Бейт-Альфа",
    "name_he": "בית אלפא ",
    "x": 240865.6943,
    "y": 713675.987
  },
  {
    "village_id": 96,
    "name_en": "Yagur ",
    "name_ar": "يَچور",
    "name_ru": "Yagur ",
    "name_he": "יגור ",
    "x": 207369.9594,
    "y": 738792.1562
  },
  {
    "village_id": 97,
    "name_en": "Merhavya(moshav) ",
    "name_ar": "مِرحَڤيا (موشاڤ)",
    "name_ru": "Merhavya(moshav) ",
    "name_he": "מרחביה (מושב) ",
    "x": 229980.4227,
    "y": 723294.7869
  },
  {
    "village_id": 98,
    "name_en": "Kefar malal ",
    "name_ar": "كفر مصر",
    "name_ru": "Kefar malal ",
    "name_he": "כפר מל\"ל ",
    "x": 190208.1527,
    "y": 674931.9247
  },
  {
    "village_id": 99,
    "name_en": "Mizpe ramon ",
    "name_ar": "مِتْسپي رَمون",
    "name_ru": "Mizpe ramon ",
    "name_he": "מצפה רמון ",
    "x": 180564.0841,
    "y": 502393.6421
  },
  {
    "village_id": 102,
    "name_en": "Me'ir shefeya ",
    "name_ar": "مِئير شفِيا",
    "name_ru": "Me'ir shefeya ",
    "name_he": "מאיר שפיה ",
    "x": 197520.899,
    "y": 721903.8736
  },
  {
    "village_id": 103,
    "name_en": "Tel adashim ",
    "name_ar": "تيل عدَشيم",
    "name_ru": "Tel adashim ",
    "name_he": "תל עדשים ",
    "x": 228546.3109,
    "y": 729066.4172
  },
  {
    "village_id": 104,
    "name_en": "Mizra ",
    "name_ar": "مِزراع",
    "name_ru": "Mizra ",
    "name_he": "מזרע ",
    "x": 227214.3525,
    "y": 728506.4119
  },
  {
    "village_id": 106,
    "name_en": "Kefar gid'on ",
    "name_ar": "كفار چِدعون",
    "name_ru": "Kefar gid'on ",
    "name_he": "כפר גדעון ",
    "x": 227598.8095,
    "y": 727737.0446
  },
  {
    "village_id": 107,
    "name_en": "Kefar silver ",
    "name_ar": "كفار سيلڤِر",
    "name_ru": "Kefar silver ",
    "name_he": "כפר סילבר ",
    "x": 163297.5301,
    "y": 620254.3352
  },
  {
    "village_id": 112,
    "name_en": "Kefar hasidim alef ",
    "name_ar": "كفار حسيديم ألف",
    "name_ru": "Kefar hasidim alef ",
    "name_he": "כפר חסידים א' ",
    "x": 209014.4109,
    "y": 739520.2421
  },
  {
    "village_id": 113,
    "name_en": "Addirim ",
    "name_ar": "أديريم",
    "name_ru": "Аддирим",
    "name_he": "אדירים ",
    "x": 225803.3209,
    "y": 717210.5745
  },
  {
    "village_id": 115,
    "name_en": "Hofit ",
    "name_ar": "حوفيت",
    "name_ru": "Hofit ",
    "name_he": "חופית ",
    "x": 188492.4314,
    "y": 699118.1596
  },
  {
    "village_id": 122,
    "name_en": "Ramat yishay ",
    "name_ar": "رَمات يِشاي",
    "name_ru": "Ramat yishay ",
    "name_he": "רמת ישי ",
    "x": 215855.8393,
    "y": 734301.8369
  },
  {
    "village_id": 126,
    "name_en": "Sarid ",
    "name_ar": "سَريد",
    "name_ru": "Sarid ",
    "name_he": "שריד ",
    "x": 221419.534,
    "y": 729870.5892
  },
  {
    "village_id": 127,
    "name_en": "Ramat rahel ",
    "name_ar": "رَمات رَحيل",
    "name_ru": "Рамат-Рахель",
    "name_he": "רמת רחל ",
    "x": 220782.5316,
    "y": 627395.2046
  },
  {
    "village_id": 128,
    "name_en": "Gat rimmon ",
    "name_ar": "چات رِمون",
    "name_ru": "Гат-Римон",
    "name_he": "גת רימון ",
    "x": 188827.478,
    "y": 663956.6817
  },
  {
    "village_id": 130,
    "name_en": "Mishmar haemeq ",
    "name_ar": "مِشمار هعيمِك",
    "name_ru": "Mishmar haemeq ",
    "name_he": "משמר העמק ",
    "x": 213745.6115,
    "y": 723854.7934
  },
  {
    "village_id": 132,
    "name_en": "Kefar barukh ",
    "name_ar": "كفار بَروخ",
    "name_ru": "Kefar barukh ",
    "name_he": "כפר ברוך ",
    "x": 218165.2405,
    "y": 728007.6678
  },
  {
    "village_id": 133,
    "name_en": "Gevat ",
    "name_ar": "چْڤات",
    "name_ru": "Гват",
    "name_he": "גבת ",
    "x": 220180.9381,
    "y": 731178.9668
  },
  {
    "village_id": 134,
    "name_en": "Yif'at ",
    "name_ar": "يِفعات",
    "name_ru": "Yif'at ",
    "name_he": "יפעת ",
    "x": 221360.102,
    "y": 731476.4469
  },
  {
    "village_id": 135,
    "name_en": "Ramat dawid ",
    "name_ar": "رَمات دَڤيد ",
    "name_ru": "Рамат-Давид",
    "name_he": "רמת דוד ",
    "x": 219309.6059,
    "y": 731525.4076
  },
  {
    "village_id": 139,
    "name_en": "En shemer ",
    "name_ar": "عين شيمِر",
    "name_ru": "En shemer ",
    "name_he": "עין שמר ",
    "x": 200963.4887,
    "y": 707695.4983
  },
  {
    "village_id": 140,
    "name_en": "Kefar yehoshua ",
    "name_ar": "كفار يِهوشووَع",
    "name_ru": "Кфар-Иехошуа",
    "name_he": "כפר יהושע ",
    "x": 214543.1664,
    "y": 732087.876
  },
  {
    "village_id": 141,
    "name_en": "Baraq ",
    "name_ar": "براك",
    "name_ru": "Барак",
    "name_he": "ברק ",
    "x": 225151.8273,
    "y": 716541.3781
  },
  {
    "village_id": 142,
    "name_en": "Sede ya'aqov ",
    "name_ar": "سْدي يعكوڤ",
    "name_ru": "Сде-Яков",
    "name_he": "שדה יעקב ",
    "x": 213517.9942,
    "y": 733748.5279
  },
  {
    "village_id": 143,
    "name_en": "Bet zera ",
    "name_ar": "بيت زيرَع",
    "name_ru": "Бейт-Зера",
    "name_he": "בית זרע ",
    "x": 254066.9752,
    "y": 732757.5142
  },
  {
    "village_id": 144,
    "name_en": "Gan shelomo ",
    "name_ar": "چان شموئيل",
    "name_ru": "Gan shelomo ",
    "name_he": "גן שלמה ",
    "x": 181022.9149,
    "y": 642884.4357
  },
  {
    "village_id": 145,
    "name_en": "Gadish ",
    "name_ar": "چديش",
    "name_ru": "Gadish ",
    "name_he": "גדיש ",
    "x": 223284.2047,
    "y": 718293.713
  },
  {
    "village_id": 146,
    "name_en": "Devora ",
    "name_ar": "دڤورا",
    "name_ru": "Девора",
    "name_he": "דבורה ",
    "x": 225102.6812,
    "y": 717593.6474
  },
  {
    "village_id": 147,
    "name_en": "Giv'at brenner ",
    "name_ar": "چِڤعات برينِر",
    "name_ru": "Гиват-Бренер",
    "name_he": "גבעת ברנר ",
    "x": 181272.5998,
    "y": 641718.5331
  },
  {
    "village_id": 154,
    "name_en": "Tel mond ",
    "name_ar": "تيل موند",
    "name_ru": "Tel mond ",
    "name_he": "תל מונד ",
    "x": 192480.4567,
    "y": 684407.1162
  },
  {
    "village_id": 155,
    "name_en": "Be'er tuveya ",
    "name_ar": "بِئير توڤِيا",
    "name_ru": "Беэр-Товия",
    "name_he": "באר טוביה ",
    "x": 173492.7045,
    "y": 626674.3107
  },
  {
    "village_id": 156,
    "name_en": "Ayanot ",
    "name_ar": "عَيَنوت",
    "name_ru": "Ayanot ",
    "name_he": "עיינות ",
    "x": 178186.3045,
    "y": 647019.738
  },
  {
    "village_id": 157,
    "name_en": "En wered ",
    "name_ar": "عين ڤيرِد",
    "name_ru": "En wered ",
    "name_he": "עין ורד ",
    "x": 193770.5858,
    "y": 685704.5698
  },
  {
    "village_id": 158,
    "name_en": "Na'an ",
    "name_ar": "ناعَن",
    "name_ru": "Na'an ",
    "name_he": "נען ",
    "x": 186699.7218,
    "y": 643422.6436
  },
  {
    "village_id": 159,
    "name_en": "Bet hanan ",
    "name_ar": "بيت حَنان",
    "name_ru": "Бейт-Ханан",
    "name_he": "בית חנן ",
    "x": 178543.2918,
    "y": 648784.8714
  },
  {
    "village_id": 160,
    "name_en": "Hulda ",
    "name_ar": "حُلدا",
    "name_ru": "Hulda ",
    "name_he": "חולדה ",
    "x": 188995.6593,
    "y": 637631.8521
  },
  {
    "village_id": 162,
    "name_en": "Herut ",
    "name_ar": "حِروت",
    "name_ru": "Herut ",
    "name_he": "חירות ",
    "x": 192245.8327,
    "y": 682938.0707
  },
  {
    "village_id": 163,
    "name_en": "Timmorim ",
    "name_ar": "تيموريم",
    "name_ru": "Timmorim ",
    "name_he": "תימורים ",
    "x": 177480.708,
    "y": 624750.0767
  },
  {
    "village_id": 164,
    "name_en": "Mele'a ",
    "name_ar": "مِلِئة",
    "name_ru": "Mele'a ",
    "name_he": "מלאה ",
    "x": 222531.6357,
    "y": 718846.0954
  },
  {
    "village_id": 165,
    "name_en": "Nir yafe ",
    "name_ar": "نير يَفي",
    "name_ru": "Nir yafe ",
    "name_he": "ניר יפה ",
    "x": 223365.0561,
    "y": 719558.6515
  },
  {
    "village_id": 166,
    "name_en": "Gan yavne ",
    "name_ar": "چان يَڤني",
    "name_ru": "Ган-Явне",
    "name_he": "גן יבנה ",
    "x": 173197.8992,
    "y": 633415.377
  },
  {
    "village_id": 167,
    "name_en": "En hahoresh ",
    "name_ar": "عين هحوريش",
    "name_ru": "En hahoresh ",
    "name_he": "עין החורש ",
    "x": 194577.5411,
    "y": 699167.7274
  },
  {
    "village_id": 168,
    "name_en": "Kefar yona ",
    "name_ar": "كفار يونا",
    "name_ru": "Кфар-Йона",
    "name_he": "כפר יונה ",
    "x": 193789.3813,
    "y": 691409.4079
  },
  {
    "village_id": 170,
    "name_en": "Kefar ya'bez ",
    "name_ar": "كفار يعبيتْس",
    "name_ru": "Kefar ya'bez ",
    "name_he": "כפר יעבץ ",
    "x": 196962.9275,
    "y": 686733.4737
  },
  {
    "village_id": 171,
    "name_en": "Pardesiyya ",
    "name_ar": "پَرْدِسِيا",
    "name_ru": "Pardesiyya ",
    "name_he": "פרדסיה ",
    "x": 191662.2182,
    "y": 690309.863
  },
  {
    "village_id": 173,
    "name_en": "Giv'at hayyim(me'uha ",
    "name_ar": "چِڤعات حَييم (مِؤُحاد)",
    "name_ru": "Giv'at hayyim(me'uha ",
    "name_he": "גבעת חיים (מאוחד) ",
    "x": 193761.9674,
    "y": 699787.1589
  },
  {
    "village_id": 174,
    "name_en": "Neta'im ",
    "name_ar": "نِتعيم",
    "name_ru": "Neta'im ",
    "name_he": "נטעים ",
    "x": 178757.7873,
    "y": 650271.8746
  },
  {
    "village_id": 175,
    "name_en": "Avihayil ",
    "name_ar": "أڤيحايِل",
    "name_ru": "Авихаил",
    "name_he": "אביחיל ",
    "x": 188164.1043,
    "y": 695191.485
  },
  {
    "village_id": 176,
    "name_en": "Afiqim ",
    "name_ar": "أفيكيم",
    "name_ru": "Afiqim ",
    "name_he": "אפיקים ",
    "x": 254519.1126,
    "y": 731863.7957
  },
  {
    "village_id": 177,
    "name_en": "Kefar bilu ",
    "name_ar": "كفار بيلو",
    "name_ru": "Kefar bilu ",
    "name_he": "כפר ביל\"ו ",
    "x": 183653.1644,
    "y": 642294.2939
  },
  {
    "village_id": 178,
    "name_en": "Ramat yohanan ",
    "name_ar": "رَمات يوحَنان",
    "name_ru": "Ramat yohanan ",
    "name_he": "רמת יוחנן ",
    "x": 211763.332,
    "y": 744232.1214
  },
  {
    "village_id": 182,
    "name_en": "Even yehuda ",
    "name_ar": "إيڤِن يِهودا",
    "name_ru": "Эвен-Йехуда",
    "name_he": "אבן יהודה ",
    "x": 189481.6015,
    "y": 686635.7491
  },
  {
    "village_id": 183,
    "name_en": "Yarqona ",
    "name_ar": "يَركونا",
    "name_ru": "Yarqona ",
    "name_he": "ירקונה ",
    "x": 190485.8284,
    "y": 672408.2633
  },
  {
    "village_id": 184,
    "name_en": "Ramat hakovesh ",
    "name_ar": "رَمات هكوڤيش",
    "name_ru": "Ramat hakovesh ",
    "name_he": "רמת הכובש ",
    "x": 194333.024,
    "y": 680486.9218
  },
  {
    "village_id": 186,
    "name_en": "Ne'urim ",
    "name_ar": "نِعوريم",
    "name_ru": "Ne'urim ",
    "name_he": "נעורים ",
    "x": 187195.6558,
    "y": 697636.8247
  },
  {
    "village_id": 187,
    "name_en": "Kefar hess ",
    "name_ar": "كفار هيس",
    "name_ru": "Kefar hess ",
    "name_he": "כפר הס ",
    "x": 193932.1679,
    "y": 683575.7691
  },
  {
    "village_id": 188,
    "name_en": "Ashdot ya'aqov(me'uh ",
    "name_ar": "أشدوت يعكوڤ (مِؤُحاد)",
    "name_ru": "Ashdot ya'aqov(me'uh ",
    "name_he": "אשדות יעקב (מאוחד) ",
    "x": 254830.3077,
    "y": 730186.501
  },
  {
    "village_id": 189,
    "name_en": "Kefar pines ",
    "name_ar": "كفار پـينِس",
    "name_ru": "Kefar pines ",
    "name_he": "כפר פינס ",
    "x": 200481.4112,
    "y": 710036.2654
  },
  {
    "village_id": 190,
    "name_en": "Kefar vitkin ",
    "name_ar": "كفار ڤيتكين",
    "name_ru": "Кфар-Виткин",
    "name_he": "כפר ויתקין ",
    "x": 188732.7636,
    "y": 698566.2775
  },
  {
    "village_id": 191,
    "name_en": "Hadar am ",
    "name_ar": "هَدار عام",
    "name_ru": "Hadar am ",
    "name_he": "הדר עם ",
    "x": 190944.1352,
    "y": 695074.9764
  },
  {
    "village_id": 192,
    "name_en": "Kefar hahoresh ",
    "name_ar": "كفار هحورِش",
    "name_ru": "Kefar hahoresh ",
    "name_he": "כפר החורש ",
    "x": 225920.4084,
    "y": 733968.5367
  },
  {
    "village_id": 193,
    "name_en": "Kefar hayyim ",
    "name_ar": "كفار حَييم",
    "name_ru": "Kefar hayyim ",
    "name_he": "כפר חיים ",
    "x": 190827.5496,
    "y": 695629.4416
  },
  {
    "village_id": 194,
    "name_en": "Mishmar hasharon ",
    "name_ar": "مِشمار هشَرون",
    "name_ru": "Mishmar hasharon ",
    "name_he": "משמר השרון ",
    "x": 191238.2617,
    "y": 695860.5688
  },
  {
    "village_id": 195,
    "name_en": "Qadima-zoran ",
    "name_ar": "كَديما–تْسُران        ",
    "name_ru": "Кадима-Цоран",
    "name_he": "קדימה-צורן ",
    "x": 192057.2281,
    "y": 687138.921
  },
  {
    "village_id": 196,
    "name_en": "Gibbeton ",
    "name_ar": "چِبتون",
    "name_ru": "Gibbeton ",
    "name_he": "גיבתון ",
    "x": 181187.7809,
    "y": 644051.4592
  },
  {
    "village_id": 197,
    "name_en": "Ma'barot ",
    "name_ar": "معبروت",
    "name_ru": "Ma'barot ",
    "name_he": "מעברות ",
    "x": 191285.1549,
    "y": 696668.4147
  },
  {
    "village_id": 198,
    "name_en": "Zofit ",
    "name_ar": "تْسوفيت",
    "name_ru": "Zofit ",
    "name_he": "צופית ",
    "x": 192837.3305,
    "y": 677588.529
  },
  {
    "village_id": 199,
    "name_en": "Ashdot ya'aqov(ihud) ",
    "name_ar": "أشدوت يعكوڤ (إحود)",
    "name_ru": "Ashdot ya'aqov(ihud) ",
    "name_he": "אשדות יעקב (איחוד) ",
    "x": 254729.0324,
    "y": 729408.6454
  },
  {
    "village_id": 200,
    "name_en": "Bet yannay ",
    "name_ar": "بيت يناي",
    "name_ru": "Bet yannay ",
    "name_he": "בית ינאי ",
    "x": 187357.4614,
    "y": 698496.0667
  },
  {
    "village_id": 202,
    "name_en": "Bet oved ",
    "name_ar": "بيت عوڤيد",
    "name_ru": "Бейт-Овед",
    "name_he": "בית עובד ",
    "x": 178749.2632,
    "y": 647749.7013
  },
  {
    "village_id": 204,
    "name_en": "Elyashiv ",
    "name_ar": "إليَشيڤ",
    "name_ru": "Elyashiv ",
    "name_he": "אלישיב ",
    "x": 191699.2124,
    "y": 698382.4035
  },
  {
    "village_id": 205,
    "name_en": "Hogla ",
    "name_ar": "هوجلا",
    "name_ru": "Hogla ",
    "name_he": "חגלה ",
    "x": 193210.0732,
    "y": 699288.317
  },
  {
    "village_id": 206,
    "name_en": "Ramot hashavim ",
    "name_ar": "رَموت هَشَڤيم",
    "name_ru": "Рамот-Хашавим",
    "name_he": "רמות השבים ",
    "x": 189190.0576,
    "y": 674771.0168
  },
  {
    "village_id": 207,
    "name_en": "Giv'at hen ",
    "name_ar": "چِڤعات حين",
    "name_ru": "Giv'at hen ",
    "name_he": "גבעת ח\"ן ",
    "x": 188669.0234,
    "y": 675076.5036
  },
  {
    "village_id": 208,
    "name_en": "Moza illit ",
    "name_ar": "موتْسا عِليت",
    "name_ru": "Moza illit ",
    "name_he": "מוצא עילית ",
    "x": 214991.9194,
    "y": 633465.4712
  },
  {
    "village_id": 212,
    "name_en": "Bet zevi ",
    "name_ar": "بيت تْسڤي",
    "name_ru": "Bet zevi ",
    "name_he": "בית צבי ",
    "x": 197450.3588,
    "y": 736105.4171
  },
  {
    "village_id": 213,
    "name_en": "Mishmarot ",
    "name_ar": "مِشمَروت",
    "name_ru": "Mishmarot ",
    "name_he": "משמרות ",
    "x": 198757.3537,
    "y": 710412.5141
  },
  {
    "village_id": 217,
    "name_en": "Kefar haro'e ",
    "name_ar": "كفار هروئي",
    "name_ru": "Kefar haro'e ",
    "name_he": "כפר הרא\"ה ",
    "x": 191856.0142,
    "y": 699606.6048
  },
  {
    "village_id": 218,
    "name_en": "Ganne am ",
    "name_ar": "چَني تِكڤا",
    "name_ru": "Ganne am ",
    "name_he": "גני עם ",
    "x": 190962.3878,
    "y": 673110.2989
  },
  {
    "village_id": 219,
    "name_en": "Hibbat ziyyon ",
    "name_ar": "حِبات تْسِيون",
    "name_ru": "Hibbat ziyyon ",
    "name_he": "חיבת ציון ",
    "x": 192016.8409,
    "y": 700452.1966
  },
  {
    "village_id": 220,
    "name_en": "Kefar bialik ",
    "name_ar": "كفار بياليك",
    "name_ru": "Kefar bialik ",
    "name_he": "כפר ביאליק ",
    "x": 208417.0572,
    "y": 747322.2365
  },
  {
    "village_id": 223,
    "name_en": "En iron ",
    "name_ar": "عين عيرون",
    "name_ru": "En iron ",
    "name_he": "עין עירון ",
    "x": 201147.52,
    "y": 709972.0607
  },
  {
    "village_id": 224,
    "name_en": "Shoshannat haamaqim ",
    "name_ar": "شوشنات هعمكيم",
    "name_ru": "Shoshannat haamaqim ",
    "name_he": "שושנת העמקים ",
    "x": 186760.553,
    "y": 695640.3095
  },
  {
    "village_id": 225,
    "name_en": "Gan hashomeron ",
    "name_ar": "چان هشومرون",
    "name_ru": "Gan hashomeron ",
    "name_he": "גן השומרון ",
    "x": 200361.2056,
    "y": 707685.465
  },
  {
    "village_id": 229,
    "name_en": "Ganne tiqwa ",
    "name_ar": "چَنيعام",
    "name_ru": "Ганей-Тиква",
    "name_he": "גני תקווה ",
    "x": 188390.2327,
    "y": 663143.6488
  },
  {
    "village_id": 230,
    "name_en": "Ma'as ",
    "name_ar": "ماعَس",
    "name_ru": "Ma'as ",
    "name_he": "מעש ",
    "x": 189893.751,
    "y": 663424.0099
  },
  {
    "village_id": 232,
    "name_en": "Shefayim ",
    "name_ar": "شْفييم",
    "name_ru": "Shefayim ",
    "name_he": "שפיים ",
    "x": 183908.6522,
    "y": 680752.1156
  },
  {
    "village_id": 233,
    "name_en": "Yedidya ",
    "name_ar": "كفار يِديديا",
    "name_ru": "Yedidya ",
    "name_he": "כפר ידידיה ",
    "x": 190660.2774,
    "y": 694690.7176
  },
  {
    "village_id": 234,
    "name_en": "Bizzaron ",
    "name_ar": "بِتسَرون",
    "name_ru": "Бизарон",
    "name_he": "ביצרון ",
    "x": 174546.9148,
    "y": 633807.4743
  },
  {
    "village_id": 235,
    "name_en": "Havazzelet hasharon ",
    "name_ar": "حڤتسيلِت هشَرون",
    "name_ru": "Havazzelet hasharon ",
    "name_he": "חבצלת השרון ",
    "x": 187302.2617,
    "y": 696380.5819
  },
  {
    "village_id": 237,
    "name_en": "Sha'ar haamaqim ",
    "name_ar": "شاعَر هعمكيم",
    "name_ru": "Sha'ar haamaqim ",
    "name_he": "שער העמקים ",
    "x": 210827.3154,
    "y": 736580.7186
  },
  {
    "village_id": 239,
    "name_en": "Gan hayyim ",
    "name_ar": "چان حَييم",
    "name_ru": "Ган-Хаим",
    "name_he": "גן חיים ",
    "x": 191305.8825,
    "y": 677992.5545
  },
  {
    "village_id": 240,
    "name_en": "Yoqne'am illit ",
    "name_ar": "يُكنِعام عِليت",
    "name_ru": "Йокнеам-Илит",
    "name_he": "יקנעם עילית ",
    "x": 210054.2295,
    "y": 729138.0086
  },
  {
    "village_id": 241,
    "name_en": "Yoqne'am(moshava) ",
    "name_ar": "يُكنِعام (موشَڤا)",
    "name_ru": "Йокнеам (мошава)",
    "name_he": "יקנעם (מושבה) ",
    "x": 211036.1447,
    "y": 728916.1199
  },
  {
    "village_id": 242,
    "name_en": "Bet hashitta ",
    "name_ar": "بيت هشِتا",
    "name_ru": "Бейт-ха-Шита",
    "name_he": "בית השיטה ",
    "x": 241517.8852,
    "y": 717384.1907
  },
  {
    "village_id": 246,
    "name_en": "Netivot ",
    "name_ar": "نِتيڤوت",
    "name_ru": "Нетивот",
    "name_he": "נתיבות ",
    "x": 160457.8459,
    "y": 592170.0098
  },
  {
    "village_id": 247,
    "name_en": "Rishpon ",
    "name_ar": "رِشپون",
    "name_ru": "Rishpon ",
    "name_he": "רשפון ",
    "x": 183602.5026,
    "y": 678548.2606
  },
  {
    "village_id": 248,
    "name_en": "Bet she'arim ",
    "name_ar": "بيت شِعريم",
    "name_ru": "Бейт Шаарим",
    "name_he": "בית שערים ",
    "x": 216940.0525,
    "y": 733480.2549
  },
  {
    "village_id": 249,
    "name_en": "Kefar sirkin ",
    "name_ar": "كفار سيركين",
    "name_ru": "Kefar sirkin ",
    "name_he": "כפר סירקין ",
    "x": 193009.9031,
    "y": 664837.8475
  },
  {
    "village_id": 250,
    "name_en": "Hazorea ",
    "name_ar": "هزورعيم",
    "name_ru": "Hazorea ",
    "name_he": "הזורע ",
    "x": 211534.4049,
    "y": 727865.2602
  },
  {
    "village_id": 252,
    "name_en": "Bitan aharon ",
    "name_ar": "بيتان أهرون",
    "name_ru": "Bitan aharon ",
    "name_he": "ביתן אהרן ",
    "x": 188040.8999,
    "y": 696791.3433
  },
  {
    "village_id": 253,
    "name_en": "Hulata ",
    "name_ar": "حولاتا",
    "name_ru": "Hulata ",
    "name_he": "חולתה ",
    "x": 257329.4735,
    "y": 773009.2736
  },
  {
    "village_id": 254,
    "name_en": "Kefar hamakkabi ",
    "name_ar": "كفار همَكَبي",
    "name_ru": "Kefar hamakkabi ",
    "name_he": "כפר המכבי ",
    "x": 211118.5366,
    "y": 743994.2937
  },
  {
    "village_id": 255,
    "name_en": "Kefar hittim ",
    "name_ar": "كفار حِتيم",
    "name_ru": "Kefar hittim ",
    "name_he": "כפר חיטים ",
    "x": 247440.5381,
    "y": 745178.5523
  },
  {
    "village_id": 256,
    "name_en": "Nir dawid (tel amal) ",
    "name_ar": "تيل عَمال (نير دَڤيد)",
    "name_ru": "Nir dawid (tel amal) ",
    "name_he": "ניר דוד (תל עמל) ",
    "x": 243299.3894,
    "y": 712195.5055
  },
  {
    "village_id": 257,
    "name_en": "Nofekh ",
    "name_ar": "نوفِخ",
    "name_ru": "Nofekh ",
    "name_he": "נופך ",
    "x": 192626.7179,
    "y": 661197.9281
  },
  {
    "village_id": 259,
    "name_en": "Sede nahum ",
    "name_ar": "سْدي نَحوم",
    "name_ru": "Сде-Наум",
    "name_he": "שדה נחום ",
    "x": 245528.1306,
    "y": 714653.6347
  },
  {
    "village_id": 262,
    "name_en": "Ginnosar ",
    "name_ar": "چِنوسار",
    "name_ru": "Гиносар",
    "name_he": "גינוסר ",
    "x": 249346.5395,
    "y": 750414.8158
  },
  {
    "village_id": 263,
    "name_en": "Massada ",
    "name_ar": "مَسَدا",
    "name_ru": "Massada ",
    "name_he": "מסדה ",
    "x": 256417.449,
    "y": 732111.8396
  },
  {
    "village_id": 264,
    "name_en": "Sha'ar hagolan ",
    "name_ar": "شاعَر هچولان",
    "name_ru": "Sha'ar hagolan ",
    "name_he": "שער הגולן ",
    "x": 256950.916,
    "y": 732533.1774
  },
  {
    "village_id": 265,
    "name_en": "Bet yosef ",
    "name_ar": "بيت يوسيف",
    "name_ru": "Bet yosef ",
    "name_he": "בית יוסף ",
    "x": 251646.4699,
    "y": 718092.8575
  },
  {
    "village_id": 267,
    "name_en": "Kefar shemaryahu ",
    "name_ar": "كفار شموئيل",
    "name_ru": "Кфар-Шмарьягу",
    "name_he": "כפר שמריהו ",
    "x": 183287.8371,
    "y": 677054.8301
  },
  {
    "village_id": 268,
    "name_en": "Tirat zevi ",
    "name_ar": "تيرات تْسڤي",
    "name_ru": "Tirat zevi ",
    "name_he": "טירת צבי ",
    "x": 249874.0761,
    "y": 703086.7861
  },
  {
    "village_id": 269,
    "name_en": "Moledet ",
    "name_ar": "موليدِت",
    "name_ru": "Moledet ",
    "name_he": "מולדת ",
    "x": 241743.6581,
    "y": 721276.378
  },
  {
    "village_id": 270,
    "name_en": "En hashofet ",
    "name_ar": "عين هشوفيت",
    "name_ru": "En hashofet ",
    "name_he": "עין השופט ",
    "x": 209724.6126,
    "y": 722388.8266
  },
  {
    "village_id": 272,
    "name_en": "Ma'oz hayyim ",
    "name_ar": "مَعوز حَييم",
    "name_ru": "Ma'oz hayyim ",
    "name_he": "מעוז חיים ",
    "x": 252042.5728,
    "y": 711051.7057
  },
  {
    "village_id": 273,
    "name_en": "En gev ",
    "name_ar": "عين چيڤ",
    "name_ru": "En gev ",
    "name_he": "עין גב ",
    "x": 260201.556,
    "y": 742949.0882
  },
  {
    "village_id": 274,
    "name_en": "Kefar menahem ",
    "name_ar": "كفر مندا",
    "name_ru": "Kefar menahem ",
    "name_he": "כפר מנחם ",
    "x": 184379.9592,
    "y": 626549.817
  },
  {
    "village_id": 276,
    "name_en": "Zur moshe ",
    "name_ar": "تْسور مُشي",
    "name_ru": "Zur moshe ",
    "name_he": "צור משה ",
    "x": 192040.143,
    "y": 689482.0497
  },
  {
    "village_id": 278,
    "name_en": "Usha ",
    "name_ar": "أوشا",
    "name_ru": "Usha ",
    "name_he": "אושה ",
    "x": 210995.2155,
    "y": 744576.3809
  },
  {
    "village_id": 280,
    "name_en": "Hanita ",
    "name_ar": "حَنيتا",
    "name_ru": "Hanita ",
    "name_he": "חניתה ",
    "x": 216619.4901,
    "y": 776984.81
  },
  {
    "village_id": 281,
    "name_en": "Peqi'in hadasha ",
    "name_ar": "پكيعين هحدشا",
    "name_ru": "Peqi'in hadasha ",
    "name_he": "פקיעין חדשה ",
    "x": 230577.7673,
    "y": 765267.1271
  },
  {
    "village_id": 282,
    "name_en": "Shave ziyyon ",
    "name_ar": "شَڤي تْسِيون",
    "name_ru": "Shave ziyyon ",
    "name_he": "שבי ציון ",
    "x": 208209.0149,
    "y": 765149.3547
  },
  {
    "village_id": 284,
    "name_en": "Sede warburg ",
    "name_ar": "سْدي ڤاربورچ",
    "name_ru": "Сде-Варбург",
    "name_he": "שדה ורבורג ",
    "x": 190510.0006,
    "y": 679556.6064
  },
  {
    "village_id": 285,
    "name_en": "Allonim ",
    "name_ar": "ألونيم",
    "name_ru": "Алоним",
    "name_he": "אלונים ",
    "x": 213809.3611,
    "y": 736259.8391
  },
  {
    "village_id": 286,
    "name_en": "Ma'ale hahamisha ",
    "name_ar": "مَعلي هحمِشا",
    "name_ru": "Ma'ale hahamisha ",
    "name_he": "מעלה החמישה ",
    "x": 210640.286,
    "y": 636102.1862
  },
  {
    "village_id": 287,
    "name_en": "Tel yizhaq ",
    "name_ar": "تيل يِتسحاك",
    "name_ru": "Tel yizhaq ",
    "name_he": "תל יצחק ",
    "x": 188110.0168,
    "y": 684357.8393
  },
  {
    "village_id": 288,
    "name_en": "Bet yehoshua ",
    "name_ar": "بيت يِهوشووَع",
    "name_ru": "Бейт-Иехошуа",
    "name_he": "בית יהושע ",
    "x": 187378.5284,
    "y": 685230.46
  },
  {
    "village_id": 289,
    "name_en": "En hamifraz ",
    "name_ar": "عين همِفراتْس",
    "name_ru": "En hamifraz ",
    "name_he": "עין המפרץ ",
    "x": 209350.4194,
    "y": 756529.7253
  },
  {
    "village_id": 290,
    "name_en": "Ma'yan zevi ",
    "name_ar": "مَعيان تْسڤي",
    "name_ru": "Ma'yan zevi ",
    "name_he": "מעין צבי ",
    "x": 194658.789,
    "y": 719320.082
  },
  {
    "village_id": 292,
    "name_en": "Sharona ",
    "name_ar": "شَرونا",
    "name_ru": "Sharona ",
    "name_he": "שרונה ",
    "x": 243720.4734,
    "y": 737105.123
  },
  {
    "village_id": 293,
    "name_en": "Sede yo'av ",
    "name_ar": "سْدي يوآڤ",
    "name_ru": "Сде-Йоав",
    "name_he": "שדה יואב ",
    "x": 169847.4483,
    "y": 616877.7848
  },
  {
    "village_id": 294,
    "name_en": "Elon ",
    "name_ar": "إيلون",
    "name_ru": "Elon ",
    "name_he": "אילון ",
    "x": 220697.712,
    "y": 774302.268
  },
  {
    "village_id": 295,
    "name_en": "Kefar ruppin ",
    "name_ar": "كفار روپين",
    "name_ru": "Kefar ruppin ",
    "name_he": "כפר רופין ",
    "x": 252570.3955,
    "y": 707117.2383
  },
  {
    "village_id": 296,
    "name_en": "Newe etan ",
    "name_ar": "نِڤي إيتان",
    "name_ru": "Newe etan ",
    "name_he": "נווה איתן ",
    "x": 250304.2789,
    "y": 710915.2546
  },
  {
    "village_id": 297,
    "name_en": "Kefar masaryk ",
    "name_ar": "كفار مِنَحيم",
    "name_ru": "Kefar masaryk ",
    "name_he": "כפר מסריק ",
    "x": 209617.5118,
    "y": 755015.4951
  },
  {
    "village_id": 298,
    "name_en": "Mesillot ",
    "name_ar": "مِسِلوت",
    "name_ru": "Mesillot ",
    "name_he": "מסילות ",
    "x": 244830.0424,
    "y": 711268.4652
  },
  {
    "village_id": 300,
    "name_en": "Daliyya ",
    "name_ar": "دلِيا",
    "name_ru": "Далия ",
    "name_he": "דליה ",
    "x": 207309.4762,
    "y": 721734.0571
  },
  {
    "village_id": 301,
    "name_en": "Bet uzzi'el ",
    "name_ar": "بيت عُزيئيل",
    "name_ru": "Bet uzzi'el ",
    "name_he": "בית עוזיאל ",
    "x": 191207.4576,
    "y": 642026.0762
  },
  {
    "village_id": 302,
    "name_en": "Dafna ",
    "name_ar": "دَفنا",
    "name_ru": "Дафна ",
    "name_he": "דפנה ",
    "x": 259908.7259,
    "y": 792811.2023
  },
  {
    "village_id": 303,
    "name_en": "Dan ",
    "name_ar": "دان",
    "name_ru": "Дан ",
    "name_he": "דן ",
    "x": 261194.1904,
    "y": 793987.5523
  },
  {
    "village_id": 304,
    "name_en": "Sede eliyyahu ",
    "name_ar": "سْدي إلياهو",
    "name_ru": "Сде-Элиягу",
    "name_he": "שדה אליהו ",
    "x": 248592.5554,
    "y": 705282.5494
  },
  {
    "village_id": 305,
    "name_en": "Gesher ",
    "name_ar": "چيشِر",
    "name_ru": "Gesher ",
    "name_he": "גשר ",
    "x": 252023.6148,
    "y": 725082.7014
  },
  {
    "village_id": 306,
    "name_en": "Shadmot devora ",
    "name_ar": "شَدْموت دْڤورا",
    "name_ru": "Shadmot devora ",
    "name_he": "שדמות דבורה ",
    "x": 241121.4025,
    "y": 733582.2834
  },
  {
    "village_id": 307,
    "name_en": "Hazore'im ",
    "name_ar": "هزيل",
    "name_ru": "Hazore'im ",
    "name_he": "הזורעים ",
    "x": 247328.3621,
    "y": 739149.9517
  },
  {
    "village_id": 308,
    "name_en": "Mahanayim ",
    "name_ar": "محَنايم",
    "name_ru": "Mahanayim ",
    "name_he": "מחניים ",
    "x": 253764.0438,
    "y": 766091.0987
  },
  {
    "village_id": 309,
    "name_en": "Nehora ",
    "name_ar": "نِهورا",
    "name_ru": "Nehora ",
    "name_he": "נהורה ",
    "x": 172089.475,
    "y": 614615.6546
  },
  {
    "village_id": 310,
    "name_en": "Kefar glikson ",
    "name_ar": "كفار چْليكْسون",
    "name_ru": "Kefar glikson ",
    "name_he": "כפר גליקסון ",
    "x": 200717.7656,
    "y": 712440.974
  },
  {
    "village_id": 311,
    "name_en": "Gan soreq ",
    "name_ar": "چان شلُمو",
    "name_ru": "Ган-Сорек",
    "name_he": "גן שורק ",
    "x": 177315.6832,
    "y": 650257.0131
  },
  {
    "village_id": 312,
    "name_en": "Newe yam ",
    "name_ar": "نِڤي يام",
    "name_ru": "Newe yam ",
    "name_he": "נווה ים ",
    "x": 193874.7925,
    "y": 731617.1208
  },
  {
    "village_id": 313,
    "name_en": "Afeq ",
    "name_ar": "أفيك",
    "name_ru": "Afeq ",
    "name_he": "אפק ",
    "x": 212302.2066,
    "y": 749405.8343
  },
  {
    "village_id": 315,
    "name_en": "Negba ",
    "name_ar": "نيچْبا",
    "name_ru": "Negba ",
    "name_he": "נגבה ",
    "x": 170022.2697,
    "y": 618825.8843
  },
  {
    "village_id": 316,
    "name_en": "Kefar netter ",
    "name_ar": "كفار نيتِر",
    "name_ru": "Kefar netter ",
    "name_he": "כפר נטר ",
    "x": 188437.1736,
    "y": 687179.7648
  },
  {
    "village_id": 317,
    "name_en": "Bet oren ",
    "name_ar": "بيت أورِن",
    "name_ru": "Бейт-Орен",
    "name_he": "בית אורן ",
    "x": 200721.7223,
    "y": 737465.1953
  },
  {
    "village_id": 318,
    "name_en": "Ammi'oz ",
    "name_ar": "عميعوز",
    "name_ru": "Ammi'oz ",
    "name_he": "עמיעוז ",
    "x": 144115.2795,
    "y": 573271.8135
  },
  {
    "village_id": 319,
    "name_en": "Amir ",
    "name_ar": "عمير",
    "name_ru": "Amir ",
    "name_he": "עמיר ",
    "x": 258320.8701,
    "y": 786981.5068
  },
  {
    "village_id": 320,
    "name_en": "Kefar warburg ",
    "name_ar": "كفار ڤاربورچ",
    "name_ru": "Kefar warburg ",
    "name_he": "כפר ורבורג ",
    "x": 174062.8415,
    "y": 625302.6218
  },
  {
    "village_id": 322,
    "name_en": "Bet hillel ",
    "name_ar": "بيت هِليل",
    "name_ru": "Бейт-Хиллель",
    "name_he": "בית הלל ",
    "x": 256823.2959,
    "y": 790521.7135
  },
  {
    "village_id": 324,
    "name_en": "She'ar yashuv ",
    "name_ar": "شآر يَشوڤ",
    "name_ru": "She'ar yashuv ",
    "name_he": "שאר ישוב ",
    "x": 261006.8605,
    "y": 792805.6051
  },
  {
    "village_id": 325,
    "name_en": "Mazzuva ",
    "name_ar": "مَتْسوڤا",
    "name_ru": "Mazzuva ",
    "name_he": "מצובה ",
    "x": 215075.9351,
    "y": 774152.2225
  },
  {
    "village_id": 326,
    "name_en": "Bet yizhaq-sh. hefer ",
    "name_ar": "بيت يِتسحاك – شاعَر حيفِر",
    "name_ru": "Bet yizhaq-sh. hefer ",
    "name_he": "בית יצחק-שער חפר ",
    "x": 189902.3423,
    "y": 693375.4245
  },
  {
    "village_id": 327,
    "name_en": "Sedot yam ",
    "name_ar": "سْدوت يام",
    "name_ru": "Sedot yam ",
    "name_he": "שדות ים ",
    "x": 190255.0594,
    "y": 711039.5762
  },
  {
    "village_id": 328,
    "name_en": "Ezuz ",
    "name_ar": "عِزوز",
    "name_ru": "Ezuz ",
    "name_he": "עזוז ",
    "x": 149411.1489,
    "y": 522613.8486
  },
  {
    "village_id": 329,
    "name_en": "Sede nehemya ",
    "name_ar": "سْدي نِحِمْيا",
    "name_ru": "Sede nehemya ",
    "name_he": "שדה נחמיה ",
    "x": 258531.0301,
    "y": 787943.0574
  },
  {
    "village_id": 330,
    "name_en": "Alummot ",
    "name_ar": "ألُموت",
    "name_ru": "Alummot ",
    "name_he": "אלומות ",
    "x": 251469.451,
    "y": 734810.2551
  },
  {
    "village_id": 331,
    "name_en": "Nir zevi ",
    "name_ar": "نير تْسڤي",
    "name_ru": "Nir zevi ",
    "name_he": "ניר צבי ",
    "x": 187234.0249,
    "y": 650936.9576
  },
  {
    "village_id": 334,
    "name_en": "Qevuzat yavne ",
    "name_ar": "كْڤوتْسات يڤني",
    "name_ru": "Qevuzat yavne ",
    "name_he": "קבוצת יבנה ",
    "x": 173601.5289,
    "y": 635913.5116
  },
  {
    "village_id": 335,
    "name_en": "Ramat hashofet ",
    "name_ar": "رَمات هشوفيت",
    "name_ru": "Ramat hashofet ",
    "name_he": "רמת השופט ",
    "x": 209388.799,
    "y": 724149.5105
  },
  {
    "village_id": 336,
    "name_en": "Dorot ",
    "name_ar": "دوروت",
    "name_ru": "Dorot ",
    "name_he": "דורות ",
    "x": 166416.2868,
    "y": 601633.6697
  },
  {
    "village_id": 338,
    "name_en": "Ibbim ",
    "name_ar": "إبيم",
    "name_ru": "Ибим",
    "name_he": "איבים ",
    "x": 163021.2301,
    "y": 604728.9419
  },
  {
    "village_id": 339,
    "name_en": "Ramat zevi ",
    "name_ar": "رَمات تْسڤي",
    "name_ru": "Ramat zevi ",
    "name_he": "רמת צבי ",
    "x": 239134.1242,
    "y": 721917.1647
  },
  {
    "village_id": 340,
    "name_en": "Gat(qibbuz) ",
    "name_ar": "چات ",
    "name_ru": "Гат",
    "name_he": "גת (קיבוץ) ",
    "x": 180509.4906,
    "y": 615062.9735
  },
  {
    "village_id": 342,
    "name_en": "Gevar'am ",
    "name_ar": "چڤَرعام",
    "name_ru": "Gevar'am ",
    "name_he": "גברעם ",
    "x": 163246.3208,
    "y": 611224.5291
  },
  {
    "village_id": 343,
    "name_en": "Hamadya ",
    "name_ar": "حمَدْيا",
    "name_ru": "Hamadya ",
    "name_he": "חמדיה ",
    "x": 249155.3054,
    "y": 714010.4532
  },
  {
    "village_id": 344,
    "name_en": "Ma'anit ",
    "name_ar": "معنيت",
    "name_ru": "Ma'anit ",
    "name_he": "מענית ",
    "x": 202937.0323,
    "y": 707003.1197
  },
  {
    "village_id": 345,
    "name_en": "Kefar szold ",
    "name_ar": "كفار سولد",
    "name_ru": "Kefar szold ",
    "name_he": "כפר סאלד ",
    "x": 261698.4866,
    "y": 788904.1534
  },
  {
    "village_id": 346,
    "name_en": "Gelil yam ",
    "name_ar": "چْليل يام",
    "name_ru": "Gelil yam ",
    "name_he": "גליל ים ",
    "x": 184204.3297,
    "y": 673863.4292
  },
  {
    "village_id": 347,
    "name_en": "Mennara ",
    "name_ar": "منارة",
    "name_ru": "Mennara ",
    "name_he": "מנרה ",
    "x": 251152.0048,
    "y": 789038.128
  },
  {
    "village_id": 348,
    "name_en": "Nir am ",
    "name_ar": "نير عام",
    "name_ru": "Nir am ",
    "name_he": "ניר עם ",
    "x": 160218.4585,
    "y": 603362.4625
  },
  {
    "village_id": 351,
    "name_en": "Nizzan ",
    "name_ar": "نِتْسان",
    "name_ru": "Nizzan ",
    "name_he": "ניצן ",
    "x": 165130.3667,
    "y": 627623.4395
  },
  {
    "village_id": 352,
    "name_en": "Gevulot ",
    "name_ar": "چڤولوت",
    "name_ru": "Gevulot ",
    "name_he": "גבולות ",
    "x": 149065.5261,
    "y": 569016.2065
  },
  {
    "village_id": 353,
    "name_en": "Bet zeid ",
    "name_ar": "بيت زايد",
    "name_ru": "Bet zeid ",
    "name_he": "בית זיד ",
    "x": 211019.5359,
    "y": 734145.8914
  },
  {
    "village_id": 354,
    "name_en": "Revivim ",
    "name_ar": "رِڤيڤيم",
    "name_ru": "Revivim ",
    "name_he": "רביבים ",
    "x": 173336.2307,
    "y": 550308.4337
  },
  {
    "village_id": 355,
    "name_en": "Horeshim ",
    "name_ar": "حورشيم",
    "name_ru": "Хоршим",
    "name_he": "חורשים ",
    "x": 197393.2671,
    "y": 671666.8346
  },
  {
    "village_id": 356,
    "name_en": "Hagosherim ",
    "name_ar": "هچوشريم",
    "name_ru": "Hagosherim ",
    "name_he": "הגושרים ",
    "x": 258603.0385,
    "y": 791762.7926
  },
  {
    "village_id": 357,
    "name_en": "Kefar blum ",
    "name_ar": "كفار بْلوم",
    "name_ru": "Kefar blum ",
    "name_he": "כפר בלום ",
    "x": 257196.3645,
    "y": 786495.6291
  },
  {
    "village_id": 358,
    "name_en": "Yad mordekhay ",
    "name_ar": "ياد مُردِخاي",
    "name_ru": "ЯД МОРДЕХАЙ",
    "name_he": "יד מרדכי ",
    "x": 158093.6072,
    "y": 610828.5567
  },
  {
    "village_id": 359,
    "name_en": "Nizzanim ",
    "name_ar": "نِتْسَنيم",
    "name_ru": "Nizzanim ",
    "name_he": "ניצנים ",
    "x": 165495.2253,
    "y": 625032.6194
  },
  {
    "village_id": 360,
    "name_en": "Giv'at nili ",
    "name_ar": "چِڤعات نيلي",
    "name_ru": "Giv'at nili ",
    "name_he": "גבעת ניל\"י ",
    "x": 204163.8494,
    "y": 717076.7304
  },
  {
    "village_id": 362,
    "name_en": "Ruhama ",
    "name_ar": "رُحاما",
    "name_ru": "Ruhama ",
    "name_he": "רוחמה ",
    "x": 172002.9267,
    "y": 600646.905
  },
  {
    "village_id": 363,
    "name_en": "Hafez hayyim ",
    "name_ar": "حَفيتس حَييم",
    "name_ru": "Хафец-Хаим",
    "name_he": "חפץ חיים ",
    "x": 181205.1057,
    "y": 633001.926
  },
  {
    "village_id": 364,
    "name_en": "Kefar uriyya ",
    "name_ar": "كفار أورِيا",
    "name_ru": "Kefar uriyya ",
    "name_he": "כפר אוריה ",
    "x": 195070.576,
    "y": 633428.4041
  },
  {
    "village_id": 365,
    "name_en": "Bet qeshet ",
    "name_ar": "بيت كيشِت",
    "name_ru": "Bet qeshet ",
    "name_he": "בית קשת ",
    "x": 237207.9946,
    "y": 736025.0314
  },
  {
    "village_id": 366,
    "name_en": "Shamir ",
    "name_ar": "شَمير",
    "name_ru": "Shamir ",
    "name_he": "שמיר ",
    "x": 261960.152,
    "y": 785696.5892
  },
  {
    "village_id": 367,
    "name_en": "En haemeq ",
    "name_ar": "عين هعيمِك",
    "name_ru": "En haemeq ",
    "name_he": "עין העמק ",
    "x": 207879.3812,
    "y": 726204.834
  },
  {
    "village_id": 368,
    "name_en": "Biriyya ",
    "name_ar": "بيريا",
    "name_ru": "Biriyya ",
    "name_he": "ביריה ",
    "x": 247094.5446,
    "y": 764837.2932
  },
  {
    "village_id": 369,
    "name_en": "Even yizhaq(gal'ed) ",
    "name_ar": "چَلعيد (إيڤِن يِتسحاك)",
    "name_ru": "Even yizhaq(gal'ed) ",
    "name_he": "גלעד (אבן יצחק) ",
    "x": 207410.5737,
    "y": 718087.2463
  },
  {
    "village_id": 370,
    "name_en": "Gezer ",
    "name_ar": "چيزِر",
    "name_ru": "Gezer ",
    "name_he": "גזר ",
    "x": 192589.1523,
    "y": 642643.5638
  },
  {
    "village_id": 371,
    "name_en": "Kadoorie ",
    "name_ar": "كَدوري",
    "name_ru": "Kadoorie ",
    "name_he": "כדורי ",
    "x": 238422.5701,
    "y": 734253.3341
  },
  {
    "village_id": 372,
    "name_en": "Ramot naftali ",
    "name_ar": "رَموت نفتلي",
    "name_ru": "Рамот-Нафтали",
    "name_he": "רמות נפתלי ",
    "x": 252009.5191,
    "y": 778455.1
  },
  {
    "village_id": 373,
    "name_en": "Bet halevi ",
    "name_ar": "بيت هلِڤي",
    "name_ru": "Bet halevi ",
    "name_he": "בית הלוי ",
    "x": 193794.4794,
    "y": 695561.1364
  },
  {
    "village_id": 374,
    "name_en": "Huqoq ",
    "name_ar": "حوكوك",
    "name_ru": "Huqoq ",
    "name_he": "חוקוק ",
    "x": 246689.7955,
    "y": 753947.1591
  },
  {
    "village_id": 375,
    "name_en": "Maggal ",
    "name_ar": "مَچال",
    "name_ru": "Maggal ",
    "name_he": "מגל ",
    "x": 203486.1624,
    "y": 699094.8998
  },
  {
    "village_id": 376,
    "name_en": "Evron ",
    "name_ar": "عِڤرون",
    "name_ru": "Evron ",
    "name_he": "עברון ",
    "x": 209759.9215,
    "y": 766320.7424
  },
  {
    "village_id": 377,
    "name_en": "Hama'pil ",
    "name_ar": "همَعپيل",
    "name_ru": "Hama'pil ",
    "name_he": "המעפיל ",
    "x": 198650.051,
    "y": 698259.3585
  },
  {
    "village_id": 378,
    "name_en": "Misgav am ",
    "name_ar": "مِسچاڤ عَام",
    "name_ru": "Misgav am ",
    "name_he": "משגב עם ",
    "x": 251561.2135,
    "y": 794706.4122
  },
  {
    "village_id": 379,
    "name_en": "Ge'ulim ",
    "name_ar": "چِؤوليم ",
    "name_ru": "Ge'ulim ",
    "name_he": "גאולים ",
    "x": 195453.7738,
    "y": 689305.7921
  },
  {
    "village_id": 380,
    "name_en": "Lahavot habashan ",
    "name_ar": "لهڤوت هبَشان",
    "name_ru": "Lahavot habashan ",
    "name_he": "להבות הבשן ",
    "x": 260842.0149,
    "y": 782879.8809
  },
  {
    "village_id": 382,
    "name_en": "Mikhmoret ",
    "name_ar": "مِخْمورِت",
    "name_ru": "Mikhmoret ",
    "name_he": "מכמורת ",
    "x": 188195.8455,
    "y": 701362.2662
  },
  {
    "village_id": 383,
    "name_en": "En hanaziv ",
    "name_ar": "عين هَنِتْسيڤ",
    "name_ru": "En hanaziv ",
    "name_he": "עין הנצי\"ב ",
    "x": 247458.7371,
    "y": 708443.826
  },
  {
    "village_id": 385,
    "name_en": "Ammi'ad ",
    "name_ar": "عميعاد",
    "name_ru": "Ammi'ad ",
    "name_he": "עמיעד ",
    "x": 250967.9684,
    "y": 759320.1634
  },
  {
    "village_id": 386,
    "name_en": "Bene deror ",
    "name_ar": "بني درور",
    "name_ru": "Бней-Дрор",
    "name_he": "בני דרור ",
    "x": 190636.6484,
    "y": 685416.315
  },
  {
    "village_id": 387,
    "name_en": "Kefar monash ",
    "name_ar": "كفار مونَش",
    "name_ru": "Kefar monash ",
    "name_he": "כפר מונש ",
    "x": 192324.6383,
    "y": 694921.4683
  },
  {
    "village_id": 388,
    "name_en": "Kefar kish ",
    "name_ar": "كفار كيش",
    "name_ru": "Kefar kish ",
    "name_he": "כפר קיש ",
    "x": 242411.6632,
    "y": 730314.8493
  },
  {
    "village_id": 389,
    "name_en": "Bazra ",
    "name_ar": "البعنة",
    "name_ru": "Bazra ",
    "name_he": "בצרה ",
    "x": 188809.1919,
    "y": 680072.9877
  },
  {
    "village_id": 390,
    "name_en": "Regba ",
    "name_ar": "ريچبا",
    "name_ru": "Regba ",
    "name_he": "רגבה ",
    "x": 209566.9615,
    "y": 764578.2939
  },
  {
    "village_id": 392,
    "name_en": "Qedma ",
    "name_ar": "كيدما",
    "name_ru": "Qedma ",
    "name_he": "קדמה ",
    "x": 178824.8646,
    "y": 623300.8755
  },
  {
    "village_id": 393,
    "name_en": "Gal'on ",
    "name_ar": "چَلؤون",
    "name_ru": "Gal'on ",
    "name_he": "גלאון ",
    "x": 185630.0714,
    "y": 615712.754
  },
  {
    "village_id": 394,
    "name_en": "Shoval ",
    "name_ar": "شوڤال",
    "name_ru": "Shoval ",
    "name_he": "שובל ",
    "x": 175289.0949,
    "y": 591434.1056
  },
  {
    "village_id": 395,
    "name_en": "Mishmar hanegev ",
    "name_ar": "مِشمار هنيچِڤ",
    "name_ru": "Mishmar hanegev ",
    "name_he": "משמר הנגב ",
    "x": 173178.949,
    "y": 585873.0718
  },
  {
    "village_id": 396,
    "name_en": "Nevatim ",
    "name_ar": "نِڤتيم",
    "name_ru": "Nevatim ",
    "name_he": "נבטים ",
    "x": 188659.375,
    "y": 570257.99
  },
  {
    "village_id": 397,
    "name_en": "Hazerim ",
    "name_ar": "حتْسِريم",
    "name_ru": "Хацерим",
    "name_he": "חצרים ",
    "x": 172789.7937,
    "y": 572097.833
  },
  {
    "village_id": 398,
    "name_en": "Sharsheret ",
    "name_ar": "شَرشيرِت",
    "name_ru": "Sharsheret ",
    "name_he": "שרשרת ",
    "x": 162320.8026,
    "y": 590425.5298
  },
  {
    "village_id": 399,
    "name_en": "Be'eri ",
    "name_ar": "بِئِري ",
    "name_ru": "Беэри",
    "name_he": "בארי ",
    "x": 151665.5291,
    "y": 592697.8044
  },
  {
    "village_id": 400,
    "name_en": "Even shemu'el ",
    "name_ar": "إيڤِن شموئيل",
    "name_ru": "Эвен Шмуэль",
    "name_he": "אבן שמואל ",
    "x": 177807.8743,
    "y": 609259.6689
  },
  {
    "village_id": 402,
    "name_en": "Nir yizhaq ",
    "name_ar": "نير يِتسحاك",
    "name_ru": "Nir yizhaq ",
    "name_he": "ניר יצחק ",
    "x": 138773.805,
    "y": 572044.4896
  },
  {
    "village_id": 403,
    "name_en": "Urim ",
    "name_ar": "أوريم",
    "name_ru": "Урим",
    "name_he": "אורים ",
    "x": 154656.9674,
    "y": 579405.5878
  },
  {
    "village_id": 405,
    "name_en": "Newe ilan ",
    "name_ar": "نِڤي إيلان",
    "name_ru": "Newe ilan ",
    "name_he": "נווה אילן ",
    "x": 207762.298,
    "y": 635055.7267
  },
  {
    "village_id": 406,
    "name_en": "Hazor-ashdod ",
    "name_ar": "حتْسور (أشدود)",
    "name_ru": "Hazor-ashdod ",
    "name_he": "חצור-אשדוד ",
    "x": 173582.8542,
    "y": 631194.2921
  },
  {
    "village_id": 407,
    "name_en": "Daverat ",
    "name_ar": "دڤرات",
    "name_ru": "Daverat ",
    "name_he": "דברת ",
    "x": 233094.7152,
    "y": 728120.8319
  },
  {
    "village_id": 408,
    "name_en": "Ne'ot mordekhay ",
    "name_ar": "نِؤوت مُردِخاي",
    "name_ru": "Ne'ot mordekhay ",
    "name_he": "נאות מרדכי ",
    "x": 256061.9995,
    "y": 785009.3915
  },
  {
    "village_id": 409,
    "name_en": "Yehi'am ",
    "name_ar": "يِحيعام",
    "name_ru": "Yehi'am ",
    "name_he": "יחיעם ",
    "x": 220877.9716,
    "y": 766924.7833
  },
  {
    "village_id": 412,
    "name_en": "Qiryat shelomo ",
    "name_ar": "كِريات شلُمو",
    "name_ru": "Кирьят Шломо",
    "name_he": "קרית שלמה ",
    "x": 187164.1469,
    "y": 682367.0609
  },
  {
    "village_id": 413,
    "name_en": "Ze'elim ",
    "name_ar": "پْتَحْيا",
    "name_ru": "Ze'elim ",
    "name_he": "צאלים ",
    "x": 155640.8655,
    "y": 568205.2271
  },
  {
    "village_id": 414,
    "name_en": "Qelahim ",
    "name_ar": "كْلَحيم",
    "name_ru": "Qelahim ",
    "name_he": "קלחים ",
    "x": 169366.0091,
    "y": 595188.2399
  },
  {
    "village_id": 415,
    "name_en": "Shoqeda ",
    "name_ar": "شوكِدا",
    "name_ru": "Shoqeda ",
    "name_he": "שוקדה ",
    "x": 154806.5809,
    "y": 592488.7634
  },
  {
    "village_id": 416,
    "name_en": "Ma'yan barukh ",
    "name_ar": "مَعيان بَروخ",
    "name_ru": "Ma'yan barukh ",
    "name_he": "מעין ברוך ",
    "x": 256997.8512,
    "y": 793905.1674
  },
  {
    "village_id": 417,
    "name_en": "Yaqum ",
    "name_ar": "يَكوم",
    "name_ru": "Yaqum ",
    "name_he": "יקום ",
    "x": 185296.7895,
    "y": 683872.3039
  },
  {
    "village_id": 418,
    "name_en": "Bene ziyyon ",
    "name_ar": "بني تْسِيون",
    "name_ru": "Bene ziyyon ",
    "name_he": "בני ציון ",
    "x": 187904.107,
    "y": 680776.8358
  },
  {
    "village_id": 419,
    "name_en": "Sa'ad ",
    "name_ar": "ساعَد",
    "name_ru": "Sa'ad ",
    "name_he": "סעד ",
    "x": 155903.7643,
    "y": 597779.2397
  },
  {
    "village_id": 421,
    "name_en": "Mash'abbe sade ",
    "name_ar": "مَشْأبي سَدي",
    "name_ru": "Mash'abbe sade ",
    "name_he": "משאבי שדה ",
    "x": 179498.0171,
    "y": 546059.5419
  },
  {
    "village_id": 422,
    "name_en": "Herev le'et ",
    "name_ar": "حيرِڤ لِئيت",
    "name_ru": "Herev le'et ",
    "name_he": "חרב לאת ",
    "x": 191644.5129,
    "y": 700842.1863
  },
  {
    "village_id": 423,
    "name_en": "Haogen ",
    "name_ar": "هعوچِن",
    "name_ru": "Haogen ",
    "name_he": "העוגן ",
    "x": 193039.266,
    "y": 696534.1003
  },
  {
    "village_id": 424,
    "name_en": "Gevim ",
    "name_ar": "چِڤيم",
    "name_ru": "Gevim ",
    "name_he": "גבים ",
    "x": 161686.4619,
    "y": 601970.9709
  },
  {
    "village_id": 425,
    "name_en": "Mishmeret ",
    "name_ar": "مِشميرِت",
    "name_ru": "Mishmeret ",
    "name_he": "משמרת ",
    "x": 192684.8312,
    "y": 681674.9815
  },
  {
    "village_id": 426,
    "name_en": "En karmel ",
    "name_ar": "عين كرميل",
    "name_ru": "En karmel ",
    "name_he": "עין כרמל ",
    "x": 195886.3933,
    "y": 731414.9684
  },
  {
    "village_id": 427,
    "name_en": "Kefar gallim ",
    "name_ar": "كفار چَليم",
    "name_ru": "Kefar gallim ",
    "name_he": "כפר גלים ",
    "x": 196423.8502,
    "y": 741359.6561
  },
  {
    "village_id": 428,
    "name_en": "Beror hayil ",
    "name_ar": "بْرور حايِل",
    "name_ru": "Beror hayil ",
    "name_he": "ברור חיל ",
    "x": 166383.146,
    "y": 607432.6387
  },
  {
    "village_id": 429,
    "name_en": "Allone abba ",
    "name_ar": "ألوني أبا",
    "name_ru": "Алоней-Абба",
    "name_he": "אלוני אבא ",
    "x": 216324.9367,
    "y": 737178.913
  },
  {
    "village_id": 430,
    "name_en": "Bet lehem hagelilit ",
    "name_ar": "بيت ليحِم هچليليت",
    "name_ru": "Bet lehem hagelilit ",
    "name_he": "בית לחם הגלילית ",
    "x": 218278.5186,
    "y": 737780.4635
  },
  {
    "village_id": 431,
    "name_en": "Dalton ",
    "name_ar": "دلتون",
    "name_ru": "Dalton ",
    "name_he": "דלתון ",
    "x": 246326.8885,
    "y": 768931.0672
  },
  {
    "village_id": 432,
    "name_en": "Shamerat ",
    "name_ar": "شَمرات",
    "name_ru": "Shamerat ",
    "name_he": "שמרת ",
    "x": 209280.238,
    "y": 761738.2062
  },
  {
    "village_id": 433,
    "name_en": "Nahsholim ",
    "name_ar": "نحْشوليم",
    "name_ru": "Nahsholim ",
    "name_he": "נחשולים ",
    "x": 192878.125,
    "y": 724395.0559
  },
  {
    "village_id": 434,
    "name_en": "Hahoterim ",
    "name_ar": "هحوتريم",
    "name_ru": "Hahoterim ",
    "name_he": "החותרים ",
    "x": 196251.9015,
    "y": 739714.7114
  },
  {
    "village_id": 435,
    "name_en": "Nezer sereni ",
    "name_ar": "نيتْسِر سِريني",
    "name_ru": "Nezer sereni ",
    "name_he": "נצר סרני ",
    "x": 183541.1684,
    "y": 647899.3917
  },
  {
    "village_id": 436,
    "name_en": "En dor ",
    "name_ar": "عين دور",
    "name_ru": "En dor ",
    "name_he": "עין דור ",
    "x": 239392.6093,
    "y": 729189.8014
  },
  {
    "village_id": 437,
    "name_en": "Reshafim ",
    "name_ar": "رِشَفيم",
    "name_ru": "Reshafim ",
    "name_he": "רשפים ",
    "x": 245046.7681,
    "y": 709606.0632
  },
  {
    "village_id": 439,
    "name_en": "Sheluhot ",
    "name_ar": "شلوحوت",
    "name_ru": "Sheluhot ",
    "name_he": "שלוחות ",
    "x": 245456.2488,
    "y": 708686.1747
  },
  {
    "village_id": 440,
    "name_en": "Yesodot ",
    "name_ar": "يِسودوت",
    "name_ru": "Yesodot ",
    "name_he": "יסודות ",
    "x": 187314.1248,
    "y": 635903.1951
  },
  {
    "village_id": 442,
    "name_en": "Gid'ona ",
    "name_ar": "چِدعونا",
    "name_ru": "Gid'ona ",
    "name_he": "גדעונה ",
    "x": 234011.3212,
    "y": 717184.3529
  },
  {
    "village_id": 443,
    "name_en": "Kefar hanasi ",
    "name_ar": "كفار هَنَسي",
    "name_ru": "Kefar hanasi ",
    "name_he": "כפר הנשיא ",
    "x": 256621.0961,
    "y": 764559.6227
  },
  {
    "village_id": 444,
    "name_en": "Regavim ",
    "name_ar": "رِچَڤيم",
    "name_ru": "Regavim ",
    "name_he": "רגבים ",
    "x": 203471.779,
    "y": 714487.2142
  },
  {
    "village_id": 445,
    "name_en": "Ramot menashe ",
    "name_ar": "رَموت مِنَشي",
    "name_ru": "Рамот-Менаше",
    "name_he": "רמות מנשה ",
    "x": 205662.8268,
    "y": 722543.3816
  },
  {
    "village_id": 446,
    "name_en": "Udim ",
    "name_ar": "أوديم",
    "name_ru": "Удим",
    "name_he": "אודים ",
    "x": 185700.7681,
    "y": 685724.1623
  },
  {
    "village_id": 447,
    "name_en": "Nordiyya ",
    "name_ar": "نوردِيا",
    "name_ru": "Nordiyya ",
    "name_he": "נורדיה ",
    "x": 190615.1086,
    "y": 691174.589
  },
  {
    "village_id": 448,
    "name_en": "Bene atarot ",
    "name_ar": "بني عتَروت",
    "name_ru": "Бней-Атарот",
    "name_he": "בני עטרות ",
    "x": 191994.7008,
    "y": 659037.4292
  },
  {
    "village_id": 449,
    "name_en": "Nehalim ",
    "name_ar": "نِحَليم",
    "name_ru": "Nehalim ",
    "name_he": "נחלים ",
    "x": 191938.8145,
    "y": 662833.3619
  },
  {
    "village_id": 450,
    "name_en": "Be'erot yizhaq ",
    "name_ar": "بِئِروت يِتسحاك",
    "name_ru": "Беэрот-Ицхак",
    "name_he": "בארות יצחק ",
    "x": 191662.9989,
    "y": 660944.6127
  },
  {
    "village_id": 452,
    "name_en": "Yizre'el ",
    "name_ar": "يِزرِعيل",
    "name_ru": "Yizre'el ",
    "name_he": "יזרעאל ",
    "x": 230450.8099,
    "y": 718605.6661
  },
  {
    "village_id": 453,
    "name_en": "Yiftah ",
    "name_ar": "يِفتاح",
    "name_ru": "Yiftah ",
    "name_he": "יפתח ",
    "x": 251872.3862,
    "y": 781395.7363
  },
  {
    "village_id": 454,
    "name_en": "Sa'ar ",
    "name_ar": "ساعَر",
    "name_ru": "Sa'ar ",
    "name_he": "סער ",
    "x": 210570.7041,
    "y": 770436.2042
  },
  {
    "village_id": 456,
    "name_en": "Shoresh ",
    "name_ar": "شورِش",
    "name_ru": "Shoresh ",
    "name_he": "שורש ",
    "x": 206295.2895,
    "y": 633812.4525
  },
  {
    "village_id": 457,
    "name_en": "Gazit ",
    "name_ar": "چزيت",
    "name_ru": "Gazit ",
    "name_he": "גזית ",
    "x": 242203.0224,
    "y": 727072.975
  },
  {
    "village_id": 460,
    "name_en": "Ramat razi'el ",
    "name_ar": "رَمات رَزيئيل",
    "name_ru": "Рамат Разиэль",
    "name_he": "רמת רזיאל ",
    "x": 207000.9093,
    "y": 631260.6368
  },
  {
    "village_id": 462,
    "name_en": "Tal shahar ",
    "name_ar": "تال إيل",
    "name_ru": "Tal shahar ",
    "name_he": "טל שחר ",
    "x": 190969.557,
    "y": 634813.6904
  },
  {
    "village_id": 463,
    "name_en": "Ga'ton ",
    "name_ar": "چَعتون",
    "name_ru": "Ga'ton ",
    "name_he": "געתון ",
    "x": 220420.3955,
    "y": 767903.9805
  },
  {
    "village_id": 464,
    "name_en": "Har'el ",
    "name_ar": "هرئيل",
    "name_ru": "Har'el ",
    "name_he": "הראל ",
    "x": 195529.3187,
    "y": 635246.8642
  },
  {
    "village_id": 465,
    "name_en": "Zova ",
    "name_ar": "تْسِڤعون",
    "name_ru": "Цуба",
    "name_he": "צובה ",
    "x": 211377.5365,
    "y": 632354.5901
  },
  {
    "village_id": 466,
    "name_en": "Bet dagan ",
    "name_ar": "بيت دَچان",
    "name_ru": "Бейт-Даган",
    "name_he": "בית דגן ",
    "x": 183680.172,
    "y": 656296.0917
  },
  {
    "village_id": 469,
    "name_en": "Qiryat eqron ",
    "name_ar": "كِريات عِكرون",
    "name_ru": "Кирьят-Экрон",
    "name_he": "קרית עקרון ",
    "x": 183172.234,
    "y": 641066.4667
  },
  {
    "village_id": 472,
    "name_en": "Abu ghosh ",
    "name_ar": "أبو غوش",
    "name_ru": "Abu ghosh ",
    "name_he": "אבו גוש ",
    "x": 210534.3263,
    "y": 634813.9596
  },
  {
    "village_id": 473,
    "name_en": "Abu sinan ",
    "name_ar": "أبو سنان",
    "name_ru": "Abu sinan ",
    "name_he": "אבו סנאן ",
    "x": 216186.6566,
    "y": 762891.9453
  },
  {
    "village_id": 475,
    "name_en": "Dahi ",
    "name_ar": "الدحي",
    "name_ru": "Dahi ",
    "name_he": "דחי ",
    "x": 232709.07,
    "y": 725195.7258
  },
  {
    "village_id": 478,
    "name_en": "Iksal ",
    "name_ar": "إكسال",
    "name_ru": "Iksal ",
    "name_he": "אכסאל ",
    "x": 230532.1016,
    "y": 731813.4439
  },
  {
    "village_id": 480,
    "name_en": "Beit jann ",
    "name_ar": "بيت جن",
    "name_ru": "Бейт-Джан",
    "name_he": "בית ג'ן ",
    "x": 235733.5059,
    "y": 763305.0347
  },
  {
    "village_id": 481,
    "name_en": "Mughar ",
    "name_ar": "موغير",
    "name_ru": "Mughar ",
    "name_he": "מגאר ",
    "x": 238961.93,
    "y": 754631.5461
  },
  {
    "village_id": 482,
    "name_en": "Bu'eine-nujeidat ",
    "name_ar": "بوتسرا",
    "name_ru": "Буейне-Нуджидат",
    "name_he": "בועיינה-נוג'ידאת ",
    "x": 234847.6576,
    "y": 745917.4556
  },
  {
    "village_id": 483,
    "name_en": "Bi ne ",
    "name_ar": "البعينة–نجيدات",
    "name_ru": "Бина",
    "name_he": "בענה ",
    "x": 226047.7246,
    "y": 759334.0996
  },
  {
    "village_id": 485,
    "name_en": "Julis ",
    "name_ar": "جولس",
    "name_ru": "Джулис",
    "name_he": "ג'וליס ",
    "x": 217247.3271,
    "y": 760702.7561
  },
  {
    "village_id": 487,
    "name_en": "Jish(gush halav) ",
    "name_ar": "الجش (چوش حَلاڤ)",
    "name_ru": "Джиш (Гуш Халав)",
    "name_he": "ג'ש (גוש חלב) ",
    "x": 242119.8848,
    "y": 769737.7139
  },
  {
    "village_id": 489,
    "name_en": "Dabburye ",
    "name_ar": "دبورية",
    "name_ru": "Дабурия",
    "name_he": "דבוריה ",
    "x": 235172.6575,
    "y": 733041.7111
  },
  {
    "village_id": 490,
    "name_en": "Deir al-asad ",
    "name_ar": "ديمونا",
    "name_ru": "Дейр-аль-Асад",
    "name_he": "דייר אל-אסד ",
    "x": 224831.5775,
    "y": 760310.6796
  },
  {
    "village_id": 492,
    "name_en": "Deir hanna ",
    "name_ar": "دير الأسد",
    "name_ru": "Дейр-Ханна",
    "name_he": "דייר חנא ",
    "x": 234775.6209,
    "y": 751882.9747
  },
  {
    "village_id": 493,
    "name_en": "Deir rafat ",
    "name_ar": "دير حنا",
    "name_ru": "Deir rafat ",
    "name_he": "דייר ראפאת ",
    "x": null,
    "y": null
  },
  {
    "village_id": 494,
    "name_en": "Daliyat al-karmel ",
    "name_ar": "دالية الكرمل",
    "name_ru": "Далият-эль-Кармель",
    "name_he": "דאלית אל-כרמל ",
    "x": 205114.7589,
    "y": 733223.1475
  },
  {
    "village_id": 496,
    "name_en": "Hurfeish ",
    "name_ar": "حرفيش",
    "name_ru": "Хурфейш",
    "name_he": "חורפיש ",
    "x": 232790.3898,
    "y": 769176.0027
  },
  {
    "village_id": 497,
    "name_en": "Tayibe(baemeq) ",
    "name_ar": "الطيبة الزعبية",
    "name_ru": "Tayibe(baemeq) ",
    "name_he": "טייבה (בעמק) ",
    "x": 242048.9893,
    "y": 723340.5173
  },
  {
    "village_id": 498,
    "name_en": "Tur'an ",
    "name_ar": "طرعان",
    "name_ru": "Tur'an ",
    "name_he": "טורעאן ",
    "x": 235425.8812,
    "y": 742477.9299
  },
  {
    "village_id": 499,
    "name_en": "Yafi ",
    "name_ar": "يافة الناصرة (يَفييَع)",
    "name_ru": "Yafi ",
    "name_he": "יפיע ",
    "x": 226046.4249,
    "y": 732145.6787
  },
  {
    "village_id": 502,
    "name_en": "Yirka ",
    "name_ar": "يركا",
    "name_ru": "Yirka ",
    "name_he": "ירכא ",
    "x": 219146.3886,
    "y": 762117.0998
  },
  {
    "village_id": 504,
    "name_en": "Kabul ",
    "name_ar": "كابول",
    "name_ru": "Kabul ",
    "name_he": "כאבול ",
    "x": 219710.219,
    "y": 753010.6368
  },
  {
    "village_id": 505,
    "name_en": "Kaokab abu al-hija ",
    "name_ar": "كوكب أبو الهيجا",
    "name_ru": "Kaokab abu al-hija ",
    "name_he": "כאוכב אבו אל-היג'א ",
    "x": 223568.6982,
    "y": 748443.2816
  },
  {
    "village_id": 507,
    "name_en": "Kafar yasif ",
    "name_ar": "كفر ياسيف",
    "name_ru": "Kafar yasif ",
    "name_he": "כפר יאסיף ",
    "x": 215528.6721,
    "y": 762047.8286
  },
  {
    "village_id": 508,
    "name_en": "Kafar kama ",
    "name_ar": "كفر كما",
    "name_ru": "Kafar kama ",
    "name_he": "כפר כמא ",
    "x": 241538.9848,
    "y": 736357.6193
  },
  {
    "village_id": 509,
    "name_en": "Kafar kanna ",
    "name_ar": "كفر كنا",
    "name_ru": "Кафр-Кана",
    "name_he": "כפר כנא ",
    "x": 232340.1829,
    "y": 739237.707
  },
  {
    "village_id": 510,
    "name_en": "Kafar manda ",
    "name_ar": "كفار مَلال",
    "name_ru": "Kafar manda ",
    "name_he": "כפר מנדא ",
    "x": 224991.2982,
    "y": 745985.5534
  },
  {
    "village_id": 511,
    "name_en": "Ilut ",
    "name_ar": "عيلوط",
    "name_ru": "Ilut ",
    "name_he": "עילוט ",
    "x": 224843.8341,
    "y": 735939.3819
  },
  {
    "village_id": 512,
    "name_en": "Kafar misr ",
    "name_ar": "كفار مَسَريك",
    "name_ru": "Kafar misr ",
    "name_he": "כפר מצר ",
    "x": 239914.5599,
    "y": 727922.5779
  },
  {
    "village_id": 514,
    "name_en": "Ein rafa ",
    "name_ar": "عين رافة",
    "name_ru": "Ein rafa ",
    "name_he": "עין ראפה ",
    "x": 210821.0662,
    "y": 632991.6683
  },
  {
    "village_id": 516,
    "name_en": "Majd al-kurum ",
    "name_ar": "مجد الكروم",
    "name_ru": "Majd al-kurum ",
    "name_he": "מג'ד אל-כרום ",
    "x": 224263.7874,
    "y": 758418.507
  },
  {
    "village_id": 517,
    "name_en": "Mazra'a ",
    "name_ar": "المزرعة",
    "name_ru": "Mazra'a ",
    "name_he": "מזרעה ",
    "x": 209523.0328,
    "y": 765471.255
  },
  {
    "village_id": 518,
    "name_en": "Mi'elya ",
    "name_ar": "معليا",
    "name_ru": "Mi'elya ",
    "name_he": "מעיליא ",
    "x": 224385.6439,
    "y": 770221.0486
  },
  {
    "village_id": 520,
    "name_en": "Meshhed ",
    "name_ar": "المشهد",
    "name_ru": "Meshhed ",
    "name_he": "משהד ",
    "x": 230687.0057,
    "y": 738411.0148
  },
  {
    "village_id": 521,
    "name_en": "Ein naqquba ",
    "name_ar": "عين نقوبا",
    "name_ru": "Ein naqquba ",
    "name_he": "עין נקובא ",
    "x": 211609.9727,
    "y": 633525.8776
  },
  {
    "village_id": 522,
    "name_en": "Nahef ",
    "name_ar": "نحف",
    "name_ru": "Nahef ",
    "name_he": "נחף ",
    "x": 230244.8714,
    "y": 760042.0006
  },
  {
    "village_id": 523,
    "name_en": "Nein ",
    "name_ar": "نيلي",
    "name_ru": "Nein ",
    "name_he": "ניין ",
    "x": 232999.3198,
    "y": 726290.2301
  },
  {
    "village_id": 524,
    "name_en": "Na'ura ",
    "name_ar": "الناعورة",
    "name_ru": "Na'ura ",
    "name_he": "נאעורה ",
    "x": 237033.3366,
    "y": 724390.9521
  },
  {
    "village_id": 525,
    "name_en": "Sajur ",
    "name_ar": "ساجور",
    "name_ru": "Sajur ",
    "name_he": "סאג'ור ",
    "x": 232572.9089,
    "y": 760710.501
  },
  {
    "village_id": 526,
    "name_en": "Sulam ",
    "name_ar": "سولم",
    "name_ru": "Sulam ",
    "name_he": "סולם ",
    "x": 231668.386,
    "y": 723560.2505
  },
  {
    "village_id": 527,
    "name_en": "Shezor ",
    "name_ar": "شِزور",
    "name_ru": "Shezor ",
    "name_he": "שזור ",
    "x": 233296.0712,
    "y": 759730.4161
  },
  {
    "village_id": 528,
    "name_en": "Uzeir ",
    "name_ar": "Uzeir ",
    "name_ru": "Uzeir ",
    "name_he": "עוזייר ",
    "x": 230736.2548,
    "y": 743963.6205
  },
  {
    "village_id": 529,
    "name_en": "I'billin ",
    "name_ar": "إعبلين",
    "name_ru": "I'billin ",
    "name_he": "אעבלין ",
    "x": 218209.5981,
    "y": 747702.9541
  },
  {
    "village_id": 530,
    "name_en": "Eilabun ",
    "name_ar": "عيلبون",
    "name_ru": "Eilabun ",
    "name_he": "עיילבון ",
    "x": 238039.1012,
    "y": 748962.1562
  },
  {
    "village_id": 531,
    "name_en": "Arrabe ",
    "name_ar": "عرابة",
    "name_ru": "Arrabe ",
    "name_he": "עראבה ",
    "x": 231930.187,
    "y": 750705.8176
  },
  {
    "village_id": 532,
    "name_en": "Ein mahel ",
    "name_ar": "عين ماهل",
    "name_ru": "Ein mahel ",
    "name_he": "עין מאהל ",
    "x": 233366.5572,
    "y": 736376.9584
  },
  {
    "village_id": 534,
    "name_en": "Isifya ",
    "name_ar": "عسفيا",
    "name_ru": "Isifya ",
    "name_he": "עספיא ",
    "x": 207294.6558,
    "y": 735229.2257
  },
  {
    "village_id": 535,
    "name_en": "Fassuta ",
    "name_ar": "فسوطة",
    "name_ru": "Fassuta ",
    "name_he": "פסוטה ",
    "x": 229333.2795,
    "y": 772551.3639
  },
  {
    "village_id": 536,
    "name_en": "Peqi'in (buqei'a) ",
    "name_ar": "البقيعة",
    "name_ru": "Peqi'in (buqei'a) ",
    "name_he": "פקיעין (בוקייעה) ",
    "x": 231264.7075,
    "y": 764332.8119
  },
  {
    "village_id": 537,
    "name_en": "Fureidis ",
    "name_ar": "فريديس",
    "name_ru": "Fureidis ",
    "name_he": "פוריידיס ",
    "x": 195940.4572,
    "y": 722648.1341
  },
  {
    "village_id": 538,
    "name_en": "Sha'ab ",
    "name_ar": "شعب",
    "name_ru": "Sha'ab ",
    "name_he": "שעב ",
    "x": 222939.5286,
    "y": 754909.8695
  },
  {
    "village_id": 539,
    "name_en": "Rummane ",
    "name_ar": "رمانة",
    "name_ru": "Rummane ",
    "name_he": "רומאנה ",
    "x": 229448.2128,
    "y": 743732.594
  },
  {
    "village_id": 540,
    "name_en": "Reihaniyye ",
    "name_ar": "الريحانية",
    "name_ru": "Reihaniyye ",
    "name_he": "ריחאניה ",
    "x": 245924.12,
    "y": 772650.1638
  },
  {
    "village_id": 541,
    "name_en": "Jisr az-zarqa ",
    "name_ar": "جنابيب",
    "name_ru": "Джиср-эз-Зарка",
    "name_he": "ג'סר א-זרקא ",
    "x": 191932.2477,
    "y": 715881.5537
  },
  {
    "village_id": 542,
    "name_en": "Reine ",
    "name_ar": "رِمونيم",
    "name_ru": "Reine ",
    "name_he": "ריינה ",
    "x": 229724.1431,
    "y": 736118.36
  },
  {
    "village_id": 543,
    "name_en": "Rame ",
    "name_ar": "الرامة",
    "name_ru": "Rame ",
    "name_he": "ראמה ",
    "x": 234657.9896,
    "y": 760394.3696
  },
  {
    "village_id": 546,
    "name_en": "Ein al-asad ",
    "name_ar": "عين الأسد",
    "name_ru": "Ein al-asad ",
    "name_he": "עין אל-אסד ",
    "x": 237669.9865,
    "y": 760599.5281
  },
  {
    "village_id": 547,
    "name_en": "Tamra (yizre'el) ",
    "name_ar": "طمرة الزعبية",
    "name_ru": "Tamra (yizre'el) ",
    "name_he": "טמרה (יזרעאל) ",
    "x": 238165.9985,
    "y": 726681.6778
  },
  {
    "village_id": 549,
    "name_en": "Gannot hadar ",
    "name_ar": "چَنوت هَدار",
    "name_ru": "Gannot hadar ",
    "name_he": "גנות הדר ",
    "x": 190890.5711,
    "y": 691941.4028
  },
  {
    "village_id": 553,
    "name_en": "Nir banim ",
    "name_ar": "نير بَنيم",
    "name_ru": "Nir banim ",
    "name_he": "ניר בנים ",
    "x": 176837.4075,
    "y": 619992.2423
  },
  {
    "village_id": 555,
    "name_en": "Shedema ",
    "name_ar": "شْدِما",
    "name_ru": "Shedema ",
    "name_he": "שדמה ",
    "x": 175642.1882,
    "y": 637954.973
  },
  {
    "village_id": 559,
    "name_en": "Bustan hagalil ",
    "name_ar": "بُستان هچليل",
    "name_ru": "Bustan hagalil ",
    "name_he": "בוסתן הגליל ",
    "x": 208073.01,
    "y": 761817.1502
  },
  {
    "village_id": 562,
    "name_en": "Bet el'azari ",
    "name_ar": "بيت إلعزَري",
    "name_ru": "Бейт-Эльазари",
    "name_he": "בית אלעזרי ",
    "x": 181484.2892,
    "y": 639151.5944
  },
  {
    "village_id": 563,
    "name_en": "Mishmar dawid ",
    "name_ar": "مِشمار دَڤيد",
    "name_ru": "Mishmar dawid ",
    "name_he": "משמר דוד ",
    "x": 190753.8436,
    "y": 636651.9383
  },
  {
    "village_id": 564,
    "name_en": "Revadim ",
    "name_ar": "رِڤَديم",
    "name_ru": "Revadim ",
    "name_he": "רבדים ",
    "x": 182624.9345,
    "y": 631141.5387
  },
  {
    "village_id": 565,
    "name_en": "Azor ",
    "name_ar": "أزور",
    "name_ru": "Азор",
    "name_he": "אזור ",
    "x": 181867.5257,
    "y": 659050.8326
  },
  {
    "village_id": 566,
    "name_en": "Giv'at shemesh ",
    "name_ar": "چِڤعات شيمِش",
    "name_ru": "Giv'at shemesh ",
    "name_he": "גבעת שמש ",
    "x": 195496.2244,
    "y": 631415.6426
  },
  {
    "village_id": 567,
    "name_en": "Zor'a ",
    "name_ar": "تْسُرعا",
    "name_ru": "Zor'a ",
    "name_he": "צרעה ",
    "x": 197174.421,
    "y": 629972.4813
  },
  {
    "village_id": 570,
    "name_en": "Me'ona ",
    "name_ar": "مِعونا",
    "name_ru": "Me'ona ",
    "name_he": "מעונה ",
    "x": 224658.1736,
    "y": 769052.5739
  },
  {
    "village_id": 571,
    "name_en": "Bet gamli'el ",
    "name_ar": "بيت چمليئيل",
    "name_ru": "Бейт-Гамлиэль",
    "name_he": "בית גמליאל ",
    "x": 177525.8435,
    "y": 640567.3108
  },
  {
    "village_id": 572,
    "name_en": "Bet haemeq ",
    "name_ar": "بيت هعيمِك",
    "name_ru": "Bet haemeq ",
    "name_he": "בית העמק ",
    "x": 213928.088,
    "y": 763952.2334
  },
  {
    "village_id": 573,
    "name_en": "Mavqi'im ",
    "name_ar": "مڤكيعيم",
    "name_ru": "Mavqi'im ",
    "name_he": "מבקיעים ",
    "x": 159765.7882,
    "y": 614546.4726
  },
  {
    "village_id": 574,
    "name_en": "Gesher haziw ",
    "name_ar": "چيشِر هزيڤ",
    "name_ru": "Gesher haziw ",
    "name_he": "גשר הזיו ",
    "x": 210732.2189,
    "y": 771685.137
  },
  {
    "village_id": 575,
    "name_en": "Yas'ur ",
    "name_ar": "يَسْعور",
    "name_ru": "Yas'ur ",
    "name_he": "יסעור ",
    "x": 215882.1368,
    "y": 756206.1842
  },
  {
    "village_id": 576,
    "name_en": "Kabri ",
    "name_ar": "كَبْري ",
    "name_ru": "Kabri ",
    "name_he": "כברי ",
    "x": 214277.4459,
    "y": 769574.0474
  },
  {
    "village_id": 577,
    "name_en": "Yad binyamin ",
    "name_ar": "ياد بِنيَمين",
    "name_ru": "ЯД БИНЬЯМИН",
    "name_he": "יד בנימין ",
    "x": 183237.1955,
    "y": 633912.335
  },
  {
    "village_id": 578,
    "name_en": "Sasa ",
    "name_ar": "ساسا",
    "name_ru": "Sasa ",
    "name_he": "סאסא ",
    "x": 237329.8953,
    "y": 770194.6158
  },
  {
    "village_id": 579,
    "name_en": "Kefar rosh haniqra ",
    "name_ar": "كفار روش هنِكرا",
    "name_ru": "Рош-ха-Никра",
    "name_he": "כפר ראש הנקרה ",
    "x": 211087.7049,
    "y": 776761.9212
  },
  {
    "village_id": 580,
    "name_en": "Kerem maharal ",
    "name_ar": "كيرِم مهرال",
    "name_ru": "Kerem maharal ",
    "name_he": "כרם מהר\"ל ",
    "x": 199397.4323,
    "y": 727963.9669
  },
  {
    "village_id": 582,
    "name_en": "Kefar hanagid ",
    "name_ar": "كفار هنَچيد",
    "name_ru": "Kefar hanagid ",
    "name_he": "כפר הנגיד ",
    "x": 176372.5194,
    "y": 643880.5391
  },
  {
    "village_id": 584,
    "name_en": "Ziqim ",
    "name_ar": "زِخرون يعكوڤ",
    "name_ru": "Ziqim ",
    "name_he": "זיקים ",
    "x": 154668.7617,
    "y": 613157.9677
  },
  {
    "village_id": 585,
    "name_en": "Lavi ",
    "name_ar": "لِڤي",
    "name_ru": "Lavi ",
    "name_he": "לביא ",
    "x": 241789.1746,
    "y": 743825.2874
  },
  {
    "village_id": 586,
    "name_en": "Megiddo ",
    "name_ar": "مِچِدو",
    "name_ru": "Megiddo ",
    "name_he": "מגידו ",
    "x": 217318.4522,
    "y": 720597.6175
  },
  {
    "village_id": 587,
    "name_en": "Savyon ",
    "name_ar": "سَڤيون",
    "name_ru": "Savyon ",
    "name_he": "סביון ",
    "x": 188644.5865,
    "y": 661603.454
  },
  {
    "village_id": 588,
    "name_en": "Bene re'em ",
    "name_ar": "بني رِئيم",
    "name_ru": "Bene re'em ",
    "name_he": "בני ראם ",
    "x": 180296.3723,
    "y": 630859.3463
  },
  {
    "village_id": 589,
    "name_en": "Bezet ",
    "name_ar": "بيتْسِت",
    "name_ru": "Bezet ",
    "name_he": "בצת ",
    "x": 212921.4627,
    "y": 775055.4933
  },
  {
    "village_id": 590,
    "name_en": "Newe ur ",
    "name_ar": "نِڤي أور",
    "name_ru": "Newe ur ",
    "name_he": "נווה אור ",
    "x": 252197.9295,
    "y": 721619.5312
  },
  {
    "village_id": 591,
    "name_en": "Aseret ",
    "name_ar": "عسيرِت",
    "name_ru": "Aseret ",
    "name_he": "עשרת ",
    "x": 176162.8362,
    "y": 636963.4388
  },
  {
    "village_id": 592,
    "name_en": "Bene darom ",
    "name_ar": "بني دروم",
    "name_ru": "Бней-Даром",
    "name_he": "בני דרום ",
    "x": 170964.758,
    "y": 636501.1934
  },
  {
    "village_id": 593,
    "name_en": "Arugot ",
    "name_ar": "عروچوت",
    "name_ru": "Arugot ",
    "name_he": "ערוגות ",
    "x": 178472.1628,
    "y": 626635.6199
  },
  {
    "village_id": 594,
    "name_en": "Zafriyya ",
    "name_ar": "تْسَفرِيا",
    "name_ru": "Zafriyya ",
    "name_he": "צפריה ",
    "x": 186523.363,
    "y": 656726.4735
  },
  {
    "village_id": 595,
    "name_en": "Lohame hageta'ot ",
    "name_ar": "لوحَمي هچِتَؤوت",
    "name_ru": "Lohame hageta'ot ",
    "name_he": "לוחמי הגיטאות ",
    "x": 209529.0554,
    "y": 763032.8687
  },
  {
    "village_id": 596,
    "name_en": "Malkiyya ",
    "name_ar": "مَلكِيا",
    "name_ru": "Malkiyya ",
    "name_he": "מלכיה ",
    "x": 248089.8212,
    "y": 778155.6474
  },
  {
    "village_id": 597,
    "name_en": "Palmahim ",
    "name_ar": "پلمحيم",
    "name_ru": "Пальмахим",
    "name_he": "פלמחים ",
    "x": 172781.3851,
    "y": 649177.771
  },
  {
    "village_id": 598,
    "name_en": "Bet qama ",
    "name_ar": "بيت كَما",
    "name_ru": "Бейт-Кама",
    "name_he": "בית קמה ",
    "x": 177333.3769,
    "y": 595140.5866
  },
  {
    "village_id": 599,
    "name_en": "Parod ",
    "name_ar": "پَرود",
    "name_ru": "Parod ",
    "name_he": "פרוד ",
    "x": 240808.5468,
    "y": 759732.2406
  },
  {
    "village_id": 602,
    "name_en": "Nirim ",
    "name_ar": "نيريم",
    "name_ru": "Nirim ",
    "name_he": "נירים ",
    "x": 142528.9399,
    "y": 582818.3956
  },
  {
    "village_id": 603,
    "name_en": "Elqosh ",
    "name_ar": "إلكوش",
    "name_ru": "Elqosh ",
    "name_he": "אלקוש ",
    "x": 230606.0459,
    "y": 770912.48
  },
  {
    "village_id": 604,
    "name_en": "Bet arif ",
    "name_ar": "بيت عريف",
    "name_ru": "Бейт-Ариф",
    "name_he": "בית עריף ",
    "x": 193945.5264,
    "y": 655882.8009
  },
  {
    "village_id": 605,
    "name_en": "Kefar shammay ",
    "name_ar": "كفار رِتَميم",
    "name_ru": "Kefar shammay ",
    "name_he": "כפר שמאי ",
    "x": 243168.254,
    "y": 762416.4819
  },
  {
    "village_id": 606,
    "name_en": "Mazor ",
    "name_ar": "مَزور",
    "name_ru": "Mazor ",
    "name_he": "מזור ",
    "x": 193142.9338,
    "y": 662197.2718
  },
  {
    "village_id": 607,
    "name_en": "Meron ",
    "name_ar": "ميرون",
    "name_ru": "Meron ",
    "name_he": "מירון ",
    "x": 241421.6673,
    "y": 765809.9773
  },
  {
    "village_id": 609,
    "name_en": "Kefar hoshen ",
    "name_ar": "كفار حوشِن",
    "name_ru": "Kefar hoshen ",
    "name_he": "כפר חושן ",
    "x": 241299.4847,
    "y": 768427.9414
  },
  {
    "village_id": 610,
    "name_en": "Sitriyya ",
    "name_ar": "سِترِيا",
    "name_ru": "Sitriyya ",
    "name_he": "סתריה ",
    "x": 185424.4569,
    "y": 644139.9239
  },
  {
    "village_id": 612,
    "name_en": "Zerufa ",
    "name_ar": "تْسروفا",
    "name_ru": "Zerufa ",
    "name_he": "צרופה ",
    "x": 195363.2376,
    "y": 728269.0421
  },
  {
    "village_id": 613,
    "name_en": "Zippori ",
    "name_ar": "تْسِپوري",
    "name_ru": "Zippori ",
    "name_he": "ציפורי ",
    "x": 226455.2162,
    "y": 739110.8961
  },
  {
    "village_id": 614,
    "name_en": "Shomera ",
    "name_ar": "شومِرا",
    "name_ru": "Shomera ",
    "name_he": "שומרה ",
    "x": 226981.8744,
    "y": 776605.7111
  },
  {
    "village_id": 615,
    "name_en": "Qidron ",
    "name_ar": "كِدرون",
    "name_ru": "Qidron ",
    "name_he": "קדרון ",
    "x": 180784.2486,
    "y": 635925.4156
  },
  {
    "village_id": 616,
    "name_en": "Rinnatya ",
    "name_ar": "رِنَتْيا",
    "name_ru": "Rinnatya ",
    "name_he": "רינתיה ",
    "x": 193355.121,
    "y": 661269.3416
  },
  {
    "village_id": 617,
    "name_en": "Barqay ",
    "name_ar": "بركاي",
    "name_ru": "Barqay ",
    "name_he": "ברקאי ",
    "x": 202995.9694,
    "y": 709033.3342
  },
  {
    "village_id": 618,
    "name_en": "Hadid ",
    "name_ar": "حديد",
    "name_ru": "Hadid ",
    "name_he": "חדיד ",
    "x": 193637.2027,
    "y": 652899.6434
  },
  {
    "village_id": 619,
    "name_en": "Bet guvrin ",
    "name_ar": "بيت چُڤرين",
    "name_ru": "Bet guvrin ",
    "name_he": "בית גוברין ",
    "x": 190168.6732,
    "y": 613367.1071
  },
  {
    "village_id": 620,
    "name_en": "Massuot yizhaq ",
    "name_ar": "مَسؤوت يِتسحاك",
    "name_ru": "Massuot yizhaq ",
    "name_he": "משואות יצחק ",
    "x": 170838.1209,
    "y": 623467.5179
  },
  {
    "village_id": 622,
    "name_en": "En zurim ",
    "name_ar": "عين تْسوريم",
    "name_ru": "En zurim ",
    "name_he": "עין צורים ",
    "x": 173400.9866,
    "y": 622648.4252
  },
  {
    "village_id": 623,
    "name_en": "Yir'on ",
    "name_ar": "يِرؤون",
    "name_ru": "Yir'on ",
    "name_he": "יראון ",
    "x": 242773.5965,
    "y": 775774.8758
  },
  {
    "village_id": 627,
    "name_en": "Jaljulye ",
    "name_ar": "جسر الزرقاء",
    "name_ru": "Джальджулия",
    "name_he": "ג'לג'וליה ",
    "x": 195924.5816,
    "y": 673075.2219
  },
  {
    "village_id": 628,
    "name_en": "Jaat ",
    "name_ar": "جت",
    "name_ru": "Джат",
    "name_he": "ג'ת ",
    "x": 203561.591,
    "y": 700608.3153
  },
  {
    "village_id": 633,
    "name_en": "Kafar bara ",
    "name_ar": "كفر برا",
    "name_ru": "Kafar bara ",
    "name_he": "כפר ברא ",
    "x": 197396.0549,
    "y": 670882.8685
  },
  {
    "village_id": 634,
    "name_en": "Kafar qasem ",
    "name_ar": "كفر قاسم",
    "name_ru": "Кафр-Касем",
    "name_he": "כפר קאסם ",
    "x": 197056.5835,
    "y": 669377.863
  },
  {
    "village_id": 635,
    "name_en": "Muqeible ",
    "name_ar": "متقلب",
    "name_ru": "Muqeible ",
    "name_he": "מוקייבלה ",
    "x": 228183.9995,
    "y": 713287.4739
  },
  {
    "village_id": 636,
    "name_en": "Sandala ",
    "name_ar": "صندلة",
    "name_ru": "Sandala ",
    "name_he": "צנדלה ",
    "x": 230643.32,
    "y": 714245.3683
  },
  {
    "village_id": 637,
    "name_en": "Ar'ara ",
    "name_ar": "عارة–عرعرة",
    "name_ru": "Ar'ara ",
    "name_he": "ערערה ",
    "x": 209237.5569,
    "y": 711628.0992
  },
  {
    "village_id": 638,
    "name_en": "Qalansawe ",
    "name_ar": "قلنسوة",
    "name_ru": "Qalansawe ",
    "name_he": "קלנסווה ",
    "x": 198261.0333,
    "y": 688411.176
  },
  {
    "village_id": 648,
    "name_en": "Mezer ",
    "name_ar": "ميتْسِر",
    "name_ru": "Mezer ",
    "name_he": "מצר ",
    "x": 204745.5279,
    "y": 705170.0129
  },
  {
    "village_id": 649,
    "name_en": "Meiser ",
    "name_ar": "ميسر",
    "name_ru": "Meiser ",
    "name_he": "מייסר ",
    "x": 204163.0351,
    "y": 705693.7627
  },
  {
    "village_id": 652,
    "name_en": "Ibtin ",
    "name_ar": "إبطن",
    "name_ru": "Ibtin ",
    "name_he": "אבטין ",
    "x": 210851.5602,
    "y": 740834.6445
  },
  {
    "village_id": 654,
    "name_en": "Kafar qara ",
    "name_ar": "كفر قرع",
    "name_ru": "Kafar qara ",
    "name_he": "כפר קרע ",
    "x": 204928.3823,
    "y": 712312.9808
  },
  {
    "village_id": 658,
    "name_en": "Sheikh dannun ",
    "name_ar": "الشيخ دنون",
    "name_ru": "Sheikh dannun ",
    "name_he": "שייח' דנון ",
    "x": 214180.2153,
    "y": 766540.5373
  },
  {
    "village_id": 661,
    "name_en": "Sha'ar efrayim ",
    "name_ar": "شاعَر إفرايِم",
    "name_ru": "Sha'ar efrayim ",
    "name_he": "שער אפרים ",
    "x": 199950.2489,
    "y": 688394.6767
  },
  {
    "village_id": 662,
    "name_en": "Hosen ",
    "name_ar": "حوسِن",
    "name_ru": "Hosen ",
    "name_he": "חוסן ",
    "x": 228312.2951,
    "y": 766995.5939
  },
  {
    "village_id": 663,
    "name_en": "Tirat yehuda ",
    "name_ar": "تيرات يِهودا",
    "name_ru": "Tirat yehuda ",
    "name_he": "טירת יהודה ",
    "x": 193848.9263,
    "y": 657824.7541
  },
  {
    "village_id": 664,
    "name_en": "Kerem ben zimra ",
    "name_ar": "كيرِم بين زِمرا",
    "name_ru": "Kerem ben zimra ",
    "name_he": "כרם בן זמרה ",
    "x": 244122.6315,
    "y": 771308.3988
  },
  {
    "village_id": 665,
    "name_en": "Tequma ",
    "name_ar": "تكوما",
    "name_ru": "Tequma ",
    "name_he": "תקומה ",
    "x": 160092.0026,
    "y": 595610.5333
  },
  {
    "village_id": 666,
    "name_en": "Omer ",
    "name_ar": "عومِر",
    "name_ru": "Омер",
    "name_he": "עומר ",
    "x": 185279.8773,
    "y": 574781.8654
  },
  {
    "village_id": 667,
    "name_en": "Bar'am ",
    "name_ar": "برعام",
    "name_ru": "Bar'am ",
    "name_he": "ברעם ",
    "x": 240937.7447,
    "y": 773659.4664
  },
  {
    "village_id": 668,
    "name_en": "Mefallesim ",
    "name_ar": " مِفَلْسيم",
    "name_ru": "Mefallesim ",
    "name_he": "מפלסים ",
    "x": 158625.856,
    "y": 601190.9898
  },
  {
    "village_id": 670,
    "name_en": "Mishmar ayyalon ",
    "name_ar": "مِشمار أيَلون",
    "name_ru": "Mishmar ayyalon ",
    "name_he": "משמר איילון ",
    "x": 194912.8137,
    "y": 642089.8589
  },
  {
    "village_id": 672,
    "name_en": "Bet neqofa ",
    "name_ar": "بيت نِكوفا",
    "name_ru": "Bet neqofa ",
    "name_he": "בית נקופה ",
    "x": 212055.1202,
    "y": 634614.9232
  },
  {
    "village_id": 673,
    "name_en": "Kefar truman ",
    "name_ar": "كفار ترومَن",
    "name_ru": "Кфар-Труман",
    "name_he": "כפר טרומן ",
    "x": 192915.6228,
    "y": 654175.4717
  },
  {
    "village_id": 674,
    "name_en": "Liman ",
    "name_ar": "ليمان",
    "name_ru": "Liman ",
    "name_he": "לימן ",
    "x": 210776.8608,
    "y": 773847.3822
  },
  {
    "village_id": 675,
    "name_en": "Habonim ",
    "name_ar": "هبونيم",
    "name_ru": "Ха-Боним",
    "name_he": "הבונים ",
    "x": 193940.5404,
    "y": 726880.1342
  },
  {
    "village_id": 676,
    "name_en": "En hashelosha ",
    "name_ar": "عين هشْلوشا",
    "name_ru": "En hashelosha ",
    "name_he": "עין השלושה ",
    "x": 143057.4086,
    "y": 584701.2225
  },
  {
    "village_id": 677,
    "name_en": "Hasolelim ",
    "name_ar": "هسولِليم",
    "name_ru": "Hasolelim ",
    "name_he": "הסוללים ",
    "x": 222599.2481,
    "y": 739595.2268
  },
  {
    "village_id": 678,
    "name_en": "Ma'agan ",
    "name_ar": "معچان",
    "name_ru": "Ma'agan ",
    "name_he": "מעגן ",
    "x": 256596.1088,
    "y": 734717.9612
  },
  {
    "village_id": 679,
    "name_en": "Avi'el ",
    "name_ar": "أڤيئيل",
    "name_ru": "Авиэль",
    "name_he": "אביאל ",
    "x": 199616.8089,
    "y": 715276.6292
  },
  {
    "village_id": 680,
    "name_en": "Omez ",
    "name_ar": "أومِتس",
    "name_ru": "Omez ",
    "name_he": "אומץ ",
    "x": 199665.2614,
    "y": 697403.8559
  },
  {
    "village_id": 681,
    "name_en": "Giv'at shemu'el ",
    "name_ar": "چِڤعات شموئيل",
    "name_ru": "Giv'at shemu'el ",
    "name_he": "גבעת שמואל ",
    "x": 186075.0373,
    "y": 664914.1637
  },
  {
    "village_id": 682,
    "name_en": "Elyaqim ",
    "name_ar": "إليَكيم",
    "name_ru": "Elyaqim ",
    "name_he": "אליקים ",
    "x": 206582.224,
    "y": 726387.4978
  },
  {
    "village_id": 683,
    "name_en": "Geva karmel ",
    "name_ar": "چيڤع كرميل",
    "name_ru": "Geva karmel ",
    "name_he": "גבע כרמל ",
    "x": 196079.5179,
    "y": 729838.3847
  },
  {
    "village_id": 684,
    "name_en": "Hayogev ",
    "name_ar": "هيوچيڤ",
    "name_ru": "Hayogev ",
    "name_he": "היוגב ",
    "x": 219437.6669,
    "y": 724162.585
  },
  {
    "village_id": 685,
    "name_en": "Benaya ",
    "name_ar": "بْنَيا",
    "name_ru": "Бная",
    "name_he": "בניה ",
    "x": 176685.5214,
    "y": 639054.5729
  },
  {
    "village_id": 686,
    "name_en": "Newe yamin ",
    "name_ar": "نِڤي يَمين",
    "name_ru": "Newe yamin ",
    "name_he": "נווה ימין ",
    "x": 194464.5271,
    "y": 675383.5446
  },
  {
    "village_id": 687,
    "name_en": "En ayyala ",
    "name_ar": "عين أيَلا",
    "name_ru": "En ayyala ",
    "name_he": "עין איילה ",
    "x": 195470.4591,
    "y": 726246.4166
  },
  {
    "village_id": 688,
    "name_en": "Alma ",
    "name_ar": "علما",
    "name_ru": "Alma ",
    "name_he": "עלמה ",
    "x": 247056.3141,
    "y": 773005.9914
  },
  {
    "village_id": 689,
    "name_en": "Megadim ",
    "name_ar": "مِچَديم",
    "name_ru": "Megadim ",
    "name_he": "מגדים ",
    "x": 196680.5634,
    "y": 736845.4968
  },
  {
    "village_id": 690,
    "name_en": "Kefar ahim ",
    "name_ar": "كفار أحيم",
    "name_ru": "Kefar ahim ",
    "name_he": "כפר אחים ",
    "x": 176990.4194,
    "y": 628121.361
  },
  {
    "village_id": 692,
    "name_en": "Shafir ",
    "name_ar": "شَفير",
    "name_ru": "Shafir ",
    "name_he": "שפיר ",
    "x": 173876.2659,
    "y": 623198.1985
  },
  {
    "village_id": 693,
    "name_en": "Netiv halamed-he ",
    "name_ar": "نِتيڤ هلامِد هي",
    "name_ru": "Netiv halamed-he ",
    "name_he": "נתיב הל\"ה ",
    "x": 198404.8114,
    "y": 621787.7199
  },
  {
    "village_id": 694,
    "name_en": "Ma'agan mikha'el ",
    "name_ar": "معچان ميخَئيل",
    "name_ru": "Ма'аган-Михаэль",
    "name_he": "מעגן מיכאל ",
    "x": 192333.7211,
    "y": 718052.6544
  },
  {
    "village_id": 695,
    "name_en": "Magen ",
    "name_ar": "مَچين",
    "name_ru": "Magen ",
    "name_he": "מגן ",
    "x": 145622.4717,
    "y": 578919.7941
  },
  {
    "village_id": 696,
    "name_en": "Kefar habad ",
    "name_ar": "كفار حَباد",
    "name_ru": "Кфар-Хабад",
    "name_he": "כפר חב\"ד ",
    "x": 185982.6899,
    "y": 655205.8328
  },
  {
    "village_id": 697,
    "name_en": "Be'erotayim ",
    "name_ar": "بِئِروتايِم",
    "name_ru": "Be'erotayim ",
    "name_he": "בארותיים ",
    "x": 198827.3182,
    "y": 691989.1446
  },
  {
    "village_id": 698,
    "name_en": "Burgeta ",
    "name_ar": "بُرچِتا",
    "name_ru": "Burgeta ",
    "name_he": "בורגתה ",
    "x": 196671.0289,
    "y": 692314.3129
  },
  {
    "village_id": 699,
    "name_en": "Nir yisra'el ",
    "name_ar": "نير يِسرَئيل",
    "name_ru": "Nir yisra'el ",
    "name_he": "ניר ישראל ",
    "x": 165579.7935,
    "y": 621816.7534
  },
  {
    "village_id": 700,
    "name_en": "Hazav ",
    "name_ar": "حتْساڤ",
    "name_ru": "Hazav ",
    "name_he": "חצב ",
    "x": 178302.9225,
    "y": 632059.8878
  },
  {
    "village_id": 701,
    "name_en": "Arbel ",
    "name_ar": "أربيل",
    "name_ru": "Arbel ",
    "name_he": "ארבל ",
    "x": 245610.409,
    "y": 746461.1292
  },
  {
    "village_id": 702,
    "name_en": "Haon ",
    "name_ar": "هَؤون",
    "name_ru": "Haon ",
    "name_he": "האון ",
    "x": 258588.6284,
    "y": 736812.5652
  },
  {
    "village_id": 703,
    "name_en": "Giv'at oz ",
    "name_ar": "چِڤعات عوز",
    "name_ru": "Giv'at oz ",
    "name_he": "גבעת עוז ",
    "x": 218886.8683,
    "y": 717897.3593
  },
  {
    "village_id": 705,
    "name_en": "Nahshonim ",
    "name_ar": "نحْشونيم",
    "name_ru": "Nahshonim ",
    "name_he": "נחשונים ",
    "x": 195276.407,
    "y": 663147.693
  },
  {
    "village_id": 706,
    "name_en": "Ge'a ",
    "name_ar": "چيئة",
    "name_ru": "Ge'a ",
    "name_he": "גיאה ",
    "x": 162401.5886,
    "y": 615222.1905
  },
  {
    "village_id": 707,
    "name_en": "Kefar daniyyel ",
    "name_ar": "كفار دَنِييل",
    "name_ru": "Kefar daniyyel ",
    "name_he": "כפר דניאל ",
    "x": 193772.6464,
    "y": 648954.938
  },
  {
    "village_id": 708,
    "name_en": "Amqa ",
    "name_ar": "عمْكا",
    "name_ru": "Amqa ",
    "name_he": "עמקה ",
    "x": 215538.1575,
    "y": 764887.3433
  },
  {
    "village_id": 709,
    "name_en": "Tifrah ",
    "name_ar": "تِفراح",
    "name_ru": "Tifrah ",
    "name_he": "תפרח ",
    "x": 169260.0132,
    "y": 581663.8047
  },
  {
    "village_id": 710,
    "name_en": "Bet zayit ",
    "name_ar": "بيت زايِت",
    "name_ru": "Bet zayit ",
    "name_he": "בית זית ",
    "x": 215424.1148,
    "y": 632187.6927
  },
  {
    "village_id": 711,
    "name_en": "Azarya ",
    "name_ar": "عزَرْيا",
    "name_ru": "Azarya ",
    "name_he": "עזריה ",
    "x": 191027.4908,
    "y": 643965.9036
  },
  {
    "village_id": 712,
    "name_en": "Ben ammi ",
    "name_ar": "بين عمي",
    "name_ru": "Ben ammi ",
    "name_he": "בן עמי ",
    "x": 211912.7102,
    "y": 767682.1714
  },
  {
    "village_id": 713,
    "name_en": "Re'im ",
    "name_ar": "رِعيم",
    "name_ru": "Re'im ",
    "name_he": "רעים ",
    "x": 148649.4954,
    "y": 588430.169
  },
  {
    "village_id": 714,
    "name_en": "Erez ",
    "name_ar": "إيرِز",
    "name_ru": "Эрез",
    "name_he": "ארז ",
    "x": 158912.7305,
    "y": 607710.6559
  },
  {
    "village_id": 715,
    "name_en": "Lahavot haviva ",
    "name_ar": "لهڤوت حڤيڤا",
    "name_ru": "Lahavot haviva ",
    "name_he": "להבות חביבה ",
    "x": 201135.8318,
    "y": 700138.2631
  },
  {
    "village_id": 716,
    "name_en": "Eyal ",
    "name_ar": "إيال",
    "name_ru": "Эяль",
    "name_he": "אייל ",
    "x": 198217.3046,
    "y": 679724.5897
  },
  {
    "village_id": 717,
    "name_en": "Hagor ",
    "name_ar": "حَچور",
    "name_ru": "Hagor ",
    "name_he": "חגור ",
    "x": 195190.3992,
    "y": 671668.1983
  },
  {
    "village_id": 718,
    "name_en": "Yarhiv ",
    "name_ar": "يَرحيڤ",
    "name_ru": "Yarhiv ",
    "name_he": "ירחיב ",
    "x": 197249.2125,
    "y": 673362.2717
  },
  {
    "village_id": 719,
    "name_en": "Tel qazir ",
    "name_ar": "تيل كَتْسير",
    "name_ru": "Tel qazir ",
    "name_he": "תל קציר ",
    "x": 258203.9596,
    "y": 734621.89
  },
  {
    "village_id": 720,
    "name_en": "Nir gallim ",
    "name_ar": "نير چليم",
    "name_ru": "Nir gallim ",
    "name_he": "ניר גלים ",
    "x": 170192.948,
    "y": 637122.9095
  },
  {
    "village_id": 721,
    "name_en": "Sede ilan ",
    "name_ar": "سْدي إيلان",
    "name_ru": "Сде-Илан",
    "name_he": "שדה אילן ",
    "x": 239999.594,
    "y": 739323.3217
  },
  {
    "village_id": 722,
    "name_en": "Magshimim ",
    "name_ar": "مچشيميم",
    "name_ru": "Magshimim ",
    "name_he": "מגשימים ",
    "x": 190592.1655,
    "y": 661531.324
  },
  {
    "village_id": 723,
    "name_en": "Bet hagaddi ",
    "name_ar": "بيت هچَدي",
    "name_ru": "Bet hagaddi ",
    "name_he": "בית הגדי ",
    "x": 162639.4345,
    "y": 592719.4709
  },
  {
    "village_id": 726,
    "name_en": "Hodiyya ",
    "name_ar": "هودَيوت",
    "name_ru": "Hodiyya ",
    "name_he": "הודיה ",
    "x": 166108.8746,
    "y": 620464.9316
  },
  {
    "village_id": 727,
    "name_en": "Talme yehi'el ",
    "name_ar": "تَلمي يِحيئيل",
    "name_ru": "Talme yehi'el ",
    "name_he": "תלמי יחיאל ",
    "x": 177557.5262,
    "y": 629058.8081
  },
  {
    "village_id": 729,
    "name_en": "Mishmar hashiv'a ",
    "name_ar": "مِشمار هشِڤعا",
    "name_ru": "Mishmar hashiv'a ",
    "name_he": "משמר השבעה ",
    "x": 183542.0161,
    "y": 657462.3996
  },
  {
    "village_id": 730,
    "name_en": "Elifelet ",
    "name_ar": "إليفيلِت",
    "name_ru": "Elifelet ",
    "name_he": "אליפלט ",
    "x": 251666.9279,
    "y": 761388.9619
  },
  {
    "village_id": 731,
    "name_en": "Meshar ",
    "name_ar": "ميشار",
    "name_ru": "Meshar ",
    "name_he": "מישר ",
    "x": 176790.1746,
    "y": 636316.3681
  },
  {
    "village_id": 732,
    "name_en": "Mishmar hayarden ",
    "name_ar": "مِشمار هيَردين",
    "name_ru": "Mishmar hayarden ",
    "name_he": "משמר הירדן ",
    "x": 256410.3221,
    "y": 767824.8252
  },
  {
    "village_id": 734,
    "name_en": "Gan yoshiyya ",
    "name_ar": "چان يوشِيا",
    "name_ru": "Gan yoshiyya ",
    "name_he": "גן יאשיה ",
    "x": 199621.3205,
    "y": 695180.9034
  },
  {
    "village_id": 735,
    "name_en": "Ramot me'ir ",
    "name_ar": "رَموت مِئير",
    "name_ru": "Рамот-Меир",
    "name_he": "רמות מאיר ",
    "x": 186416.2095,
    "y": 642405.5716
  },
  {
    "village_id": 736,
    "name_en": "Gilat ",
    "name_ar": "چيلات",
    "name_ru": "Gilat ",
    "name_he": "גילת ",
    "x": 167013.022,
    "y": 581818.3957
  },
  {
    "village_id": 737,
    "name_en": "Olesh ",
    "name_ar": "عولِش",
    "name_ru": "Olesh ",
    "name_he": "עולש ",
    "x": 198749.0858,
    "y": 693213.1695
  },
  {
    "village_id": 738,
    "name_en": "Dor ",
    "name_ar": "دور",
    "name_ru": "Dor ",
    "name_he": "דור ",
    "x": 193071.6542,
    "y": 723685.1052
  },
  {
    "village_id": 739,
    "name_en": "Sede uzziyyahu ",
    "name_ar": "سْدي عُزِياهو",
    "name_ru": "Sede uzziyyahu ",
    "name_he": "שדה עוזיהו ",
    "x": 169439.7059,
    "y": 629532.4431
  },
  {
    "village_id": 740,
    "name_en": "Eshta'ol ",
    "name_ar": "إشتَؤول",
    "name_ru": "Эштаол",
    "name_he": "אשתאול ",
    "x": 200916.5289,
    "y": 632102.8403
  },
  {
    "village_id": 741,
    "name_en": "Sho'eva ",
    "name_ar": "شوئِڤا",
    "name_ru": "Sho'eva ",
    "name_he": "שואבה ",
    "x": 207532.8389,
    "y": 634018.8504
  },
  {
    "village_id": 742,
    "name_en": "Mesillat ziyyon ",
    "name_ar": "مِسِلات تْسِيون",
    "name_ru": "Mesillat ziyyon ",
    "name_he": "מסילת ציון ",
    "x": 201146.7407,
    "y": 634396.232
  },
  {
    "village_id": 743,
    "name_en": "Kefar shemu'el ",
    "name_ar": "كفار شماي",
    "name_ru": "Kefar shemu'el ",
    "name_he": "כפר שמואל ",
    "x": 193779.8907,
    "y": 643975.6937
  },
  {
    "village_id": 744,
    "name_en": "Talme yafe ",
    "name_ar": "تَلمي يَفي",
    "name_ru": "Talme yafe ",
    "name_he": "תלמי יפה ",
    "x": 163750.69,
    "y": 613861.3164
  },
  {
    "village_id": 745,
    "name_en": "Gimzo ",
    "name_ar": "چِمزو",
    "name_ru": "Gimzo ",
    "name_he": "גמזו ",
    "x": 194516.0053,
    "y": 648383.5442
  },
  {
    "village_id": 746,
    "name_en": "Berekhya ",
    "name_ar": "بِرِخيا",
    "name_ru": "Berekhya ",
    "name_he": "ברכיה ",
    "x": 164588.7074,
    "y": 619842.4039
  },
  {
    "village_id": 747,
    "name_en": "Bet shiqma ",
    "name_ar": "بيت شِكما",
    "name_ru": "Bet shiqma ",
    "name_he": "בית שקמה ",
    "x": 162923.9431,
    "y": 616312.3071
  },
  {
    "village_id": 748,
    "name_en": "Maslul ",
    "name_ar": "مسلول",
    "name_ru": "Maslul ",
    "name_he": "מסלול ",
    "x": 160863.6139,
    "y": 581458.7252
  },
  {
    "village_id": 749,
    "name_en": "Pattish ",
    "name_ar": "پَتيش",
    "name_ru": "Pattish ",
    "name_he": "פטיש ",
    "x": 158065.9748,
    "y": 581822.9446
  },
  {
    "village_id": 750,
    "name_en": "Peduyim ",
    "name_ar": "پْدوييم",
    "name_ru": "Peduyim ",
    "name_he": "פדויים ",
    "x": 163045.833,
    "y": 581868.6471
  },
  {
    "village_id": 751,
    "name_en": "Bet me'ir ",
    "name_ar": "بيت مِئير",
    "name_ru": "Bet me'ir ",
    "name_he": "בית מאיר ",
    "x": 203581.3527,
    "y": 633508.1554
  },
  {
    "village_id": 752,
    "name_en": "Ta'oz ",
    "name_ar": "تَعوز",
    "name_ru": "Ta'oz ",
    "name_he": "תעוז ",
    "x": 197600.4922,
    "y": 634373.3961
  },
  {
    "village_id": 753,
    "name_en": "Yanuv ",
    "name_ar": "يَنوڤ",
    "name_ru": "Yanuv ",
    "name_he": "ינוב ",
    "x": 195381.0164,
    "y": 690207.4862
  },
  {
    "village_id": 755,
    "name_en": "Goren ",
    "name_ar": "چورِن",
    "name_ru": "Goren ",
    "name_he": "גורן ",
    "x": 222343.6968,
    "y": 773588.9194
  },
  {
    "village_id": 756,
    "name_en": "Bet ezra ",
    "name_ar": "بيت عِزرا",
    "name_ru": "Bet ezra ",
    "name_he": "בית עזרא ",
    "x": 167395.3011,
    "y": 627001.1899
  },
  {
    "village_id": 757,
    "name_en": "Mazliah ",
    "name_ar": "مَتْسلييَح",
    "name_ru": "Mazliah ",
    "name_he": "מצליח ",
    "x": 188267.5435,
    "y": 645762.1853
  },
  {
    "village_id": 758,
    "name_en": "Yad hanna ",
    "name_ar": "ياد حنا",
    "name_ru": "ЯД Хана",
    "name_he": "יד חנה ",
    "x": 200777.4062,
    "y": 692470.2064
  },
  {
    "village_id": 759,
    "name_en": "Yaziz ",
    "name_ar": "يَتْسيتْس",
    "name_ru": "Yaziz ",
    "name_he": "יציץ ",
    "x": 187006.8294,
    "y": 641259.3987
  },
  {
    "village_id": 760,
    "name_en": "Ben zakkay ",
    "name_ar": "بين زكاي",
    "name_ru": "Бен-Закай",
    "name_he": "בן זכאי ",
    "x": 174369.5859,
    "y": 640486.6685
  },
  {
    "village_id": 761,
    "name_en": "Shuva ",
    "name_ar": "شوڤا",
    "name_ru": "Shuva ",
    "name_he": "שובה ",
    "x": 156786.224,
    "y": 595536.9926
  },
  {
    "village_id": 762,
    "name_en": "Bitha ",
    "name_ar": "بِتحا",
    "name_ru": "Bitha ",
    "name_he": "בטחה ",
    "x": 164986.2495,
    "y": 582657.988
  },
  {
    "village_id": 763,
    "name_en": "Shetulim ",
    "name_ar": "شْتوليم",
    "name_ru": "Shetulim ",
    "name_he": "שתולים ",
    "x": 170148.8635,
    "y": 631342.6176
  },
  {
    "village_id": 764,
    "name_en": "Kefar mordekhay ",
    "name_ar": "كفار مُردِخاي",
    "name_ru": "Kefar mordekhay ",
    "name_he": "כפר מרדכי ",
    "x": 176844.5664,
    "y": 637722.3966
  },
  {
    "village_id": 765,
    "name_en": "Misgav dov ",
    "name_ar": "مِسچاڤ دوڤ",
    "name_ru": "Misgav dov ",
    "name_he": "משגב דב ",
    "x": 175446.6635,
    "y": 636459.6711
  },
  {
    "village_id": 766,
    "name_en": "Qomemiyyut ",
    "name_ar": "كومِمِيوت",
    "name_ru": "Qomemiyyut ",
    "name_he": "קוממיות ",
    "x": 174473.2105,
    "y": 618957.1017
  },
  {
    "village_id": 767,
    "name_en": "Porat ",
    "name_ar": "پورات",
    "name_ru": "Porat ",
    "name_he": "פורת ",
    "x": 195659.1016,
    "y": 686984.5078
  },
  {
    "village_id": 768,
    "name_en": "Karmiyya ",
    "name_ar": "كرمِيا",
    "name_ru": "Karmiyya ",
    "name_he": "כרמיה ",
    "x": 156623.801,
    "y": 612697.8461
  },
  {
    "village_id": 769,
    "name_en": "Nir ezyon ",
    "name_ar": "نير عِتْسيون",
    "name_ru": "Nir ezyon ",
    "name_he": "ניר עציון ",
    "x": 199503.7757,
    "y": 733893.6237
  },
  {
    "village_id": 771,
    "name_en": "Mevo betar ",
    "name_ar": "مِڤو بيتار",
    "name_ru": "Mevo betar ",
    "name_he": "מבוא ביתר ",
    "x": 210077.4848,
    "y": 625585.3103
  },
  {
    "village_id": 772,
    "name_en": "Emunim ",
    "name_ar": "إمونيم",
    "name_ru": "Emunim ",
    "name_he": "אמונים ",
    "x": 169428.523,
    "y": 628026.4474
  },
  {
    "village_id": 773,
    "name_en": "Ammiqam ",
    "name_ar": "عميكام",
    "name_ru": "Ammiqam ",
    "name_he": "עמיקם ",
    "x": 202207.5239,
    "y": 718811.6346
  },
  {
    "village_id": 774,
    "name_en": "Zuri'el ",
    "name_ar": "تْسوريئيل",
    "name_ru": "Zuri'el ",
    "name_he": "צוריאל ",
    "x": 229714.8044,
    "y": 767955.0777
  },
  {
    "village_id": 775,
    "name_en": "Yad natan ",
    "name_ar": "ياد نتان",
    "name_ru": "ЯД НАТАН",
    "name_he": "יד נתן ",
    "x": 172134.4503,
    "y": 618101.565
  },
  {
    "village_id": 776,
    "name_en": "Mahseya ",
    "name_ar": "مَحسِيا",
    "name_ru": "Mahseya ",
    "name_he": "מחסיה ",
    "x": 200771.8471,
    "y": 628401.1723
  },
  {
    "village_id": 777,
    "name_en": "Nahshon ",
    "name_ar": "نحْشون ",
    "name_ru": "Nahshon ",
    "name_he": "נחשון ",
    "x": 195817.4542,
    "y": 637575.9209
  },
  {
    "village_id": 778,
    "name_en": "Tarum ",
    "name_ar": "تَروم",
    "name_ru": "Tarum ",
    "name_he": "תרום ",
    "x": 198418.1692,
    "y": 632307.074
  },
  {
    "village_id": 779,
    "name_en": "Amminadav ",
    "name_ar": "عمينَداڤ",
    "name_ru": "АММИНАДАВ",
    "name_he": "עמינדב ",
    "x": 213481.7248,
    "y": 628694.1553
  },
  {
    "village_id": 780,
    "name_en": "Ora ",
    "name_ar": "أورا",
    "name_ru": "Ора",
    "name_he": "אורה ",
    "x": 214936.4478,
    "y": 629310.143
  },
  {
    "village_id": 783,
    "name_en": "Even sappir ",
    "name_ar": "إيڤِن سَپير",
    "name_ru": "Эвен Сапир",
    "name_he": "אבן ספיר ",
    "x": 212796.1771,
    "y": 630072.6613
  },
  {
    "village_id": 784,
    "name_en": "Bet nehemya ",
    "name_ar": "بيت نِحِمْيا",
    "name_ru": "Бейт-Нехемия",
    "name_he": "בית נחמיה ",
    "x": 195926.1167,
    "y": 653614.7108
  },
  {
    "village_id": 785,
    "name_en": "Ahihud ",
    "name_ar": "أحيهود",
    "name_ru": "Ахихуд",
    "name_he": "אחיהוד ",
    "x": 216805.2854,
    "y": 756917.6154
  },
  {
    "village_id": 786,
    "name_en": "Kefar zetim ",
    "name_ar": "كفار زيتيم",
    "name_ru": "Kefar zetim ",
    "name_he": "כפר זיתים ",
    "x": 243750.0796,
    "y": 746339.3298
  },
  {
    "village_id": 787,
    "name_en": "Giv'at ye'arim ",
    "name_ar": "چِڤعات يِعَريم",
    "name_ru": "Giv'at ye'arim ",
    "name_he": "גבעת יערים ",
    "x": 208799.0379,
    "y": 632665.7595
  },
  {
    "village_id": 788,
    "name_en": "Zetan ",
    "name_ar": "زيكيم",
    "name_ru": "Zetan ",
    "name_he": "זיתן ",
    "x": 189751.2033,
    "y": 653659.1759
  },
  {
    "village_id": 789,
    "name_en": "Rannen ",
    "name_ar": "رَنين",
    "name_ru": "Rannen ",
    "name_he": "רנן ",
    "x": 162022.3146,
    "y": 583060.827
  },
  {
    "village_id": 791,
    "name_en": "Mash'en ",
    "name_ar": "مَشعين",
    "name_ru": "Mash'en ",
    "name_he": "משען ",
    "x": 164327.379,
    "y": 618525.1348
  },
  {
    "village_id": 792,
    "name_en": "Netiv hashayyara ",
    "name_ar": "نِتيڤ هَشَيَرا",
    "name_ru": "Netiv hashayyara ",
    "name_he": "נתיב השיירה ",
    "x": 213065.8015,
    "y": 766543.1284
  },
  {
    "village_id": 793,
    "name_en": "Giv'ati ",
    "name_ar": "چِڤعتي",
    "name_ru": "Giv'ati ",
    "name_he": "גבעתי ",
    "x": 169744.0121,
    "y": 626779.7255
  },
  {
    "village_id": 794,
    "name_en": "Agur ",
    "name_ar": "عَچور",
    "name_ru": "Agur ",
    "name_he": "עגור ",
    "x": 191765.2829,
    "y": 622782.6354
  },
  {
    "village_id": 795,
    "name_en": "Ya'ara ",
    "name_ar": "يَعرا",
    "name_ru": "Ya'ara ",
    "name_he": "יערה ",
    "x": 217714.0061,
    "y": 774717.8248
  },
  {
    "village_id": 796,
    "name_en": "Zelafon ",
    "name_ar": "تْسلَفون",
    "name_ru": "Zelafon ",
    "name_he": "צלפון ",
    "x": 193207.0017,
    "y": 634814.0659
  },
  {
    "village_id": 797,
    "name_en": "Ahi'ezer ",
    "name_ar": "أحيعيزِر",
    "name_ru": "Ахиэзер",
    "name_he": "אחיעזר ",
    "x": 188015.0726,
    "y": 654283.3452
  },
  {
    "village_id": 798,
    "name_en": "Yagel ",
    "name_ar": "يَچيل",
    "name_ru": "Yagel ",
    "name_he": "יגל ",
    "x": 188701.5546,
    "y": 655073.8409
  },
  {
    "village_id": 799,
    "name_en": "Zekharya ",
    "name_ar": "زِخَريا",
    "name_ru": "Zekharya ",
    "name_he": "זכריה ",
    "x": 194844.9902,
    "y": 624165.2623
  },
  {
    "village_id": 800,
    "name_en": "Bet hananya ",
    "name_ar": "بيت حَنَنْيا",
    "name_ru": "Bet hananya ",
    "name_he": "בית חנניה ",
    "x": 193285.5696,
    "y": 715000.0829
  },
  {
    "village_id": 801,
    "name_en": "Hemed ",
    "name_ar": "حيمِد",
    "name_ru": "Hemed ",
    "name_he": "חמד ",
    "x": 185030.4216,
    "y": 658421.7943
  },
  {
    "village_id": 802,
    "name_en": "Giv'at koah ",
    "name_ar": "چِڤعات كووَح",
    "name_ru": "Giv'at koah ",
    "name_he": "גבעת כ\"ח ",
    "x": 194087.4768,
    "y": 659789.152
  },
  {
    "village_id": 803,
    "name_en": "Yoshivya ",
    "name_ar": "يوشيڤْيا",
    "name_ru": "Yoshivya ",
    "name_he": "יושיביה ",
    "x": 162827.99,
    "y": 594738.4778
  },
  {
    "village_id": 804,
    "name_en": "Ahisamakh ",
    "name_ar": "أحيسَماخ",
    "name_ru": "Ахисамах",
    "name_he": "אחיסמך ",
    "x": 191340.8645,
    "y": 649057.4436
  },
  {
    "village_id": 805,
    "name_en": "Yish'i ",
    "name_ar": "يِشْعي",
    "name_ru": "Yish'i ",
    "name_he": "ישעי ",
    "x": 196835.0309,
    "y": 628775.4465
  },
  {
    "village_id": 806,
    "name_en": "En yahav ",
    "name_ar": "عين ياهَڤ",
    "name_ru": "En yahav ",
    "name_he": "עין יהב ",
    "x": 222652.8379,
    "y": 507532.5143
  },
  {
    "village_id": 807,
    "name_en": "Hanni'el ",
    "name_ar": "حَنيئيل",
    "name_ru": "Hanni'el ",
    "name_he": "חניאל ",
    "x": 195329.2064,
    "y": 693222.5124
  },
  {
    "village_id": 808,
    "name_en": "Nir eliyyahu ",
    "name_ar": "نير إلِياهو",
    "name_ru": "Nir eliyyahu ",
    "name_he": "ניר אליהו ",
    "x": 195406.0038,
    "y": 678194.7746
  },
  {
    "village_id": 809,
    "name_en": "Naham ",
    "name_ar": "ناحَم",
    "name_ru": "Naham ",
    "name_he": "נחם ",
    "x": 200386.1447,
    "y": 630459.438
  },
  {
    "village_id": 810,
    "name_en": "Ofer ",
    "name_ar": "عوفِر",
    "name_ru": "Ofer ",
    "name_he": "עופר ",
    "x": 198560.6568,
    "y": 725354.4012
  },
  {
    "village_id": 811,
    "name_en": "Yakhini ",
    "name_ar": "يَخيني",
    "name_ru": "Yakhini ",
    "name_he": "יכיני ",
    "x": 162022.1134,
    "y": 599145.3664
  },
  {
    "village_id": 812,
    "name_en": "Shelomi ",
    "name_ar": "شلومي",
    "name_ru": "Shelomi ",
    "name_he": "שלומי ",
    "x": 214152.2914,
    "y": 775690.5177
  },
  {
    "village_id": 813,
    "name_en": "En ya'aqov ",
    "name_ar": "عين يعكوڤ",
    "name_ru": "En ya'aqov ",
    "name_he": "עין יעקב ",
    "x": 221975.2176,
    "y": 768379.2323
  },
  {
    "village_id": 814,
    "name_en": "Telamim ",
    "name_ar": "تْلَميم",
    "name_ru": "Telamim ",
    "name_he": "תלמים ",
    "x": 168975.6761,
    "y": 608187.7783
  },
  {
    "village_id": 815,
    "name_en": "Zavdi'el ",
    "name_ar": "زَڤديئيل",
    "name_ru": "Zavdi'el ",
    "name_he": "זבדיאל ",
    "x": 177328.5549,
    "y": 618587.814
  },
  {
    "village_id": 816,
    "name_en": "Zanoah ",
    "name_ar": "زنووَح",
    "name_ru": "Zanoah ",
    "name_he": "זנוח ",
    "x": 200045.2429,
    "y": 626653.9314
  },
  {
    "village_id": 817,
    "name_en": "Azriqam ",
    "name_ar": "عزريكام",
    "name_ru": "Azriqam ",
    "name_he": "עזריקם ",
    "x": 171404.225,
    "y": 628846.0008
  },
  {
    "village_id": 818,
    "name_en": "Zerahya ",
    "name_ar": "زِرَحيا",
    "name_ru": "Zerahya ",
    "name_he": "זרחיה ",
    "x": 176010.2348,
    "y": 621127.3045
  },
  {
    "village_id": 819,
    "name_en": "Avigedor ",
    "name_ar": "أڤيچدور",
    "name_ru": "Авигдор",
    "name_he": "אביגדור ",
    "x": 175778.5001,
    "y": 624224.9967
  },
  {
    "village_id": 820,
    "name_en": "Helez ",
    "name_ar": "حيلِتس",
    "name_ru": "Helez ",
    "name_he": "חלץ ",
    "x": 167546.3466,
    "y": 609657.4106
  },
  {
    "village_id": 821,
    "name_en": "Ahuzzam ",
    "name_ar": "أحُزام",
    "name_ru": "Ахуззам",
    "name_he": "אחוזם ",
    "x": 178241.7764,
    "y": 606942.5752
  },
  {
    "village_id": 822,
    "name_en": "Matta ",
    "name_ar": "مَتاع",
    "name_ru": "Матта",
    "name_he": "מטע ",
    "x": 205894.4736,
    "y": 625024.9871
  },
  {
    "village_id": 823,
    "name_en": "Bar giyyora ",
    "name_ar": "بار چيورا",
    "name_ru": "Bar giyyora ",
    "name_he": "בר גיורא ",
    "x": 207008.9692,
    "y": 626280.4066
  },
  {
    "village_id": 824,
    "name_en": "Kokhav mikha'el ",
    "name_ar": "كوخاڤ ميخَئيل",
    "name_ru": "Kokhav mikha'el ",
    "name_he": "כוכב מיכאל ",
    "x": 168680.8606,
    "y": 615461.6147
  },
  {
    "village_id": 825,
    "name_en": "Nes harim ",
    "name_ar": "نيس هريم",
    "name_ru": "Nes harim ",
    "name_he": "נס הרים ",
    "x": 205326.0237,
    "y": 628113.781
  },
  {
    "village_id": 826,
    "name_en": "Uza ",
    "name_ar": "عُزا",
    "name_ru": "Uza ",
    "name_he": "עוזה ",
    "x": 177659.8354,
    "y": 611191.2798
  },
  {
    "village_id": 827,
    "name_en": "Newe mivtah ",
    "name_ar": "نِڤي مِڤتاح",
    "name_ru": "Newe mivtah ",
    "name_he": "נווה מבטח ",
    "x": 175524.0569,
    "y": 634844.8872
  },
  {
    "village_id": 828,
    "name_en": "Yashresh ",
    "name_ar": "يَشْريش",
    "name_ru": "Yashresh ",
    "name_he": "ישרש ",
    "x": 185782.1605,
    "y": 646941.2647
  },
  {
    "village_id": 829,
    "name_en": "Mivtahim ",
    "name_ar": "مِڤتَحيم",
    "name_ru": "Mivtahim ",
    "name_he": "מבטחים ",
    "x": 143612.5458,
    "y": 572306.751
  },
  {
    "village_id": 831,
    "name_en": "Yeroham ",
    "name_ar": "يِرُحام",
    "name_ru": "Йерухам",
    "name_he": "ירוחם ",
    "x": 193329.7188,
    "y": 544192.6121
  },
  {
    "village_id": 833,
    "name_en": "Nurit ",
    "name_ar": "نوريت",
    "name_ru": "Nurit ",
    "name_he": "נורית ",
    "x": 233737.0367,
    "y": 716387.7948
  },
  {
    "village_id": 836,
    "name_en": "Gannot ",
    "name_ar": "چَنوت",
    "name_ru": "Ганот",
    "name_he": "גנות ",
    "x": 183949.3525,
    "y": 658364.6917
  },
  {
    "village_id": 837,
    "name_en": "Azri'el ",
    "name_ar": "عزْريئيل",
    "name_ru": "Azri'el ",
    "name_he": "עזריאל ",
    "x": 197430.8179,
    "y": 685372.729
  },
  {
    "village_id": 838,
    "name_en": "Pedaya ",
    "name_ar": "پْدَيا",
    "name_ru": "Pedaya ",
    "name_he": "פדיה ",
    "x": 188617.4504,
    "y": 640374.4055
  },
  {
    "village_id": 839,
    "name_en": "Petahya ",
    "name_ar": "پيتح تِكڤا",
    "name_ru": "Petahya ",
    "name_he": "פתחיה ",
    "x": 189332.5995,
    "y": 641703.8381
  },
  {
    "village_id": 840,
    "name_en": "Kissufim ",
    "name_ar": "كِسوفيم",
    "name_ru": "Kissufim ",
    "name_he": "כיסופים ",
    "x": 142951.1235,
    "y": 587239.82
  },
  {
    "village_id": 841,
    "name_en": "Elishama ",
    "name_ar": "إليشَماع",
    "name_ru": "Элишама",
    "name_he": "אלישמע ",
    "x": 193394.7298,
    "y": 673237.6897
  },
  {
    "village_id": 842,
    "name_en": "Ga'ash ",
    "name_ar": "چاعَش",
    "name_ru": "Ga'ash ",
    "name_he": "געש ",
    "x": 183774.3219,
    "y": 681773.5957
  },
  {
    "village_id": 843,
    "name_en": "Margaliyyot ",
    "name_ar": "مرْچَلِيوت",
    "name_ru": "Margaliyyot ",
    "name_he": "מרגליות ",
    "x": 251209.4768,
    "y": 791061.3758
  },
  {
    "village_id": 844,
    "name_en": "Nahal oz ",
    "name_ar": "ناحَل عوز",
    "name_ru": "Nahal oz ",
    "name_he": "נחל עוז ",
    "x": 152292.1163,
    "y": 598029.159
  },
  {
    "village_id": 845,
    "name_en": "Kefar azza ",
    "name_ar": "كفار عزا",
    "name_ru": "Kefar azza ",
    "name_he": "כפר עזה ",
    "x": 155660.3538,
    "y": 599250.4923
  },
  {
    "village_id": 846,
    "name_en": "Shefer ",
    "name_ar": "شيفِر",
    "name_ru": "Shefer ",
    "name_he": "שפר ",
    "x": 241153.5407,
    "y": 761027.8384
  },
  {
    "village_id": 848,
    "name_en": "Bet rabban ",
    "name_ar": "بيت رَبان",
    "name_ru": "Bet rabban ",
    "name_he": "בית רבן ",
    "x": 174479.0073,
    "y": 636150.1277
  },
  {
    "village_id": 849,
    "name_en": "Dvir ",
    "name_ar": "دڤير",
    "name_ru": "Dvir ",
    "name_he": "דביר ",
    "x": 183323.5516,
    "y": 591204.2188
  },
  {
    "village_id": 850,
    "name_en": "Ahituv ",
    "name_ar": "أحيتوڤ",
    "name_ru": "Ahituv ",
    "name_he": "אחיטוב ",
    "x": 199347.8665,
    "y": 699336.5415
  },
  {
    "village_id": 851,
    "name_en": "Nizzane oz ",
    "name_ar": "نِتْسَني عوز",
    "name_ru": "Nizzane oz ",
    "name_he": "ניצני עוז ",
    "x": 200534.9646,
    "y": 690320.718
  },
  {
    "village_id": 852,
    "name_en": "Gonen ",
    "name_ar": "چونين",
    "name_ru": "Gonen ",
    "name_he": "גונן ",
    "x": 260684.867,
    "y": 780918.1215
  },
  {
    "village_id": 853,
    "name_en": "Ge'alya ",
    "name_ar": "چِأليا ",
    "name_ru": "Геалия",
    "name_he": "גאליה ",
    "x": 177965.2724,
    "y": 643567.9311
  },
  {
    "village_id": 854,
    "name_en": "Rehov ",
    "name_ar": "رِحوڤ",
    "name_ru": "Rehov ",
    "name_he": "רחוב ",
    "x": 246206.7053,
    "y": 706375.8216
  },
  {
    "village_id": 856,
    "name_en": "Sha'alvim ",
    "name_ar": "شعلڤيم",
    "name_ru": "Sha'alvim ",
    "name_he": "שעלבים ",
    "x": 198513.9132,
    "y": 642031.0894
  },
  {
    "village_id": 857,
    "name_en": "Kefar aviv ",
    "name_ar": "كفار أڤيڤ",
    "name_ru": "Kefar aviv ",
    "name_he": "כפר אביב ",
    "x": 173692.2097,
    "y": 637678.7407
  },
  {
    "village_id": 858,
    "name_en": "Newe yaraq ",
    "name_ar": "نِڤي يَراك",
    "name_ru": "Newe yaraq ",
    "name_he": "נווה ירק ",
    "x": 193054.0853,
    "y": 671091.9566
  },
  {
    "village_id": 859,
    "name_en": "Kesalon ",
    "name_ar": "كْسَلون",
    "name_ru": "Kesalon ",
    "name_he": "כסלון ",
    "x": 204786.104,
    "y": 631243.7135
  },
  {
    "village_id": 861,
    "name_en": "Sede eli'ezer ",
    "name_ar": "سْدي إليعيزِر",
    "name_ru": "Сде-Элиэзер",
    "name_he": "שדה אליעזר ",
    "x": 252664.4816,
    "y": 772251.7787
  },
  {
    "village_id": 862,
    "name_en": "Ganne yohanan ",
    "name_ar": "چَني يوحنان",
    "name_ru": "Ganne yohanan ",
    "name_he": "גני יוחנן ",
    "x": 185357.1261,
    "y": 640720.5462
  },
  {
    "village_id": 863,
    "name_en": "Ginnaton ",
    "name_ar": "چِنتون",
    "name_ru": "Гинатон",
    "name_he": "גינתון ",
    "x": 191979.3488,
    "y": 652283.0239
  },
  {
    "village_id": 864,
    "name_en": "Beqoa ",
    "name_ar": "بكووَع",
    "name_ru": "Beqoa ",
    "name_he": "בקוע ",
    "x": 192970.3927,
    "y": 637423.7721
  },
  {
    "village_id": 865,
    "name_en": "Shibbolim ",
    "name_ar": "شِبوليم",
    "name_ru": "Shibbolim ",
    "name_he": "שיבולים ",
    "x": 162695.3331,
    "y": 589541.1219
  },
  {
    "village_id": 866,
    "name_en": "Yotvata ",
    "name_ar": "يُتڤاتا",
    "name_ru": "Йотвата",
    "name_he": "יטבתה ",
    "x": 205802.5761,
    "y": 422945.2318
  },
  {
    "village_id": 868,
    "name_en": "Allone yizhaq ",
    "name_ar": "ألوني يِتسحاك",
    "name_ru": "Алоней-Ицхак",
    "name_he": "אלוני יצחק ",
    "x": 200633.9674,
    "y": 712933.5238
  },
  {
    "village_id": 870,
    "name_en": "Giv'at hashelosha ",
    "name_ar": "چِڤعات هشلوشا",
    "name_ru": "Гиват-ха-Шлоша",
    "name_he": "גבעת השלושה ",
    "x": 192809.1103,
    "y": 667311.5977
  },
  {
    "village_id": 871,
    "name_en": "Enat ",
    "name_ar": "عينات",
    "name_ru": "Enat ",
    "name_he": "עינת ",
    "x": 194379.4128,
    "y": 665508.7489
  },
  {
    "village_id": 872,
    "name_en": "Ge'ule teman ",
    "name_ar": "چِؤولي تيمان",
    "name_ru": "Ge'ule teman ",
    "name_he": "גאולי תימן ",
    "x": 190951.9081,
    "y": 699717.6063
  },
  {
    "village_id": 873,
    "name_en": "Shalwa ",
    "name_ar": "شَلڤا",
    "name_ru": "Shalwa ",
    "name_he": "שלווה ",
    "x": 177987.8061,
    "y": 608104.4797
  },
  {
    "village_id": 874,
    "name_en": "Migdal haemeq ",
    "name_ar": "مِچدال هعيمِك",
    "name_ru": "Мигдаль-ха-Эмек",
    "name_he": "מגדל העמק ",
    "x": 223062.4398,
    "y": 731638.8091
  },
  {
    "village_id": 875,
    "name_en": "Kefar avoda ",
    "name_ar": "كفار عڤودا",
    "name_ru": "Kefar avoda ",
    "name_he": "כפר עבודה ",
    "x": 194644.8958,
    "y": 684916.6543
  },
  {
    "village_id": 877,
    "name_en": "Bet herut ",
    "name_ar": "بيت حِروت",
    "name_ru": "Bet herut ",
    "name_he": "בית חירות ",
    "x": 187768.2525,
    "y": 698508.835
  },
  {
    "village_id": 880,
    "name_en": "En sarid ",
    "name_ar": "عين سَريد",
    "name_ru": "En sarid ",
    "name_he": "עין שריד ",
    "x": 194130.978,
    "y": 686706.3884
  },
  {
    "village_id": 882,
    "name_en": "Oranim ",
    "name_ar": "أُرَنيم",
    "name_ru": "Oranim ",
    "name_he": "אורנים ",
    "x": 210496.2916,
    "y": 735330.0298
  },
  {
    "village_id": 885,
    "name_en": "Sede boqer ",
    "name_ar": "سْدي بوكير",
    "name_ru": "Сде Бокер",
    "name_he": "שדה בוקר ",
    "x": 180131.4644,
    "y": 531281.6972
  },
  {
    "village_id": 886,
    "name_en": "Etanim ",
    "name_ar": "إيتَنيم",
    "name_ru": "Etanim ",
    "name_he": "איתנים ",
    "x": 209155.4018,
    "y": 631742.6071
  },
  {
    "village_id": 888,
    "name_en": "Kefar harif ",
    "name_ar": "كفار هريف",
    "name_ru": "Kefar harif ",
    "name_he": "כפר הרי\"ף ",
    "x": 180271.3863,
    "y": 628336.3364
  },
  {
    "village_id": 889,
    "name_en": "Kefar hasidim bet ",
    "name_ar": "كفار حسيديم بيت",
    "name_ru": "Kefar hasidim bet ",
    "name_he": "כפר חסידים ב' ",
    "x": 208457.9668,
    "y": 739474.0455
  },
  {
    "village_id": 890,
    "name_en": "Kefar hano'ar hadati ",
    "name_ar": "كفار هنوعر هدَتي",
    "name_ru": "Kefar hano'ar hadati ",
    "name_he": "כפר הנוער הדתי ",
    "x": 209586.7725,
    "y": 738632.3343
  },
  {
    "village_id": 892,
    "name_en": "Avdon ",
    "name_ar": "عڤدون",
    "name_ru": "Avdon ",
    "name_he": "עבדון ",
    "x": 217380.3866,
    "y": 772688.8264
  },
  {
    "village_id": 897,
    "name_en": "Midreshet ruppin ",
    "name_ar": "مِدريشِت روپين",
    "name_ru": "Midreshet ruppin ",
    "name_he": "מדרשת רופין ",
    "x": 191951.1724,
    "y": 694271.765
  },
  {
    "village_id": 913,
    "name_en": "Shibli ",
    "name_ar": "الشبلي – أم الغنم",
    "name_ru": "Shibli ",
    "name_he": "שבלי - אום אל-גנם ",
    "x": 238269.3821,
    "y": 731590.309
  },
  {
    "village_id": 916,
    "name_en": "Yesha ",
    "name_ar": "يِيشع",
    "name_ru": "Yesha ",
    "name_he": "ישע ",
    "x": 143015.1847,
    "y": 573153.1121
  },
  {
    "village_id": 917,
    "name_en": "Atsmon-segev ",
    "name_ar": "عتْسمون (سيچِڤ) ",
    "name_ru": "Atsmon-segev ",
    "name_he": "עצמון שגב ",
    "x": 223947.6479,
    "y": 752296.3796
  },
  {
    "village_id": 919,
    "name_en": "Giv'at yesha'yahu ",
    "name_ar": "چِڤعات يِشعياهو",
    "name_ru": "Giv'at yesha'yahu ",
    "name_he": "גבעת ישעיהו ",
    "x": 195117.6713,
    "y": 619794.8492
  },
  {
    "village_id": 921,
    "name_en": "Sha'ar menashe ",
    "name_ar": "شاعَر مِنَشي",
    "name_ru": "Sha'ar menashe ",
    "name_he": "שער מנשה ",
    "x": 201749.1151,
    "y": 706217.0889
  },
  {
    "village_id": 922,
    "name_en": "Rekhasim ",
    "name_ar": "رِخَسيم",
    "name_ru": "Rekhasim ",
    "name_he": "רכסים ",
    "x": 209864.6531,
    "y": 739656.0104
  },
  {
    "village_id": 926,
    "name_en": "Newe avot ",
    "name_ar": "نِڤي أڤوت",
    "name_ru": "Newe avot ",
    "name_he": "נווה אבות ",
    "x": 197154.2478,
    "y": 710203.3007
  },
  {
    "village_id": 932,
    "name_en": "Abu amre ",
    "name_ar": "أبو عمرة",
    "name_ru": "Abu amre ",
    "name_he": "אבו עמרה (שבט) ",
    "x": null,
    "y": null
  },
  {
    "village_id": 935,
    "name_en": "Abu sureihan ",
    "name_ar": "أبو سريحان",
    "name_ru": "Abu sureihan ",
    "name_he": "אבו סריחאן (שבט) ",
    "x": 189040.6101,
    "y": 574593.8317
  },
  {
    "village_id": 939,
    "name_en": "Mas'udin al-'azazme ",
    "name_ar": "مسعودين العزازمة",
    "name_ru": "Mas'udin al-'azazme ",
    "name_he": "מסעודין אל-עזאזמה ",
    "x": 182384.3088,
    "y": 567415.3741
  },
  {
    "village_id": 942,
    "name_en": "Sawa'id(hamriyye) ",
    "name_ar": "سواعد (حميرة)",
    "name_ru": "Sawa'id(hamriyye) ",
    "name_he": "סואעד (חמרייה) ",
    "x": 216032.3397,
    "y": 741429.3291
  },
  {
    "village_id": 944,
    "name_en": "Basmat tab'un ",
    "name_ar": "بسمة طبعون",
    "name_ru": "Басмат-Табун",
    "name_he": "בסמת טבעון ",
    "x": 213605.9871,
    "y": 737897.1052
  },
  {
    "village_id": 948,
    "name_en": "Hujeirat (dahra) ",
    "name_ar": "حُچلا",
    "name_ru": "Hujeirat (dahra) ",
    "name_he": "חוג'ייראת (ד'הרה) ",
    "x": 227372.181,
    "y": 748920.1721
  },
  {
    "village_id": 956,
    "name_en": "Huzayyel ",
    "name_ar": "هوشعيا",
    "name_ru": "Huzayyel ",
    "name_he": "הוזייל (שבט) ",
    "x": 176003.5924,
    "y": 590746.6739
  },
  {
    "village_id": 957,
    "name_en": "Uqbi (banu uqba) ",
    "name_ar": "العقبي (بنو عقبة)",
    "name_ru": "Uqbi (banu uqba) ",
    "name_he": "עוקבי (בנו עוקבה) ",
    "x": 184816.3247,
    "y": 578866.3358
  },
  {
    "village_id": 958,
    "name_en": "Abu abdun ",
    "name_ar": "أبو عبدون",
    "name_ru": "Abu abdun ",
    "name_he": "אבו עבדון (שבט) ",
    "x": 199453.769,
    "y": 580314.3896
  },
  {
    "village_id": 959,
    "name_en": "Afeinish ",
    "name_ar": "أفينش",
    "name_ru": "Afeinish ",
    "name_he": "אפיניש (שבט) ",
    "x": 199453.769,
    "y": 580314.3896
  },
  {
    "village_id": 960,
    "name_en": "Asad ",
    "name_ar": "أسد",
    "name_ru": "Asad ",
    "name_he": "אסד (שבט) ",
    "x": null,
    "y": null
  },
  {
    "village_id": 961,
    "name_en": "Abu ruqayyeq ",
    "name_ar": "أبو رقيق",
    "name_ru": "Abu ruqayyeq ",
    "name_he": "אבו רוקייק (שבט) ",
    "x": 191714.5304,
    "y": 565882.3972
  },
  {
    "village_id": 962,
    "name_en": "Tuba-zangariyye ",
    "name_ar": "طوبا–زنغرية",
    "name_ru": "Туба-Зангария",
    "name_he": "טובא-זנגריה ",
    "x": 255953.3204,
    "y": 763666.7383
  },
  {
    "village_id": 963,
    "name_en": "A'sam ",
    "name_ar": "أعصم",
    "name_ru": "A'sam ",
    "name_he": "אעצם (שבט) ",
    "x": 191841.5905,
    "y": 561236.7545
  },
  {
    "village_id": 964,
    "name_en": "Qudeirat as-sani ",
    "name_ar": "قديرات الصانع",
    "name_ru": "Qudeirat as-sani ",
    "name_he": "קודייראת א-צאנע(שבט) ",
    "x": 210963.0934,
    "y": 578239.5299
  },
  {
    "village_id": 965,
    "name_en": "Atrash ",
    "name_ar": "أطرش",
    "name_ru": "Atrash ",
    "name_he": "אטרש (שבט) ",
    "x": null,
    "y": null
  },
  {
    "village_id": 966,
    "name_en": "Abu rubei'a ",
    "name_ar": "أبو ربيعة",
    "name_ru": "Abu rubei'a ",
    "name_he": "אבו רובייעה (שבט) ",
    "x": null,
    "y": null
  },
  {
    "village_id": 967,
    "name_en": "Abu juwei'id ",
    "name_ar": "أبو جويعد",
    "name_ru": "Abu juwei'id ",
    "name_he": "אבו ג'ווייעד (שבט) ",
    "x": 206977.0073,
    "y": 565472.1393
  },
  {
    "village_id": 968,
    "name_en": "Abu qureinat ",
    "name_ar": "أبو قرينات",
    "name_ru": "Abu qureinat ",
    "name_he": "אבו קורינאת (שבט) ",
    "x": null,
    "y": null
  },
  {
    "village_id": 969,
    "name_en": "Atawne ",
    "name_ar": "العطاونة",
    "name_ru": "Atawne ",
    "name_he": "עטאוונה (שבט) ",
    "x": 193819.0481,
    "y": 576813.6923
  },
  {
    "village_id": 970,
    "name_en": "Tarabin as-sani ",
    "name_ar": "ترابين الصانع",
    "name_ru": "Tarabin as-sani ",
    "name_he": "תראבין א-צאנע (שבט) ",
    "x": 182470.0234,
    "y": 563993.9105
  },
  {
    "village_id": 972,
    "name_en": "Qawa'in ",
    "name_ar": "قواعين",
    "name_ru": "Qawa'in ",
    "name_he": "קוואעין (שבט) ",
    "x": 202565.6438,
    "y": 581389.0918
  },
  {
    "village_id": 975,
    "name_en": "Zarzir ",
    "name_ar": "زرزير",
    "name_ru": "Zarzir ",
    "name_he": "זרזיר ",
    "x": 221258.3241,
    "y": 735858.6397
  },
  {
    "village_id": 976,
    "name_en": "Junnabib ",
    "name_ar": "جلجولية",
    "name_ru": "Junnabib ",
    "name_he": "ג'נאביב (שבט) ",
    "x": null,
    "y": null
  },
  {
    "village_id": 978,
    "name_en": "Ka'abiyye-tabbash-ha ",
    "name_ar": "كعبية–طباش–حجاجرة",
    "name_ru": "Ka'abiyye-tabbash-ha ",
    "name_he": "כעביה-טבאש-חג'אג'רה ",
    "x": 219398.9301,
    "y": 738650.472
  },
  {
    "village_id": 986,
    "name_en": "Khawaled ",
    "name_ar": "خوالد",
    "name_ru": "Khawaled ",
    "name_he": "ח'ואלד (שבט) ",
    "x": 215131.4965,
    "y": 740488.737
  },
  {
    "village_id": 989,
    "name_en": "Sawa'id (kamane) ",
    "name_ar": "سواعد (كمانة)",
    "name_ru": "Sawa'id (kamane) ",
    "name_he": "סואעד (כמאנה) (שבט) ",
    "x": null,
    "y": null
  },
  {
    "village_id": 990,
    "name_en": "Ras ali ",
    "name_ar": "رأس علي",
    "name_ru": "Ras ali ",
    "name_he": "ראס עלי ",
    "x": 214763.9237,
    "y": 741912.4215
  },
  {
    "village_id": 993,
    "name_en": "Hamam ",
    "name_ar": "حمام",
    "name_ru": "Hamam ",
    "name_he": "חמאם ",
    "x": 246177.5189,
    "y": 748372.4666
  },
  {
    "village_id": 994,
    "name_en": "Manshiyyet zabda ",
    "name_ar": "منشية الزبدة",
    "name_ru": "Manshiyyet zabda ",
    "name_he": "מנשית זבדה ",
    "x": 218310.8303,
    "y": 734569.2807
  },
  {
    "village_id": 997,
    "name_en": "Rumat heib ",
    "name_ar": "رمة الهيب",
    "name_ru": "Rumat heib ",
    "name_he": "רומת הייב ",
    "x": 228688.0251,
    "y": 742751.5633
  },
  {
    "village_id": 998,
    "name_en": "Bir el-maksur ",
    "name_ar": "بئر المكسور ",
    "name_ru": "Бир-эль-Максур",
    "name_he": "ביר אל-מכסור ",
    "x": 221189.7767,
    "y": 742698.3405
  },
  {
    "village_id": 1015,
    "name_en": "Mevasseret ziyyon ",
    "name_ar": "مِڤَسيرِت تْسِيون",
    "name_ru": "Мевасерет-Цион",
    "name_he": "מבשרת ציון ",
    "x": 213484.9019,
    "y": 633641.3781
  },
  {
    "village_id": 1020,
    "name_en": "Or aqiva ",
    "name_ar": "أور عكيڤا",
    "name_ru": "Ор-Акива",
    "name_he": "אור עקיבא ",
    "x": 192736.1735,
    "y": 712855.8218
  },
  {
    "village_id": 1024,
    "name_en": "Haruzim ",
    "name_ar": "حروتْسيم",
    "name_ru": "Haruzim ",
    "name_he": "חרוצים ",
    "x": 187444.7783,
    "y": 681587.1723
  },
  {
    "village_id": 1031,
    "name_en": "Sederot ",
    "name_ar": "سدِروت",
    "name_ru": "Сдерот",
    "name_he": "שדרות ",
    "x": 161853.6833,
    "y": 603953.7757
  },
  {
    "village_id": 1034,
    "name_en": "Qiryat mal'akhi ",
    "name_ar": "كِريات مَلأخي",
    "name_ru": "Кирьят-Малахи",
    "name_he": "קרית מלאכי ",
    "x": 175960.9805,
    "y": 626640.5975
  },
  {
    "village_id": 1041,
    "name_en": "Nasasra ",
    "name_ar": "النصاصرة",
    "name_ru": "Nasasra ",
    "name_he": "נצאצרה (שבט) ",
    "x": 209964.2474,
    "y": 569452.6981
  },
  {
    "village_id": 1042,
    "name_en": "Abu ammar ",
    "name_ar": "أبو عمار",
    "name_ru": "Abu ammar ",
    "name_he": "אבו עמאר (שבט) ",
    "x": null,
    "y": null
  },
  {
    "village_id": 1043,
    "name_en": "Gizo ",
    "name_ar": "چيزو",
    "name_ru": "Gizo ",
    "name_he": "גיזו ",
    "x": 194284.1836,
    "y": 634725.9559
  },
  {
    "village_id": 1044,
    "name_en": "Ye'af ",
    "name_ar": "يِعاف",
    "name_ru": "Ye'af ",
    "name_he": "יעף ",
    "x": 197010.3373,
    "y": 686043.03
  },
  {
    "village_id": 1045,
    "name_en": "Shetula ",
    "name_ar": "شْتولا",
    "name_ru": "Shetula ",
    "name_he": "שתולה ",
    "x": 229675.6011,
    "y": 776737.2711
  },
  {
    "village_id": 1046,
    "name_en": "Ohad ",
    "name_ar": "أوهَد",
    "name_ru": "Ohad ",
    "name_he": "אוהד ",
    "x": 145868.5112,
    "y": 571971.7196
  },
  {
    "village_id": 1047,
    "name_en": "Hazon ",
    "name_ar": "حَزون",
    "name_ru": "Hazon ",
    "name_he": "חזון ",
    "x": 237469.196,
    "y": 756679.9253
  },
  {
    "village_id": 1050,
    "name_en": "Bet hashmonay ",
    "name_ar": "بيت حشموناي",
    "name_ru": "Bet hashmonay ",
    "name_he": "בית חשמונאי ",
    "x": 192479.2893,
    "y": 644002.6818
  },
  {
    "village_id": 1051,
    "name_en": "Talme eliyyahu ",
    "name_ar": "تَلمي إلِياهو",
    "name_ru": "Talme eliyyahu ",
    "name_he": "תלמי אליהו ",
    "x": 145622.3068,
    "y": 570978.3821
  },
  {
    "village_id": 1052,
    "name_en": "Qetura ",
    "name_ar": "كْتورا",
    "name_ru": "Qetura ",
    "name_he": "קטורה ",
    "x": 205626.9404,
    "y": 431196.2207
  },
  {
    "village_id": 1053,
    "name_en": "En hazeva ",
    "name_ar": "عين حتْسِڤا",
    "name_ru": "En hazeva ",
    "name_he": "עין חצבה ",
    "x": 223416.8501,
    "y": 523078.9795
  },
  {
    "village_id": 1054,
    "name_en": "Tel sheva ",
    "name_ar": "تل السبع",
    "name_ru": "Tel sheva ",
    "name_he": "תל שבע ",
    "x": 186825.9903,
    "y": 573009.0864
  },
  {
    "village_id": 1056,
    "name_en": "En karem-b.s.haqla'i ",
    "name_ar": "عين كيرِم (مدرسة زراعية)",
    "name_ru": "En karem-b.s.haqla'i ",
    "name_he": "עין כרם-בי\"ס חקלאי ",
    "x": 214530.0877,
    "y": 631690.1429
  },
  {
    "village_id": 1057,
    "name_en": "Newe zohar ",
    "name_ar": "نِڤي زوهَر",
    "name_ru": "Newe zohar ",
    "name_he": "נווה זוהר ",
    "x": 234728.6087,
    "y": 562362.0813
  },
  {
    "village_id": 1058,
    "name_en": "Sede nizzan ",
    "name_ar": "سْدي نِتْسان",
    "name_ru": "Sede nizzan ",
    "name_he": "שדה ניצן ",
    "x": 144651.7257,
    "y": 571168.0517
  },
  {
    "village_id": 1059,
    "name_en": "Kuseife ",
    "name_ar": "كسيفة",
    "name_ru": "Kuseife ",
    "name_he": "כסיפה ",
    "x": 207852.4622,
    "y": 572828.5837
  },
  {
    "village_id": 1060,
    "name_en": "Laqye ",
    "name_ar": "اللقية",
    "name_ru": "Laqye ",
    "name_he": "לקיה ",
    "x": 187117.3596,
    "y": 581400.3412
  },
  {
    "village_id": 1061,
    "name_en": "Nof hagalil ",
    "name_ar": "نوف هچليل",
    "name_ru": "Nof hagalil ",
    "name_he": "נוף הגליל ",
    "x": 230795.1149,
    "y": 734451.6386
  },
  {
    "village_id": 1063,
    "name_en": "Ma'alot-tarshiha ",
    "name_ar": "مَعلوت–ترْشيحا",
    "name_ru": "Маалот-Таршиха",
    "name_he": "מעלות-תרשיחא ",
    "x": 226247.7155,
    "y": 768737.2069
  },
  {
    "village_id": 1064,
    "name_en": "Amirim ",
    "name_ar": "أميريم",
    "name_ru": "Амирим",
    "name_he": "אמירים ",
    "x": 242366.1175,
    "y": 760380.0567
  },
  {
    "village_id": 1065,
    "name_en": "Zimrat ",
    "name_ar": "زِمرات",
    "name_ru": "Zimrat ",
    "name_he": "זמרת ",
    "x": 157555.8365,
    "y": 595285.0523
  },
  {
    "village_id": 1066,
    "name_en": "Bene ayish ",
    "name_ar": "بني عايِش",
    "name_ru": "Бней-Айш",
    "name_he": "בני עי\"ש ",
    "x": 177411.3946,
    "y": 633029.8046
  },
  {
    "village_id": 1067,
    "name_en": "Dovev ",
    "name_ar": "دوڤيڤ",
    "name_ru": "Dovev ",
    "name_he": "דוב\"ב ",
    "x": 238607.7661,
    "y": 773207.0525
  },
  {
    "village_id": 1068,
    "name_en": "Adamit ",
    "name_ar": "أدَميت",
    "name_ru": "Адамит",
    "name_he": "אדמית ",
    "x": 219889.9808,
    "y": 776068.6839
  },
  {
    "village_id": 1069,
    "name_en": "Ram-on ",
    "name_ar": "رام أون",
    "name_ru": "Ram-on ",
    "name_he": "רם-און ",
    "x": 224579.6297,
    "y": 714808.4711
  },
  {
    "village_id": 1070,
    "name_en": "Avi'ezer ",
    "name_ar": "أڤيعيزِر",
    "name_ru": "Авиэзер",
    "name_he": "אביעזר ",
    "x": 201599.7238,
    "y": 621035.0598
  },
  {
    "village_id": 1071,
    "name_en": "Newe mikha'el ",
    "name_ar": "نِڤي ميخَئيل",
    "name_ru": "Newe mikha'el ",
    "name_he": "נווה מיכאל ",
    "x": 200673.244,
    "y": 620074.6088
  },
  {
    "village_id": 1072,
    "name_en": "Gan hadarom ",
    "name_ar": "چان هدَروم",
    "name_ru": "Ган ха-Даром",
    "name_he": "גן הדרום ",
    "x": 171776.9791,
    "y": 634693.6996
  },
  {
    "village_id": 1076,
    "name_en": "Bet berl ",
    "name_ar": "بيت بيرل",
    "name_ru": "Bet berl ",
    "name_he": "בית ברל ",
    "x": 193266.2679,
    "y": 678680.5027
  },
  {
    "village_id": 1077,
    "name_en": "Giv'at shappira ",
    "name_ar": "چِڤعات شَپيرا",
    "name_ru": "Giv'at shappira ",
    "name_he": "גבעת שפירא ",
    "x": 188629.9261,
    "y": 696019.4889
  },
  {
    "village_id": 1079,
    "name_en": "Zafririm ",
    "name_ar": "تْسَفريريم",
    "name_ru": "Zafririm ",
    "name_he": "צפרירים ",
    "x": 194680.3101,
    "y": 618688.8831
  },
  {
    "village_id": 1080,
    "name_en": "Mabbu'im ",
    "name_ar": "مَبوعيم",
    "name_ru": "Mabbu'im ",
    "name_he": "מבועים ",
    "x": 167133.5863,
    "y": 595465.5403
  },
  {
    "village_id": 1081,
    "name_en": "Even menahem ",
    "name_ar": "إيڤِن مِنَحيم",
    "name_ru": "Эвен Менахем",
    "name_he": "אבן מנחם ",
    "x": 227716.5102,
    "y": 775372.8969
  },
  {
    "village_id": 1082,
    "name_en": "Ma'galim ",
    "name_ar": "معچليم",
    "name_ru": "Ma'galim ",
    "name_he": "מעגלים ",
    "x": 161867.074,
    "y": 589545.9342
  },
  {
    "village_id": 1083,
    "name_en": "Tushiyya ",
    "name_ar": "توشِيا",
    "name_ru": "Tushiyya ",
    "name_he": "תושיה ",
    "x": 156372.9371,
    "y": 593715.3239
  },
  {
    "village_id": 1084,
    "name_en": "Ben shemen(k.no'ar) ",
    "name_ar": "بين شيمِن",
    "name_ru": "Бен-Ше́мен (молодёжная деревня)",
    "name_he": "בן שמן (כפר נוער) ",
    "x": 193301.5944,
    "y": 651895.5114
  },
  {
    "village_id": 1085,
    "name_en": "Kerem shalom ",
    "name_ar": "كيرِم شلوم",
    "name_ru": "Kerem shalom ",
    "name_he": "כרם שלום ",
    "x": 131804.5046,
    "y": 571062.851
  },
  {
    "village_id": 1094,
    "name_en": "Kerem beyavne ",
    "name_ar": "كيرِم بِيَڤني (يِشيڤا)",
    "name_ru": "Kerem beyavne ",
    "name_he": "כרם ביבנה (ישיבה) ",
    "x": 173754.9325,
    "y": 636403.0425
  },
  {
    "village_id": 1095,
    "name_en": "Kefar maymon ",
    "name_ar": "كفار ميْمون",
    "name_ru": "Kefar maymon ",
    "name_he": "כפר מימון ",
    "x": 155793.1549,
    "y": 593629.6911
  },
  {
    "village_id": 1098,
    "name_en": "Merkaz shappira ",
    "name_ar": "مِركاز شپيرا",
    "name_ru": "Merkaz shappira ",
    "name_he": "מרכז שפירא ",
    "x": 172421.8854,
    "y": 622839.0791
  },
  {
    "village_id": 1102,
    "name_en": "Shoshannat haamaqim( ",
    "name_ar": "تْسوكي يام",
    "name_ru": "Shoshannat haamaqim( ",
    "name_he": "צוקי ים ",
    "x": 186873.6578,
    "y": 696385.9171
  },
  {
    "village_id": 1103,
    "name_en": "Ganne hadar ",
    "name_ar": "چَني هَدار",
    "name_ru": "Ganne hadar ",
    "name_he": "גני הדר ",
    "x": 186291.3136,
    "y": 642956.8267
  },
  {
    "village_id": 1104,
    "name_en": "Poriyya-kefar avoda ",
    "name_ar": "پورِيا (كفار عڤودا)",
    "name_ru": "Poriyya-kefar avoda ",
    "name_he": "פוריה - כפר עבודה ",
    "x": 251671.2427,
    "y": 735870.4747
  },
  {
    "village_id": 1105,
    "name_en": "Poriyya-newe oved ",
    "name_ar": "پورِيا (نِڤي عوڤيد)",
    "name_ru": "Poriyya-newe oved ",
    "name_he": "פוריה - נווה עובד ",
    "x": 250469.9755,
    "y": 739117.4102
  },
  {
    "village_id": 1108,
    "name_en": "Omen ",
    "name_ar": "أومِن",
    "name_ru": "Omen ",
    "name_he": "אומן ",
    "x": 223021.6043,
    "y": 718784.9429
  },
  {
    "village_id": 1110,
    "name_en": "Hever ",
    "name_ar": "حيڤِر",
    "name_ru": "Hever ",
    "name_he": "חבר ",
    "x": 225077.755,
    "y": 717051.6457
  },
  {
    "village_id": 1111,
    "name_en": "Zofiyya ",
    "name_ar": "تْسوحَر",
    "name_ru": "Zofiyya ",
    "name_he": "צופיה ",
    "x": 175378.9865,
    "y": 640470.8746
  },
  {
    "village_id": 1112,
    "name_en": "Yodefat ",
    "name_ar": "يودفات",
    "name_ru": "Yodefat ",
    "name_he": "יודפת ",
    "x": 225813.3294,
    "y": 749112.0867
  },
  {
    "village_id": 1113,
    "name_en": "Zur hadassa ",
    "name_ar": "تْسور هَدَسا",
    "name_ru": "ЗУР ХАДАССА",
    "name_he": "צור הדסה ",
    "x": 209163.0798,
    "y": 624797.5732
  },
  {
    "village_id": 1114,
    "name_en": "Li-on ",
    "name_ar": "سَريچيم (لي أون)",
    "name_ru": "Li-on ",
    "name_he": "שריגים (לי-און) ",
    "x": 193870.7149,
    "y": 620536.8108
  },
  {
    "village_id": 1115,
    "name_en": "Avivim ",
    "name_ar": "أڤيڤيم",
    "name_ru": "Авивим",
    "name_he": "אביבים ",
    "x": 244368.9927,
    "y": 777107.7224
  },
  {
    "village_id": 1117,
    "name_en": "Ya'el ",
    "name_ar": "ياعيل",
    "name_ru": "Ya'el ",
    "name_he": "יעל ",
    "x": 229184.0159,
    "y": 717562.8979
  },
  {
    "village_id": 1123,
    "name_en": "Adderet ",
    "name_ar": "أديرِت",
    "name_ru": "Adderet ",
    "name_he": "אדרת ",
    "x": 199546.0036,
    "y": 618646.7735
  },
  {
    "village_id": 1124,
    "name_en": "Ne'ot hakikkar ",
    "name_ar": "نِؤوت هكِكار",
    "name_ru": "Ne'ot hakikkar ",
    "name_he": "נאות הכיכר ",
    "x": 236016.9666,
    "y": 538164.8978
  },
  {
    "village_id": 1125,
    "name_en": "Almagor ",
    "name_ar": "ألمچور",
    "name_ru": "Almagor ",
    "name_he": "אלמגור ",
    "x": 256683.5541,
    "y": 757451.5014
  },
  {
    "village_id": 1126,
    "name_en": "Elot ",
    "name_ar": "إيلوت",
    "name_ru": "Elot ",
    "name_he": "אילות ",
    "x": 196090.4628,
    "y": 388091.1479
  },
  {
    "village_id": 1127,
    "name_en": "Ma'ale gilboa ",
    "name_ar": "مَعلي چِلبووَع",
    "name_ru": "Ma'ale gilboa ",
    "name_he": "מעלה גלבוע ",
    "x": 239631.9882,
    "y": 709199.6785
  },
  {
    "village_id": 1128,
    "name_en": "Me ammi ",
    "name_ar": "مي عمي",
    "name_ru": "Me ammi ",
    "name_he": "מי עמי ",
    "x": 214182.9227,
    "y": 712142.8184
  },
  {
    "village_id": 1129,
    "name_en": "Gerofit ",
    "name_ar": "چروفيت",
    "name_ru": "Gerofit ",
    "name_he": "גרופית ",
    "x": 206083.3875,
    "y": 427809.8487
  },
  {
    "village_id": 1130,
    "name_en": "Kefar rozenwald(zar. ",
    "name_ar": "كفار روزِنڤلد (زرعيت)",
    "name_ru": "Kefar rozenwald(zar. ",
    "name_he": "כפר רוזנואלד (זרעית) ",
    "x": 227250.5395,
    "y": 778189.2601
  },
  {
    "village_id": 1132,
    "name_en": "Senir ",
    "name_ar": "سْنير",
    "name_ru": "Senir ",
    "name_he": "שניר ",
    "x": 263514.8169,
    "y": 793978.8138
  },
  {
    "village_id": 1133,
    "name_en": "Wardon ",
    "name_ar": "ڤردون",
    "name_ru": "Wardon ",
    "name_he": "ורדון ",
    "x": 179330.7437,
    "y": 619151.7184
  },
  {
    "village_id": 1134,
    "name_en": "Yad hashemona ",
    "name_ar": "ياد هشمونا",
    "name_ru": "ЯД АШЕМОНА",
    "name_he": "יד השמונה ",
    "x": 208625.5878,
    "y": 635132.4201
  },
  {
    "village_id": 1136,
    "name_en": "Zohar ",
    "name_ar": "تْسوڤا",
    "name_ru": "Zohar ",
    "name_he": "צוחר ",
    "x": 145003.3106,
    "y": 572104.4913
  },
  {
    "village_id": 1137,
    "name_en": "Qiryat ye'arim ",
    "name_ar": "كِريات يِعَريم",
    "name_ru": "Кирьят-Ярим",
    "name_he": "קרית יערים ",
    "x": 209472.8573,
    "y": 634428.7107
  },
  {
    "village_id": 1138,
    "name_en": "Ya'ad ",
    "name_ar": "ياعَد",
    "name_ru": "Ya'ad ",
    "name_he": "יעד ",
    "x": 223132.015,
    "y": 753695.4416
  },
  {
    "village_id": 1139,
    "name_en": "Karmi'el ",
    "name_ar": "كرميئيل",
    "name_ru": "Кармиэль",
    "name_he": "כרמיאל ",
    "x": 227276.1034,
    "y": 757321.7084
  },
  {
    "village_id": 1140,
    "name_en": "Midreshet ben gurion ",
    "name_ar": "مِدريشِت بين چوريون",
    "name_ru": "Midreshet ben gurion ",
    "name_he": "מדרשת בן גוריון ",
    "x": 179015.7949,
    "y": 529236.6874
  },
  {
    "village_id": 1141,
    "name_en": "Mevo modi'im ",
    "name_ar": "مِڤو موديعيم",
    "name_ru": "Mevo modi'im ",
    "name_he": "מבוא מודיעים ",
    "x": 198900.4705,
    "y": 648989.5789
  },
  {
    "village_id": 1143,
    "name_en": "Nes ammim ",
    "name_ar": "نيس عميم",
    "name_ru": "Nes ammim ",
    "name_he": "נס עמים ",
    "x": 211664.4234,
    "y": 763444.4098
  },
  {
    "village_id": 1144,
    "name_en": "Yedida ",
    "name_ar": "يِديدا",
    "name_ru": "Yedida ",
    "name_he": "ידידה ",
    "x": 210089.7996,
    "y": 635580.7963
  },
  {
    "village_id": 1145,
    "name_en": "Alumma ",
    "name_ar": "ألُما",
    "name_ru": "Alumma ",
    "name_he": "אלומה ",
    "x": 175650.919,
    "y": 617825.29
  },
  {
    "village_id": 1146,
    "name_en": "Alumim ",
    "name_ar": "علوميم",
    "name_ru": "Alumim ",
    "name_he": "עלומים ",
    "x": 153766.5455,
    "y": 595736.3453
  },
  {
    "village_id": 1147,
    "name_en": "Netu'a ",
    "name_ar": "نِتوعا",
    "name_ru": "Netu'a ",
    "name_he": "נטועה ",
    "x": 230527.5555,
    "y": 774382.9233
  },
  {
    "village_id": 1148,
    "name_en": "Zur natan ",
    "name_ar": "تْسور نتان",
    "name_ru": "ЦУР НАТАН",
    "name_he": "צור נתן ",
    "x": 201384.9717,
    "y": 683035.195
  },
  {
    "village_id": 1149,
    "name_en": "Ezer ",
    "name_ar": "عيزِر",
    "name_ru": "Ezer ",
    "name_he": "עזר ",
    "x": 168894.1479,
    "y": 627266.3763
  },
  {
    "village_id": 1150,
    "name_en": "Zofar ",
    "name_ar": "تْسوفار",
    "name_ru": "Zofar ",
    "name_he": "צופר ",
    "x": 217144.4824,
    "y": 496654.8394
  },
  {
    "village_id": 1151,
    "name_en": "Paran ",
    "name_ar": "پاران",
    "name_ru": "Paran ",
    "name_he": "פארן ",
    "x": 214544.5785,
    "y": 475180.6165
  },
  {
    "village_id": 1152,
    "name_en": "Ashalim ",
    "name_ar": "أشليم",
    "name_ru": "Ashalim ",
    "name_he": "אשלים ",
    "x": 171812.9899,
    "y": 541653.5241
  },
  {
    "village_id": 1153,
    "name_en": "Kishor ",
    "name_ar": "كيشور",
    "name_ru": "Kishor ",
    "name_he": "כישור ",
    "x": 223680.7922,
    "y": 761178.5986
  },
  {
    "village_id": 1154,
    "name_en": "Metal ",
    "name_ar": "ميتال",
    "name_ru": "Metal ",
    "name_he": "מיטל ",
    "x": 239065.9006,
    "y": 704926.2389
  },
  {
    "village_id": 1155,
    "name_en": "Magen sha'ul ",
    "name_ar": "مَچين شَؤول",
    "name_ru": "Magen sha'ul ",
    "name_he": "מגן שאול ",
    "x": 229195.1705,
    "y": 713941.2091
  },
  {
    "village_id": 1156,
    "name_en": "Samar ",
    "name_ar": "سَمار",
    "name_ru": "Samar ",
    "name_he": "סמר ",
    "x": 201903.9315,
    "y": 415937.0769
  },
  {
    "village_id": 1157,
    "name_en": "Ahawa ",
    "name_ar": "أحَڤا",
    "name_ru": "Ахава",
    "name_he": "אחווה ",
    "x": 178185.9856,
    "y": 627980.6668
  },
  {
    "village_id": 1158,
    "name_en": "Yahel ",
    "name_ar": "يَهيل",
    "name_ru": "Yahel ",
    "name_he": "יהל ",
    "x": 212232.2364,
    "y": 443859.6759
  },
  {
    "village_id": 1160,
    "name_en": "Shekhanya ",
    "name_ar": "شخَنْيا",
    "name_ru": "Shekhanya ",
    "name_he": "שכניה ",
    "x": 223461.0237,
    "y": 750398.741
  },
  {
    "village_id": 1161,
    "name_en": "Rahat ",
    "name_ar": "رهط",
    "name_ru": "Рахат",
    "name_he": "רהט ",
    "x": 176773.2174,
    "y": 588617.2577
  },
  {
    "village_id": 1162,
    "name_en": "Bet rimmon ",
    "name_ar": "بيت رِمون",
    "name_ru": "Bet rimmon ",
    "name_he": "בית רימון ",
    "x": 231235.3897,
    "y": 743124.6588
  },
  {
    "village_id": 1163,
    "name_en": "Moran ",
    "name_ar": "موران",
    "name_ru": "Moran ",
    "name_he": "מורן ",
    "x": 237049.4594,
    "y": 758583.2829
  },
  {
    "village_id": 1165,
    "name_en": "Shilat ",
    "name_ar": "شيلات",
    "name_ru": "Shilat ",
    "name_he": "שילת ",
    "x": 201953.607,
    "y": 647253.8967
  },
  {
    "village_id": 1166,
    "name_en": "Kefar rut ",
    "name_ar": "كفار روت",
    "name_ru": "Кфар-Рут",
    "name_he": "כפר רות ",
    "x": 203485.0133,
    "y": 646362.7687
  },
  {
    "village_id": 1167,
    "name_en": "Qesariyya ",
    "name_ar": "كيسَريا",
    "name_ru": "Кесария",
    "name_he": "קיסריה ",
    "x": 192079.4112,
    "y": 711223.4433
  },
  {
    "village_id": 1169,
    "name_en": "Hawashla ",
    "name_ar": "الهواشلة",
    "name_ru": "Hawashla ",
    "name_he": "הוואשלה (שבט) ",
    "x": 198021.8974,
    "y": 555751.3266
  },
  {
    "village_id": 1170,
    "name_en": "Sayyid ",
    "name_ar": "سيد",
    "name_ru": "Sayyid ",
    "name_he": "סייד (שבט) ",
    "x": null,
    "y": null
  },
  {
    "village_id": 1171,
    "name_en": "Lotem ",
    "name_ar": "لوتِم",
    "name_ru": "Lotem ",
    "name_he": "לוטם ",
    "x": 233746.1611,
    "y": 754200.1218
  },
  {
    "village_id": 1172,
    "name_en": "Tuval ",
    "name_ar": "توڤال",
    "name_ru": "Tuval ",
    "name_he": "תובל ",
    "x": 223348.2452,
    "y": 759429.4112
  },
  {
    "village_id": 1173,
    "name_en": "Lappidot ",
    "name_ar": "لپيدوت",
    "name_ru": "Lappidot ",
    "name_he": "לפידות ",
    "x": 225179.7675,
    "y": 762887.6458
  },
  {
    "village_id": 1174,
    "name_en": "Manof ",
    "name_ar": "مَنوف",
    "name_ru": "Manof ",
    "name_he": "מנוף ",
    "x": 222584.1315,
    "y": 750887.0138
  },
  {
    "village_id": 1175,
    "name_en": "Iddan ",
    "name_ar": "عِدان",
    "name_ru": "Iddan ",
    "name_he": "עידן ",
    "x": 228644.5603,
    "y": 523934.6595
  },
  {
    "village_id": 1176,
    "name_en": "Sappir ",
    "name_ar": "سَپير",
    "name_ru": "Sappir ",
    "name_he": "ספיר ",
    "x": 217900.1965,
    "y": 501948.5934
  },
  {
    "village_id": 1177,
    "name_en": "Telalim ",
    "name_ar": "تْلَليم",
    "name_ru": "Telalim ",
    "name_he": "טללים ",
    "x": 178163.7989,
    "y": 544721.2849
  },
  {
    "village_id": 1178,
    "name_en": "Moreshet ",
    "name_ar": "موريشِت",
    "name_ru": "Moreshet ",
    "name_he": "מורשת ",
    "x": 222206.8577,
    "y": 748011.9422
  },
  {
    "village_id": 1179,
    "name_en": "Qoranit ",
    "name_ar": "كورَنيت",
    "name_ru": "Qoranit ",
    "name_he": "קורנית ",
    "x": 223991.4881,
    "y": 749838.3512
  },
  {
    "village_id": 1180,
    "name_en": "Zviyya ",
    "name_ar": "تْسِئِليم",
    "name_ru": "Zviyya ",
    "name_he": "צביה ",
    "x": 231309.7059,
    "y": 755006.2448
  },
  {
    "village_id": 1181,
    "name_en": "Tal-el ",
    "name_ar": "تال شاحَر",
    "name_ru": "Tal-el ",
    "name_he": "טל-אל ",
    "x": 217064.1923,
    "y": 759066.8592
  },
  {
    "village_id": 1182,
    "name_en": "Allon hagalil ",
    "name_ar": "ألون هچليل",
    "name_ru": "Allon hagalil ",
    "name_he": "אלון הגליל ",
    "x": 220885.5405,
    "y": 740390.0153
  },
  {
    "village_id": 1183,
    "name_en": "Kelil ",
    "name_ar": "كْليل",
    "name_ru": "Kelil ",
    "name_he": "כליל ",
    "x": 219000.2099,
    "y": 765057.6014
  },
  {
    "village_id": 1184,
    "name_en": "Mattat ",
    "name_ar": "مَتات",
    "name_ru": "Mattat ",
    "name_he": "מתת ",
    "x": 233773.1846,
    "y": 771747.4715
  },
  {
    "village_id": 1185,
    "name_en": "Pelekh ",
    "name_ar": "پيلِخ",
    "name_ru": "Pelekh ",
    "name_he": "פלך ",
    "x": 222387.6923,
    "y": 759767.2064
  },
  {
    "village_id": 1186,
    "name_en": "Hosha'aya ",
    "name_ar": "هزورييَع",
    "name_ru": "Hosha'aya ",
    "name_he": "הושעיה ",
    "x": 227689.7628,
    "y": 740391.5973
  },
  {
    "village_id": 1187,
    "name_en": "Ir ovot ",
    "name_ar": "عير أوڤوت",
    "name_ru": "Ir ovot ",
    "name_he": "עיר אובות ",
    "x": 223416.1052,
    "y": 524407.8001
  },
  {
    "village_id": 1188,
    "name_en": "Eshhar ",
    "name_ar": "إشحار",
    "name_ru": "Eshhar ",
    "name_he": "אשחר ",
    "x": 228445.3347,
    "y": 754563.0022
  },
  {
    "village_id": 1190,
    "name_en": "Mizpe netofa ",
    "name_ar": "مِتْسپي نِتوفا",
    "name_ru": "Mizpe netofa ",
    "name_he": "מצפה נטופה ",
    "x": 236512.4582,
    "y": 745444.571
  },
  {
    "village_id": 1191,
    "name_en": "Bar yohay ",
    "name_ar": "بار يوحاي",
    "name_ru": "Bar yohay ",
    "name_he": "בר יוחאי ",
    "x": 242250.3515,
    "y": 766982.3517
  },
  {
    "village_id": 1192,
    "name_en": "Ar'ara-banegev ",
    "name_ar": "عرعرة النقب",
    "name_ru": "Ar'ara-banegev ",
    "name_he": "ערערה-בנגב ",
    "x": 202125.9701,
    "y": 562695.9301
  },
  {
    "village_id": 1195,
    "name_en": "Nizzana (qehilat hin ",
    "name_ar": "نِتْسانا",
    "name_ru": "Nizzana (qehilat hin ",
    "name_he": "ניצנה (קהילת חינוך) ",
    "x": 144725.3668,
    "y": 532939.6121
  },
  {
    "village_id": 1197,
    "name_en": "Shizzafon ",
    "name_ar": "نِؤوت سْـمَدار",
    "name_ru": "Shizzafon ",
    "name_he": "נאות סמדר ",
    "x": 202361.647,
    "y": 440086.7042
  },
  {
    "village_id": 1198,
    "name_en": "Keramim ",
    "name_ar": "كرَميم",
    "name_ru": "Keramim ",
    "name_he": "כרמים ",
    "x": 192160.6944,
    "y": 582396.2046
  },
  {
    "village_id": 1199,
    "name_en": "Adi ",
    "name_ar": "عَدي",
    "name_ru": "Adi ",
    "name_he": "עדי ",
    "x": 216577.3761,
    "y": 743131.3463
  },
  {
    "village_id": 1200,
    "name_en": "Modi'in-makkabbim-re ",
    "name_ar": "موديعين–مكبيم–رِعوت",
    "name_ru": "Модиин-Маккабим-Реут",
    "name_he": "מודיעין-מכבים-רעות ",
    "x": 200207.991,
    "y": 645701.6199
  },
  {
    "village_id": 1201,
    "name_en": "Kammon ",
    "name_ar": "كَمون",
    "name_ru": "Kammon ",
    "name_he": "כמון ",
    "x": 233919.2914,
    "y": 757072.5122
  },
  {
    "village_id": 1202,
    "name_en": "Mikhmannim ",
    "name_ar": "مِخْمَنيم",
    "name_ru": "Mikhmannim ",
    "name_he": "מכמנים ",
    "x": 231190.8512,
    "y": 756842.7017
  },
  {
    "village_id": 1203,
    "name_en": "Hararit ",
    "name_ar": "هرريت",
    "name_ru": "Hararit ",
    "name_he": "הררית ",
    "x": 234591.7872,
    "y": 750076.4778
  },
  {
    "village_id": 1204,
    "name_en": "Gilon ",
    "name_ar": "چيلون",
    "name_ru": "Гилон",
    "name_he": "גילון ",
    "x": 222412.2221,
    "y": 756538.2294
  },
  {
    "village_id": 1205,
    "name_en": "Manot ",
    "name_ar": "مَنوت",
    "name_ru": "Manot ",
    "name_he": "מנות ",
    "x": 218718.3369,
    "y": 771460.5852
  },
  {
    "village_id": 1206,
    "name_en": "Gitta ",
    "name_ar": "چِتا",
    "name_ru": "Gitta ",
    "name_he": "גיתה ",
    "x": 223746.4185,
    "y": 763574.7692
  },
  {
    "village_id": 1207,
    "name_en": "Lavon ",
    "name_ar": "لڤون",
    "name_ru": "Lavon ",
    "name_he": "לבון ",
    "x": 227162.3729,
    "y": 760839.5565
  },
  {
    "village_id": 1208,
    "name_en": "Hilla ",
    "name_ar": "هِلا",
    "name_ru": "Hilla ",
    "name_he": "הילה ",
    "x": 223176.2251,
    "y": 771237.6217
  },
  {
    "village_id": 1209,
    "name_en": "Harashim ",
    "name_ar": "حَرَشيم",
    "name_ru": "Harashim ",
    "name_he": "חרשים ",
    "x": 231024.0056,
    "y": 762432.8017
  },
  {
    "village_id": 1210,
    "name_en": "Kahal ",
    "name_ar": "كَحال",
    "name_ru": "Kahal ",
    "name_he": "כחל ",
    "x": 248051.6591,
    "y": 755280.4365
  },
  {
    "village_id": 1211,
    "name_en": "Qaddarim ",
    "name_ar": "كَدَريم",
    "name_ru": "Qaddarim ",
    "name_he": "קדרים ",
    "x": 244714.4611,
    "y": 756023.9226
  },
  {
    "village_id": 1212,
    "name_en": "Amuqqa ",
    "name_ar": "عَمُكا",
    "name_ru": "Amuqqa ",
    "name_he": "עמוקה ",
    "x": 249391.6016,
    "y": 767009.1642
  },
  {
    "village_id": 1213,
    "name_en": "Ziv'on ",
    "name_ar": "تْسڤِيا",
    "name_ru": "Ziv'on ",
    "name_he": "צבעון ",
    "x": 239300.0781,
    "y": 770057.1364
  },
  {
    "village_id": 1214,
    "name_en": "Tefahot ",
    "name_ar": "تْفَحوت",
    "name_ru": "Tefahot ",
    "name_he": "טפחות ",
    "x": 239898.3663,
    "y": 752524.2346
  },
  {
    "village_id": 1219,
    "name_en": "Gornot hagalil ",
    "name_ar": "چُرنوت هچليل",
    "name_ru": "Gornot hagalil ",
    "name_he": "גורנות הגליל ",
    "x": 223674.6198,
    "y": 773860.1926
  },
  {
    "village_id": 1220,
    "name_en": "Abbirim ",
    "name_ar": "أبيريم",
    "name_ru": "Абирим",
    "name_he": "אבירים ",
    "x": 227180.9847,
    "y": 771470.6024
  },
  {
    "village_id": 1221,
    "name_en": "Zurit ",
    "name_ar": "تْسوريت",
    "name_ru": "Zurit ",
    "name_he": "צורית ",
    "x": 223824.5197,
    "y": 756358.6496
  },
  {
    "village_id": 1222,
    "name_en": "Mizpe aviv ",
    "name_ar": "مِتْسپي أڤيڤ",
    "name_ru": "Mizpe aviv ",
    "name_he": "מצפה אבי\"ב ",
    "x": 218868.8633,
    "y": 749362.4912
  },
  {
    "village_id": 1223,
    "name_en": "Sede avraham ",
    "name_ar": "سْدي أڤرهام",
    "name_ru": "Sede avraham ",
    "name_he": "שדי אברהם ",
    "x": 136855.0982,
    "y": 569226.8719
  },
  {
    "village_id": 1224,
    "name_en": "Kokhav ya'ir ",
    "name_ar": "كوخاڤ يَئير – تْسور يِچآل",
    "name_ru": "Kokhav ya'ir ",
    "name_he": "כוכב יאיר ",
    "x": 199705.9047,
    "y": 680886.786
  },
  {
    "village_id": 1225,
    "name_en": "Ravid ",
    "name_ar": "رَڤيد",
    "name_ru": "Ravid ",
    "name_he": "רביד ",
    "x": 243681.003,
    "y": 750680.1164
  },
  {
    "village_id": 1226,
    "name_en": "Yuvalim ",
    "name_ar": "يوڤَليم",
    "name_ru": "Yuvalim ",
    "name_he": "יובלים ",
    "x": 225637.8771,
    "y": 753700.2674
  },
  {
    "village_id": 1227,
    "name_en": "Yated ",
    "name_ar": "يَتيد",
    "name_ru": "Yated ",
    "name_he": "יתד ",
    "x": 135777.5594,
    "y": 568690.3477
  },
  {
    "village_id": 1228,
    "name_en": "Raqefet ",
    "name_ar": "رَكيفِت",
    "name_ru": "Raqefet ",
    "name_he": "רקפת ",
    "x": 225087.4714,
    "y": 751092.2403
  },
  {
    "village_id": 1229,
    "name_en": "Kallanit ",
    "name_ar": "كلنيت",
    "name_ru": "Kallanit ",
    "name_he": "כלנית ",
    "x": 242831.6664,
    "y": 753333.0253
  },
  {
    "village_id": 1230,
    "name_en": "Livnim ",
    "name_ar": "لِڤْنيم",
    "name_ru": "Livnim ",
    "name_he": "לבנים ",
    "x": 246707.8047,
    "y": 752177.8273
  },
  {
    "village_id": 1231,
    "name_en": "Peri gan ",
    "name_ar": "پْري چان",
    "name_ru": "Peri gan ",
    "name_he": "פרי גן ",
    "x": 138601.0582,
    "y": 570478.109
  },
  {
    "village_id": 1232,
    "name_en": "Yevul ",
    "name_ar": "يِڤول",
    "name_ru": "Yevul ",
    "name_he": "יבול ",
    "x": 135115.8494,
    "y": 566749.2012
  },
  {
    "village_id": 1233,
    "name_en": "Sheqef ",
    "name_ar": "شيكِف",
    "name_ru": "Sheqef ",
    "name_he": "שקף ",
    "x": 193904.3916,
    "y": 602409.3011
  },
  {
    "village_id": 1234,
    "name_en": "Qabbo'a ",
    "name_ar": "قبوعة",
    "name_ru": "Qabbo'a ",
    "name_he": "קבועה (שבט) ",
    "x": 215905.3687,
    "y": 571855.1474
  },
  {
    "village_id": 1235,
    "name_en": "Shorashim ",
    "name_ar": "شُرَشيم",
    "name_ru": "Shorashim ",
    "name_he": "שורשים ",
    "x": 224568.2678,
    "y": 755368.3765
  },
  {
    "village_id": 1236,
    "name_en": "Nirit ",
    "name_ar": "نيريت",
    "name_ru": "Nirit ",
    "name_he": "נירית ",
    "x": 198751.9588,
    "y": 672576.5708
  },
  {
    "village_id": 1237,
    "name_en": "Talme yosef ",
    "name_ar": "تَلمي يوسيف",
    "name_ru": "Talme yosef ",
    "name_he": "תלמי יוסף ",
    "x": 139207.8687,
    "y": 567604.7526
  },
  {
    "village_id": 1238,
    "name_en": "Sufa ",
    "name_ar": "سوفا",
    "name_ru": "Sufa ",
    "name_he": "סופה ",
    "x": 137293.1794,
    "y": 572051.7217
  },
  {
    "village_id": 1239,
    "name_en": "Holit ",
    "name_ar": "حوليت",
    "name_ru": "Holit ",
    "name_he": "חולית ",
    "x": 135725.7349,
    "y": 571304.8065
  },
  {
    "village_id": 1240,
    "name_en": "En habesor ",
    "name_ar": "عين هبْسور",
    "name_ru": "En habesor ",
    "name_he": "עין הבשור ",
    "x": 147050.9316,
    "y": 576904.7433
  },
  {
    "village_id": 1241,
    "name_en": "Deqel ",
    "name_ar": "ديكِل",
    "name_ru": "Декель ",
    "name_he": "דקל ",
    "x": 137803.359,
    "y": 567354.1958
  },
  {
    "village_id": 1242,
    "name_en": "Netiv haasara ",
    "name_ar": "نِتيڤ هعَسرا",
    "name_ru": "Netiv haasara ",
    "name_he": "נתיב העשרה ",
    "x": 156403.1497,
    "y": 608735.6535
  },
  {
    "village_id": 1243,
    "name_en": "Qazir ",
    "name_ar": "كَتْسير",
    "name_ru": "Qazir ",
    "name_he": "קציר ",
    "x": 209954.4536,
    "y": 710458.5894
  },
  {
    "village_id": 1244,
    "name_en": "Timrat ",
    "name_ar": "تِمرات",
    "name_ru": "Timrat ",
    "name_he": "תמרת ",
    "x": 221403.7194,
    "y": 734234.0289
  },
  {
    "village_id": 1245,
    "name_en": "Sallama ",
    "name_ar": "سلامة",
    "name_ru": "Sallama ",
    "name_he": "סלמה ",
    "x": 233968.7219,
    "y": 755712.5602
  },
  {
    "village_id": 1246,
    "name_en": "Aramsha ",
    "name_ar": "عرامشة",
    "name_ru": "Aramsha ",
    "name_he": "עראמשה ",
    "x": 221364.7083,
    "y": 777066.7245
  },
  {
    "village_id": 1247,
    "name_en": "Harish ",
    "name_ar": "حريش",
    "name_ru": "Хариш",
    "name_he": "חריש ",
    "x": 204614.7954,
    "y": 707440.617
  },
  {
    "village_id": 1248,
    "name_en": "Elifaz ",
    "name_ar": "إليفاز",
    "name_ru": "Элифаз",
    "name_he": "אליפז ",
    "x": 200826.6221,
    "y": 411789.5849
  },
  {
    "village_id": 1249,
    "name_en": "Harduf ",
    "name_ar": "هردوف",
    "name_ru": "Harduf ",
    "name_he": "הרדוף ",
    "x": 216609.1305,
    "y": 741033.3374
  },
  {
    "village_id": 1251,
    "name_en": "En tamar ",
    "name_ar": "عين تَمار",
    "name_ru": "En tamar ",
    "name_he": "עין תמר ",
    "x": 235153.1078,
    "y": 539338.0268
  },
  {
    "village_id": 1252,
    "name_en": "Korazim ",
    "name_ar": "كورَزيم",
    "name_ru": "Korazim ",
    "name_he": "כורזים ",
    "x": 251894.8506,
    "y": 757204.5333
  },
  {
    "village_id": 1253,
    "name_en": "Amnun ",
    "name_ar": "أمنون",
    "name_ru": "Amnun ",
    "name_he": "אמנון ",
    "x": 253965.0714,
    "y": 756630.4011
  },
  {
    "village_id": 1254,
    "name_en": "Nataf ",
    "name_ar": "نَتاف",
    "name_ru": "Nataf ",
    "name_he": "נטף ",
    "x": 206516.6032,
    "y": 637706.8336
  },
  {
    "village_id": 1255,
    "name_en": "Lotan ",
    "name_ar": "لوتان",
    "name_ru": "Lotan ",
    "name_he": "לוטן ",
    "x": 208213.3386,
    "y": 433106.2029
  },
  {
    "village_id": 1256,
    "name_en": "Asherat ",
    "name_ar": "أشرات",
    "name_ru": "Asherat ",
    "name_he": "אשרת ",
    "x": 215046.0787,
    "y": 763967.1973
  },
  {
    "village_id": 1257,
    "name_en": "Hannaton ",
    "name_ar": "حَنَتون",
    "name_ru": "Hannaton ",
    "name_he": "חנתון ",
    "x": 223157.6091,
    "y": 743314.4331
  },
  {
    "village_id": 1258,
    "name_en": "Massad ",
    "name_ar": "مَساد",
    "name_ru": "Massad ",
    "name_he": "מסד ",
    "x": 239956.7998,
    "y": 749880.3837
  },
  {
    "village_id": 1259,
    "name_en": "Newe shalom ",
    "name_ar": "نِڤي شَلوم",
    "name_ru": "Newe shalom ",
    "name_he": "נווה שלום ",
    "x": 198045.8998,
    "y": 636160.9705
  },
  {
    "village_id": 1260,
    "name_en": "Retamim ",
    "name_ar": "ريتاميم",
    "name_ru": "Retamim ",
    "name_he": "רתמים ",
    "x": 170320.3077,
    "y": 551505.9414
  },
  {
    "village_id": 1261,
    "name_en": "Har amasa ",
    "name_ar": "هار عَمَسا",
    "name_ru": "Har amasa ",
    "name_he": "הר עמשא ",
    "x": 209681.086,
    "y": 583602.2967
  },
  {
    "village_id": 1262,
    "name_en": "Mahane bildad ",
    "name_ar": "تْسوكيم",
    "name_ru": "Mahane bildad ",
    "name_he": "צוקים ",
    "x": 215982.1085,
    "y": 488713.4317
  },
  {
    "village_id": 1263,
    "name_en": "Kefar weradim ",
    "name_ar": "كفار ڤرَديم",
    "name_ru": "Кфар-Врадим",
    "name_he": "כפר ורדים ",
    "x": 225354.2814,
    "y": 766749.5193
  },
  {
    "village_id": 1264,
    "name_en": "Karme yosef ",
    "name_ar": "كرمي يوسيف",
    "name_ru": "Karme yosef ",
    "name_he": "כרמי יוסף ",
    "x": 192556.2287,
    "y": 639483.849
  },
  {
    "village_id": 1265,
    "name_en": "Shomeriyya ",
    "name_ar": "شومْرِيا",
    "name_ru": "Shomeriyya ",
    "name_he": "שומריה ",
    "x": 189008.4563,
    "y": 593353.9253
  },
  {
    "village_id": 1266,
    "name_en": "Shaharut ",
    "name_ar": "شَحَروت",
    "name_ru": "Shaharut ",
    "name_he": "שחרות ",
    "x": 199743.9026,
    "y": 424020.7733
  },
  {
    "village_id": 1267,
    "name_en": "Nahal shittim ",
    "name_ar": "شيتيم",
    "name_ru": "Nahal shittim ",
    "name_he": "שיטים ",
    "x": 201348.0666,
    "y": 454210.3747
  },
  {
    "village_id": 1268,
    "name_en": "Metar ",
    "name_ar": "ميتار",
    "name_ru": "Metar ",
    "name_he": "מיתר ",
    "x": 194103.0781,
    "y": 581797.2479
  },
  {
    "village_id": 1271,
    "name_en": "Lehavim ",
    "name_ar": "لِهڤيم",
    "name_ru": "Лехавим",
    "name_he": "להבים ",
    "x": 182192.4624,
    "y": 586529.4624
  },
  {
    "village_id": 1272,
    "name_en": "Haluz ",
    "name_ar": "حَلوتس",
    "name_ru": "Haluz ",
    "name_he": "חלוץ ",
    "x": 229503.8102,
    "y": 761706.7258
  },
  {
    "village_id": 1274,
    "name_en": "Gan ner ",
    "name_ar": "چان سوريك",
    "name_ru": "Gan ner ",
    "name_he": "גן נר ",
    "x": 231645.8013,
    "y": 714817.1522
  },
  {
    "village_id": 1275,
    "name_en": "Avtalyon ",
    "name_ar": "أڤتَليون",
    "name_ru": "Avtalyon ",
    "name_he": "אבטליון ",
    "x": 233228.8983,
    "y": 749154.1198
  },
  {
    "village_id": 1276,
    "name_en": "Nahal eshbal ",
    "name_ar": "إشبال",
    "name_ru": "Nahal eshbal ",
    "name_he": "אשבל ",
    "x": 228861.0902,
    "y": 753539.9423
  },
  {
    "village_id": 1278,
    "name_en": "Be'er milka ",
    "name_ar": "بِئير مِلكا",
    "name_ru": "Be'er milka ",
    "name_he": "באר מילכה ",
    "x": 143323.7477,
    "y": 538139.9091
  },
  {
    "village_id": 1279,
    "name_en": "Newe harif ",
    "name_ar": "نِڤي حَريف",
    "name_ru": "Newe harif ",
    "name_he": "נווה חריף ",
    "x": 203329.2354,
    "y": 438788.9524
  },
  {
    "village_id": 1280,
    "name_en": "Nizzane sinay ",
    "name_ar": "نِتْسَني سيناي",
    "name_ru": "Nizzane sinay ",
    "name_he": "ניצני סיני ",
    "x": 142315.3368,
    "y": 534951.3002
  },
  {
    "village_id": 1282,
    "name_en": "Merav ",
    "name_ar": "مِراڤ",
    "name_ru": "Merav ",
    "name_he": "מירב ",
    "x": 239895.4916,
    "y": 706554.4712
  },
  {
    "village_id": 1283,
    "name_en": "Tel te'omim ",
    "name_ar": "تيل تِؤوميم",
    "name_ru": "Tel te'omim ",
    "name_he": "תל תאומים ",
    "x": 246872.8444,
    "y": 705367.6252
  },
  {
    "village_id": 1284,
    "name_en": "Nofit ",
    "name_ar": "نوفيت",
    "name_ru": "Nofit ",
    "name_he": "נופית ",
    "x": 213996.7406,
    "y": 740501.7712
  },
  {
    "village_id": 1285,
    "name_en": "Karkom ",
    "name_ar": "كركوم",
    "name_ru": "Karkom ",
    "name_he": "כרכום ",
    "x": 257203.2334,
    "y": 759528.9144
  },
  {
    "village_id": 1286,
    "name_en": "Segev-shalom ",
    "name_ar": "شقيب السلام",
    "name_ru": "Segev-shalom ",
    "name_he": "שגב-שלום ",
    "x": 184634.6097,
    "y": 567181.8731
  },
  {
    "village_id": 1287,
    "name_en": "Shani ",
    "name_ar": "شَني",
    "name_ru": "Shani ",
    "name_he": "שני ",
    "x": 206639.3778,
    "y": 584908.4264
  },
  {
    "village_id": 1288,
    "name_en": "Giv'at ela ",
    "name_ar": "چِڤعات إلا",
    "name_ru": "Giv'at ela ",
    "name_he": "גבעת אלה ",
    "x": 223273.8317,
    "y": 736324.2186
  },
  {
    "village_id": 1290,
    "name_en": "Zemer ",
    "name_ar": "زيمِر",
    "name_ru": "Zemer ",
    "name_he": "זמר ",
    "x": 203677.2981,
    "y": 696698.8723
  },
  {
    "village_id": 1291,
    "name_en": "Kemehin ",
    "name_ar": "كْمِهين",
    "name_ru": "Kemehin ",
    "name_he": "כמהין ",
    "x": 145574.4023,
    "y": 535788.9769
  },
  {
    "village_id": 1292,
    "name_en": "Judeide-maker ",
    "name_ar": "جديدة–مكر",
    "name_ru": "Джудейда-Макр",
    "name_he": "ג'דיידה-מכר ",
    "x": 213374.6999,
    "y": 759652.9886
  },
  {
    "village_id": 1293,
    "name_en": "Giv'at avni ",
    "name_ar": "چِڤعات أڤني",
    "name_ru": "Giv'at avni ",
    "name_he": "גבעת אבני ",
    "x": 241353.0564,
    "y": 742259.484
  },
  {
    "village_id": 1294,
    "name_en": "Or haganuz ",
    "name_ar": "أور هچَنوز",
    "name_ru": "Or haganuz ",
    "name_he": "אור הגנוז ",
    "x": 242033.8697,
    "y": 767876.6403
  },
  {
    "village_id": 1295,
    "name_en": "Yanuh-jat ",
    "name_ar": "يانوح–جت",
    "name_ru": "Yanuh-jat ",
    "name_he": "יאנוח-ג'ת ",
    "x": 223183.5852,
    "y": 765572.9331
  },
  {
    "village_id": 1296,
    "name_en": "Kisra-sumei ",
    "name_ar": "كسرا–سميع",
    "name_ru": "Kisra-sumei ",
    "name_he": "כסרא-סמיע ",
    "x": 228628.6452,
    "y": 763251.881
  },
  {
    "village_id": 1297,
    "name_en": "Kefar hananya ",
    "name_ar": "كفار حَنَنْيا",
    "name_ru": "Kefar hananya ",
    "name_he": "כפר חנניה ",
    "x": 239700.3046,
    "y": 758031.1429
  },
  {
    "village_id": 1298,
    "name_en": "Etgar ",
    "name_ar": "اتجار ",
    "name_ru": "Etgar ",
    "name_he": "אתגר ",
    "x": null,
    "y": null
  },
  {
    "village_id": 1303,
    "name_en": "Hura ",
    "name_ar": "حورة",
    "name_ru": "Hura ",
    "name_he": "חורה ",
    "x": 193769.4725,
    "y": 578599.6967
  },
  {
    "village_id": 1304,
    "name_en": "Shoham ",
    "name_ar": "شوهَم",
    "name_ru": "Шохам",
    "name_he": "שוהם ",
    "x": 194983.8805,
    "y": 656155.4612
  },
  {
    "village_id": 1309,
    "name_en": "El'ad ",
    "name_ar": "إلعاد",
    "name_ru": "Эльад",
    "name_he": "אלעד ",
    "x": 196084.9638,
    "y": 661964.4267
  },
  {
    "village_id": 1310,
    "name_en": "Lappid ",
    "name_ar": "لپيد",
    "name_ru": "Lappid ",
    "name_he": "לפיד ",
    "x": 203184.4365,
    "y": 647168.4964
  },
  {
    "village_id": 1311,
    "name_en": "Avshalom ",
    "name_ar": "أڤشَلوم",
    "name_ru": "Авшалом",
    "name_he": "אבשלום ",
    "x": 136239.8541,
    "y": 567408.9103
  },
  {
    "village_id": 1313,
    "name_en": "Poriyya illit ",
    "name_ar": "پورِيا عِليت",
    "name_ru": "Poriyya illit ",
    "name_he": "פוריה עילית ",
    "x": 251524.0275,
    "y": 737394.2123
  },
  {
    "village_id": 1314,
    "name_en": "Newe ziv ",
    "name_ar": "نِڤي زيڤ",
    "name_ru": "Newe ziv ",
    "name_he": "נווה זיו ",
    "x": 217323.0206,
    "y": 770268.3841
  },
  {
    "village_id": 1315,
    "name_en": "Mattan ",
    "name_ar": "مَتان",
    "name_ru": "Mattan ",
    "name_he": "מתן ",
    "x": 197818.1,
    "y": 673857.2968
  },
  {
    "village_id": 1316,
    "name_en": "Al-aryan ",
    "name_ar": "العريان",
    "name_ru": "Al-aryan ",
    "name_he": "אל-עריאן ",
    "x": 212010.769,
    "y": 711558.1461
  },
  {
    "village_id": 1317,
    "name_en": "Demeide ",
    "name_ar": "ضميدة ",
    "name_ru": "Demeide ",
    "name_he": "דמיידה ",
    "x": 221589.0551,
    "y": 746761.7598
  },
  {
    "village_id": 1318,
    "name_en": "Mevo'ot yam ",
    "name_ar": "مِڤوؤوت يام",
    "name_ru": "Mevo'ot yam ",
    "name_he": "מבואות ים ",
    "x": 187860,
    "y": 701036
  },
  {
    "village_id": 1319,
    "name_en": "Bat hefer ",
    "name_ar": "بات حيفِر",
    "name_ru": "Bat hefer ",
    "name_he": "בת חפר ",
    "x": 201472.4294,
    "y": 693309.2337
  },
  {
    "village_id": 1320,
    "name_en": "Ein hod ",
    "name_ar": "عين حوض",
    "name_ru": "Ein hod ",
    "name_he": "עין חוד ",
    "x": 200271.8722,
    "y": 733024.1581
  },
  {
    "village_id": 1321,
    "name_en": "Khawaled ",
    "name_ar": "الخوالد",
    "name_ru": "Khawaled ",
    "name_he": "ח'ואלד ",
    "x": 241097.5159,
    "y": 743768.2291
  },
  {
    "village_id": 1322,
    "name_en": "Hodayot ",
    "name_ar": "هودِيا",
    "name_ru": "Hodayot ",
    "name_he": "הודיות ",
    "x": 161742.0836,
    "y": 617250.0037
  },
  {
    "village_id": 1323,
    "name_en": "Bat hadar ",
    "name_ar": "بات هَدار",
    "name_ru": "Bat hadar ",
    "name_he": "בת הדר ",
    "x": 182854.0355,
    "y": 680078.242
  },
  {
    "village_id": 1324,
    "name_en": "Arsuf ",
    "name_ar": "أرسوف",
    "name_ru": "Arsuf ",
    "name_he": "ארסוף ",
    "x": 192996.3316,
    "y": 614385.4086
  },
  {
    "village_id": 1325,
    "name_en": "Kefar zoharim ",
    "name_ar": "كفار زوهريم",
    "name_ru": "Kefar zoharim ",
    "name_he": "כפר זוהרים ",
    "x": 209002.2049,
    "y": 708738.6726
  },
  {
    "village_id": 1326,
    "name_en": "Basma ",
    "name_ar": "بسمة",
    "name_ru": "Басма",
    "name_he": "בסמ\"ה ",
    "x": 214550.3453,
    "y": 716794.9395
  },
  {
    "village_id": 1327,
    "name_en": "Ma'ale iron ",
    "name_ar": "طلعة عارة (مَعلي عيرون)",
    "name_ru": "Ma'ale iron ",
    "name_he": "מעלה עירון ",
    "x": 205727.6817,
    "y": 583939.126
  },
  {
    "village_id": 1330,
    "name_en": "Ahuzzat baraq ",
    "name_ar": "أحُزات بَراك",
    "name_ru": "Ахузат Барак",
    "name_he": "אחוזת ברק ",
    "x": 231928.6643,
    "y": 727713.0384
  },
  {
    "village_id": 1331,
    "name_en": "Kammana",
    "name_ar": "الكمانة",
    "name_ru": "Kammana",
    "name_he": "כמאנה ",
    "x": 232327.4447,
    "y": 757077.769
  },
  {
    "village_id": 1332,
    "name_en": "Hussniyya ",
    "name_ar": "الحسنية",
    "name_ru": "Hussniyya ",
    "name_he": "חוסנייה ",
    "x": 229986.5556,
    "y": 756293.5968
  },
  {
    "village_id": 1333,
    "name_en": "Nof ayyalon ",
    "name_ar": "نوف أيَلون",
    "name_ru": "Nof ayyalon ",
    "name_he": "נוף איילון ",
    "x": 198833.3662,
    "y": 641487.6319
  },
  {
    "village_id": 1334,
    "name_en": "Ras al-ein ",
    "name_ar": "رأس العين",
    "name_ru": "Ras al-ein ",
    "name_he": "ראס אל-עין ",
    "x": 235149.0484,
    "y": 757697.692
  },
  {
    "village_id": 1335,
    "name_en": "Arrab al naim ",
    "name_ar": "عرب النعيم",
    "name_ru": "Arrab al naim ",
    "name_he": "ערב אל נעים ",
    "x": 227512.5439,
    "y": 754791.9606
  },
  {
    "village_id": 1336,
    "name_en": "Irus ",
    "name_ar": "إيروس",
    "name_ru": "Irus ",
    "name_he": "אירוס ",
    "x": 178958.3484,
    "y": 648435.5254
  },
  {
    "village_id": 1337,
    "name_en": "Shimshit ",
    "name_ar": "شِمشيت",
    "name_ru": "Shimshit ",
    "name_he": "שמשית ",
    "x": 223411.8597,
    "y": 737611.6094
  },
  {
    "village_id": 1338,
    "name_en": "Kaddita ",
    "name_ar": "كديتا",
    "name_ru": "Kaddita ",
    "name_he": "כדיתה ",
    "x": 243691.1086,
    "y": 767906.742
  },
  {
    "village_id": 1339,
    "name_en": "Al-azy ",
    "name_ar": "العزي",
    "name_ru": "Al-azy ",
    "name_he": "אל-עזי ",
    "x": 180948.2126,
    "y": 625628.3852
  },
  {
    "village_id": 1340,
    "name_en": "Merhav am ",
    "name_ar": "مِرحاڤ عام",
    "name_ru": "Merhav am ",
    "name_he": "מרחב עם ",
    "x": 183551.3184,
    "y": 533087.8005
  },
  {
    "village_id": 1341,
    "name_en": "Ruah midbar ",
    "name_ar": "رووَح مِدبار",
    "name_ru": "Ruah midbar ",
    "name_he": "רוח מדבר ",
    "x": 174431.0139,
    "y": 543458.8764
  },
  {
    "village_id": 1342,
    "name_en": "Abu qureinat ",
    "name_ar": "أبو قرينات",
    "name_ru": "Abu qureinat ",
    "name_he": "אבו קרינאת (יישוב) ",
    "x": 196438.86,
    "y": 561046.9249
  },
  {
    "village_id": 1343,
    "name_en": "Makchul ",
    "name_ar": "مكحول",
    "name_ru": "Makchul ",
    "name_he": "מכחול ",
    "x": 207194.4932,
    "y": 577463.1348
  },
  {
    "village_id": 1344,
    "name_en": "Geva'ot bar ",
    "name_ar": "چِڤعوت بار",
    "name_ru": "Geva'ot bar ",
    "name_he": "גבעות בר ",
    "x": 177027.1579,
    "y": 585037.3449
  },
  {
    "village_id": 1345,
    "name_en": "Zur yizhaq ",
    "name_ar": "تْسور يِتسحاك",
    "name_ru": "Zur yizhaq ",
    "name_he": "צור יצחק ",
    "x": 199951.8864,
    "y": 683024.8486
  },
  {
    "village_id": 1346,
    "name_en": "Tarabin as-sani ",
    "name_ar": "ترابين الصانع",
    "name_ru": "Tarabin as-sani ",
    "name_he": "תראבין א-צאנע(ישוב) ",
    "x": 175124.1412,
    "y": 583710.3586
  },
  {
    "village_id": 1347,
    "name_en": "Kasr as-sirr",
    "name_ar": "قصر السر",
    "name_ru": "Kasr as-sirr",
    "name_he": "קצר א-סר ",
    "x": 198334.1096,
    "y": 554232.4206
  },
  {
    "village_id": 1348,
    "name_en": "Bir hadage ",
    "name_ar": "بئر هداج",
    "name_ru": "Бир-Хададж",
    "name_he": "ביר הדאג' ",
    "x": 172405.7835,
    "y": 548447.836
  },
  {
    "village_id": 1349,
    "name_en": "Derig'at ",
    "name_ar": "دريجات",
    "name_ru": "Дриджат",
    "name_he": "דריג'את ",
    "x": 207251.4416,
    "y": 578754.1578
  },
  {
    "village_id": 1358,
    "name_en": "Umm batin ",
    "name_ar": "أم بطين",
    "name_ru": "Umm batin ",
    "name_he": "אום בטין ",
    "x": 188982.2774,
    "y": 575675.3961
  },
  {
    "village_id": 1359,
    "name_en": "Al sayyid ",
    "name_ar": "السيد",
    "name_ru": "Al sayyid ",
    "name_he": "אל סייד ",
    "x": 191783.2494,
    "y": 577062.6102
  },
  {
    "village_id": 1360,
    "name_en": "Sa'wa ",
    "name_ar": "سعوة",
    "name_ru": "Sa'wa ",
    "name_he": "סעוה ",
    "x": null,
    "y": null
  },
  {
    "village_id": 1361,
    "name_en": "Bat hen ",
    "name_ar": "بات حين",
    "name_ru": "Bat hen ",
    "name_he": "בת חן ",
    "x": 188158.355,
    "y": 696318.1596
  },
  {
    "village_id": 1362,
    "name_en": "Giv'ot eden ",
    "name_ar": "چِڤعوت عيدِن",
    "name_ru": "Giv'ot eden ",
    "name_he": "גבעות עדן ",
    "x": null,
    "y": null
  },
  {
    "village_id": 1363,
    "name_en": "Bene nezarim ",
    "name_ar": "بني نِتَسريم",
    "name_ru": "Bene nezarim ",
    "name_he": "בני נצרים ",
    "x": 134582.3515,
    "y": 561600.1315
  },
  {
    "village_id": 1364,
    "name_en": "Shlomit ",
    "name_ar": "شلوميت",
    "name_ru": "Shlomit ",
    "name_he": "שלומית ",
    "x": 133694.3416,
    "y": 564569.4672
  },
  {
    "village_id": 1365,
    "name_en": "Eliav ",
    "name_ar": "إليآڤ",
    "name_ru": "Eliav ",
    "name_he": "אליאב ",
    "x": 193712.8384,
    "y": 604347.1121
  },
  {
    "village_id": 1366,
    "name_en": "Nave ",
    "name_ar": "نَڤي",
    "name_ru": "Nave ",
    "name_he": "נווה ",
    "x": 136120.5022,
    "y": 563726.8346
  },
  {
    "village_id": 1367,
    "name_en": "Kochlea ",
    "name_ar": "كحلة",
    "name_ru": "Kochlea ",
    "name_he": "כחלה ",
    "x": 204913.9784,
    "y": 577443.112
  },
  {
    "village_id": 1368,
    "name_en": "Bne dkalim ",
    "name_ar": "بني دكليم",
    "name_ru": "Bne dkalim ",
    "name_he": "בני דקלים ",
    "x": 192221.8808,
    "y": 602946.4101
  },
  {
    "village_id": 1369,
    "name_en": "Neta ",
    "name_ar": "نيتع",
    "name_ru": "Neta ",
    "name_he": "נטע ",
    "x": 192981.6614,
    "y": 598289.4233
  },
  {
    "village_id": 1370,
    "name_en": "Mitspe ilan ",
    "name_ar": "مِتْسپي إيلان",
    "name_ru": "Mitspe ilan ",
    "name_he": "מצפה אילן ",
    "x": 206715.2074,
    "y": 707479.778
  },
  {
    "village_id": 1371,
    "name_en": "Ganne tal ",
    "name_ar": "چَني تال",
    "name_ru": "Ganne tal ",
    "name_he": "גני טל ",
    "x": 180440.1643,
    "y": 632907.9784
  },
  {
    "village_id": 1372,
    "name_en": "Nezer hazzani ",
    "name_ar": "نيتْسِر حَزَني",
    "name_ru": "Nezer hazzani ",
    "name_he": "נצר חזני ",
    "x": 187094.6066,
    "y": 636530.01
  },
  {
    "village_id": 1374,
    "name_en": "Karme qatif ",
    "name_ar": "كرمي كَتيف",
    "name_ru": "Karme qatif ",
    "name_he": "כרמי קטיף ",
    "x": 174682.8823,
    "y": 527022.6061
  },
  {
    "village_id": 1375,
    "name_en": "Abu tulul ",
    "name_ar": "أبو تلول",
    "name_ru": "Abu tulul ",
    "name_he": "אבו תלול ",
    "x": 193812.4781,
    "y": 565910.4169
  },
  {
    "village_id": 1376,
    "name_en": "Beer gannim ",
    "name_ar": "بِئير چنيم",
    "name_ru": "Beer gannim ",
    "name_he": "באר גנים ",
    "x": 163035.7324,
    "y": 623198.785
  },
  {
    "village_id": 1377,
    "name_en": "Shave darom ",
    "name_ar": "شَڤي دَروم",
    "name_ru": "Shave darom ",
    "name_he": "שבי דרום ",
    "x": null,
    "y": null
  },
  {
    "village_id": 1378,
    "name_en": "Shezaf ",
    "name_ar": "شزاف",
    "name_ru": "Shezaf ",
    "name_he": "שיזף ",
    "x": 177542.6588,
    "y": 545703.1761
  },
  {
    "village_id": 1419,
    "name_en": "Nizzan b ",
    "name_ar": "نِتْسان بيت",
    "name_ru": "Nizzan b ",
    "name_he": "ניצן ב' ",
    "x": 165290.3242,
    "y": 627062.406
  },
  {
    "village_id": 2002,
    "name_en": "Tenuvot ",
    "name_ar": "تْنوڤوت",
    "name_ru": "Tenuvot ",
    "name_he": "תנובות ",
    "x": 196715.0403,
    "y": 690384.3865
  },
  {
    "village_id": 2003,
    "name_en": "Talme el'azar ",
    "name_ar": "تَلمي إلعَزار",
    "name_ru": "Talme el'azar ",
    "name_he": "תלמי אלעזר ",
    "x": 198210.4051,
    "y": 705889.5469
  },
  {
    "village_id": 2006,
    "name_en": "Kannot ",
    "name_ar": "كَنوت",
    "name_ru": "Kannot ",
    "name_he": "כנות ",
    "x": 176599.6225,
    "y": 634524.1257
  },
  {
    "village_id": 2008,
    "name_en": "Sede yizhaq ",
    "name_ar": "سْدي يِتسحاك",
    "name_ru": "Сде-Ицхак",
    "name_he": "שדה יצחק ",
    "x": 199736.2936,
    "y": 701226.8891
  },
  {
    "village_id": 2009,
    "name_en": "Yuval ",
    "name_ar": "يوڤال",
    "name_ru": "Yuval ",
    "name_he": "יובל ",
    "x": 256105.4563,
    "y": 794433.1809
  },
  {
    "village_id": 2010,
    "name_en": "Kefar bin nun ",
    "name_ar": "كفار بين نون ",
    "name_ru": "Kefar bin nun ",
    "name_he": "כפר בן נון ",
    "x": 195094.0668,
    "y": 640966.8387
  },
  {
    "village_id": 2011,
    "name_en": "Yinnon ",
    "name_ar": "يِنون",
    "name_ru": "Yinnon ",
    "name_he": "ינון ",
    "x": 179396.8506,
    "y": 627840.0665
  },
  {
    "village_id": 2012,
    "name_en": "Orot ",
    "name_ar": "أوروت",
    "name_ru": "Orot ",
    "name_he": "אורות ",
    "x": 174987.5692,
    "y": 627855.9726
  },
  {
    "village_id": 2013,
    "name_en": "Ben shemen (moshav) ",
    "name_ar": "بين شيمِن",
    "name_ru": "Бен-Ше́мен",
    "name_he": "בן שמן (מושב) ",
    "x": 192721.6745,
    "y": 651052.7563
  },
  {
    "village_id": 2014,
    "name_en": "Giv'olim ",
    "name_ar": "چِڤعوليم",
    "name_ru": "Giv'olim ",
    "name_he": "גבעולים ",
    "x": 161102.8131,
    "y": 589565.0151
  },
  {
    "village_id": 2015,
    "name_en": "Sede hemed ",
    "name_ar": "سْدِي حيمِد",
    "name_ru": "Sede hemed ",
    "name_he": "שדי חמד ",
    "x": 194935.3925,
    "y": 674125.9933
  },
  {
    "village_id": 2016,
    "name_en": "Rewaya ",
    "name_ar": "رِڤَيا",
    "name_ru": "Rewaya ",
    "name_he": "רוויה ",
    "x": 244594.1842,
    "y": 706018.624
  },
  {
    "village_id": 2018,
    "name_en": "Giv'at hayyim (ihud) ",
    "name_ar": "چِڤعات حَييم (إحود)",
    "name_ru": "Giv'at hayyim (ihud) ",
    "name_he": "גבעת חיים (איחוד) ",
    "x": 193934.3928,
    "y": 700646.1951
  },
  {
    "village_id": 2021,
    "name_en": "Eshel hanasi ",
    "name_ar": "إيشِل هنَسي",
    "name_ru": "Eshel hanasi ",
    "name_he": "אשל הנשיא ",
    "x": 171184.218,
    "y": 581629.1015
  },
  {
    "village_id": 2023,
    "name_en": "Lahav ",
    "name_ar": "لاهَڤ",
    "name_ru": "Lahav ",
    "name_he": "להב ",
    "x": 187593.4328,
    "y": 587518.3084
  },
  {
    "village_id": 2024,
    "name_en": "Umm al-qutuf ",
    "name_ar": "أم القطف",
    "name_ru": "Umm al-qutuf ",
    "name_he": "אום אל-קוטוף ",
    "x": 205802.4233,
    "y": 708202.8773
  },
  {
    "village_id": 2026,
    "name_en": "Yardena ",
    "name_ar": "يرْدينا",
    "name_ru": "Yardena ",
    "name_he": "ירדנה ",
    "x": 253269.7036,
    "y": 718999.6918
  },
  {
    "village_id": 2029,
    "name_en": "Midrakh oz ",
    "name_ar": "مِدراخ عوز",
    "name_ru": "Midrakh oz ",
    "name_he": "מדרך עוז ",
    "x": 215200.8495,
    "y": 722308.717
  },
  {
    "village_id": 2030,
    "name_en": "Menuha ",
    "name_ar": "مِنوحا",
    "name_ru": "Menuha ",
    "name_he": "מנוחה ",
    "x": 178808.2192,
    "y": 618320.9211
  },
  {
    "village_id": 2033,
    "name_en": "Bet hilqiyya ",
    "name_ar": "بيت حِلْكِيا",
    "name_ru": "Bet hilqiyya ",
    "name_he": "בית חלקיה ",
    "x": 182271.4147,
    "y": 633313.9183
  },
  {
    "village_id": 2034,
    "name_en": "Hazor hagelilit ",
    "name_ar": "حتْسور هچليليت",
    "name_ru": "Хацор-ха-Глилит",
    "name_he": "חצור הגלילית ",
    "x": 251316.8213,
    "y": 765400.7463
  },
  {
    "village_id": 2035,
    "name_en": "Adanim ",
    "name_ar": "عَدَنيم",
    "name_ru": "Adanim ",
    "name_he": "עדנים ",
    "x": 191228.0634,
    "y": 671895.7793
  },
  {
    "village_id": 2038,
    "name_en": "Bareqet ",
    "name_ar": "بريكِت",
    "name_ru": "Барекет",
    "name_he": "ברקת ",
    "x": 194993.1414,
    "y": 658041.4125
  },
  {
    "village_id": 2042,
    "name_en": "En gedi ",
    "name_ar": "عين چيدي",
    "name_ru": "En gedi ",
    "name_he": "עין גדי ",
    "x": 237499.5323,
    "y": 596614.0381
  },
  {
    "village_id": 2043,
    "name_en": "Bahan ",
    "name_ar": "باحَن",
    "name_ru": "Bahan ",
    "name_he": "בחן ",
    "x": 202076.8484,
    "y": 695244.964
  },
  {
    "village_id": 2044,
    "name_en": "Melilot ",
    "name_ar": "مِليلوت",
    "name_ru": "Melilot ",
    "name_he": "מלילות ",
    "x": 161496.0808,
    "y": 588902.2586
  },
  {
    "village_id": 2045,
    "name_en": "Nahala ",
    "name_ar": "نَحَلا",
    "name_ru": "Nahala ",
    "name_he": "נחלה ",
    "x": 180611.5876,
    "y": 618566.0932
  },
  {
    "village_id": 2046,
    "name_en": "Segula ",
    "name_ar": "سْچُلا",
    "name_ru": "Segula ",
    "name_he": "סגולה ",
    "x": 179166.8519,
    "y": 619897.6325
  },
  {
    "village_id": 2047,
    "name_en": "Nir moshe ",
    "name_ar": "نير مُشي",
    "name_ru": "Nir moshe ",
    "name_he": "ניר משה ",
    "x": 164974.817,
    "y": 598529.7013
  },
  {
    "village_id": 2048,
    "name_en": "Nir aqiva ",
    "name_ar": "نير عكيڤا",
    "name_ru": "Nir aqiva ",
    "name_he": "ניר עקיבא ",
    "x": 166452.5688,
    "y": 597677.4653
  },
  {
    "village_id": 2049,
    "name_en": "Sede zevi ",
    "name_ar": "سْدي تْسڤي",
    "name_ru": "Sede zevi ",
    "name_he": "שדה צבי ",
    "x": 172644.8735,
    "y": 595431.7075
  },
  {
    "village_id": 2050,
    "name_en": "Talme bilu ",
    "name_ar": "تَلمي بيلو",
    "name_ru": "Talme bilu ",
    "name_he": "תלמי ביל\"ו ",
    "x": 166366.0923,
    "y": 594141.7019
  },
  {
    "village_id": 2051,
    "name_en": "Rewaha ",
    "name_ar": "رِڤَحا",
    "name_ru": "Rewaha ",
    "name_he": "רווחה ",
    "x": 174794.0341,
    "y": 617551.2006
  },
  {
    "village_id": 2052,
    "name_en": "Avital ",
    "name_ar": "أڤيتال",
    "name_ru": "Авиталь",
    "name_he": "אביטל ",
    "x": 228853.1598,
    "y": 718138.6966
  },
  {
    "village_id": 2053,
    "name_en": "Perazon ",
    "name_ar": "پْرَزون",
    "name_ru": "Perazon ",
    "name_he": "פרזון ",
    "x": 229409.5193,
    "y": 716851.3978
  },
  {
    "village_id": 2054,
    "name_en": "Metav ",
    "name_ar": "ميتاڤ",
    "name_ru": "Metav ",
    "name_he": "מיטב ",
    "x": 228472.6214,
    "y": 716826.9286
  },
  {
    "village_id": 2055,
    "name_en": "Ma'or ",
    "name_ar": "مَؤور",
    "name_ru": "Ma'or ",
    "name_he": "מאור ",
    "x": 200749.8622,
    "y": 703379.2045
  },
  {
    "village_id": 2057,
    "name_en": "Sede terumot ",
    "name_ar": "سْدي تروموت",
    "name_ru": "Sede terumot ",
    "name_he": "שדי תרומות ",
    "x": 245932.8699,
    "y": 705033.4014
  },
  {
    "village_id": 2059,
    "name_en": "Pa'ame tashaz ",
    "name_ar": "پَعَمي تَشاز",
    "name_ru": "Pa'ame tashaz ",
    "name_he": "פעמי תש\"ז ",
    "x": 170859.4419,
    "y": 594365.3916
  },
  {
    "village_id": 2060,
    "name_en": "Berosh ",
    "name_ar": "بْروش",
    "name_ru": "Berosh ",
    "name_he": "ברוש ",
    "x": 164983.0857,
    "y": 586666.9327
  },
  {
    "village_id": 2061,
    "name_en": "Tidhar ",
    "name_ar": "تِدهار",
    "name_ru": "Tidhar ",
    "name_he": "תדהר ",
    "x": 164500.9471,
    "y": 587549.3242
  },
  {
    "village_id": 2062,
    "name_en": "Te'ashur ",
    "name_ar": "تِأشور",
    "name_ru": "Te'ashur ",
    "name_he": "תאשור ",
    "x": 166116.9473,
    "y": 586767.4016
  },
  {
    "village_id": 2063,
    "name_en": "Dishon ",
    "name_ar": "ديشون",
    "name_ru": "Dishon ",
    "name_he": "דישון ",
    "x": 248808.3932,
    "y": 776367.0884
  },
  {
    "village_id": 2064,
    "name_en": "Zeru'a ",
    "name_ar": "زروعا",
    "name_ru": "Zeru'a ",
    "name_he": "זרועה ",
    "x": 164327.6096,
    "y": 596423.77
  },
  {
    "village_id": 2100,
    "name_en": "Tirat karmel ",
    "name_ar": "تيرات كرميل",
    "name_ru": "Тират-Кармель",
    "name_he": "טירת כרמל ",
    "x": 197358.8695,
    "y": 741270.2953
  },
  {
    "village_id": 2200,
    "name_en": "Dimona ",
    "name_ar": "دير رافات",
    "name_ru": "Димона",
    "name_he": "דימונה ",
    "x": 202602.1187,
    "y": 553029.9717
  },
  {
    "village_id": 2300,
    "name_en": "Qiryat tiv'on ",
    "name_ar": "كِريات تِڤعون",
    "name_ru": "Кирьят-Тивон",
    "name_he": "קרית טבעון ",
    "x": 212129.6086,
    "y": 735221.4453
  },
  {
    "village_id": 2400,
    "name_en": "Or yehuda ",
    "name_ar": "أور يِهودا",
    "name_ru": "Ор-Йехуда",
    "name_he": "אור יהודה ",
    "x": 186588.3236,
    "y": 659471.3286
  },
  {
    "village_id": 2500,
    "name_en": "Nesher ",
    "name_ar": "نيشِر",
    "name_ru": "Nesher ",
    "name_he": "נשר ",
    "x": 204344.9586,
    "y": 741703.4878
  },
  {
    "village_id": 2530,
    "name_en": "Be'er ya'aqov ",
    "name_ar": "بِئير يعكوڤ",
    "name_ru": "Беэр-Яаков",
    "name_he": "באר יעקב ",
    "x": 184930.6173,
    "y": 649631.406
  },
  {
    "village_id": 2550,
    "name_en": "Gedera ",
    "name_ar": "چِدِرا",
    "name_ru": "Гедера",
    "name_he": "גדרה ",
    "x": 179217.7094,
    "y": 635457.1174
  },
  {
    "village_id": 2560,
    "name_en": "Arad ",
    "name_ar": "عَراد",
    "name_ru": "Arad ",
    "name_he": "ערד ",
    "x": 220151.0134,
    "y": 573794.5091
  },
  {
    "village_id": 2600,
    "name_en": "Elat ",
    "name_ar": "إيلات",
    "name_ru": "Эйлат",
    "name_he": "אילת ",
    "x": 193982.2397,
    "y": 384601.1613
  },
  {
    "village_id": 2610,
    "name_en": "Bet shemesh ",
    "name_ar": "بيت شيمِش",
    "name_ru": "Бейт-Шемеш",
    "name_he": "בית שמש ",
    "x": 199782.6636,
    "y": 624450.9704
  },
  {
    "village_id": 2620,
    "name_en": "Qiryat ono ",
    "name_ar": "كِريات أونو",
    "name_ru": "Кирьят Оно",
    "name_he": "קרית אונו ",
    "x": 186798.4471,
    "y": 662934.9997
  },
  {
    "village_id": 2630,
    "name_en": "Qiryat gat ",
    "name_ar": "كِريات چات",
    "name_ru": "Кирьят-Гат",
    "name_he": "קרית גת ",
    "x": 177362.0581,
    "y": 613281.6054
  },
  {
    "village_id": 2640,
    "name_en": "Rosh haayin ",
    "name_ar": "روش هعايِن",
    "name_ru": "Рош-ха-Аин",
    "name_he": "ראש העין ",
    "x": 196550.4375,
    "y": 666814.9584
  },
  {
    "village_id": 2650,
    "name_en": "Ramat hasharon ",
    "name_ar": "رَمات هشَرون",
    "name_ru": "Рамат ха-Шарон",
    "name_he": "רמת השרון ",
    "x": 184507.9311,
    "y": 671981.2669
  },
  {
    "village_id": 2660,
    "name_en": "Yavne ",
    "name_ar": "يَڤني",
    "name_ru": "Явне",
    "name_he": "יבנה ",
    "x": 175078.9669,
    "y": 643145.1924
  },
  {
    "village_id": 2710,
    "name_en": "Umm al-fahm ",
    "name_ar": "أم الفحم",
    "name_ru": "Умм-эль-Фахм",
    "name_he": "אום אל-פחם ",
    "x": 214214.9183,
    "y": 714102.0366
  },
  {
    "village_id": 2720,
    "name_en": "Tire ",
    "name_ar": "الطيرة",
    "name_ru": "Тира",
    "name_he": "טירה ",
    "x": 196099.0598,
    "y": 682303.3879
  },
  {
    "village_id": 2730,
    "name_en": "Tayibe ",
    "name_ar": "الطيبة",
    "name_ru": "Tayibe ",
    "name_he": "טייבה ",
    "x": 200706.3096,
    "y": 685368.4038
  },
  {
    "village_id": 2742,
    "name_en": "Zabarga ",
    "name_ar": "الزبارقة",
    "name_ru": "Zabarga ",
    "name_he": "זבארגה (שבט) ",
    "x": 202950.0737,
    "y": 572215.7431
  },
  {
    "village_id": 2800,
    "name_en": "Qiryat shemona ",
    "name_ar": "كِريات شـمونا",
    "name_ru": "Кирьят-Шмона",
    "name_he": "קרית שמונה ",
    "x": 253643.3399,
    "y": 790131.3797
  },
  {
    "village_id": 3000,
    "name_en": "Jerusalem ",
    "name_ar": "أورشليم القدس",
    "name_ru": "Иерусалим",
    "name_he": "ירושלים ",
    "x": 220183.7413,
    "y": 632033.9331
  },
  {
    "village_id": 3400,
    "name_en": "Hebron",
    "name_ar": "الخليل",
    "name_ru": "Hebron",
    "name_he": "חברון ",
    "x": 208878.6616,
    "y": 604219.3859
  },
  {
    "village_id": 3488,
    "name_en": "Kefar ezyon ",
    "name_ar": "كفار عِتْسيون",
    "name_ru": "Kefar ezyon ",
    "name_he": "כפר עציון ",
    "x": 211013.1463,
    "y": 617427.971
  },
  {
    "village_id": 3555,
    "name_en": "Netiv hagedud ",
    "name_ar": "نِتيڤ هچدود",
    "name_ru": "Netiv hagedud ",
    "name_he": "נתיב הגדוד ",
    "x": 242214.3726,
    "y": 655052.6935
  },
  {
    "village_id": 3556,
    "name_en": "Almog ",
    "name_ar": "ألموچ",
    "name_ru": "Альмог",
    "name_he": "אלמוג ",
    "x": 243794.4597,
    "y": 632584.7537
  },
  {
    "village_id": 3557,
    "name_en": "Qedumim ",
    "name_ar": "كْدوميم",
    "name_ru": "Qedumim ",
    "name_he": "קדומים ",
    "x": 214809.353,
    "y": 680010.4984
  },
  {
    "village_id": 3558,
    "name_en": "Tomer ",
    "name_ar": "تومِر",
    "name_ru": "Tomer ",
    "name_he": "תומר ",
    "x": 241635.7827,
    "y": 658397.6561
  },
  {
    "village_id": 3560,
    "name_en": "Elqana ",
    "name_ar": "إلكنا",
    "name_ru": "Элькана",
    "name_he": "אלקנה ",
    "x": 203626.1607,
    "y": 668496.8796
  },
  {
    "village_id": 3561,
    "name_en": "Migdal oz ",
    "name_ar": "مِچدال عوز",
    "name_ru": "Migdal oz ",
    "name_he": "מגדל עוז ",
    "x": 213673.3605,
    "y": 616320.1022
  },
  {
    "village_id": 3563,
    "name_en": "Teqoa ",
    "name_ar": "تْكووَع",
    "name_ru": "Teqoa ",
    "name_he": "תקוע ",
    "x": 221818.1745,
    "y": 617856.6354
  },
  {
    "village_id": 3564,
    "name_en": "Kokhav hashahar ",
    "name_ar": "كوخاڤ هشاحَر",
    "name_ru": "Kokhav hashahar ",
    "name_he": "כוכב השחר ",
    "x": 233186.5759,
    "y": 651983.4987
  },
  {
    "village_id": 3565,
    "name_en": "Rimmonim ",
    "name_ar": "الرينة",
    "name_ru": "Rimmonim ",
    "name_he": "רימונים ",
    "x": 232336.9583,
    "y": 649013.6058
  },
  {
    "village_id": 3566,
    "name_en": "Yafit ",
    "name_ar": "يَفيت",
    "name_ru": "Yafit ",
    "name_he": "יפית ",
    "x": 244908.415,
    "y": 663354.6941
  },
  {
    "village_id": 3567,
    "name_en": "Sal'it ",
    "name_ar": "سلعيت",
    "name_ru": "Sal'it ",
    "name_he": "סלעית ",
    "x": 204892.8247,
    "y": 683206.7476
  },
  {
    "village_id": 3568,
    "name_en": "Rehan ",
    "name_ar": "ريحان",
    "name_ru": "Rehan ",
    "name_he": "ריחן ",
    "x": 213051.7478,
    "y": 708250.1455
  },
  {
    "village_id": 3569,
    "name_en": "Mevo dotan ",
    "name_ar": "مِڤو دوتان",
    "name_ru": "Mevo dotan ",
    "name_he": "מבוא דותן ",
    "x": 216734.0466,
    "y": 702910.4631
  },
  {
    "village_id": 3570,
    "name_en": "Ari'el ",
    "name_ar": "أريئيل",
    "name_ru": "Ариэль",
    "name_he": "אריאל ",
    "x": 216862.7944,
    "y": 668104.2558
  },
  {
    "village_id": 3571,
    "name_en": "Shave shomeron ",
    "name_ar": "شَڤي شومرون",
    "name_ru": "Shave shomeron ",
    "name_he": "שבי שומרון ",
    "x": 217644.0259,
    "y": 685536.4023
  },
  {
    "village_id": 3572,
    "name_en": "Kefar tappuah ",
    "name_ar": "كفار تَڤور",
    "name_ru": "Kefar tappuah ",
    "name_he": "כפר תפוח ",
    "x": 223741.0675,
    "y": 669571.081
  },
  {
    "village_id": 3573,
    "name_en": "Neve tsuf ",
    "name_ar": "نِڤي تسوف",
    "name_ru": "Neve tsuf ",
    "name_he": "נוה צוף ",
    "x": 211999.0697,
    "y": 657012.6686
  },
  {
    "village_id": 3574,
    "name_en": "Bet el ",
    "name_ar": "بيت إيل",
    "name_ru": "Бейт-Эль",
    "name_he": "בית אל ",
    "x": 221031.4195,
    "y": 649824.0908
  },
  {
    "village_id": 3575,
    "name_en": "Bet horon ",
    "name_ar": "بيت حورون",
    "name_ru": "Bet horon ",
    "name_he": "בית חורון ",
    "x": 211796.9519,
    "y": 642990.9084
  },
  {
    "village_id": 3576,
    "name_en": "Mizpe yeriho ",
    "name_ar": "مِتْسپي يِريحو",
    "name_ru": "Мицпе-Йерихо",
    "name_he": "מצפה יריחו ",
    "x": 237414.1736,
    "y": 635901.2904
  },
  {
    "village_id": 3578,
    "name_en": "Shadmot mehola ",
    "name_ar": "شَدْموت مِحولا",
    "name_ru": "Shadmot mehola ",
    "name_he": "שדמות מחולה ",
    "x": 250549.0991,
    "y": 694734.4206
  },
  {
    "village_id": 3579,
    "name_en": "Elon more ",
    "name_ar": "إلون موري",
    "name_ru": "Элон-Море",
    "name_he": "אלון מורה ",
    "x": 231575.977,
    "y": 682121.5683
  },
  {
    "village_id": 3598,
    "name_en": "Argaman ",
    "name_ar": "أرچمان",
    "name_ru": "Argaman ",
    "name_he": "ארגמן ",
    "x": 249396.9276,
    "y": 675501.5126
  },
  {
    "village_id": 3599,
    "name_en": "Mehola ",
    "name_ar": "مِحولا",
    "name_ru": "Mehola ",
    "name_he": "מחולה ",
    "x": 248751.7649,
    "y": 696876.368
  },
  {
    "village_id": 3601,
    "name_en": "Qalya ",
    "name_ar": "كَلْيا",
    "name_ru": "Qalya ",
    "name_he": "קליה ",
    "x": 244328.8477,
    "y": 628681.8656
  },
  {
    "village_id": 3602,
    "name_en": "Rosh zurim ",
    "name_ar": "روش تْسوريم",
    "name_ru": "Rosh zurim ",
    "name_he": "ראש צורים ",
    "x": 211834.3218,
    "y": 619443.9789
  },
  {
    "village_id": 3603,
    "name_en": "Har gillo ",
    "name_ar": "هار چيلو",
    "name_ru": "Har gillo ",
    "name_he": "הר גילה ",
    "x": 215781.6617,
    "y": 625752.3305
  },
  {
    "village_id": 3604,
    "name_en": "Allon shevut ",
    "name_ar": "ألون شْڤوت",
    "name_ru": "Алон-Швут",
    "name_he": "אלון שבות ",
    "x": 211820.98,
    "y": 618294.4281
  },
  {
    "village_id": 3605,
    "name_en": "Massu'a ",
    "name_ar": "مَسوؤة",
    "name_ru": "Massu'a ",
    "name_he": "משואה ",
    "x": 246471.0591,
    "y": 668863.6882
  },
  {
    "village_id": 3606,
    "name_en": "Gilgal ",
    "name_ar": "چِلچال",
    "name_ru": "Gilgal ",
    "name_he": "גלגל ",
    "x": 242221.2234,
    "y": 656274.3026
  },
  {
    "village_id": 3607,
    "name_en": "Yitav ",
    "name_ar": "يِيتاڤ",
    "name_ru": "Yitav ",
    "name_he": "ייט\"ב ",
    "x": 240295.442,
    "y": 650516.4757
  },
  {
    "village_id": 3608,
    "name_en": "Ma'ale efrayim ",
    "name_ar": "مَعلي إفرايِم",
    "name_ru": "Ma'ale efrayim ",
    "name_he": "מעלה אפרים ",
    "x": 238396.7005,
    "y": 664090.2095
  },
  {
    "village_id": 3609,
    "name_en": "Hamra ",
    "name_ar": "حمرا",
    "name_ru": "Hamra ",
    "name_he": "חמרה ",
    "x": 241439.0601,
    "y": 678534.9004
  },
  {
    "village_id": 3610,
    "name_en": "Mizpe shalem ",
    "name_ar": "مِتْسپي شَليم",
    "name_ru": "Mizpe shalem ",
    "name_he": "מצפה שלם ",
    "x": 238191.0919,
    "y": 608453.4755
  },
  {
    "village_id": 3611,
    "name_en": "Qiryat arba ",
    "name_ar": "كِريات أرباع",
    "name_ru": "Кирьят-Арба",
    "name_he": "קרית ארבע ",
    "x": 211492.7251,
    "y": 605570.9667
  },
  {
    "village_id": 3612,
    "name_en": "Beqa'ot ",
    "name_ar": "بِكَعوت",
    "name_ru": "Beqa'ot ",
    "name_he": "בקעות ",
    "x": 242840.4718,
    "y": 683363.7816
  },
  {
    "village_id": 3613,
    "name_en": "Gittit ",
    "name_ar": "چِتيت",
    "name_ru": "Gittit ",
    "name_he": "גיתית ",
    "x": 237687.3368,
    "y": 667649.2883
  },
  {
    "village_id": 3614,
    "name_en": "Mekhora ",
    "name_ar": "مِخورا",
    "name_ru": "Mekhora ",
    "name_he": "מכורה ",
    "x": 240073.3367,
    "y": 674594.0104
  },
  {
    "village_id": 3615,
    "name_en": "Peza'el ",
    "name_ar": "پِتْسَئيل",
    "name_ru": "Peza'el ",
    "name_he": "פצאל ",
    "x": 241960.1092,
    "y": 661284.0865
  },
  {
    "village_id": 3616,
    "name_en": "Ma'ale adummim ",
    "name_ar": "مَعلي أدُميم",
    "name_ru": "Маале-Адумим",
    "name_he": "מעלה אדומים ",
    "x": 229773.99,
    "y": 632377.0976
  },
  {
    "village_id": 3617,
    "name_en": "Ofra ",
    "name_ar": "عُفرا",
    "name_ru": "Ofra ",
    "name_he": "עופרה ",
    "x": 224849.5931,
    "y": 651201.1764
  },
  {
    "village_id": 3618,
    "name_en": "El'azar ",
    "name_ar": "إلعَزار",
    "name_ru": "El'azar ",
    "name_he": "אלעזר ",
    "x": 213663.3602,
    "y": 618565.2234
  },
  {
    "village_id": 3619,
    "name_en": "Ro'i ",
    "name_ar": "روعي",
    "name_ru": "Ro'i ",
    "name_he": "רועי ",
    "x": 245780.9254,
    "y": 683881.534
  },
  {
    "village_id": 3620,
    "name_en": "Na'aran ",
    "name_ar": "نَعَران",
    "name_ru": "Na'aran ",
    "name_he": "נערן ",
    "x": 243199.5673,
    "y": 652721.1725
  },
  {
    "village_id": 3638,
    "name_en": "Kefar adummim ",
    "name_ar": "كفار أدُميم",
    "name_ru": "Кфар-Адумим",
    "name_he": "כפר אדומים ",
    "x": 231266.7292,
    "y": 636989.6376
  },
  {
    "village_id": 3639,
    "name_en": "Wered yeriho ",
    "name_ar": "ڤيرِد يِريحو",
    "name_ru": "Wered yeriho ",
    "name_he": "ורד יריחו ",
    "x": 241380.2976,
    "y": 637089.1118
  },
  {
    "village_id": 3640,
    "name_en": "Qarne shomeron ",
    "name_ar": "كرني شومرون",
    "name_ru": "Qarne shomeron ",
    "name_he": "קרני שומרון ",
    "x": 209242.4882,
    "y": 675419.0131
  },
  {
    "village_id": 3641,
    "name_en": "Shilo ",
    "name_ar": "شيلو",
    "name_ru": "Shilo ",
    "name_he": "שילה ",
    "x": 228272.1231,
    "y": 662232.9145
  },
  {
    "village_id": 3643,
    "name_en": "Hinnanit ",
    "name_ar": "حِنَنيت",
    "name_ru": "Hinnanit ",
    "name_he": "חיננית ",
    "x": 216423.2112,
    "y": 709612.2974
  },
  {
    "village_id": 3644,
    "name_en": "Giv'on hahadasha ",
    "name_ar": "چِڤعون هحدشا",
    "name_ru": "Гивон-ха-Хадаша",
    "name_he": "גבעון החדשה ",
    "x": 215126.8761,
    "y": 639483.3858
  },
  {
    "village_id": 3645,
    "name_en": "Bet haarava ",
    "name_ar": "بيت هعَرَڤا",
    "name_ru": "Bet haarava ",
    "name_he": "בית הערבה ",
    "x": 245339.9998,
    "y": 635094.413
  },
  {
    "village_id": 3646,
    "name_en": "Nahal hemdat ",
    "name_ar": "حِمدات",
    "name_ru": "Nahal hemdat ",
    "name_he": "חמדת ",
    "x": 249813.7873,
    "y": 684218.8877
  },
  {
    "village_id": 3647,
    "name_en": "Yaqir ",
    "name_ar": "يَكير",
    "name_ru": "Yaqir ",
    "name_he": "יקיר ",
    "x": 210863.4807,
    "y": 672838.9398
  },
  {
    "village_id": 3648,
    "name_en": "Mattityahu ",
    "name_ar": "مَتِتْياهو",
    "name_ru": "Mattityahu ",
    "name_he": "מתתיהו ",
    "x": 203398.1835,
    "y": 648522.1564
  },
  {
    "village_id": 3649,
    "name_en": "Shaqed ",
    "name_ar": "شَكيد",
    "name_ru": "Shaqed ",
    "name_he": "שקד ",
    "x": 216102.2525,
    "y": 708862.632
  },
  {
    "village_id": 3650,
    "name_en": "Efrat ",
    "name_ar": "إفرات",
    "name_ru": "Эфрат",
    "name_he": "אפרת ",
    "x": 214198.2615,
    "y": 617813.0605
  },
  {
    "village_id": 3651,
    "name_en": "Ma'ale mikhmas ",
    "name_ar": "مَعلي مِخماس",
    "name_ru": "Ma'ale mikhmas ",
    "name_he": "מעלה מכמש ",
    "x": 229073.2131,
    "y": 642943.5432
  },
  {
    "village_id": 3652,
    "name_en": "Bet arye ",
    "name_ar": "بيت أرْيي – عُفَريم",
    "name_ru": "Бейт-Арье-Офарим",
    "name_he": "בית אריה ",
    "x": 204700.4326,
    "y": 660662.895
  },
  {
    "village_id": 3653,
    "name_en": "Ma'ale amos ",
    "name_ar": "مَعلي عموس",
    "name_ru": "Ma'ale amos ",
    "name_he": "מעלה עמוס ",
    "x": 221870.2181,
    "y": 611566.1736
  },
  {
    "village_id": 3654,
    "name_en": "Barqan ",
    "name_ar": "بركان",
    "name_ru": "Баркан",
    "name_he": "ברקן ",
    "x": 210353.9546,
    "y": 668281.7129
  },
  {
    "village_id": 3655,
    "name_en": "Nili ",
    "name_ar": "نين",
    "name_ru": "Nili ",
    "name_he": "ניל\"י ",
    "x": 204670.3799,
    "y": 652422.8419
  },
  {
    "village_id": 3656,
    "name_en": "Karmel ",
    "name_ar": "كرميل",
    "name_ru": "Karmel ",
    "name_he": "כרמל ",
    "x": 217490.2233,
    "y": 593114.1151
  },
  {
    "village_id": 3657,
    "name_en": "Ma'on ",
    "name_ar": "مَعون",
    "name_ru": "Ma'on ",
    "name_he": "מעון ",
    "x": 215258.5008,
    "y": 590797.3635
  },
  {
    "village_id": 3658,
    "name_en": "Ateret ",
    "name_ar": "عتيرِت",
    "name_ru": "Ateret ",
    "name_he": "עטרת ",
    "x": 216812.0796,
    "y": 656357.3599
  },
  {
    "village_id": 3659,
    "name_en": "Pesagot ",
    "name_ar": "پْسَچوت",
    "name_ru": "Pesagot ",
    "name_he": "פסגות ",
    "x": 221431.2209,
    "y": 645067.419
  },
  {
    "village_id": 3660,
    "name_en": "Immanu'el ",
    "name_ar": "عِمَنوئيل",
    "name_ru": "Immanu'el ",
    "name_he": "עמנואל ",
    "x": 213321.4077,
    "y": 674242.1824
  },
  {
    "village_id": 3709,
    "name_en": "Mevo horon ",
    "name_ar": "مِڤو حورون",
    "name_ru": "Мево-Хорон",
    "name_he": "מבוא חורון ",
    "x": 203417.9019,
    "y": 639691.1859
  },
  {
    "village_id": 3710,
    "name_en": "Berakha ",
    "name_ar": "بْرَخا",
    "name_ru": "Браха",
    "name_he": "ברכה ",
    "x": 225203.214,
    "y": 677708.0361
  },
  {
    "village_id": 3712,
    "name_en": "Enav ",
    "name_ar": "عِناڤ",
    "name_ru": "Enav ",
    "name_he": "ענב ",
    "x": 212192.4482,
    "y": 688034.2618
  },
  {
    "village_id": 3713,
    "name_en": "Naama ",
    "name_ar": "نَعَما",
    "name_ru": "Naama ",
    "name_he": "נעמ\"ה ",
    "x": 244235.495,
    "y": 646076.62
  },
  {
    "village_id": 3715,
    "name_en": "Almon ",
    "name_ar": "علمون",
    "name_ru": "Almon ",
    "name_he": "עלמון ",
    "x": 227941.7743,
    "y": 637490.8643
  },
  {
    "village_id": 3717,
    "name_en": "Hermesh ",
    "name_ar": "حِرميش",
    "name_ru": "Hermesh ",
    "name_he": "חרמש ",
    "x": 211398.0114,
    "y": 703300.8062
  },
  {
    "village_id": 3719,
    "name_en": "Telem ",
    "name_ar": "تيلِم",
    "name_ru": "Telem ",
    "name_he": "תלם ",
    "x": 202989.1479,
    "y": 608048.412
  },
  {
    "village_id": 3722,
    "name_en": "Eshkolot ",
    "name_ar": "إشكولوت",
    "name_ru": "Эшколот",
    "name_he": "אשכולות ",
    "x": 190972.9045,
    "y": 588834.7251
  },
  {
    "village_id": 3723,
    "name_en": "Pene hever ",
    "name_ar": "پْني حيڤِر",
    "name_ru": "Pene hever ",
    "name_he": "פני חבר ",
    "x": 215758.3467,
    "y": 599297.8966
  },
  {
    "village_id": 3724,
    "name_en": "Negohot ",
    "name_ar": "نِچوهوت",
    "name_ru": "Negohot ",
    "name_he": "נגוהות ",
    "x": 198440.4903,
    "y": 600118.5233
  },
  {
    "village_id": 3725,
    "name_en": "Newe daniyyel ",
    "name_ar": "نِڤي دَنِييل",
    "name_ru": "Newe daniyyel ",
    "name_he": "נווה דניאל ",
    "x": 213628.1067,
    "y": 620660.371
  },
  {
    "village_id": 3726,
    "name_en": "Noqedim ",
    "name_ar": "نوكديم",
    "name_ru": "Noqedim ",
    "name_he": "נוקדים ",
    "x": 223135.0112,
    "y": 616970.6377
  },
  {
    "village_id": 3727,
    "name_en": "Ale zahav ",
    "name_ar": "علي زَهاڤ",
    "name_ru": "Ale zahav ",
    "name_he": "עלי זהב ",
    "x": 206520.6612,
    "y": 664003.8232
  },
  {
    "village_id": 3730,
    "name_en": "Giv'at ze'ev ",
    "name_ar": "چِڤعات زِئيڤ",
    "name_ru": "Гиват-Зеэв",
    "name_he": "גבעת זאב ",
    "x": 215340.4011,
    "y": 641077.3407
  },
  {
    "village_id": 3743,
    "name_en": "Tene ",
    "name_ar": "تيني",
    "name_ru": "Tene ",
    "name_he": "טנא ",
    "x": 195882.7474,
    "y": 586986.9933
  },
  {
    "village_id": 3744,
    "name_en": "Brukhin ",
    "name_ar": "بروخين",
    "name_ru": "Brukhin ",
    "name_he": "ברוכין ",
    "x": 208401.0232,
    "y": 665195.683
  },
  {
    "village_id": 3745,
    "name_en": "Mezadot yehuda ",
    "name_ar": "مِتْسَدوت يِهودا",
    "name_ru": "Mezadot yehuda ",
    "name_he": "מצדות יהודה ",
    "x": 210723.1563,
    "y": 585794.0314
  },
  {
    "village_id": 3746,
    "name_en": "Qiryat netafim ",
    "name_ar": "كِريات نِتَفيم",
    "name_ru": "Кирьят-Натафим",
    "name_he": "קרית נטפים ",
    "x": 210759.1444,
    "y": 669282.0593
  },
  {
    "village_id": 3747,
    "name_en": "Dolev ",
    "name_ar": "دولِڤ",
    "name_ru": "Долев",
    "name_he": "דולב ",
    "x": 212712.0016,
    "y": 648164.9365
  },
  {
    "village_id": 3748,
    "name_en": "Otni'el ",
    "name_ar": "عُتنيئيل",
    "name_ru": "Otni'el ",
    "name_he": "עתניאל ",
    "x": 202893.3869,
    "y": 594147.3429
  },
  {
    "village_id": 3749,
    "name_en": "Yizhar ",
    "name_ar": "يِتْسهار",
    "name_ru": "Yizhar ",
    "name_he": "יצהר ",
    "x": 222546.479,
    "y": 675004.5358
  },
  {
    "village_id": 3750,
    "name_en": "Alfe menashe ",
    "name_ar": "ألفي مِنَشي",
    "name_ru": "Альфей-Менаше",
    "name_he": "אלפי מנשה ",
    "x": 201590.9217,
    "y": 675174.4031
  },
  {
    "village_id": 3751,
    "name_en": "Migdalim ",
    "name_ar": "مِچدليم",
    "name_ru": "Migdalim ",
    "name_he": "מגדלים ",
    "x": 232468.095,
    "y": 666331.5632
  },
  {
    "village_id": 3752,
    "name_en": "Ma'ale levona ",
    "name_ar": "مَعلي لِڤونا",
    "name_ru": "Ma'ale levona ",
    "name_he": "מעלה לבונה ",
    "x": 222847.7176,
    "y": 662402.2752
  },
  {
    "village_id": 3754,
    "name_en": "Asefar ",
    "name_ar": "أسفار",
    "name_ru": "Asefar ",
    "name_he": "אספר ",
    "x": 217850.1939,
    "y": 610365.6928
  },
  {
    "village_id": 3756,
    "name_en": "Suseya ",
    "name_ar": "سوسيا",
    "name_ru": "Suseya ",
    "name_he": "סוסיה ",
    "x": 210847.7847,
    "y": 588834.3883
  },
  {
    "village_id": 3759,
    "name_en": "Adora ",
    "name_ar": "أدورا",
    "name_ru": "Адора",
    "name_he": "אדורה ",
    "x": 201740.3036,
    "y": 606714.2873
  },
  {
    "village_id": 3760,
    "name_en": "Oranit ",
    "name_ar": "أُرَنيت",
    "name_ru": "Оранит",
    "name_he": "אורנית ",
    "x": 199249.9915,
    "y": 670803.9194
  },
  {
    "village_id": 3762,
    "name_en": "Itamar ",
    "name_ar": "إيتمار",
    "name_ru": "Итамар",
    "name_he": "איתמר ",
    "x": 229184.8538,
    "y": 675639.3656
  },
  {
    "village_id": 3763,
    "name_en": "Geva binyamin ",
    "name_ar": "چيڤع بِنيَمين",
    "name_ru": "Гева-Биньямин",
    "name_he": "גבע בנימין ",
    "x": 226211.4812,
    "y": 639676.0742
  },
  {
    "village_id": 3764,
    "name_en": "Haggai ",
    "name_ar": "حَچاي",
    "name_ru": "Haggai ",
    "name_he": "חגי ",
    "x": 207652.3068,
    "y": 600092.515
  },
  {
    "village_id": 3765,
    "name_en": "Eli ",
    "name_ar": "عِلي",
    "name_ru": "Eli ",
    "name_he": "עלי ",
    "x": 224803.7889,
    "y": 663960.7411
  },
  {
    "village_id": 3766,
    "name_en": "Karme zur ",
    "name_ar": "كرمي تْسور",
    "name_ru": "Karme zur ",
    "name_he": "כרמי צור ",
    "x": 209558.7204,
    "y": 612930.6711
  },
  {
    "village_id": 3767,
    "name_en": "Nahali'el ",
    "name_ar": "نحَليئيل",
    "name_ru": "Nahali'el ",
    "name_he": "נחליאל ",
    "x": 213378.9682,
    "y": 653430.4598
  },
  {
    "village_id": 3768,
    "name_en": "Pedu'el ",
    "name_ar": "پدوئيل",
    "name_ru": "Pedu'el ",
    "name_he": "פדואל ",
    "x": 205040.705,
    "y": 663186.7149
  },
  {
    "village_id": 3769,
    "name_en": "Har adar ",
    "name_ar": "هار أدار",
    "name_ru": "Har adar ",
    "name_he": "הר אדר ",
    "x": 212355.4034,
    "y": 637107.2184
  },
  {
    "village_id": 3770,
    "name_en": "Hashmona'im ",
    "name_ar": "حشمونَئيم",
    "name_ru": "Хашмонаим",
    "name_he": "חשמונאים ",
    "x": 202370.0876,
    "y": 648708.4392
  },
  {
    "village_id": 3777,
    "name_en": "Sansanna",
    "name_ar": "سنْسنا",
    "name_ru": "Sansanna",
    "name_he": "סנסנה ",
    "x": 190800.3774,
    "y": 585723.6602
  },
  {
    "village_id": 3779,
    "name_en": "Kokhav ya'aqov ",
    "name_ar": "كوخاڤ يعكوڤ",
    "name_ru": "Кохав Яаков",
    "name_he": "כוכב יעקב ",
    "x": 223094.7844,
    "y": 643251.6746
  },
  {
    "village_id": 3780,
    "name_en": "Betar illit ",
    "name_ar": "بيتار عِليت",
    "name_ru": "Бейтар-Илит",
    "name_he": "ביתר עילית ",
    "x": 210802.9693,
    "y": 622871.3749
  },
  {
    "village_id": 3781,
    "name_en": "Qedar ",
    "name_ar": "كِدار",
    "name_ru": "Qedar ",
    "name_he": "קדר ",
    "x": 229493.0915,
    "y": 629041.3832
  },
  {
    "village_id": 3782,
    "name_en": "Rotem ",
    "name_ar": "روتيم",
    "name_ru": "Rotem ",
    "name_he": "רותם ",
    "x": 249000.3635,
    "y": 693700.0881
  },
  {
    "village_id": 3784,
    "name_en": "Shim'a ",
    "name_ar": "شِمعا",
    "name_ru": "Shim'a ",
    "name_he": "שמעה ",
    "x": 201204.3725,
    "y": 588431.7242
  },
  {
    "village_id": 3785,
    "name_en": "Maskiyyot ",
    "name_ar": "مَسْكِيوت",
    "name_ru": "Maskiyyot ",
    "name_he": "משכיות ",
    "x": 247498.4906,
    "y": 691639.3467
  },
  {
    "village_id": 3786,
    "name_en": "Avenat ",
    "name_ar": "أڤنات",
    "name_ru": "Авенат",
    "name_he": "אבנת ",
    "x": 241534.9017,
    "y": 620749.9602
  },
  {
    "village_id": 3787,
    "name_en": "Na'ale ",
    "name_ar": "نَعَلي",
    "name_ru": "Na'ale ",
    "name_he": "נעלה ",
    "x": 205819.9108,
    "y": 652001.3701
  },
  {
    "village_id": 3788,
    "name_en": "Talmon ",
    "name_ar": "تَلمون",
    "name_ru": "Talmon ",
    "name_he": "טלמון ",
    "x": 213329.1925,
    "y": 649256.2134
  },
  {
    "village_id": 3790,
    "name_en": "Nofim ",
    "name_ar": "نوفيم",
    "name_ru": "Nofim ",
    "name_he": "נופים ",
    "x": 209854.1895,
    "y": 673564.461
  },
  {
    "village_id": 3791,
    "name_en": "Zufin ",
    "name_ar": "تْسوفيم",
    "name_ru": "Zufin ",
    "name_he": "צופים ",
    "x": 201125.8342,
    "y": 678165.5199
  },
  {
    "village_id": 3793,
    "name_en": "Avne hefez ",
    "name_ar": "أڤني حيفِتس",
    "name_ru": "Avne hefez ",
    "name_he": "אבני חפץ ",
    "x": 206992.133,
    "y": 688057.5689
  },
  {
    "village_id": 3794,
    "name_en": "Bat ayin ",
    "name_ar": "بات عايِن",
    "name_ru": "Bat ayin ",
    "name_he": "בת עין ",
    "x": 209552.6326,
    "y": 618491.6271
  },
  {
    "village_id": 3795,
    "name_en": "Revava ",
    "name_ar": "رِڤَڤا",
    "name_ru": "Revava ",
    "name_he": "רבבה ",
    "x": 212215.0499,
    "y": 669497.457
  },
  {
    "village_id": 3796,
    "name_en": "Kefar haoranim ",
    "name_ar": "كفار هَؤُرَنيم",
    "name_ru": "Kefar haoranim ",
    "name_he": "כפר האורנים ",
    "x": 203609.6653,
    "y": 647450.997
  },
  {
    "village_id": 3797,
    "name_en": "Modi'in illit ",
    "name_ar": "موديعين عِليت",
    "name_ru": "Модиин-Илит",
    "name_he": "מודיעין עילית ",
    "x": 204437.4911,
    "y": 648815.6846
  },
  {
    "village_id": 3822,
    "name_en": "Rehelim ",
    "name_ar": "رِحِليم",
    "name_ru": "Rehelim ",
    "name_he": "רחלים ",
    "x": 224367.6897,
    "y": 667625.6145
  },
  {
    "village_id": 3823,
    "name_en": "Ganne modiin ",
    "name_ar": "چَني موديعين",
    "name_ru": "Ganne modiin ",
    "name_he": "גני מודיעין ",
    "x": null,
    "y": null
  },
  {
    "village_id": 3824,
    "name_en": "Ammihay ",
    "name_ar": "عميحاي",
    "name_ru": "Ammihay ",
    "name_he": "עמיחי ",
    "x": 231141,
    "y": 661419
  },
  {
    "village_id": 3825,
    "name_en": "Mevo'ot yeriho ",
    "name_ar": "مِڤوؤوت يريهو",
    "name_ru": "Mevo'ot yeriho ",
    "name_he": "מבואות יריחו ",
    "x": null,
    "y": null
  },
  {
    "village_id": 3826,
    "name_en": "Sha'ar shomron ",
    "name_ar": "شاعر شمرون",
    "name_ru": "Sha'ar shomron ",
    "name_he": "שער שומרון ",
    "x": null,
    "y": null
  },
  {
    "village_id": 4000,
    "name_en": "Haifa ",
    "name_ar": "حيفا",
    "name_ru": "Хайфа",
    "name_he": "חיפה ",
    "x": 198947.2387,
    "y": 744928.038
  },
  {
    "village_id": 4001,
    "name_en": "Buq'ata ",
    "name_ar": "بقعاثا",
    "name_ru": "Буката",
    "name_he": "בוקעאתא ",
    "x": 273129.8703,
    "y": 789557.639
  },
  {
    "village_id": 4002,
    "name_en": "Eli al ",
    "name_ar": "إلي عاد",
    "name_ru": "Eli al ",
    "name_he": "אלי-עד ",
    "x": 269201.9334,
    "y": 745761.4822
  },
  {
    "village_id": 4003,
    "name_en": "El-rom ",
    "name_ar": "إيل روم",
    "name_ru": "El-rom ",
    "name_he": "אל-רום ",
    "x": 272350.3253,
    "y": 787208.6914
  },
  {
    "village_id": 4004,
    "name_en": "Kefar haruv ",
    "name_ar": "كفار حَروڤ",
    "name_ru": "Kefar haruv ",
    "name_he": "כפר חרוב ",
    "x": 262428.896,
    "y": 740913.3783
  },
  {
    "village_id": 4005,
    "name_en": "Haspin ",
    "name_ar": "حِسپين",
    "name_ru": "Haspin ",
    "name_he": "חספין ",
    "x": 274600.7049,
    "y": 750229.2603
  },
  {
    "village_id": 4006,
    "name_en": "Qeshet ",
    "name_ar": "كيشِت",
    "name_ru": "Qeshet ",
    "name_he": "קשת ",
    "x": 276066.7334,
    "y": 764892.442
  },
  {
    "village_id": 4007,
    "name_en": "Yonatan ",
    "name_ar": "يونَتان",
    "name_ru": "Yonatan ",
    "name_he": "יונתן ",
    "x": 274574.2819,
    "y": 760335.4447
  },
  {
    "village_id": 4008,
    "name_en": "Ma'ale gamla ",
    "name_ar": "مَعلي چَملا",
    "name_ru": "Ma'ale gamla ",
    "name_he": "מעלה גמלא ",
    "x": 264355.4255,
    "y": 754912.6538
  },
  {
    "village_id": 4009,
    "name_en": "Sha'al ",
    "name_ar": "شاعَل",
    "name_ru": "Sha'al ",
    "name_he": "שעל ",
    "x": 267460.9753,
    "y": 780075.0962
  },
  {
    "village_id": 4010,
    "name_en": "Odem ",
    "name_ar": "أودِم",
    "name_ru": "Odem ",
    "name_he": "אודם ",
    "x": 270329.0868,
    "y": 788776.5343
  },
  {
    "village_id": 4011,
    "name_en": "Avne etan ",
    "name_ar": "أڤني إيتان",
    "name_ru": "Авне Этан",
    "name_he": "אבני איתן ",
    "x": 272057.1639,
    "y": 747902.0443
  },
  {
    "village_id": 4012,
    "name_en": "Ani'am ",
    "name_ar": "أنيعام",
    "name_ru": "Ani'am ",
    "name_he": "אניעם ",
    "x": 269577.4476,
    "y": 762675.9902
  },
  {
    "village_id": 4013,
    "name_en": "Ortal ",
    "name_ar": "أورتال",
    "name_ru": "Ortal ",
    "name_he": "אורטל ",
    "x": 271481.9442,
    "y": 776744.0033
  },
  {
    "village_id": 4014,
    "name_en": "Natur ",
    "name_ar": "نَتور",
    "name_ru": "Natur ",
    "name_he": "נטור ",
    "x": 270653.6588,
    "y": 751177.5768
  },
  {
    "village_id": 4015,
    "name_en": "Bene yehuda ",
    "name_ar": "بني يِهودا",
    "name_ru": "Бней Иегуда",
    "name_he": "בני יהודה ",
    "x": 264891.2605,
    "y": 744943.4363
  },
  {
    "village_id": 4017,
    "name_en": "Allone habashan ",
    "name_ar": "ألوني هبشان",
    "name_ru": "Allone habashan ",
    "name_he": "אלוני הבשן ",
    "x": 278701.2379,
    "y": 772040.9491
  },
  {
    "village_id": 4019,
    "name_en": "Mezar ",
    "name_ar": "مِتْسار",
    "name_ru": "Mezar ",
    "name_he": "מיצר ",
    "x": 269362.8127,
    "y": 741684.8387
  },
  {
    "village_id": 4021,
    "name_en": "Giv'at yo'av ",
    "name_ar": "چِڤعات يوآڤ",
    "name_ru": "Giv'at yo'av ",
    "name_he": "גבעת יואב ",
    "x": 264144.941,
    "y": 744973.1065
  },
  {
    "village_id": 4022,
    "name_en": "Geshur ",
    "name_ar": "چِشور",
    "name_ru": "Geshur ",
    "name_he": "גשור ",
    "x": 267329.1705,
    "y": 747316.2542
  },
  {
    "village_id": 4024,
    "name_en": "Qela ",
    "name_ar": "كيلع",
    "name_ru": "Qela ",
    "name_he": "קלע ",
    "x": 264456.0922,
    "y": 781996.7666
  },
  {
    "village_id": 4025,
    "name_en": "Qidmat zevi ",
    "name_ar": "كِدمات تْسڤي",
    "name_ru": "Qidmat zevi ",
    "name_he": "קדמת צבי ",
    "x": 265689.3042,
    "y": 770409.7506
  },
  {
    "village_id": 4026,
    "name_en": "Had-nes ",
    "name_ar": "حاد نيس",
    "name_ru": "Had-nes ",
    "name_he": "חד-נס ",
    "x": 259922.0134,
    "y": 759989.1864
  },
  {
    "village_id": 4028,
    "name_en": "Kanaf ",
    "name_ar": "كَناف",
    "name_ru": "Kanaf ",
    "name_he": "כנף ",
    "x": 265952.8108,
    "y": 753090.6167
  },
  {
    "village_id": 4029,
    "name_en": "Trump heights ",
    "name_ar": "رَمات ترامپ",
    "name_ru": "Trump heights ",
    "name_he": "רמת טראמפ ",
    "x": null,
    "y": null
  },
  {
    "village_id": 4035,
    "name_en": "Nimrod ",
    "name_ar": "نِمرود",
    "name_ru": "Nimrod ",
    "name_he": "נמרוד ",
    "x": null,
    "y": null
  },
  {
    "village_id": 4100,
    "name_en": "Qazrin ",
    "name_ar": "كَتْسرين",
    "name_ru": "Кацрин",
    "name_he": "קצרין ",
    "x": 265389.4708,
    "y": 766057.7314
  },
  {
    "village_id": 4101,
    "name_en": "Merom golan ",
    "name_ar": "مِروم چولان",
    "name_ru": "Merom golan ",
    "name_he": "מרום גולן ",
    "x": 272810.5103,
    "y": 782099.228
  },
  {
    "village_id": 4201,
    "name_en": "Majdal shams ",
    "name_ar": "مجدل شمس",
    "name_ru": "Мадждаль-Шамс",
    "name_he": "מג'דל שמס ",
    "x": 272000.9949,
    "y": 796891.5243
  },
  {
    "village_id": 4203,
    "name_en": "Mas'ade ",
    "name_ar": "مسعدة",
    "name_ru": "Mas'ade ",
    "name_he": "מסעדה ",
    "x": 270849.3837,
    "y": 793044.4879
  },
  {
    "village_id": 4204,
    "name_en": "Mevo hamma ",
    "name_ar": "مِڤو حَما",
    "name_ru": "Mevo hamma ",
    "name_he": "מבוא חמה ",
    "x": 261752.1711,
    "y": 738218.0347
  },
  {
    "village_id": 4301,
    "name_en": "Afiq ",
    "name_ar": "أفيك",
    "name_ru": "Afiq ",
    "name_he": "אפיק ",
    "x": 266147.4963,
    "y": 742757.6682
  },
  {
    "village_id": 4303,
    "name_en": "Newe ativ ",
    "name_ar": "نِڤي أتيڤ",
    "name_ru": "Newe ativ ",
    "name_he": "נווה אטי\"ב ",
    "x": 269539.4885,
    "y": 796400.3579
  },
  {
    "village_id": 4304,
    "name_en": "Nov ",
    "name_ar": "نوڤ",
    "name_ru": "Nov ",
    "name_he": "נוב ",
    "x": 273652.7232,
    "y": 748789.7763
  },
  {
    "village_id": 4501,
    "name_en": "Ghajar ",
    "name_ar": "غجر",
    "name_ru": "Ghajar ",
    "name_he": "ע'ג'ר ",
    "x": 258461.6232,
    "y": 797294.9593
  },
  {
    "village_id": 4502,
    "name_en": "Ein qiniyye ",
    "name_ar": "عين قنية",
    "name_ru": "Ein qiniyye ",
    "name_he": "עין קנייא ",
    "x": 268576.9893,
    "y": 793573.6516
  },
  {
    "village_id": 4503,
    "name_en": "En ziwan ",
    "name_ar": "عين زيڤان",
    "name_ru": "En ziwan ",
    "name_he": "עין זיוון ",
    "x": 274589.3336,
    "y": 777961.9906
  },
  {
    "village_id": 4551,
    "name_en": "Ne'ot golan ",
    "name_ar": "نِؤوت چولان",
    "name_ru": "Ne'ot golan ",
    "name_he": "נאות גולן ",
    "x": 265150.6084,
    "y": 743631.061
  },
  {
    "village_id": 4701,
    "name_en": "Ramat magshimim ",
    "name_ar": "رَمات مَچْشيميم",
    "name_ru": "Ramat magshimim ",
    "name_he": "רמת מגשימים ",
    "x": 275974.2283,
    "y": 750125.59
  },
  {
    "village_id": 4702,
    "name_en": "Ramot ",
    "name_ar": "رَموت",
    "name_ru": "Ramot ",
    "name_he": "רמות ",
    "x": 261150.801,
    "y": 751054.9212
  },
  {
    "village_id": 5000,
    "name_en": "Tel aviv - yafo ",
    "name_ar": "تل أبيب – يافا",
    "name_ru": "Тель-Авив Яффо",
    "name_he": "תל אביב - יפו ",
    "x": 180489.6796,
    "y": 665757.436
  },
  {
    "village_id": 6000,
    "name_en": "Baqa al-gharbiyye ",
    "name_ar": "باقة الغربية",
    "name_ru": "Бака-Аль-Гарбия",
    "name_he": "באקה אל-גרביה ",
    "x": 204103.4231,
    "y": 702912.7022
  },
  {
    "village_id": 6100,
    "name_en": "Bene beraq ",
    "name_ar": "بني براك",
    "name_ru": "Бней-Брак",
    "name_he": "בני ברק ",
    "x": 184664.9222,
    "y": 666370.5283
  },
  {
    "village_id": 6200,
    "name_en": "Bat yam ",
    "name_ar": "بات يام",
    "name_ru": "Бат-Ям",
    "name_he": "בת ים ",
    "x": 176423.4831,
    "y": 657968.8081
  },
  {
    "village_id": 6300,
    "name_en": "Giv'atayim ",
    "name_ar": "چِڤعتايم",
    "name_ru": "Гиватаим",
    "name_he": "גבעתיים ",
    "x": 182247.9274,
    "y": 664184.7933
  },
  {
    "village_id": 6400,
    "name_en": "Herzeliyya ",
    "name_ar": "هِرتسليا",
    "name_ru": "Герцлия",
    "name_he": "הרצליה ",
    "x": 184092.1638,
    "y": 675213.4927
  },
  {
    "village_id": 6500,
    "name_en": "Hadera ",
    "name_ar": "حَدِرا",
    "name_ru": "Хадера",
    "name_he": "חדרה ",
    "x": 191964.8863,
    "y": 705012.6646
  },
  {
    "village_id": 6600,
    "name_en": "Holon ",
    "name_ar": "حولون",
    "name_ru": "Холон",
    "name_he": "חולון ",
    "x": 179547.8525,
    "y": 657938.5887
  },
  {
    "village_id": 6700,
    "name_en": "Tiberias ",
    "name_ar": "تڤِريا",
    "name_ru": "Тверия",
    "name_he": "טבריה ",
    "x": 249488.8046,
    "y": 743591.7782
  },
  {
    "village_id": 6800,
    "name_en": "Qiryat atta ",
    "name_ar": "كِريات آتا",
    "name_ru": "Кирьят-Ата",
    "name_he": "קרית אתא ",
    "x": 210144.2812,
    "y": 745681.4784
  },
  {
    "village_id": 6900,
    "name_en": "Kefar sava ",
    "name_ar": "كفار سَڤا",
    "name_ru": "Кфар-Сава",
    "name_he": "כפר סבא ",
    "x": 192723.2892,
    "y": 676090.6946
  },
  {
    "village_id": 7000,
    "name_en": "Lod ",
    "name_ar": "اللد",
    "name_ru": "Лод",
    "name_he": "לוד ",
    "x": 189790.68,
    "y": 650979.2926
  },
  {
    "village_id": 7100,
    "name_en": "Ashqelon ",
    "name_ar": "أشكِلون",
    "name_ru": "Ашкелон",
    "name_he": "אשקלון ",
    "x": 159183.2739,
    "y": 618811.6413
  },
  {
    "village_id": 7200,
    "name_en": "Nes ziyyona ",
    "name_ar": "نيس تْسِيونا",
    "name_ru": "Нес-Циона",
    "name_he": "נס ציונה ",
    "x": 180734.4016,
    "y": 647841.026
  },
  {
    "village_id": 7300,
    "name_en": "Nazareth ",
    "name_ar": "الناصرة",
    "name_ru": "Назарет",
    "name_he": "נצרת ",
    "x": 227728.0189,
    "y": 734143.2878
  },
  {
    "village_id": 7400,
    "name_en": "Netanya ",
    "name_ar": "نِتنيا",
    "name_ru": "Нетания",
    "name_he": "נתניה ",
    "x": 186926.721,
    "y": 690287.2458
  },
  {
    "village_id": 7500,
    "name_en": "Sakhnin ",
    "name_ar": "سخنين",
    "name_ru": "Сахнин",
    "name_he": "סח'נין ",
    "x": 228479.8393,
    "y": 752272.2282
  },
  {
    "village_id": 7600,
    "name_en": "Akko ",
    "name_ar": "عكا",
    "name_ru": "Akko ",
    "name_he": "עכו ",
    "x": 208329.246,
    "y": 758645.1294
  },
  {
    "village_id": 7700,
    "name_en": "Afula ",
    "name_ar": "عفولا",
    "name_ru": "Афула",
    "name_he": "עפולה ",
    "x": 227459.6876,
    "y": 724073.5691
  },
  {
    "village_id": 7800,
    "name_en": "Pardes hanna-karkur ",
    "name_ar": "پَرْديس حَنا – كَرْكور",
    "name_ru": "Pardes hanna-karkur ",
    "name_he": "פרדס חנה-כרכור ",
    "x": 197819.0115,
    "y": 708950.571
  },
  {
    "village_id": 7900,
    "name_en": "Petah tiqwa ",
    "name_ar": "الفريديس",
    "name_ru": "Петах-Тиква",
    "name_he": "פתח תקווה ",
    "x": 189330.9033,
    "y": 666948.3729
  },
  {
    "village_id": 8000,
    "name_en": "Zefat ",
    "name_ar": "تْسفات",
    "name_ru": "Цфат",
    "name_he": "צפת ",
    "x": 247792.468,
    "y": 764037.3763
  },
  {
    "village_id": 8200,
    "name_en": "Qiryat motzkin ",
    "name_ar": "كِريات موتْسكين",
    "name_ru": "Кирьят-Моцкин",
    "name_he": "קרית מוצקין ",
    "x": 208058.4158,
    "y": 749680.3738
  },
  {
    "village_id": 8300,
    "name_en": "Rishon leziyyon ",
    "name_ar": "ريشون لِتْسِيون",
    "name_ru": "Ришон ле-Цион",
    "name_he": "ראשון לציון ",
    "x": 180285.3817,
    "y": 652959.1938
  },
  {
    "village_id": 8400,
    "name_en": "Rehovot ",
    "name_ar": "رِحوڤوت",
    "name_ru": "Реховот",
    "name_he": "רחובות ",
    "x": 182861.8575,
    "y": 644475.9211
  },
  {
    "village_id": 8500,
    "name_en": "Ramla ",
    "name_ar": "رملة",
    "name_ru": "Рамла",
    "name_he": "רמלה ",
    "x": 188065.9515,
    "y": 648252.092
  },
  {
    "village_id": 8600,
    "name_en": "Ramat gan ",
    "name_ar": "رَمات چان  ",
    "name_ru": "Рамат-Ган",
    "name_he": "רמת גן ",
    "x": 183799.2091,
    "y": 663693.3378
  },
  {
    "village_id": 8700,
    "name_en": "Ra'anana ",
    "name_ar": "رَعَنَنا",
    "name_ru": "Раанана",
    "name_he": "רעננה ",
    "x": 187849.7638,
    "y": 677143.1302
  },
  {
    "village_id": 8800,
    "name_en": "Shefar'am ",
    "name_ar": "شفاعمرو",
    "name_ru": "Shefar'am ",
    "name_he": "שפרעם ",
    "x": 216417.3184,
    "y": 745668.617
  },
  {
    "village_id": 8900,
    "name_en": "Tamra ",
    "name_ar": "طمرة ",
    "name_ru": "Tamra ",
    "name_he": "טמרה ",
    "x": 218174.2477,
    "y": 751017.3587
  },
  {
    "village_id": 9000,
    "name_en": "Be'er sheva ",
    "name_ar": "بِئير شيڤع",
    "name_ru": "Беэр-Шева",
    "name_he": "באר שבע ",
    "x": 179975.3102,
    "y": 573353.3219
  },
  {
    "village_id": 9100,
    "name_en": "Nahariyya ",
    "name_ar": "نهريا",
    "name_ru": "Нагария",
    "name_he": "נהריה ",
    "x": 209576.4694,
    "y": 768554.9245
  },
  {
    "village_id": 9200,
    "name_en": "Bet she'an ",
    "name_ar": "بيت شِآن",
    "name_ru": "Бейт-Шеан",
    "name_he": "בית שאן ",
    "x": 247138.8652,
    "y": 711634.0844
  },
  {
    "village_id": 9300,
    "name_en": "Zikhron ya'aqov ",
    "name_ar": "زيتان",
    "name_ru": "Зихрон-Яаков",
    "name_he": "זכרון יעקב ",
    "x": 196428.7174,
    "y": 719347.2143
  },
  {
    "village_id": 9400,
    "name_en": "Yehud-monoson ",
    "name_ar": "يِهود",
    "name_ru": "Йехуд-Моноссон",
    "name_he": "יהוד-מונוסון ",
    "x": 189533.9203,
    "y": 659908.3691
  },
  {
    "village_id": 9500,
    "name_en": "Qiryat bialik ",
    "name_ar": "كِريات بْياليك",
    "name_ru": "Кирьят Бялик",
    "name_he": "קרית ביאליק ",
    "x": 209407.525,
    "y": 749073.2659
  },
  {
    "village_id": 9600,
    "name_en": "Qiryat yam ",
    "name_ar": "كِريات يام",
    "name_ru": "Кирьят-Ям",
    "name_he": "קרית ים ",
    "x": 207045.023,
    "y": 750441.7036
  },
  {
    "village_id": 9700,
    "name_en": "Hod hasharon ",
    "name_ar": "هود هشَرون",
    "name_ru": "Ход-ха-Шарон",
    "name_he": "הוד השרון ",
    "x": 189170.0528,
    "y": 672983.6101
  },
  {
    "village_id": 9800,
    "name_en": "Binyamina ",
    "name_ar": "بِنيَمينا – چِڤعات عدا",
    "name_ru": "Биньямина-Гиват-Ада",
    "name_he": "בנימינה-גבעת עדה ",
    "x": 195299.4524,
    "y": 713965.2371
  },
  {
    "village_id": 3815,
    "name_en": "Tel tsiyon ",
    "name_ar": "تل تسيون",
    "name_ru": "ТЕЛЬ ЦИЙОН",
    "name_he": "תל ציון",
    "x": null,
    "y": null
  }
]
export {vilages};
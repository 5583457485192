
import { useIntervalFn } from '@vueuse/core';
import { ref } from 'vue';

// Create a reactive reference for the current time
const now = ref(Date.now());

// Use useIntervalFn to update `now` every second (or another suitable interval)
const { pause, resume } = useIntervalFn(() => {
     now.value = Date.now() },
     1000,
     { immediate: true }); // Start the interval function


export function useSlowReactiveTime() {
  return {
    now,
    resume,
    pause
  }
}



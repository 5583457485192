
const addParticipantAndSetup = async (puuid,participantData,setupData) => {
    try {
        console.log('CSAPI:addParticipantAndSetup',puuid,participantData,setupData);
        const response = await fetch('/api/participants_and_setups', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({puuid:puuid, pdata:participantData,sdata:setupData}),
        });
        console.log('addParticipant And Setup result response:', response);
        const data = await response.json();
        console.log('addParticipant And Setup saved:', data);
        return data; // Return data for further processing or confirmation
    } catch (error) {

        console.error('Error saving participant:', error);
    }
    };

const addReport = async (puuid,reportData) => {
  try {
      const response = await fetch('/api/reports', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },

          body: JSON.stringify({puuid,data:reportData}),
      });
      if (!response.ok) {
          throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log('Report saved:', data);
      return data; // For further processing or user feedback
  } catch (error) {
      console.error('Error saving report:', error);
  }
};

const addPhoto = async (puuid,ruuid,photo_uuid,is_pos,photo_data) => {
    try {
        const payload = {puuid,ruuid,photo_uuid,is_pos,photo_data};
        const response = await fetch('/api/photos', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },

            body: JSON.stringify(payload),
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log('Report saved:', data);
        return data; // For further processing or user feedback
    } catch (error) {
        console.error('Error saving report:', error);
    }
  };


export function useCSAPI() {
  return {
    addParticipantAndSetup,
    addReport,
    addPhoto
  }
}
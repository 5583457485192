<template>
  <v-container v-bind="$attrs">
    <span class="text-decoration-underline">Permissions</span><br/>
    push: {{pushIsSupported}} {{ pushPermission }}<br/>
    notifications: {{notificationsIsSupported}} {{ notificationsPermission}}<br/>
    camera: {{cameraIsSupported}} {{ cameraPremission}}<br/>
    geolocation: {{geolocationIsSupported}} {{ geolocationPremission}}<br/>
    microphone: {{microphoneIsSupported}} {{ microphonePremission}}<br/>
  </v-container>
</template>

<script setup>
  import { usePermission } from '@vueuse/core';

  const {state:pushPermission, isSupported:pushIsSupported} = usePermission('push',{controls : true});
  const {state:notificationsPermission, isSupported:notificationsIsSupported} = usePermission('notifications',{controls : true});
  const {state:cameraPremission, isSupported:cameraIsSupported} = usePermission('camera',{controls : true});
  const {state:geolocationPremission, isSupported:geolocationIsSupported} = usePermission('geolocation',{controls : true});
  const {state:microphonePremission, isSupported:microphoneIsSupported} = usePermission('microphone',{controls : true});

</script>

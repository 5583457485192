/* eslint-disable no-console */
import { useServiceWorker } from '@/services/useServiceWorker'
import { register } from 'register-service-worker'
const {swLifecycleEvent} = useServiceWorker();
// import { useGlobalCircularLog } from './composables/useGlobalCircularLog';
// import uuidController from './controllers/UUIDController';
// import languageController from './controllers/LanguageController';
// const gLog=useGlobalCircularLog(20);




if (process.env.NODE_ENV === 'production') {
  swLifecycleEvent("registering");
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      swLifecycleEvent("ready");
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    async registered () {
      //gLog.addToLog('in registerServiceWorker:registered');
      swLifecycleEvent("registered");
      //gLog.addToLog('after swLifecycleEvent');
      console.log('Service worker has been registered.');

      // Now that the service worker is registered, let's subscribe to push notifications.
      /*
      navigator.serviceWorker.ready.then(function(registration) {
        return registration.pushManager.getSubscription().then(async function(subscription) {
          if (subscription) {
            return subscription;
          }
          const response = await fetch('./vapidPublicKey');
          const vapidPublicKey = await response.text();
          const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);
          return registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: convertedVapidKey
          });
        }).then(function(subscription) {
          // Send the subscription to the server
          fetch('./register', {
            method: 'post',
            headers: {
              'Content-type': 'application/json'
            },
            body: JSON.stringify({
              subscription: subscription
            }),
          });
        });
      });
      */

        //same as the commented block above but using await.
        // try {

        //   const registration = await navigator.serviceWorker.ready;
        //   gLog.addToLog('ready resolved');
        //   let subscription = await registration.pushManager.getSubscription();
        //   gLog.addToLog('getSubscription resolved');
        //   if (!subscription) {
        //     gLog.addToLog('no subscription');
        //     const response = await fetch('/api/push/vapidPublicKey');
        //     gLog.addToLog('fetched /api/push/vapidPublicKey');
        //     const vapidPublicKey = await response.text();
        //     gLog.addToLog('got key');
        //     const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);
        //     gLog.addToLog('converted to base64');
        //     subscription = await registration.pushManager.subscribe({
        //       userVisibleOnly: true,
        //       applicationServerKey: convertedVapidKey
        //     });
        //     gLog.addToLog('called subscribe');
        //     localStorage.setItem('push_vapidPublicKey',vapidPublicKey);
        //   }
        //   gLog.addToLog(JSON.stringify(subscription)); // {endpoint:'...',expirationTime:null,keys:{p256dh:"...",auth:"..."}}
        //   localStorage.setItem('push_subscription',JSON.stringify(subscription));
        //   gLog.addToLog('getting puuid');
        //   const puuid=uuidController.getUUID();
        //   gLog.addToLog('puuid:' + puuid);
        //   if (null != puuid) {
        //     gLog.addToLog('calling api/push/register');
        //     await fetch('/api/push/register', {
        //       method: 'post',
        //       headers: {
        //         'Content-type': 'application/json'
        //       },
        //       body: JSON.stringify({
        //         puuid:puuid,
        //         subscription: subscription,
        //         lang:languageController.getLanguage(),
        //         user_time_zone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone
        //       }),
        //     });
        //   }
        // } catch (error) {
        //   gLog.addToLog('lastError:'+error.toString());
        //   localStorage.setItem('lastError',error.toString());
        //   swLifecycleEvent("error");
        //   console.error('Error during service worker registration or push subscription:', error);
        // }
        // gLog.addToLog('done');
    },
    cached () {
      swLifecycleEvent("cached");
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      swLifecycleEvent("updatefound");
      console.log('New content is downloading.')
    },
    updated () {
      swLifecycleEvent("updated");
      console.log('New content is available; please refresh.')
    },
    offline () {
      swLifecycleEvent("offline");
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      swLifecycleEvent("error");
      console.error('Error during service worker registration:', error)
    }
  })
}

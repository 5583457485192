<template>
    <v-container>
      <v-row justify="center">
        <v-col cols="12" sm="6">
          <v-row justify="center">
              <v-btn @click="OpenCamera">Capture Photo</v-btn>
              <v-btn @click="capturePhoto">Capture Photo</v-btn>
          </v-row>
          <v-row justify="center">
            <video ref="video" width="320" height="240" style="xdisplay: none;"></video>
            <canvas ref="canvas" width="320" height="240" style="xdisplay: none;"></canvas>
            <img v-if="capturedImage" :src="capturedImage" alt="Captured Image" />
            <textarea v-model="capturedImage">
            </textarea>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </template>

  <script>
  export default {
    data() {
        return {
        capturedImage: null, // Property to hold the captured image data URL
        stream:null
        };
    },
    async mounted() {
        if (!navigator.mediaDevices) {
            console.log('No navigator.mediaDevices');
            return;
        }

          this.stream = await navigator.mediaDevices.getUserMedia({ video: true });
          this.$refs.video.srcObject = this.stream;
          this.$refs.video.play();

          // Wait for the video to start playing
          await new Promise(resolve => this.$refs.video.addEventListener('play', resolve));

    },
    methods: {
      async capturePhoto() {
        try {

          // Draw the video frame to the canvas
          this.$refs.canvas.getContext('2d').drawImage(this.$refs.video, 0, 0, 320, 240);

          // Convert canvas to Data URL
          const photoData = this.$refs.canvas.toDataURL('image/png');
          this.capturedImage = photoData;

          // Stop the video stream
          this.stream.getTracks().forEach(track => track.stop());

          // Store in local storage
          localStorage.setItem('capturedPhoto', photoData);

          console.log('Photo captured and stored in local storage');
//          this.$router.replace('/CaptureSoundLevel');
        } catch (err) {
          console.error('Error capturing photo:', err);
        }
      },
    },
  };
  </script>

  <style>
  /* Add your styles here */
  </style>

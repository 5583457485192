import { ref } from 'vue';

import { createRouter, createWebHashHistory } from 'vue-router'
import RedirectStack from './RedirectStack'
//import HomeView from '../views/HomeView.vue'
//import ComingSoonView from '../views/ComingSoonView.vue'
import ChooseLangView from '../views/LanguageSelectionView'
import Sheelon1View from '../views/Sheelon1View'
import Sheelon2View from '../views/Sheelon2View'
import OngoingFeedback from '../views/OngoingFeedback'
import FinalFeedback from '../views/FinalFeedback'

import TranslationStatusView from "../views/TranslationStatusView"

import TakePhotoView from '../views/TakePhotoView'

import languageController from '../controllers/LanguageController.js';
import uuidController from '@/controllers/UUIDController';

// import { useCircularLog} from '@/composables/useCircularLog';
// const {addToLog/*, clearLog*/, logs} = useCircularLog(10);
// const dbg = (s) => {
//   addToLog(s);
//   localStorage.setItem('dbg',JSON.stringify(logs.value));
// }
// const version=process.env.VUE_APP_VERSION;
//dbg('router/index.js: Log stated for version ' + version);

import { useServiceWorker} from '@/services/useServiceWorker';
import { usePermission } from '@vueuse/core'
const {isUpdateAvailable ,updateServiceWorkerNow} = useServiceWorker();

import { useSubscribeToPushNotifications} from '@/composables/useSubscribeToPushNotifications';
const {subscribeToPushNotifications} = useSubscribeToPushNotifications();

//dbg('router/index.js: defining routes');

const chooseLangRoute= {
  path: '/lang',
  name: 'chooseLang',
  component: ChooseLangView
};

const GeneralInfoRoute = {
  path: '/info',
  name: 'generalInfo',
    // route level code-splitting
    // this generates a separate chunk (generalInfo.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "generalInfo" */ '../views/GeneralInfoView.vue')
}

const ContactRoute = {
  path: '/contact',
  name: 'Contact',
    // route level code-splitting
    // this generates a separate chunk (Contact.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Contact" */ '../views/ContactView.vue')
}

const ConsentFormRoute = {
  path: '/consent-form',
  name: 'consentForm',
    // route level code-splitting
    // this generates a separate chunk (generalInfo.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "consentForm" */ '../views/ConsentFormView.vue')
}

/*
const LocationPermissionsRoute = {
  path: '/location-permissions',
  name: 'locationPermissions',
    // route level code-splitting
    // this generates a separate chunk (generalInfo.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "locationPermissions" * / '../views/LocationPermissionsView.vue')
}
*/
/*
const CameraPermissionsRoute = {
  path: '/camera-permissions',
  name: 'cameraPermissions',
    // route level code-splitting
    // this generates a separate chunk (generalInfo.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "cameraPermissions" * / '../views/CameraPermissionsView.vue')
}
*/
const Sheelon1Route = {
  path: '/sh1',
  name: 'Sheelon1',
  component: Sheelon1View
}

const Sheelon2Route = {
  path: '/sh2',
  name: 'Sheelon2',
  component: Sheelon2View
}

const TranslationStatusRoute = {
  path: '/TRS',
  name: 'TranslationStatus',
  component: TranslationStatusView
}


const OngoingFeedbackRoute = {
  path: '/ongoing-feedback',
  name: 'OngoingFeedback',
  component: OngoingFeedback
}

const FinalFeedbackRoute = {
  path: '/final-feedback',
  name: 'FinalFeedback',
  component: FinalFeedback
}


// const GeneralInfoRoute = {
//   path: '/info/:screen',
//   name: 'generalInfo',
//     // route level code-splitting
//     // this generates a separate chunk (generalInfo.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "generalInfo" */ '../views/ScreenPlaceHolderView.vue')
// }


const routes = [
  //TODO:remove in final product
  TranslationStatusRoute,
  chooseLangRoute, GeneralInfoRoute,ConsentFormRoute, /*LocationPermissionsRoute, CameraPermissionsRoute, */
  Sheelon1Route, Sheelon2Route,  OngoingFeedbackRoute, FinalFeedbackRoute,
  ContactRoute,
  {
    path: '/takePhoto',
    name: 'takePhoto',
    component: TakePhotoView
  },

  // {
  //   path: '/',
  //   name: 'commingsoon',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "commingsoon" */ '../views/ComingSoonView.vue')
  // },

  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/notification',
    name: 'notification',
    // route level code-splitting
    // this generates a separate chunk (notification.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "notification" */ '../views/NotificationView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})



router.beforeEach((to, from, next) => {
  console.debug('ROUTER: beforeEach from ' + from.path + ' => to ' + to.path) ;
  //dbg('ROUTER: beforeEach from ' + from.path + ' => to ' + to.path);

  // Initial logic:
  // 1. If there is no langauge choosen goto language choosing view and than continue to original path
        //if the original path was the languageController and the stack was empty
        //we will try to go to the recurring reporting screen

  // 2. if no UUID exists then set one.
    // 3. If Introduction was not done goto introduction
    //    (i.e. general, concent, location and picture taking guidelines and permissions)
  // 4. if initial form was not filled goto initial form.
  // 5. if no alerts are set - set them.
  // 6. is it a an active reporting window?
  //    No: show some text about when would be the next notification window
  //    Yes: Was a report submitted?
  //        Yes: Display thanks and notification on the next window
  //        No: Display feporting form
  if ((to.path == chooseLangRoute.path) ||
      (to.path == Sheelon2Route.path) ||
      (to.path == Sheelon1Route.path) ) {
        if (isUpdateAvailable.value) {
          updateServiceWorkerNow();
        }
  }

  if (to.path=="/") {
    next(Sheelon2Route.path);
    return;
  }

  if (to.path == chooseLangRoute.path) {
    if (RedirectStack.isEmpty()) {
      RedirectStack.push(Sheelon2Route.path);
    }
    //allow
    next();
    return;
  }

  if (to.path==ContactRoute.path) {
    console.debug('ROUTER: to path approved for rendering (from ) ' + from.path + ' => to ' + to.path ) ;
    //dbg('ROUTER: to path approved for rendering (from ) ' + from.path + ' => to ' + to.path) ;
    next();
    return;
  }


  // and if you haven't chosen a language you are sent to this screen.
  const code = languageController.getLanguage();
  if (code =='') {
    console.debug('ROUTER: beforeEach from ' + from.path + ' => to ' + to.path + ' language code not found') ;
    //dbg('ROUTER: beforeEach from ' + from.path + ' => to ' + to.path + ' language code not found') ;
    RedirectStack.push(Sheelon2Route.path);
    next(chooseLangRoute.path);
    return ;
  }

  // those are now allowed
  if (to.path==GeneralInfoRoute.path || to.path==ConsentFormRoute.path) {
    console.debug('ROUTER: to path approved for rendering (from ) ' + from.path + ' => to ' + to.path) ;
    //dbg('ROUTER: to path approved for rendering (from ) ' + from.path + ' => to ' + to.path) ;
    next();
    return;
  }

  const uuid =uuidController.getUUID();
  if (!uuidController.isValidUUID(uuid)) {
    //only when the user agrees to the concent form (in ConsentFormView.vue )
    //will it set a UUID (useing uuidController.setUUID(uuidController.createUUID());
    //and this if statement would be skipped and additional routes become available.
    console.debug('ROUTER: ' + from.path + ' => to ' + to.path + ' added UUID continueing') ;
    //dbg('ROUTER: ' + from.path + ' => to ' + to.path + ' added UUID continueing') ;
    next(GeneralInfoRoute.path);
    return ;
  }

  // after concent
  console.debug('ROUTER: ' + from.path + ' => to ' + to.path + ' UUID is: ' + uuid) ;
  //dbg('ROUTER: ' + from.path + ' => to ' + to.path + ' UUID is: ' + uuid) ;
/*
  if (to.path==LocationPermissionsRoute.path) {
    console.debug('ROUTER: to path approved for rendering (from ) ' + from.path + ' => to ' + to.path) ;
    //dbg('ROUTER: to path approved for rendering (from ) ' + from.path + ' => to ' + to.path) ;
    next();
    return;
  }
*/
  if (to.path==Sheelon1Route.path) {
    console.debug('ROUTER: to path approved for rendering (from ) ' + from.path + ' => to ' + to.path) ;
    //dbg('ROUTER: to path approved for rendering (from ) ' + from.path + ' => to ' + to.path) ;
    next();
    return;

  }

  if (localStorage.getItem("formData")==null) {
    //dbg('going to Sheelon1')
    next(Sheelon1Route.path);
    return;
  }

  //dbg('Befor notifications block');
  // enable push notifications after the user filled the initial form (sheelon1)
  // and said that he wants notifications
  if (null==localStorage.getItem('userWantsPusNotifications')) {
    localStorage.setItem('userWantsPusNotifications',JSON.stringify(false));
  }
  try {
    const userWantsPushNotifications=ref(JSON.parse(localStorage.getItem('userWantsPusNotifications')));
    if (userWantsPushNotifications.value) {
      let notificationsPermissions=usePermission('notifications');
      if (notificationsPermissions.value=='granted') {
        subscribeToPushNotifications();
      }
    }
  }
  catch (error) {
    console.log('an error has occured during preparation for push notifications');
  }
  // will happen when the user check the checkbox on sh2 phase 0

  if (to.path==Sheelon2Route.path) {
    console.debug('ROUTER: to path approved for rendering (from ) ' + from.path + ' => to ' + to.path) ;
    //dbg('ROUTER: to path approved for rendering (from ) ' + from.path + ' => to ' + to.path) ;
    next();
    return;

  }

  // now lets make sure you can not reach sheelon2 until you did shelon1

  console.log('something else');

  // Your custom logic here
  if (to.path=='/') { next(); return; } // no checks for the home page

  // if (shouldNavigateElsewhere(to)) {
  //   next({ name: 'AnotherRoute' }); // Redirect to a different route
  // } else {
  //   next(); // Continue to the intended route
  // }

  next();
  return;
});

//function shouldNavigateElsewhere(route) {
  // Implement your logic, return true or false
//}

export default router

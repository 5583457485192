function resetRedirectStack() {
  console.log('resetting Redirect Stack');
  const stack = [];
  localStorage.setItem('redirectStack', JSON.stringify(stack));
}

function pushToRedirectStack(path) {
  console.log('Adding ', path ,  ' to RedirectStack');
  const stack = JSON.parse(localStorage.getItem('redirectStack')) || [];
  stack.push(path);
  localStorage.setItem('redirectStack', JSON.stringify(stack));
}

function popFromRedirectStack() {
  const stack = JSON.parse(localStorage.getItem('redirectStack')) || [];
  const path = stack.pop();
  console.log('popped ', path ,  ' from RedirectStack');
  localStorage.setItem('redirectStack', JSON.stringify(stack));
  return path;
}

function lengthOfRedirectStack() {
  const stack = JSON.parse(localStorage.getItem('redirectStack')) || [];
  console.log('RedirectStack length is ' + stack.length);
  return stack.length;
}

function isEmptyRedirectStack() {
  var stackIsEmpty= lengthOfRedirectStack()==0;
  console.log('RedirectStack is ' + (stackIsEmpty?'':' not') + ' empty');
  if (!stackIsEmpty) console.log('Redirect Stack:' + JSON.parse(localStorage.getItem('redirectStack')) || [] )
  return stackIsEmpty;
}

export default {
  reset:resetRedirectStack,
  push:pushToRedirectStack,
  pop:popFromRedirectStack,
  length:lengthOfRedirectStack,
  isEmpty:isEmptyRedirectStack,
}
import { ref } from 'vue';

const isInstalled=ref(false);
const resetInstalled=()=> {
  isInstalled.value = (window.matchMedia('(display-mode: standalone)').matches ||
                      (window.navigator.standalone === true) );
}
const setInstalled=(value)=> {
  isInstalled.value = value;
}

resetInstalled();

export function usePWAIsInstalled() {
    return { isInstalled, setInstalled, resetInstalled };
}
/*
import {usePWAIsInstalled} from '@/composables/usePWAIsInstalled'
const { isInstalled, setInstalled, resetInstalled } = usePWAIsInstalled();
*/

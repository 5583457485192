import {UseRandomReportGenerator} from '@/services/debug/RandomReportGenerator';
  const {generateRandomReport} = UseRandomReportGenerator();

import {useReportingWindowsManager} from '@/services/ReportingWidnowsManager';
  const {loadSetup} = useReportingWindowsManager();

import {useReportsManager} from '@/services/ReportsManager'
  const {dependsOnLS,getReports, addReport, deleteLastReport } = useReportsManager();

//import { useRouter } from 'vue-router';


import {usePermission} from '@vueuse/core';
  const notificationsPermission = usePermission('notifications')

import { useWebNotifications } from '@/composables/useWebNotifications';
const notify = useWebNotifications();


//import { useWebNotification } from '@vueuse/core';
import { useServiceWorker } from '@/services/useServiceWorker';
 const {showNotification} = useServiceWorker();

const resetAll = () => {
//  const router = useRouter();
  localStorage.clear();
//  router.replace('/');
}

const addRandomReport = () => {
  loadSetup();
  let idx= getReports(dependsOnLS).length; // just for consistancy dependsOnLS does not do anything here as no reactivity is required
  if (idx !=-1) {
    let RR=generateRandomReport();
    console.log('adding random report @ idx' , idx , RR);
    addReport(idx,RR);

    alert('random report ' + idx + ' added ')
  }
};

const debug_deleteLastReport = () => {
  deleteLastReport();
  alert('there are now ' + getReports(dependsOnLS).length + ' reports'); // just for consistancy dependsOnLS does not do anything here as no reactivity is required
};

const sendNotificationNow = async () => {
    if (notify.isSupported) {
      alert('notify.isSupported.value=' + notify.isSupported.value);
      if (notify.premissionStatus.value=='prompt') {
        await notify.requestPermissions();
      }
      alert('notify.premissionStatus.value=' + notify.premissionStatus.value);
      if (notify.premissionStatus.value!='granted') {
        alert(notify.premissionStatus.value);
        return;
      }
      let result = await notify.setUpANotification();
      alert('result from  call to setUpANotification' + JSON.stringify(result));
    }
  //   return {
  //     RPLog,
  //     isSupported,
  //     premissionStatus,
  //     setUpANotification

  //   }
  // }


  //showNotification("This is a test", {});
/*
  const {
    isSupported,
//    notification,
    show,
//    close,
//    onClick,
//    onShow,
//    onError,
//    onClose,
  } = useWebNotification({
    title: 'Hello, VueUse world!',
    dir: 'auto',
    lang: 'en',
    renotify: true,
    tag: 'test',
  })
  if (isSupported.value) show()
*/
}

const scheduleNotification30Sec = () => {
  showNotification("בודק הודעה בעברית", {body:'טקסט בגוף ההודעה', dir:'rtl', vibrate:[100,100,500,100,100]},30000);
/*  const {
    isSupported,
//    notification,
    show,
    // close,
    // onClick,
    // onShow,
    // onError,
    // onClose,
  } = useWebNotification({
    title: 'Hello, VueUse world!',
    dir: 'auto',
    lang: 'en',
    renotify: true,
    tag: 'test',
  })
  if (isSupported.value) show()
*/
}

// Helper function to add or subtract days from a date
function addDays(date, days) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

// Utility function to modify the reportingScheduleSetup entry by a specified number of days
function updateReportingSchedule(daysOffset) {
  const reportingScheduleSetup = JSON.parse(localStorage.getItem('reportingScheduleSetup'));

  // Modify the joinDateTimeInfo object
  const joinDateTimeInfo = reportingScheduleSetup.joinDateTimeInfo;
  const localDateObj = new Date(
    joinDateTimeInfo.local_fullYear,
    joinDateTimeInfo.local_month,
    joinDateTimeInfo.local_date,
    joinDateTimeInfo.local_hours,
    joinDateTimeInfo.local_minutes
  );
  const updatedLocalDateObj = addDays(localDateObj, daysOffset);

  joinDateTimeInfo.utc = updatedLocalDateObj.toISOString();
  joinDateTimeInfo.local_fullYear = updatedLocalDateObj.getFullYear();
  joinDateTimeInfo.local_month = updatedLocalDateObj.getMonth();
  joinDateTimeInfo.local_date = updatedLocalDateObj.getDate();
  joinDateTimeInfo.local_hours = updatedLocalDateObj.getHours();
  joinDateTimeInfo.local_minutes = updatedLocalDateObj.getMinutes();

  const updatedReportingScheduleSetup = JSON.stringify(reportingScheduleSetup);
  localStorage.setItem('reportingScheduleSetup', updatedReportingScheduleSetup);
  localStorage.setItem('dbg','In useDebugActions.js:updateReportingSchedule');
}


const  regDayBack = () => {
  updateReportingSchedule(-1);
}
const  regDayFwd = () => {
  updateReportingSchedule(1);
}

export function useDebugActions() {
  return {
    resetAll,
    addRandomReport,
    deleteLastReport:debug_deleteLastReport,
    sendNotificationNow,
    scheduleNotification30Sec,
    notificationsPermission,
    regDayBack,
    regDayFwd
  }
}
import {ref
  //, onMounted
} from 'vue';

const RPLog=ref([]);
const isSupported=ref(null);
const premissionStatus=ref(null);

// https://caniuse.com/?search=Notification
// in Safari version 16.3 - 17.4 on iOS the Notification API
// Requires website to first be added to the Home Screen

//see also
// https://stackoverflow.com/questions/41493866/firebase-on-ipads-safari-cant-find-variable-notification

if (('Notification' in window) &&
    ('serviceWorker' in navigator) &&
    ('PushManager' in window)) {
      isSupported.value=true;
      premissionStatus.value=Notification.permission;
    }
else {
  isSupported.value=false;
  premissionStatus.value=null;

}


const requestPermissions = async() => {
  if (isSupported.value==false) {
    return Promise.resolve('denied');
  }
  let result = await Notification.requestPermission();
  RPLog.value.push(JSON.stringify(result));
  RPLog.value.push(Notification.permission);
  return Notification.permission
}

const setUpANotification = async () => {
  //const now = Date.now();
  //const targetTime = new Date(now + 24 * 60 * 60 * 1000).setHours(8, 0, 0, 0);
  //const targetTime = new Date(now + 10 * 1000)
   const notificationOptions = {
     title: "Wake Up!",
     body: "It's time to start your day!",
     icon: "alarm.png",
   };

  const text = `HEY! Your task "${notificationOptions.title}" is now overdue.`;
  const notification = new Notification("To do list", { body: text,requireInteraction:true});
  setTimeout(() => {
    notification.close();
  },20*1000);
}


export function useWebNotifications(){
  return {
    RPLog,
    isSupported,
    requestPermissions,
    premissionStatus,
    setUpANotification
  }
}
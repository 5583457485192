import { useTimeUtils} from "@/services/TimeUtils";
const {enhanceWithLocalTime} = useTimeUtils();

import uuidController from "./UUIDController";
import { useReportsManager } from '@/services/ReportsManager.js'
  const {
    dependsOnLS,
    // getReport,
    getReports,
    // addReport,
    // timeLeftToSubmitCurrentReport,
    // missedPreviousReport,
    // submitedCurrentReport,
    // submitedCurrentOrPreviousReport
  } = useReportsManager();

import { useCSAPI } from '@/services/CSAPI';
const api = useCSAPI();

const getSyncStat = () => {
  let sync = JSON.parse(localStorage.getItem('sync')) || {participantAndSetup:null,reports:[],ver:6};
  if (sync.ver==null || sync.ver<6 ) { // means that sync did exist (or we would have gotten get the default)
    // clear all prior syncs to force re-sync of all.
    sync={participantAndSetup:null,reports:[],ver:6};
    updateSyncStat(sync);
  }
  if (sync.reports==null) {
    sync.reports=[];
    updateSyncStat(sync);
  }
  return sync;
}

const updateSyncStat= (sync)=>{
  localStorage.setItem('sync', JSON.stringify(sync));
}

const markParticipantAndSetupSynchronized = (date) => {
  const sync = getSyncStat();
  sync.participantAndSetup = enhanceWithLocalTime({},date);
  updateSyncStat(sync);
  return  true;
}

const markReportSynchronized = (idx,date) => {
  const sync = getSyncStat();
  sync.reports[idx] = enhanceWithLocalTime({},date);
  updateSyncStat(sync);
  return true;
}

const isParticipantAndSetupSynchronized = () => {
  console.log (' isParticipantAndSetupSynchronized called')
  const sync = getSyncStat();
  console.log (' isParticipantAndSetupSynchronized sync is ', sync, sync.participantAndSetup != null)
  return sync.participantAndSetup != null;
}

const isReportSynchronized = (idx) => {
  const sync = getSyncStat();
  console.log (' isReportSynchronized ' , idx , ' called sync is ', sync, 'sync.reports[idx]=' , sync.reports[idx] , sync.reports[idx] != null)

  return sync.reports[idx] != null;
}

const tryToSync = async (internalLog,force=false) => {

  let puuid=uuidController.getUUID();
  //make sure everything is in sync
  internalLog.addToLog('Trying To sync ' + (force?' by force':' if needed'));
  console.log('isParticipantAndSetupSynchronized():' , isParticipantAndSetupSynchronized());
  if (force || !isParticipantAndSetupSynchronized()) {
    console.log('!isParticipantAndSetupSynchronized => calling api.addParticipant ');
    let pdata=JSON.parse(localStorage.getItem('formData'));
    let sdata=JSON.parse(localStorage.getItem('reportingScheduleSetup'));
    try {
      internalLog.addToLog('calling  api.addParticipantAndSetup');
      internalLog.addToLog(puuid);
      internalLog.addToLog(pdata);
      internalLog.addToLog(sdata);

      await api.addParticipantAndSetup(puuid,pdata,sdata);
      markParticipantAndSetupSynchronized(new Date());
    }
    catch (error) {
        internalLog.addToLog('An error while trying to sync participant and setup');
        internalLog.addToLog(error);
        console.log('an error occured while calling api.addParticipant')
    }
  }

  if (isParticipantAndSetupSynchronized()) {
    console.log('checking for unSyncronizied Reports');
    var reports=getReports(dependsOnLS); // just for consistancy dependsOnLS does not do anything here as no reactivity is required
    for (let i=0; i<reports.length ; i++){
      if (reports[i] !==null) {
        console.log('report ', i , ' exists');
        if (force || !isReportSynchronized(i)) {
          try {
            console.log('report ', i , ' is not inSync');
            internalLog.addToLog('calling add report ' + i);
            let res = await api.addReport(puuid,reports[i]);
            console.log('result of calling api.addReport ' , res);
            if (res!==undefined) {
              console.log('checking there are photos in this report ');
              if (reports[i].pos_photo_uuid) {
                console.log('report['+i+'] has pos photo:' + reports[i].pos_photo_uuid );
                let pos_photo_data=JSON.parse(localStorage.getItem('photo_pos_' + reports[i].pos_photo_uuid));
                if (pos_photo_data) {
                    internalLog.addToLog('calling add photo for pos photo ' + reports[i].pos_photo_uuid);
                    console.log('calling add photo for pos photo ' + reports[i].pos_photo_uuid  );
                    let res = await api.addPhoto(puuid,reports[i].ruuid,reports[i].pos_photo_uuid,1,pos_photo_data);
                    console.log(res);
                }//puuid,ruuid,photo_uuid,is_pos,photo_data
              }
              if (reports[i].neg_photo_uuid) {
                internalLog.addToLog('calling add photo for neg photo ' + reports[i].neg_photo_uuid);
                console.log('report['+i+'] has neg photo:' + reports[i].neg_photo_uuid );
                let neg_photo_data=JSON.parse(localStorage.getItem('photo_neg_' + reports[i].neg_photo_uuid));
                if (neg_photo_data) {
                    let res = await api.addPhoto(puuid,reports[i].ruuid,reports[i].neg_photo_uuid,0,neg_photo_data);
                    console.log(res);
                }
              }
              markReportSynchronized(i,new Date());
            }
          }
          catch (error) {
            internalLog.addToLog('Error sending a report or a photo in sheelon2View');
            internalLog.addToLog(error);
            console.log('Error sending a report or a photo in sheelon2View' , error);
          }
        } else {
          console.log('report ', i , ' is inSync');
        }
      }
    }
  }

}




export function useSyncController() {
  return {
    isParticipantAndSetupSynchronized,
    markParticipantAndSetupSynchronized,
    isReportSynchronized,
    markReportSynchronized,
    tryToSync
  }
}
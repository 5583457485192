<template>
  <div>
    <v-checkbox v-model="userWantsPushNotifications">
      <template #label>
        <slot name="label">
            Send me notifications when its time to report
        </slot>
      </template>
      <template #details>
        <div v-if="notificationsPermission=='prompt' && !userWantsPushNotifications"><slot name="explainPermissions">Your device will prompt you for permissions</slot></div>
        <div v-if="notificationsPermission=='denied' && userWantsPushNotifications"><slot name="permissionsDenied">You have denied push permissions.<br/>To get notifications you must modify the permissions via your device.</slot></div>
      </template>
    </v-checkbox><br/>
  </div>
</template>
<script setup>

  import  {watch} from 'vue';
  import { usePermission } from '@vueuse/core';
  import { useSubscribeToPushNotifications} from '@/composables/useSubscribeToPushNotifications';
  const {subscribeToPushNotifications,unSubscribeToPushNotifications} = useSubscribeToPushNotifications();
  const notificationsPermission = usePermission('notifications');
  const userWantsPushNotifications = defineModel({type: Boolean})

  //is supported?
  //has permissions?
  //requests notifications?
  //const checkboxLabel=ref();

/*
  function askPermission() {
    return new Promise(function (resolve, reject) {
      const permissionResult = Notification.requestPermission(function (result) {
        resolve(result);
      });

      if (permissionResult) {
        permissionResult.then(resolve, reject);
      }
    }).then(function (permissionResult) {
      if (permissionResult !== 'granted') {
        throw new Error("We weren't granted permission.");
      }
      console.log('permissionResult=',permissionResult)
    });
  }
*/
  function askPermission() {
    return new Promise(function (resolve, reject) {
      const permissionResult = Notification.requestPermission(function (result) {
        resolve(result);
      });

      if (permissionResult) {
        permissionResult.then(resolve, reject);
      }
    }).then(function (permissionResult) {
      if (permissionResult !== 'granted') {
        throw new Error("We weren't granted permission.");
      }
      console.log('permissionResult=',permissionResult)
    });
  }

  // Emit update event to the parent when the internal value changes
  watch(userWantsPushNotifications, async (newVal) => {
    console.log('userWantsPushNotifications changed to ' + newVal)
    if (newVal) {
        await askPermission(); // for Notifications
        await subscribeToPushNotifications(); // for Push
    }
    else {
      unSubscribeToPushNotifications();
    }
  });
</script>
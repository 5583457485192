import {ref} from 'vue'
const status=ref(JSON.parse(localStorage.getItem('allow_alerts') || 'false'));
const set_alerts=(new_status)=> {
  localStorage.setItem('allow_alerts',!!new_status);
  status.value=new_status;
}
const c_alert=(msg)=>{
  if (status.value) {
    alert(msg);
  }
};

export function useConditionalAlerts() {
  return { c_alert , set_alerts }
}

/*
import {useConditionalAlerts} from '@/composables/useConditionalAlerts';
const { c_alert , set_alerts } = useConditionalAlerts();
*/
import { ref, computed } from 'vue';
import { useTimestamp } from '@vueuse/core';

// Reactive state for the stopwatch
const startTime = ref(0);
const elapsed = ref(0);
const running = ref(false);

// Using useTimestamp with controls
const { timestamp, pause, resume } = useTimestamp({ controls: true, offset: 0 });

// Computed property for elapsed time
const elapsedTime = computed(() => {
  return running.value ? timestamp.value - startTime.value : elapsed.value;
});

// Methods to control the stopwatch
const start = () => {
  if (!running.value) {
    startTime.value = timestamp.value;
    running.value = true;
    resume(); // Start updating the timestamp
  }
};

const stop = () => {
  if (running.value) {
    elapsed.value = timestamp.value - startTime.value;
    running.value = false;
    pause(); // Stop updating the timestamp
  }
};

const reset = () => {
  elapsed.value = 0;
  running.value = false;
  pause(); // Ensure the timestamp is not updating
};

export function useStopWatch() {
  return {
    elapsedTime,
    start,
    stop,
    reset
  }
}
function toMySQLFormat(date) {
  return date.getUTCFullYear() + '-' +
      ('00' + (date.getUTCMonth()+1)).slice(-2) + '-' +
      ('00' + date.getUTCDate()).slice(-2) + ' ' +
      ('00' + date.getUTCHours()).slice(-2) + ':' +
      ('00' + date.getUTCMinutes()).slice(-2) + ':' +
      ('00' + date.getUTCSeconds()).slice(-2) + '.' +
      ('000' + date.getUTCMilliseconds()).slice(-3);
}

function fromMySQLFormat(mysqlStr) {
  // Replace space with 'T' and append 'Z' to indicate UTC time zone
  const isoStr = mysqlStr.replace(' ', 'T') + 'Z';
  return new Date(isoStr);
}

/**
 * Adds the fields utc, local_fullYear,local_month,local_date,local_hours,local_minutes
 * to the given object.
 * if the optional date element is provided it is used to determine the time, otherwise the current time is used.
 */

const enhanceWithLocalTime = (o,date) => {
  let d=date || new Date();
  o.utc=d.toISOString();
  o.local_fullYear=d.getFullYear();
  o.local_month=d.getMonth();
  o.local_date=d.getDate();
  o.local_hours=d.getHours();
  o.local_minutes=d.getMinutes();
  return o;
}

export function toDDMMYYYYHHMM(date) {
  // Function to ensure each part of the date is in two digits
  console.log('date=',date);
  const pad = (number) => number < 10 ? `0${number}` : number;

  // Manually constructing the format
  const day = pad(date.getDate());
  const month = pad(date.getMonth() + 1); // Month is 0-indexed
  const year = date.getFullYear();
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());

  const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;

  return formattedDate;
}

export function useTimeUtils() {
  return {
    enhanceWithLocalTime,
    toMySQLFormat,
    fromMySQLFormat,
    toDDMMYYYYHHMM
  }
}


/*
//usage:

import {useTimeUtils} from '@/services/TimeUtils';
const { enhanceWithLocalTime,
    toMySQLFormat,
    fromMySQLFormat} = useTimeUtils();
*/
<template>
  <v-container class="rounded-lg" style="direction:ltr;background-color:aquamarine">
    <v-row><ArrayVisualizer :array="theReports" :count="windowsToVisualize" ></ArrayVisualizer></v-row>
  </v-container>

</template>

<script setup>
  import {computed} from 'vue';
  import ArrayVisualizer from '@/components/ArrayVisualizer';
  import {useReportsManager} from '@/services/ReportsManager';
  import { useReportingWindowsManager } from '@/services/ReportingWidnowsManager';
  const {
    //nextReportWindowHour,
    previousReportingWindowIndex,
    currentReportingWindowIndex,
    //nextReportingWindowIndex,
  } = useReportingWindowsManager();

  const {dependsOnLS,getReports/*,submitedCurrentReport*/} = useReportsManager();
  const theReports = computed(()=>getReports(dependsOnLS.value));
  const windowsToVisualize = computed(()=>Math.max(currentReportingWindowIndex.value,previousReportingWindowIndex.value,getReports().length))
</script>
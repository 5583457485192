<template>
  <v-container>
    <v-sheet color="white" dir="ltr">
      Translation Status::<br/>
      <!--div class="d-flex ga-20">
         <div class="flex-column flex-shrink-1 xflex-grow-1" cols="2" v-for="(val,key) in languageResources" :key="key">
          <v-row>{{ key }}</v-row>
          <v-row>{{ val }}</v-row>

        </div>
      </div>
      <div-->
      <div v-for="(value, key) in comparisonResults" :key="key">
        <h3>{{ key }} comparison:</h3>
        <ul>
          <template v-for="(result, lang) in value" :key="lang">
            <li v-if="result.type=='Missing key'" >
                {{ lang }}: {{ result.type }} {{ result.info }}
            </li>
            <li v-if="result.type=='Identical values'" >
                {{ lang }}: {{ result.type }} {{ result.info }}
            </li>

          </template>
        </ul>
      </div>
    </v-sheet>
  </v-container>

  <div>
    <!-- Your component template here, possibly using the loaded resources -->
  </div>
</template>

<script setup>
/* view this page in http://10.0.0.175:8080/#/TRS */

import { ref,computed, onMounted } from 'vue';

// Assuming you know which languages are available
const languages = ['en', 'ar', 'he', 'ru'];
const languageResources = ref({});

const referenceLanguage = 'he'; // The language to compare against

onMounted(async () => {
    await loadLanguageResources();
});

async function loadLanguageResources() {
    const imports = languages.map(lang =>
        import(`../plugins/lang/${lang}.js`).then(module => {
            languageResources.value[lang] = module[lang];
        })
    );

    // Wait for all imports to finish
    await Promise.all(imports);
}

// Function to compare two objects for the same keys and values
function compareLanguages(referenceObj, targetObj) {
    const differences = {};
    const checkKeys = (base, compare, path = '') => {
        if (base==null) return;
        Object.keys(base).forEach(key => {
            const newPath = path ? `${path}.${key}` : key;
            if (compare!=null) {
              if (!(key in compare)) {
                  differences[newPath] = {type:'Missing key'};
              } else if (typeof base[key] === 'object' && typeof compare[key] === 'object') {
                  checkKeys(base[key], compare[key], newPath);
              } else if ((base[key] == compare[key]) && (typeof compare[key] === 'string') && (path.indexOf('general.vilages')!=0)) {
                  differences[newPath] = {type:'Identical values', info: `${base[key]} vs ${compare[key]}`};
              }
            }
        });
    };
    checkKeys(referenceObj, targetObj);
    return differences;
}

// Computed property to generate comparison results
const comparisonResults = computed(() => {
    const results = {};
    languages.forEach(lang => {
        if (lang !== referenceLanguage) {
            results[lang] = compareLanguages(languageResources.value[referenceLanguage], languageResources.value[lang]);
        }
    });
    return results;
});


</script>
import {ref, computed} from 'vue';
import {useReportingWindowsManager} from './ReportingWidnowsManager';
import uuidController from '@/controllers/UUIDController';
import { useSlowReactiveTime } from '@/composables/useSlowReactiveTime';
const { previousReportingWindowIndex, currentReportingWindowIndex, nextReportingWindowIndex, getReportingWindow } = useReportingWindowsManager();

const {now:slowCurrentTime} = useSlowReactiveTime();
const dependsOnLS=ref(1); // used to force reactivity in computed functions that depend on the LocalStorage

//there was a previsous window AND it was missed
const missedPreviousReport = computed(()=>{
  return previousReportingWindowIndex.value>-1 && getReport(previousReportingWindowIndex.value,dependsOnLS.value) ==null
})

//there was a previsous window AND it was used
const submitedPreviousReport = computed(()=>{
  return previousReportingWindowIndex.value>-1 && getReport(previousReportingWindowIndex.value,dependsOnLS.value) !=null
})
//there was a previsous window AND it was used
const submitedCurrentReport = computed(()=>{
  return currentReportingWindowIndex.value>-1 && getReport(currentReportingWindowIndex.value,dependsOnLS.value) !=null
})

const submitedCurrentOrPreviousReport =computed(()=>submitedCurrentReport.value || submitedPreviousReport.value);
const reportingPeriodEnded = computed(()=>nextReportingWindowIndex.value==-1);
//const nextUpdateTime = computed(()=>_nextUpdateTime)

// Helper functions

const getReports=(dependancy)=> {
  dependancy; //this allows passing dependsOnLS.value to getReports which will make anything that uses it react to changes made via addReport or deleteReport
  const arr= JSON.parse(localStorage.getItem('reports') || '[]');
  console.log('arr has length :' + arr.length )
  return arr;
}

const getReport=(idx,dependancy)=> {
  dependancy; //this allows passing dependsOnLS.value to getReports which will make anything that uses it react to changes made via addReport or deleteReport
  const reports = JSON.parse(localStorage.getItem('reports') || '[]');
  return reports[idx];
}

/**
 * ReportsManager.addReport
 * */
// the pair of photos are assigned UUUIDs inside the report and stored separately in
// photo_pos_ {puuid}
const addReport=(idx, report,photos) => {
  console.log('addReport to local storage called' , idx, report, photos);
  const reports = JSON.parse(localStorage.getItem('reports') || '[]');
  report.ruuid = uuidController.createUUID();
  console.log('addReport allocated report.ruuid' , report.ruuid);
  if (photos) {
    console.log('got photos param');
    if (photos.pos_photo) {
        console.log('got pos_photo');
       report.pos_photo_uuid = uuidController.createUUID();
       localStorage.setItem('photo_pos_' + report.pos_photo_uuid , JSON.stringify(photos.pos_photo));
    }
    if (photos.neg_photo) {
      console.log('got neg_photo');
      report.neg_photo_uuid = uuidController.createUUID();
      localStorage.setItem('photo_neg_' + report.neg_photo_uuid , JSON.stringify(photos.neg_photo));
    }
  }
  report.idx=idx;
  report.seq=reports.slice(0,idx).filter(el => el !== null).length + 1; // the sequence number of actual reports
  console.log('patched report idx ' , idx , report);
  reports[idx] = report;
  localStorage.setItem('reports', JSON.stringify(reports));
  console.log('stored reports as ' , JSON.stringify(reports) );
  dependsOnLS.value++; //trigger change to dependsOnLS.value to let computed properties depend on it reactively
}

const deleteLastReport=() => {
  let reports = JSON.parse(localStorage.getItem('reports') || '[]');
  if (reports.length>0) {reports.pop();}
  localStorage.setItem('reports', JSON.stringify(reports));
  dependsOnLS.value++; //trigger change to dependsOnLS.value to let computed properties depend on it reactively
}


const timeLeftToSubmitCurrentReport=computed(()=>{
  var rwi = currentReportingWindowIndex.value;
  if (rwi==-1) return 0;
  if (getReport(rwi,dependsOnLS.value)!=null) return 0;
  let rw = getReportingWindow(rwi);
  //{end:rw.end, current:slowCurrentTime.value,  };
  return Math.floor((rw.end - slowCurrentTime.value) / 1000 / 60);
});

export function useReportsManager() {
  return {
    dependsOnLS,
    getReports,
    getReport,
    addReport,
    deleteLastReport,
    timeLeftToSubmitCurrentReport, // either 0 or [end time,currenttime,time left in minutes]
    reportingPeriodEnded,
    // note that missed is not the opposite of submited because its possible that there was no previous report yet
    // and so both might be false at the same time.
    missedPreviousReport,
    submitedPreviousReport,
    submitedCurrentReport,
    submitedCurrentOrPreviousReport
    }
}

import {reactive, computed } from 'vue';

import {useTimeUtils} from '@/services/TimeUtils';
const { enhanceWithLocalTime,
    //toMySQLFormat,
    //fromMySQLFormat
    } = useTimeUtils();

//shared state between instances
let timeoutId=null;

//shared reactive state between instances
const state = reactive({
  setup:null,
  reportingWindows:[],
  previousReportingWindow:-1,
  currentReportingWindow:-1,
  nextReportingWindow:-1,
  nextUpdateTime:null,
  isJoinDate:false
})

// Constants for the reporting schedule
const defaultReportingWindowDurationHours = 2;
const defaultReportingScheduleDays = 14;
const defaultReportingTimes = ['07:00', '12:00', '17:00'];

const defaultSetup = () => {
  return {
    joinDateTimeInfo:enhanceWithLocalTime({}),
    reportingWindowDurationHours: defaultReportingWindowDurationHours,
    reportingScheduleDays: defaultReportingScheduleDays,
    reportingTimes: defaultReportingTimes,
    userTimeZone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone,
  };
}

const loadSetup = ()=> {
    /*
    // Define the debugging function and attach it to the window object
    localStorage.setItem('dbg', JSON.stringify([]));
    window.dbg = (thing) => {
      // Retrieve the existing debug log from localStorage, or initialize a new array if none exists
      let debugLog =[];
      try {
        debugLog = JSON.parse(localStorage.getItem('dbg')) || [];
      }
      catch {
        alert('Failed to JSON.parse: ' + localStorage.getItem('dbg'));
      }
      // Add the new item to the end of the array
      debugLog.push({ timestamp: Date.now(), message: thing });

      // Ensure the array doesn't grow beyond 10 items
      if (debugLog.length > 10) {
        debugLog.shift(); // Remove the oldest item to keep the length to 10
      }

      // Serialize the updated array and store it back in localStorage
      localStorage.setItem('dbg', JSON.stringify(debugLog));
    };
*/

  // alert('hello2');
  // window.dbg('ReportingWidnowsManager.js:loadSetup');
  // alert(localStorage.getItem('dbg'));
  state.setup = JSON.parse(localStorage.getItem('reportingScheduleSetup'));
  //console.log('ReportsManager: stored setup ' , state.setup);
  if (!state.setup) {
    //console.log('ReportsManager: using default setup' );
    // Setup does not exist, use defaults and save to localStorage
    state.setup = defaultSetup();
    localStorage.setItem('reportingScheduleSetup', JSON.stringify(state.setup));
  }
  else {
    if (state.setup.reportingScheduleDays==20) {
      state.setup.reportingScheduleDays=14;
      localStorage.setItem('reportingScheduleSetup', JSON.stringify(state.setup));
    }
    if (state.setup.userTimeZone==null){
      state.setup.userTimeZone= Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
      localStorage.setItem('reportingScheduleSetup', JSON.stringify(state.setup));
    }
  }
  //loading of the setup requires reCalculation of the reporting windows
  //or inconsistancy might occur.
  ReCalculateReportingWindows();
}

const ReCalculateReportingWindows = () => {
  console.log('ReCalculateReportingWindows');
  state.reportingWindows.splice(0, state.reportingWindows.length); // Clear existing schedule
  var jdti = state.setup.joinDateTimeInfo;
  for (let day = 1; day <= state.setup.reportingScheduleDays; day++) {
    state.setup.reportingTimes.forEach(time => {
      // could be improoved to not perform this
      const [hours, minutes] = time.split(':').map(t => parseInt(t, 10));
      let reportStartDateTme= new Date(
        jdti.local_fullYear,
        jdti.local_month, // actually a month Index
        jdti.local_date + day,  //local_date is Day number in the month
        hours,
        minutes
      );
      let reportEndDateTme= new Date(
        jdti.local_fullYear,
        jdti.local_month, // actually a month Index
        jdti.local_date + day, //local_date is Day number in the month
        hours + state.setup.reportingWindowDurationHours, //state.setup.,
        minutes
      );
      state.reportingWindows.push({ start: reportStartDateTme, end: reportEndDateTme });
    });
  }

  // if the reporting windows schedule was re-calculated
  // then maintanance cycle should be restarted.
  maintainWindowStatus();
}

// Update the status of the current, previous and next windows, and schedule the next update if required.
const maintainWindowStatus = () => {
  const currentTime = new Date(Date.now());
  //it would be wrong to assume next is current+1 or before is current-1 because of to reasons:
  //1) it is possible that we are outside any time window so there is no current
  //2) we might be in the first or last so no prev or next respectively.
  //so lets find each of them seperately
  //TODO:might be possible to be more effective and done with one scan of the reporting windows.
  state.previousReportingWindow = recentReportingWindowBefore(currentTime);
  state.currentReportingWindow = findReportingWindowAt(currentTime);
  state.nextReportingWindow = nearestReportingWindowAfter(currentTime);
  var jdti = state.setup.joinDateTimeInfo;
  //console.log('Calculating state.isJoinDate based on joindate ', jdti , ' w.r.t ', currentTime  );
  //console.log(jdti.local_fullYear,currentTime.getFullYear(),
  //                     jdti.local_month,currentTime.getMonth(),
  //                     jdti.local_date,currentTime.getDate());

  state.isJoinDate = (jdti.local_fullYear==currentTime.getFullYear()) &&
                     (jdti.local_month==currentTime.getMonth()) &&
                     (jdti.local_date==currentTime.getDate());
  //console.log('state.isJoinDate set to:' , state.isJoinDate);
  scheduleNextUpdate(currentTime);

}

// Schedule the next update
const scheduleNextUpdate= (currentTime)=>{
  //const dateTimeObj = new Date(currentTime);
  state.nextUpdateTime = null;
  //find the next window we will be either entering or leaving
  for (let window of state.reportingWindows) {
    if (currentTime < window.start) {
      state.nextUpdateTime = window.start;
      break;
    } else if (currentTime < window.end) {
      state.nextUpdateTime = window.end;
      break;
    }
  }

  //handle updating of isJoinDate change at midnight
  if ((state.isJoinDate) && (state.nextUpdateTime.getDate() != currentTime.getDate())) {
    //console.log('changing state.nextUpdateTime from ' , state.nextUpdateTime)
    state.nextUpdateTime=new Date(state.nextUpdateTime.getFullYear(),state.nextUpdateTime.getMonth(),state.nextUpdateTime.getDate());
    //console.log('changed state.nextUpdateTime to ' , state.nextUpdateTime)
  }

  if (timeoutId) {
    clearTimeout(timeoutId);
    timeoutId = null;
  }

  if (state.nextUpdateTime) {
    const timeoutDuration = state.nextUpdateTime - currentTime;
    if (timeoutDuration>0) {
        timeoutId = setTimeout(maintainWindowStatus, timeoutDuration);
    }
    else {
      timeoutId = setTimeout(maintainWindowStatus, 10);
    }

  } else {
    console.log('no next update scedule');
  }

}
// The following 3 functions return the relevant window Index or -1

// Logic to find the reporting window containing a given datetime
const findReportingWindowAt = (datetime) => {
  const dateTimeObj = new Date(datetime);
  return state.reportingWindows.findIndex(window => (window.start <= dateTimeObj) && (dateTimeObj < window.end) );
}

// Logic to find the nearest reporting window before a given datetime
const recentReportingWindowBefore = (datetime) => {
  const dateTimeObj = new Date(datetime);
  return state.reportingWindows.findLastIndex(window =>  window.end <= dateTimeObj );
}

// Logic to find the nearest reporting window before a given datetime
const nearestReportingWindowAfter = (datetime) => {
  const dateTimeObj = new Date(datetime);
  return state.reportingWindows.findIndex(window => dateTimeObj <= window.start);
}

loadSetup();

const previousReportingWindowIndex = computed(()=>state.previousReportingWindow)
const currentReportingWindowIndex = computed(()=>state.currentReportingWindow)
const nextReportingWindowIndex =computed(()=>state.nextReportingWindow)
const isJoinDate = computed(()=>state.isJoinDate);

const reportingEndDate = computed(()=>{
  console.log('in computed:reportingEndDate state.reportingWindows', state.reportingWindows)
  console.log('state.reportingWindows[state.reportingWindows.length-1]' , state.reportingWindows[state.reportingWindows.length-1])
  return state.reportingWindows[state.reportingWindows.length-1].end
});


const getReportingWindowsHTML=computed(() => {
  console.log('in getReportingWindowsHTML');
  var h=[];
  h.push('<table border=1><thead><tr><th>idx</th><th>start</th><th>end</th></thead><tbody>');
    let idx=0
    state.reportingWindows.reduce((acc, rw)=>{
      console.log('in getReportingWindowsHTML ', idx);
      acc.h.push('<tr>');
      acc.h.push('<td>' , JSON.stringify(acc.idx) , '</td>')
      acc.h.push('<td>' , JSON.stringify(rw.start) , '</td>')
      acc.h.push('<td>' , JSON.stringify(rw.end) , '</td>')
      acc.idx++;
      return acc;
    },{h,idx})
  h.push('</tbody></table>')
  return h.join('')
});

/*
const getReportingWindows=()=>{state.reportingWindows};
*/
const getReportingWindow = (idx) => {
  if (idx==-1) return false;
  return state.reportingWindows[idx];
};

const nextReportWindowHour=computed(()=>{
  console.log('in nextReportWindowHour');

  let rwi = state.nextReportingWindow;
  console.log('rwi =' , rwi);
  if (rwi==-1) return '';
  let rw=getReportingWindow(rwi);
  if (false==rw) return '';

  let arr=[];
  let h=rw.start.getHours();
  let m=rw.start.getMinutes();
  if (h<10) arr.push('0');
  arr.push(h);
  arr.push(':');
  if (m<10) arr.push('0');
  arr.push(m);
  return arr.join('');
  });



export function useReportingWindowsManager() {
    return {
      enhanceWithLocalTime,
      loadSetup,

      previousReportingWindowIndex,
      currentReportingWindowIndex,
      nextReportingWindowIndex,

      getReportingWindow,
      nextReportWindowHour, // returns an empty string or a time string '07:30'
      getReportingWindowsHTML,

      reportingEndDate,
      isJoinDate

    }
}


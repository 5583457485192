<template>
  <v-container>

    <v-row v-show="isRecording" class="justify-space-evenly">
        <v-progress-linear :model-value="recordingDurationPercent" :height="6"></v-progress-linear>
        {{(elapsedTime/1000).toFixed(2)}}
        <!--v-btn v-if="isRecording" @click="stopRecording"><slot name="stop">Stop Recording</slot></v-btn-->
    </v-row>
    <v-row v-if="!isRecording && microphonePermission!='denied' &&  (localdB==null || localdB==0 || isNaN(localdB))" class="justify-space-evenly">
        <v-btn :prepend-icon="mdiCircle" @click="startRecording">
          <template v-slot:prepend>
              <v-icon color="red"></v-icon>
          </template>
          <slot name="start">Start Recording</slot></v-btn>
    </v-row>

    <v-row v-if="microphonePermission=='denied'">
        <slot name="permissions">To allow audio recording you need to grant microphone permission in user device</slot>
    </v-row>
    <v-row v-if="localAudioUrl || localdB || localRms" class="justify-space-evenly">
        <slot name="audioRecorded">
            <audio :src="localAudioUrl" controls></audio>
        </slot>
    </v-row>
  </v-container>
</template>

<script setup>

    import {ref,toRefs,watch,computed} from 'vue';

    const emittingPropWrapper = (propRef, eventName, conversionFn = null) => {
      const local = ref(conversionFn ? conversionFn(propRef.value) : propRef.value);
      const accessor = computed({
        get() {
          return local.value;
        },
        set(value) {
          const newValue = conversionFn ? conversionFn(value) : value;
          local.value = newValue;
          console.log('emittingPropWrapper is emmiting:', eventName, newValue , ' for ', local)
          emit(eventName, newValue);
        }
      });
      watch(propRef, (newValue) => {
        local.value = conversionFn ? conversionFn(newValue) : newValue;
      });
      return accessor;
    };

    import { usePermission } from '@vueuse/core';
    const microphonePermission = usePermission('microphone');
    import { useStopWatch } from '@/composables/useStopWatch';
    const { elapsedTime,
            start,
            stop,
            reset
    } = useStopWatch();

    import { mdiCircle } from '@mdi/js';

    const maxRecordingLengthMs=ref(30000.0);
    const recordingDurationPercent=computed(()=>{
      return Math.round(100.0* elapsedTime.value / maxRecordingLengthMs.value )}
    );

    // Define the events your component can emit
    const emit = defineEmits(['update:audioUrl','update:rms','update:dB'])
    const props = defineProps({
      audioURL : String,
      rms: { type:Number, default: 0.0},
      dB: { type:Number, default: 0.0},
    });

    //const { au dioURL:audioURLProp,rms:rmsProp,dB:dBProp } = toRefs(props);
    const refs = toRefs(props);
    const audioURLProp = refs.audioURL;
    const rmsProp = refs.rms;
    const dBProp = refs.dB;

    //todo consider using defineModel
    const localAudioUrl = emittingPropWrapper(audioURLProp,'update:audioUrl');
    const localRms = emittingPropWrapper(rmsProp,'update:rms',parseFloat);
    const localdB = emittingPropWrapper(dBProp,'update:dB',parseFloat);

    const isRecording=ref(false);
    defineExpose({
      isRecording
    })

    let mediaRecorder=null;
    let audioChunks=[];
    let audioBlob=null;


    const startRecording = async ()=> {
      try {
        if (!navigator.mediaDevices) {
            console.log('No navigator.mediaDevices');
            return;
        }

        // Request microphone access
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        start();
        mediaRecorder = new MediaRecorder(stream);

        // Reset previous recordings if any
        audioChunks = [];

        mediaRecorder.ondataavailable = (event) => {
          audioChunks.push(event.data);
        };

        mediaRecorder.onstop = () => {
          audioBlob = new Blob(audioChunks, { type: 'audio/mp3' });
          const newUrl = URL.createObjectURL(audioBlob);
          localAudioUrl.value = newUrl;
          console.log('newUrl ', newUrl)
          analyzeBlob(audioBlob);
        };

        // Start recording
        isRecording.value = true;
        mediaRecorder.start();


      } catch (error) {
        isRecording.value = false;
        console.error("Error accessing the microphone", error);
      }
    };

    const stopRecording = () => {
      if (mediaRecorder && mediaRecorder.state !== 'inactive') {
        mediaRecorder.stop();
        // You might also want to release the media stream to turn off the microphone
        mediaRecorder.stream.getTracks().forEach(track => track.stop());
      }
      isRecording.value = false;
    };

    // Stop recording after 30 seconds
    watch([elapsedTime],()=>{
      if (elapsedTime.value>=maxRecordingLengthMs.value) {
        console.log('elapsedTime.value>=maxRecordingLengthMs.value',elapsedTime.value, maxRecordingLengthMs.value)
        stop();
        reset();
        stopRecording();
      }
    });

    const blobToArrayBuffer = (blob) => {
      return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsArrayBuffer(blob);
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
      });
    };
    const decodeAudioData = async (arrayBuffer) => {
      const audioContext = new AudioContext();
      return audioContext.decodeAudioData(arrayBuffer);
    };

    const calculateRMS = (audioBuffer) => {
      const channelData = audioBuffer.getChannelData(0); // Assuming mono audio for simplicity
      let sum = 0;
      for (let i = 0; i < channelData.length; i++) {
          sum += channelData[i] * channelData[i];
      }
      const rms = Math.sqrt(sum / channelData.length);
      return rms;
    };

    const rmsToDecibels = (rms) => {
      return 20 * Math.log10(rms);
    };

    const analyzeBlob = async (blob) => {
      try {
          const arrayBuffer = await blobToArrayBuffer(blob);
          const audioBuffer = await decodeAudioData(arrayBuffer);
          const rmsV= calculateRMS(audioBuffer);
          localRms.value = rmsV;
          localdB.value = rmsToDecibels(rmsV);
          //emit('update:rms', rms);
          //emit('update:dB', dB);
          // Or convert RMS to a percentage of maximum possible noise if preferred
      } catch (error) {
          console.error('Error analyzing audio:', error);
      }
    };

</script>


import { useTimeUtils } from "../TimeUtils"
const {enhanceWithLocalTime} = useTimeUtils();
function Between(a,b) {
  return  Math.round(FBetween(a,b))
}
function FBetween(a,b) {
  return  a+Math.random()*(b-a)
}


function generateRandomReport(){
  return enhanceWithLocalTime({
      in_out:Between(0,1),
      company:Between(1,5),
      activity:Between(1,8),
      location:Between(1,8),
      trees:Between(1,5),
      bushes:Between(1,5),
      carsp:Between(1,5),
      carsd:Between(1,5),
      grass:Between(1,3),
      birds:Between(1,3),
      blue:Between(1,3),
      green:Between(1,3),
      stai_1:Between(1,4),
      stai_2:Between(1,4),
      stai_3:Between(1,4),
      panas_1:Between(1,5),
      panas_2:Between(1,5),
      panas_3:Between(1,5),
      panas_4:Between(1,5),
      panas_5:Between(1,5),
      panas_6:Between(1,5),
      audio_rms:null,
      audio_dB:null,
      latitude:FBetween(30.0,50.0),
      longitude:FBetween(30.0,50.0),
      accuracy:FBetween(3.0,3050.0)
      })
}

export function UseRandomReportGenerator() {
  return {
    generateRandomReport
  }
}


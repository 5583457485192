/* Instructions for translators:
1. Only translate the texts with single quotes  (i.e. alwyas on the right side of :)
2. Do not add,remove or change the order of lines.
3. if a text include within it tags like <b></b> (like para4 of the consentForm ) keep the tags in the translation and
   make sure the segment inside them has the same meaning as in the original.
4. if a text include some variable within {} ( like the reported_so_far key for example)
   it means that it will be replaced with an appropriate replacement -
   keep the {} and their content un translated)
5. In case of doubt of any Q's please call Eyal Peleg : 054-4638212

*/
const ru = {
    $vuetify :{
      open:'открой',
      close:'закрой',
      noDataText:'информация Не нашлась'
    },
    general: {
      buttons: {
        understood:'понял',
        continue:'продолжение',
        next:'следующее',
        previous:'предыдущий',
        close:'закрой',
        sendReport:'отправить репорт'
      }
    },
    app: {
      title:'IntoMyZone.Com', // do not translate the title.
      menu:{
        choose_language:'Выбор языка',
        continue_reporting:'продолжите репорт',
        ongoing_feedback:'Промежуточная отзыв',
        final_feedback:'Окончательный отзыв',
        contact:'контакт',
      }
    },
    install: {
      please_install_to_continue:'Пожалуйста, следуйте инструкциям по установке выше',
      after_install:'По окончании установки откройте приложение, чтобы продолжить.',
      guidelines:'Инструкции по установке',
      your_os_is:'Ваша рабочая среда {operatingSystem}.',
      Android:{
        para_1:'на устройствах Android, убедитесь что у вас установлена ​​последняя версия Google Chrome.',
        para_2:'используйте google chrome и откройте https://app.intomyzone.com',
        para_3:'в меню Google Chrome Ищите вариант install App',
        para_4:'Вас попросят подтвердить установку.',
        para_5:'После одобрения вы можете добавить приложение из списка приложений, установленных на вашем устройстве, обычным способом..',
      },
      iOS:{
        para_1:'на устройствах iPhone or iPad, откройте Safari.',
        para_2:'Выпишите адрес https://app.intomyzone.com',
        para_3:'Внизу экрана нажмите на кнопку {share_icon}.',
        para_4:'Найдите и нажмите "Add to home Screen" {add_to_home_screen_icon}',
        para_5:'Подтвердите информацию о сайте и нажмите "Add".',
        para_6:'Ссылка для запуска приложения будет добавлена ​​на главный экран.',
      },
      Windows: {
        para_1:'Это приложение не работает в среде Microsoft Windows.',
      },
      Other: {
        para_1:'вы используете: "{userAgent}"',
        para_2:'Это среда нам незнакомая.',
        para_3:'Пожалуйста, сделайте снимок экрана и отправьте его нам по адресу: {email} чтобы мы могли помочь.',
      }
    },
    view: {
      generalInfo: {
        title:'Общая информация',
        head1:'IntoMyZone',
        para1:'IntoMyZone это приложение, которое позволяет вам с удовольствием погрузиться в свою среду измеряя мгновенные ощущения в городских или природных условиях',
      },
      contact: {
        can_contact_via_email:'Вы можете связаться с нами по {email}',
      },
      consentForm: {
        title:'Согласие на участие в исследовании',
        para1:'Запрос на ваше участие в исследовании, исследующем взаимосвязь между окружающей средой и психическим благополучием в городской и природной среде – IntoMyZone',
        para2:'Здравствуйте, ',
        para3:'Мы просим вас принять участие в исследовании, посвященном изучению взаимосвязи между окружающей средой и психическим благополучием в городской и природной среде. Исследование проводится в Израиле под руководством группы исследователей педагогического факультета Хайфского университета и получило одобрение комитета по этике.',
        para4:'<b>Цель исследования</b> понять мгновенное и накопленное воздействие различных окружающих условий на психологическое благополучие человека. Результаты исследования способствуют более глубокому пониманию ценности различных окружающих условий как с точки зрения поддержания здоровья, так и экологического аспекта для общества.',
        para5:'Для вашего согласия прочтите условия использования и выберите опцию «Я согласен участвовать в исследовании». Выбирая «Я согласен участвовать в исследовании», вы подтверждаете, что согласны участвовать в исследовании. Вы сможете снова получить доступ к этой информации и даже отозвать свое согласие через меню настроек приложения.',
        termsAndConditions: {
          buttonCaption:'показания условия эксплуатации',
          title:'условия эксплуатации',
          head1:'Способы участия.',
          para1:'Для того, чтобы принять участие в исследовании, необходимо заполнить онлайн-анкеты в приложении «IntoMyZone», на различных сайтах по вашему выбору. Если вы решите принять участие в исследовании, вам сначала предложат ответить на анкету относительно возраста, пола, рода занятий, места жительства, образа жизни и степени близости к природе. После этого приложение предложит вам сообщить о характеристиках вашего окружения и ваших ощущениях 3 раза в день в течение двух недель. Кроме того, ваше географическое местоположение будет записано с помощью датчика GPS на мобильном телефоне.',
          head2:'',
          para2:'Мы попросим вашего согласия указать географическое местоположение при первом использовании приложения. Кроме того, в конце каждого отчета у вас будет возможность сфотографировать что-то в вашем окружении, что вызывает у вас положительные или отрицательные чувства, и записать звук окружающей среды, в которой вы находитесь. Заполнение первоначальной анкеты занимает около 5 минут, а заполнение текущих отчетов занимает около 2 минут.',
          head3:'',
          para3:'Уведомления будут отправляться на ваш мобильный телефон в заранее установленное время. После каждого оповещения вам будет предоставлен временной интервал в 2 часа для завершения отчета. Если вы не можете сообщить в течение этого периода, вы можете пропустить этот отчет и дождаться следующего уведомления. Мы просим вас заполнить как можно больше отчетов, так как это позволит нам получить более достоверную картину происходящего.',
          head4:'Конфиденциальность',
          para4:'Участие в исследовании полностью анонимно. Вас никогда не попросят предоставить личные данные и/или идентификаторы любого рода. Собранные данные будут использоваться только в исследовательских целях и будут представлены в агрегированной форме. Ваши ответы на анкеты будут храниться неопределенно долго и защищены на компьютерах, защищенных паролем, или на отдельных дисках. Фотографии и аудиоклипы будут анализироваться только в исследовательских целях и могут отображаться в социальных сетях и использоваться на выставках цифрового искусства.',
          head5:'анализ данных.',
          para5:'הПолученные данные могут быть представлены в виде научных отчетов и опубликованы в научных журналах.Кроме того, данные могут быть доступны другим исследователям. Любая информация, которая будет представлена ​​в отчетах или возможных публикациях, будет полностью анонимной и неидентифицируемой.',
          head6:'Польза от участия в этом исследовании.',
          para6:'Участие в исследовании приносит пользу как лично, так и обществу. На личном уровне участник, заполнивший более 30 отчетов, получит в конце периода личный отчет, описывающий, как различные окружающие условия связаны с разными эмоциональными состояниями. На общественном уровне результаты исследования способствуют более глубокому пониманию ценности различных окружающих условий для поддержания психического и физического здоровья и, таким образом, вносят вклад в общую популяцию. Кроме того, на основе результатов можно будет рекомендовать сбалансированное городское планирования в будующем.',
          head7:'Дополнительная информация.',
          para7:'Если вы заинтересованы в получении дополнительной информации об исследовании, мы приглашаем вас связаться с исследовательской группой по электронной почте: intomyzone24@gmail.com',
          head8:'',
          para8:'Мы будем признательны, если вы согласитесь принять участие в исследовании. Отмечаем, что участие будет возможно только при наличии вашего согласия. Согласие не является обязательным, и вы можете прекратить свое участие в любое время без какого-либо вреда для вас. Вы можете удалить данные через меню настроек приложения, нажав &apos;удалить все записи &apos;. Кроме того, вы можете удалить приложение в любой момент. Но после этого вы не сможете удалить записи и не сможете отозвать свое согласие на участие в исследовании. Это связано с тем, что мы не можем идентифицировать отдельные объявления, поскольку не собираем никакой идентифицирующей информации. ',

          },
        bullet1:'Я заявляю, что согласен участвовать в исследовании на тему изучения связей между окружающей средой и психическим благополучием в городской и природной среде',
        bullet2:'Я знаю, что я имею право не участвовать в исследовании и могу прекратить свое участие в любое время, не нарушая своих прав, не причиняя мне никакого вреда и не применяя против меня никаких санкций.',
        bullet3:'Я знаю, что мне гарантируется конфиденциальность относительно моей личности на каждом этапе исследования, включая научные публикации. ',
        bullet4:'Я заявляю, что дал свое согласие по собственному желанию и понял все вышеизложенное.',
        bullet5:'Если вы заинтересованы в получении дополнительной информации об исследовании, мы приглашаем вас связаться с исследовательской группой по электронной почте: intomyzone24@gmail.com',
        iagree:'Я согласен участвовать в исследовании'
      },
      sheelon1:{
        title:'Статистическая информация',
        intro:'Для статистического анализа мы просим вас ответить на несколько вопросов, прежде чем начать использовать приложение. Вся информация предназначена только для исследовательских целей, и ни в коем случае не собирается никакая частная информация, такая как имена участников.',
        NR_title:'На сколько вы согласны с следующими утверждениями?',
        PA_title:'Перед вами условия, описывающие контекст места проживания. Пожалуйста, ответьте, насколько вы согласны с следующими условиями, относящимися к месту, где вы живете сейчас (1 = \'совсем не согласен\' до 5 = \'очень согласен\')?',
        WHO_title:'Относительно следующих пяти утверждений ответьте: Как вы себя чувствовали в течение последних двух недель?',
        agreeScaleText:'(1 = совсем не согласен до 5 = очень согласен )',
        satisfactionTexts:[
          'совсем не согласен','не согласен','безразлично','согласен','очень согласен'
        ],
        itemSets:{
          ProjectItems:[
            {value:0, title:'самостоятельный'},
            {value:1, title:'школьный проект'},
            {value:2, title:'Общественный проект'},
          ],
          YesNoItems:[
            {value:1, title:'да'},
            {value:0, title:'нет'},
          ],
          NoYesItems:[
            {value:0, title:'нет'},
            {value:1, title:'да'}
          ],
          residence:[
            {value:1, title:'в городе'},
            {value:2, title:'В городском поселении с населением менее 20 000 человек'},
            {value:3, title:'в сельской местности (например, кибуц)'},
            {value:4, title:'в несколько местах'},
            {value:5, title:'другое'},
          ],
          house:[
            {value:10 , title:'частный дом' ,hasFloors:false},
            {value:20 , title:'Квартира в здании' , hasFloors:true},
            {value:30 , title:'отель' , hasFloors:true},
            {value:40 , title:'Студенческое общежитие' , hasFloors:true },
            {value:90 , title:'Никакой из них' ,hasFloors:false}
          ]

      },
        Qs: {
          //phase 1
          project:
            {
              label:'Ваше участие в исследовании:',
              //items: was @:view.sheelon1.itemSets.YesNoItems
              // changed to @:view.sheelon1.itemSets.ProjectItems
            },
          schoolCode:{
            label_1:'Запишите код из 6 цифр',
            label_2:'Запишите код из 6 цифр',
            errors: {
              schoolCodeRuleOutOfRange_1:'код обязан быть из 6 цифр',
              schoolCodeRuleOutOfRange_2:'код обязан быть из 6 цифр'
            }
          },

          //phase 2
          age: {
            label:'Сколько вам лет?',
            errors:{
              ageRuleOutOfRange:'Возраст должен быть от 6 до 99 лет.'
            }
          },
          gender:
          {
            label:'Какого Вы пола?',
            items:[
              {value:1, title:'женщина'},
              {value:0, title:'мужчина'},
              {value:2, title:'не бинарный'},
              {value:3, title:'не хочу сказать'},
            ]
          },
          israel:{
            label: "Вы родились в Израиле??",
            //items: @:view.sheelon1.itemSets.NoYesItems
          },
          evacuated:{
            label: "Война привела к смене места жительства?",
            //items: @:view.sheelon1.itemSets.NoYesItems

          },
          residenceChildhood:{
            label: "В каком городе ты вырос?",
          },

          residenceName:{
            label: "Где вы постоянно живёте сейчас?",
            label_evacuated: "Где вы жили до эвакуации?",
            //list of vilages is injected automatically
            errors:{
              noDataText:'район такой не нашёлся'
            }
          },
          //phase 3
          residencePermanent:{
            label:'В каком месте вы постоянно живете (ваш постоянный дом)?'
          },
          residenceBefore:{
            label:'В каком месте вы жили до эвакуации (ваш постоянный дом)?'
          },
          residenceAfter:{
            label:'В каком месте вы сейчас живете?'
          },

          houseNow:{
            label: "В каком жилье вы сейчас проживаете?",
          },
          houseBefore:{
            label: "В каком типе дома Вы жили до переезда?",
          },
          houseAfter:{
            label: "В каком типе дома Вы живёте сегодня?",
          },
          floor: { // used for all house* Q's where the  hasFloors is true for the selected answer
            label: "в каком этаже?"
          },

          educationLevel:{
            label: "Уровень образования",
            items:[
              {value:1, title:'Средняя школа'},
              {value:2, title:'старшая школа без аттестата'},
              {value:3, title:'старшая школа с аттестатом '},
              {value:4, title:'Послешкольное образование и/или профессиональное образование'},
              {value:5, title:'степень бакалавра'},
              {value:6, title:'Степень магистра или выше'},
            ]
          },

          //phase 3
          NR_1:{label: "Идеальное место отдыха для меня — это далёкое место с дикой природой."},
          NR_2:{label:'Я всегда думаю о том, как мои действия влияют на окружающую среду',},
          NR_3:{label:'Моя связь с природой и окружающей средой является частью моей духовности',},
          NR_4:{label:'Я обращаю внимание на природу, где бы я ни был',},
          NR_5:{label:'Мои отношения с природой — важная часть того, кто я есть',},
          NR_6:{label:'Я чувствую себя очень связанным со всеми живыми существами и землей',},

          //phase 4
          PA_nature1:{label:'я связан с природой в этом месте'},
          PA_nature2:{label:'Когда я далеко отсюда, я скучаю по здешней природе'},
          PA_nature3:{label:'Природа этого места вызывает у меня чувство принадлежности к этому месту'},
          PA_nature4:{label:'Природа этого места отражает то, кем я являюсь'},
          PA_people1:{label:'Я чувствую связь с людьми в этом месте'},
          PA_people2:{label:'Когда я далеко отсюда, я скучаю по людям здесь'},
          PA_people3:{label:'Люди здесь позволяют мне почувствовать принадлежность к этому месту.'},
          PA_people4:{label:'Связи, которые у меня есть с людьми в этом месте, позволяют мне чувствовать принадлежность к этому месту'},
          PA_identity1:{label:'Это место отражает, кто я есть'},
          PA_identity2:{label:'у меня эмоциональная связь к этому месту'},
          PA_identity3:{label:'Я чувствую принадлежность к этому месту'},
          PA_identity4:{label:'Когда я далеко, я скучаю по этому месту'},

          //phase 5
          WHO_1:{label:'Я чувствовал себя бодрым и в хорошем настроении'},
          WHO_2:{label:'Я чувствовал себя спокойно и расслабленно'},
          WHO_3:{label:'я себя чувствовал энергичной и активной'},
          WHO_4:{label:'Я проснулся, чувствуя себя отдохнувшим'},
          WHO_5:{label:'Моя повседневная жизнь полна вещей, которые меня интересуют'},

          WHO_Headers_5: 'Все время',
          WHO_Headers_4: 'Большую часть времени',
          WHO_Headers_3: 'Более половины времени',
          WHO_Headers_2: 'менее чем половину время',
          WHO_Headers_1: 'меньше половины времени',
          WHO_Headers_0: 'в Никакое время'
        }
      },
      sheelon2:{
          phase0: {
            goodToSeeYouAgain:'рады вас видеть.',
            readyLetsGo:'готовы сдавать новый репорт? давай начнём!',
            thanksForJoining:'Спасибо что присоединились.',
            thrilledYourWithUs:'радуй что вы здесь.',
            youStartTommorow:'Ваши отчеты начнутся завтра.',
            thanksForYouRecentReport:'Спасибо за последнего репорта.',
            oopsYouMissed:'жаль вы пропустили возможность сдать репорт',
            nextReportWillBeAvailable:'следующий репорт будет открытый для вас{hour}',
            thanksForParticipating:'Отчетный период закончился, спасибо за участие в проекте',
            //youCanSeeYourReports:'Здесь вы сможете просмотреть ваши отчеты.',
            showReports:'показания отчётов',
            timeLeft:'время которое осталось: {time} минуты',
            reminders:{
              display_reminder_notifications:'Напоминания отображаются в начале каждого нового отчета.',
              explain_permissions:'При выборе отображения напоминаний вам будет предложено предоставить соответствующие разрешения.',
              permission_denied:'Вы отказались дать разрешение, поэтому напоминания отображаться не будут. На вашем устройстве должны быть предоставлены соответствующие разрешения.',
            }
          },

          agreeExtent:'В какой степени Вы согласны следующими утверждениями?',

          nearBy:'в районе рядом со мной:',
          atTheDistance:'в далёком виде (более чем 100 м)',
          recording_prompt:'запишите звук, который вы сейчас слышите.',
          microphone_permissions_are_needed:'Чтобы включить запись звука, вы должны предоставить соответствующие разрешения на своем телефоне.',
          recording_prompt_v2:'Теперь мы попросим вас записать 30 секунд звуков вокруг вас (запись будет зависеть только от громкости)',
          start_recording:'Начать запись',
          stop_recording:'Конец записи',
          recording_has_ended:'Спасибо, запись окончена',

          camera_permissions_are_needed:'Чтобы включить фотосъемку, вы должны предоставить соответствующие разрешения на своем телефоне.',
          photo_pos_prompt:'фотографируете пожалуйста что-то в вашем районе что вы видите и вам эту хорошо делает. пожалуйста не фотографируете людей.',
          photo_neg_prompt:'фотографируете пожалуйста что-то в вашем районе что вы видите и вам эту плохо делает. пожалуйста не фотографируете людей.',
          open_camera:'включение камеру',
          take_picture:'сфотать',
          dont_use_this_image:'Не отправлять это фото',

          form_filled_press_send:'Вы закончили заполнение формы. Нажмите кнопку "Отправить отчет" ниже, чтобы отправить отчет.',

          Qscales: {
            stai_1_4:"сейчас у меня настроение такое(из 1='совсем нет' до-4='очень')",
            panas_1_5:"сейчас я чувствую себя (из 1='совсем нет' до-5='очень')"
          },
          Qs: {
            //phase 1
            in_out:
              {
                label:'сейчас я нахожусь:',
                items:[{value:0, title:'внутри'},
                       {value:1, title:'на улице'},]
              },
            company:{
              label:'сейчас я нахожусь с:',
              items:[
                {value:1, title:'никто'},
                {value:2, title:'семьёй'},
                {value:3, title:'животным'},
                {value:4, title:'друзьями'},
                {value:5, title:'другое'},
              ]
            },
            //phase 2
            activity:
            {
              label:'сейчас я занимаюсь с:',
              items:[
                {value:1, title:'работай'},
                {value:2, title:'учёбой'},
                {value:3, title:'покупкой '},
                {value:4, title:'домашняя работа '},
                {value:5, title:'рекреационной деятельности'},
                {value:6, title:'отдыхом'},
                {value:7, title:'по пути куда-то'},
                {value:9, title:'Спортивная деятельность'},
                {value:8, title:'другое'},
              ]
            },
            /// Those are for people Outside...
            location: {
              label:'сейчас я нахожусь в:',
              items:[
                {value:1, title:'домашний двор или сад'},
                {value:2, title:'улице'},
                {value:3, title:'пляже'},
                {value:4, title:'Городской сад'},
                {value:9, title:'общественный сад'},
                {value:5, title:'торговый центр'},
                {value:6, title:'парке'},
                {value:7, title:'натуральный районе'},
                {value:8, title:'другое'},
              ]
            },
            //nearBy
            trees:{
              label: "я вижу деревья:",
              items:[
                {value:1, title:'совсем нет'},
                {value:2, title:'между 1-3'},
                {value:3, title:'между 4-10'},
                {value:4, title:'между 11-20'},
                {value:5, title:'более 20'},
              ]
            },
            bushes:{
              label: "я вижу цветы:",
              items:[
                {value:1, title:'совсем нет'},
                {value:2, title:'между 1-3'},
                {value:3, title:'между 4-10'},
                {value:4, title:'между 11-20'},
                {value:5, title:'более 20'},
              ]
            },
            carsp:{
              label: "Я вижу припаркованные машины:",
              items:[
                {value:1, title:'нету припаркованные машины'},
                {value:2, title:'между 1-3'},
                {value:3, title:'между 4-10'},
                {value:4, title:'между 11-20'},
                {value:5, title:'более 20'},
              ]
            },
            carsd:{
              label: "я вижу машины в движении:",
              items:[
                {value:1, title:'нету машины в движении'},
                {value:2, title:'между 1-3'},
                {value:3, title:'между 4-10'},
                {value:4, title:'между 11-20'},
                {value:5, title:'более 20'},
              ]
            },
            grass:{
              label: "я вижу траву:",
              items:[
                {value:1, title:'Да'},
                {value:2, title:'нет'},
                {value:3, title:'не уверен'},
              ]
            },
            birds:{
              label: "я вижу или слышу птиц:",
              items:[
                {value:1, title:'Да'},
                {value:2, title:'нет'},
                {value:3, title:'не уверен'},
              ]
            },
            // phase
            // в далёком виде(более чем 100 м)
            blue:{
              label: "я вижу море или озеро:",
              items:[
                {value:1, title:'Да'},
                {value:2, title:'нет'},
                {value:3, title:'не уверен'},
              ]
            },
            green:{
              label: "я вижу зелёный вид :",
              items:[
                {value:1, title:'Да'},
                {value:2, title:'нет'},
                {value:3, title:'не уверен'},
              ]
            },
            //phase 3
            stai_1:{label: "напряженный"},
            stai_2:{label: "довольный"},
            stai_3:{label: "спокойной"},
            panas_1: {label: "вдохновения"},
            panas_2: {label: "злобный"},
            panas_3: {label: "сильный"},
            panas_4: {label: "расстроенным"},
            panas_5: {label: "решимость"},
            panas_6: {label: "нервный"},

            sound:{
              label:'Каков источник записанного вами звука?',
              items:[
                {value:1, title:'люди говорят'},
                {value:2, title:'собаки гавкают'},
                {value:3, title:'автомобильное движение'},
                {value:4, title:'музыка'},
                {value:6, title:'звуки птиц'},
                {value:5, title:'другое'},
              ]
            },

          },

          locationPermissions: {
            title: 'Разрешения на определение местоположения',
            para1:'Чтобы исследовать взаимосвязь между местоположением и эмоциональными переживаниями, мы хотели бы получить доступ к вашему местоположению, пока вы используете приложение, и только в это время.. ',
            para2:'После того как вы нажмете "Далее", вам будет предложено дать согласие. Пожалуйста, подтвердите свое согласие. Без этого согласия невозможно участвовать в исследовании.',
            denied:'Вы не давали разрешения, поэтому участие в мероприятии невозможно. Вы должны предоставить соответствующие разрешения на своем телефоне.',
             },

      },
      get_feedback: {

        reported_so_far:'до сих пор Вы закончили {n} репорты.',

        where_were_you:'Где вы находились когда давали репорты:',
        outside:'на улице',
        inside:'внутри',
        sea_or_lake_view:'вид озеро или море',
        green_view:'зелёный вид',

        who_were_you_with:'с кем вы были когда давали репорты:',
        alone:'один',
        with_pet:'с животный ',
        with_friends:'с друзьями',
        with_family:'с семьёй',
        other:'другое',

        trees_seen:'номер деревьев которые вы видели:',
        carsd_seen:'номер машины в движении которые вы видели:',
        range_none:'совсем нет',
        range_1_3:'между 1-3',
        range_4_10:'между 4-10',
        range_11_20:'между 11-20',
        range_over_20:'более 20',
        number_of_times_you_reported_of:'Сколько раз вы сообщили:',
        reported_feeling_avg:'Среднее значение эмоций (между 1-4) которые вы сообщили:',
        no_reports_yet:'Здесь будет отзыв по вашим отчетам. Пожалуйста, предоставьте хотя бы один отчет для получения отзыва.',

        data_will_be_here: 'Здесь будут находиться начиная с {end_date} ( в конце двух недель участия) суммарные данные  отражающие  связь вашего эмоциональого состояния с использованием различных Веб-сайтов.',
        data_N_required:'Для этого вам необходимо собрать не менее {n_req} отчетов.',
        data_requirements:'вам недостаточно {n} отчётах Чтобы иметь право на получение сводного отчета.',
        your_reporting_progress:'Ваш прогресс в отчетах',

        did_N_reports:'Поздравляем с завершением {n_req} отчетов! Вы имеете право получить итоговый отчет в конце двух недель участия.',
        did_N_continue_reporting: 'В любом случае, чтобы сводный отчет был максимально точным, рекомендуется продолжить заполнение отчетов.',

        ended_without_enough_reports:'Большое спасибо за ваше участие. Поскольку {n_req} отчетов не были получены, создать персональный сводный отчет невозможно, но вы все равно можете просмотреть отчет об основных действиях.',

        we_created:'На основе заполненных вами данных мы создали индексы для трех типов эмоций:',
        indicators:'Положительные эмоции, отрицательные эмоции и чувство стресса',
        averages:'Ниже показано приведены средние значения эмоций, которые вы испытываете в разных ситуациях.',
        averages_feelings:'Средние положительные и отрицательные эмоции показано в диапазоне от 1-5.',
        averages_stress:'Среднее чувство стресса показано в диапазоне от 1-4.',
        limits_note:'Обратите внимание: графики основаны на собранных переменных, а не на всех переменных исследования.',

        final:{
          positive_and_negative_feelings:'Положительные и отрицательные эмоции:',
          stress:'стресс:',
          categories: {
            'g_in_out_1':'на улице',
            'g_in_out_0':'внутри',
            'blue_1':'вид озеро или море',
            'g_green_1':'зелёный вид',
            'g_birds_1':'птицы',
            'g_company_1':'один',
            'g_company_2':'с друзьями',
            'g_company_3':'с семьёй',
            'g_company_4':'с животными',
            'g_trees_1':'когда нету деревьев',
            'g_trees_2':'1-3 деревьев',
            'g_trees_3':'4-10 деревьев',
            'g_trees_4':'11-20 деревьев',
            'g_carsd_1':'когда нету машины в движении',
            'g_carsd_2':'1-3 машины в движении',
            'g_carsd_3':'4-10 машины в движении',
            'g_carsd_4':'11-20 машины в движении',
          }
        },
        no_information_word_1:'нету',
        no_information_word_2:'информации'

      }
    }

  };



export {ru};
//import {computed} from 'vue';
import {useReportsManager} from './ReportsManager';
const {dependsOnLS, getReports } = useReportsManager();


// eslint-disable-next-line no-unused-vars
const stress = (report)=> {
  return (report.stai_1 + (5 - report.stai_2) + (5 - report.stai_3)) / 3;
}
// eslint-disable-next-line no-unused-vars
const pos_feel = (report)=> {
  return (report.panas_1 + report.panas_3 + report.panas_5) / 3;
}
// eslint-disable-next-line no-unused-vars
const neg_feel = (report)=> {
  return (report.panas_2 + report.panas_4 + report.panas_6) / 3;
}

const calculateAverages = (reportsGroup) => {
  return reportsGroup.reduce((acc, group) => {
      const {name, reports} = group;

      // Initialize sums
      let stressSum = 0;
      let posFeelSum = 0;
      let negFeelSum = 0;

      // Calculate sums
      reports.forEach(report => {
          stressSum += stress(report);
          posFeelSum += pos_feel(report);
          negFeelSum += neg_feel(report);
      });

      // Calculate averages
      const reportCount = reports.length;
      const averages = {
          N: reportCount,
          stress: reportCount > 0 ? stressSum / reportCount : 0,
          pos_feel: reportCount > 0 ? posFeelSum / reportCount : 0,
          neg_feel: reportCount > 0 ? negFeelSum / reportCount : 0
      };

      // Assign the averages object to the corresponding group name key in the accumulator
      acc[name] = averages;
      return acc;
  }, {}); // Start with an empty object as the accumulator
};

export function useFeedbackStats() {

    var reports = getReports(dependsOnLS);// non reactive.  just for consistancy dependsOnLS does not do anything here as no reactivity is required);
    var actualReports = reports.filter((v)=>v!=null);
    var n_reportsSoFar = actualReports.length;

    var g_in_out_0=actualReports.filter((v)=>{return (v.in_out==0)});
    var g_in_out_1=actualReports.filter((v)=>{return (v.in_out==1)});

    var n_reports_inside = g_in_out_0.length;
    var n_reports_outside = g_in_out_1.length;

    var g_trees_1=actualReports.filter((v)=>{return (v.trees==1)});
    var g_trees_2=actualReports.filter((v)=>{return (v.trees==2)});
    var g_trees_3=actualReports.filter((v)=>{return (v.trees==3)});
    var g_trees_4=actualReports.filter((v)=>{return (v.trees==4)});
    var g_trees_5=actualReports.filter((v)=>{return (v.trees==5)});

    var n_g_trees_1=g_trees_1.length;
    var n_g_trees_2=g_trees_2.length;
    var n_g_trees_3=g_trees_3.length;
    var n_g_trees_4=g_trees_4.length;
    var n_g_trees_5=g_trees_5.length;

    var g_carsd_1=actualReports.filter((v)=>{return (v.carsd==1)});
    var g_carsd_2=actualReports.filter((v)=>{return (v.carsd==2)});
    var g_carsd_3=actualReports.filter((v)=>{return (v.carsd==3)});
    var g_carsd_4=actualReports.filter((v)=>{return (v.carsd==4)});
    var g_carsd_5=actualReports.filter((v)=>{return (v.carsd==5)});

    var n_g_carsd_1=g_carsd_1.length;
    var n_g_carsd_2=g_carsd_2.length;
    var n_g_carsd_3=g_carsd_3.length;
    var n_g_carsd_4=g_carsd_4.length;
    var n_g_carsd_5=g_carsd_5.length;

    var g_blue_1=actualReports.filter((v)=>{return (v.blue==1)});
    var g_blue_2=actualReports.filter((v)=>{return (v.blue==2)});

    var g_green_1=actualReports.filter((v)=>{return (v.green==1)});
    var g_green_2=actualReports.filter((v)=>{return (v.green==2)});

    var g_birds_1=actualReports.filter((v)=>{return (v.birds==1)});
    var g_birds_2=actualReports.filter((v)=>{return (v.birds==2)});

    var n_g_blue_1 = g_blue_1.length;
    var n_g_blue_2 = g_blue_2.length;

    var n_g_green_1 = g_green_1.length;
    var n_g_green_2 = g_green_2.length;

    var n_g_birds_1 = g_birds_1.length;
    var n_g_birds_2 = g_birds_2.length;


    var g_company_1=actualReports.filter((v)=>{return (v.company==1)}); //alone
    var g_company_2=actualReports.filter((v)=>{return (v.company==2)}); //with family
    var g_company_3=actualReports.filter((v)=>{return (v.company==3)}); //with pet
    var g_company_4=actualReports.filter((v)=>{return (v.company==4)}); //with friends
    var g_company_5=actualReports.filter((v)=>{return (v.company==5)}); //with friends

    var n_g_company_1 = g_company_1.length;
    var n_g_company_2 = g_company_2.length;
    var n_g_company_3 = g_company_3.length;
    var n_g_company_4 = g_company_4.length;
    var n_g_company_5 = g_company_5.length;


    var sum_stai_1 = actualReports.reduce((accumulator, v) => {
      return accumulator + v.stai_1;
    }, 0);
    var sum_stai_2 = actualReports.reduce((accumulator, v) => {
      return accumulator + v.stai_2;
    }, 0);
    var sum_stai_3 = actualReports.reduce((accumulator, v) => {
      return accumulator + v.stai_3;
    }, 0);

    var avg_stai_1=1.0 * sum_stai_1 / n_reportsSoFar;
    var avg_stai_2=1.0 * sum_stai_2 / n_reportsSoFar;
    var avg_stai_3=1.0 * sum_stai_3 / n_reportsSoFar;

  // Public API
  return {
    // n_ are counts
    n_reportsSoFar,
    n_reports_inside,
    n_reports_outside,
    n_g_blue_1,
    n_g_blue_2,
    n_g_green_1,
    n_g_green_2,
    n_g_birds_1,
    n_g_birds_2,
    n_g_company_1,
    n_g_company_2,
    n_g_company_3,
    n_g_company_4,
    n_g_company_5,
    n_g_trees_1,
    n_g_trees_2,
    n_g_trees_3,
    n_g_trees_4,
    n_g_trees_5,
    n_g_carsd_1,
    n_g_carsd_2,
    n_g_carsd_3,
    n_g_carsd_4,
    n_g_carsd_5,

    //avg_ are averages
    avg_stai_1,
    avg_stai_2,
    avg_stai_3,

    //returns the subgroups of reports
    g_in_out_0,
    g_in_out_1,
    g_blue_1,
    g_blue_2,
    g_green_1,
    g_green_2,
    g_birds_1,
    g_birds_2,
    g_company_1,
    g_company_2,
    g_company_3,
    g_company_4,
    g_company_5,
    g_trees_1,
    g_trees_2,
    g_trees_3,
    g_trees_4,
    g_trees_5,
    g_carsd_1,
    g_carsd_2,
    g_carsd_3,
    g_carsd_4,
    g_carsd_5,

   calculateAverages,

  };
}




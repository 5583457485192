// import Vue from 'vue';
// import Vuetify from 'vuetify';
// import 'vuetify/dist/vuetify.min.css'; // Ensure you are using css-loader

// Vue.use(Vuetify);

// const opts = {}; // You can include Vuetify options here

// export default new Vuetify(opts);



// Import necessary dependencies
import { createVuetify } from 'vuetify';
import 'vuetify/styles'; // Import Vuetify styles
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { md3 } from 'vuetify/blueprints'
/*import "@mdi/font/css/materialdesignicons.css";*/
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
import { mdiMagnify, mdiMenu, mdiAccount } from '@mdi/js'

import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n';

import i18n from './i18nPlugin';
import {useI18n} from 'vue-i18n';

const myCustomLightTheme = {
    dark: false,
    colors: {
      background: '#FFFFFF',
      surface: '#FFFFFF',
      primary: '#6200EE',
      'primary-darken-1': '#3700B3',
      secondary: '#03DAC6',
      'secondary-darken-1': '#018786',
      error: '#B00020',
      info: '#2196F3',
      success: '#4CAF50',
      warning: '#FB8C00',
    },
  }

const coldAndClamTheme = {
    dark: false,
    colors: {
        background: '#F0F4F8', // Light Grey Blue
        surface: '#FFFFFF', // White
        primary: '#546E7A', // Blue Grey
        'primary-darken-1': '#29434E', // Dark Blue Grey
        secondary: '#80CBC4', // Light Green Cyan
        'secondary-darken-1': '#4DB6AC', // Green Cyan
        error: '#FF5252', // Red Accent
        info: '#29B6F6', // Light Blue
        success: '#66BB6A', // Green
        warning: '#FFCA28', // Amber
      },

  }

  const warmAndEearthyTheme = {
    dark:false,
    colors: {
        background: '#FFF8E1', // Light Yellow
        surface: '#FFFFFF', // White
        primary: '#8D6E63', // Brown
        'primary-darken-1': '#5D4037', // Dark Brown
        secondary: '#FFAB91', // Light Red Orange
        'secondary-darken-1': '#FF8A65', // Red Orange
        error: '#E53935', // Red
        info: '#26C6DA', // Cyan
        success: '#9CCC65', // Light Green
        warning: '#FFA726', // Orange
      },
  }

 const boldAndVibrantTheme = {
    colors: {
        background: '#ECEFF1', // Blue Grey Lighten
        surface: '#FFFFFF', // White
        primary: '#7B1FA2', // Dark Purple
        'primary-darken-1': '#4A148C', // Deep Purple
        secondary: '#FF4081', // Pink Accent
        'secondary-darken-1': '#F50057', // Pink Accent Dark
        error: '#D32F2F', // Red
        info: '#1976D2', // Blue
        success: '#388E3C', // Green
        warning: '#FBC02D', // Yellow
      },
 }

// Create a new instance of Vuetify
const vuetify = createVuetify({
    defaults: {
      VBtn:{
        color:'green'
      },
      VSelect: {
        menuProps:{
          maxHeight: '480'
        }
      }
    },
    components,
    directives,
    locale: {
        adapter: createVueI18nAdapter({ i18n, useI18n }),
    },
    icons: {
      defaultSet: 'mdi',
      aliases:{
        ...aliases,
        menu:mdiMenu,
        mdiMagnify,
        mdiAccount
      },
      sets: {
        mdi
      },
    },
    blueprint:md3,
    ignored___theme: {
        //defaultTheme: 'myCustomLightTheme',
        defaultTheme: 'coldAndClamTheme',
        themes: {
          myCustomLightTheme,
          coldAndClamTheme,
          warmAndEearthyTheme,
          boldAndVibrantTheme
        },
    },
});

export default vuetify;
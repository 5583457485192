<!--
  properties that are expected to be passed in via the v-bind $attrs are:
  class="mx-1 ps-3"
  :name="'slider_stai_' +i "
-->
<!-- min max and step are used as defaults overridable by providing the attribue via
    v-bind="$attrs"
 -->
<template>
 <v-slider xstyle="border:1px solid black"
    Xmessages="['test message','message 2']"
    :show-ticks="'always'"
    :thumb-size="hasAValue?10:0"
    :color="hasAValue?'#555':'#aaa'"
    :track-fill-color="'transparent'"
    xx_thumb-label="wasClicked?'always':false"

    v-bind="$attrs"
    :min="props.min" :max="props.max" :step="props.step"

    v-model="internalValue"
    @mousedown="handleSliderMouseDown"
  >
     <template v-slot:thumb-label="{ modelValue }">
        <span>
            {{ (true?'':modelValue) }}
        </span>
      </template>
      <template v-slot:tick-label="{tick}">
        <span xstyle="position:relative;top:-42px;transform:translateX(50%)" >
            {{tick.value}}
        </span>
      </template>
      <!--template v-slot:prepend>1</template>
      <template v-slot:append>4</template-->
  </v-slider>
</template>

<!--style scoped>

  .v-locale--is-rtl.v-slider.v-input--horizontal
  .v-slider-track__tick--first .v-slider-track__tick-label,
  .v-locale--is-rtl .v-slider.v-input--horizontal
  .v-slider-track__tick--first .v-slider-track__tick-label,
  .v-locale--is-rtl .v-slider.v-input--horizontal
  .v-slider-track__tick--first .v-slider-track__tick-label,
  .v-locale--is-rtl .v-slider.v-input--horizontal
  .v-slider-track__tick--first .v-slider-track__tick-label {
    transform:translateX(250%) !important;background-color:red;
  }

</style-->
<script setup>
import { ref, computed, watch} from 'vue';

// Define the props your component will accept, including defaults for min and max
const props = defineProps({
  modelValue: Number, // use appropriate type
  min: {
    type: Number,
    default: 0, // Default minimum value
  },
  max: {
    type: Number,
    default: 4, // Default maximum value
  },
  step: {
    type: Number,
    default: 1, // Default maximum value
  },

});

const emits = defineEmits(['update:modelValue']);

// Internal value for the slider
const internalValue = ref(props.modelValue);
const wasClicked=ref(false);
const handleSliderMouseDown= () => {
    wasClicked.value = true;
    internalValue.value = (null==internalValue.value)?1:internalValue.value;
    //not sure if the following is required as I am not sure if the watch of internalValue will not do this on its own.
    //emits('update:modelValue', newVal);
  }
const hasAValue=computed(()=>(wasClicked.value || null!=internalValue.value))


// Watch for external modelValue changes and update the internal value
watch(() => props.modelValue, (newVal) => {
  internalValue.value = newVal;
});
// Emit update event to the parent when the internal value changes
watch(internalValue, (newVal) => {
  emits('update:modelValue', newVal);
});
</script>

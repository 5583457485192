/* Instructions for translators:
1. Only translate the texts with single quotes  (i.e. alwyas on the right side of :)
2. Do not add,remove or change the order of lines.
3. if a text include within it tags like <b></b> (like para4 of the consentForm ) keep the tags in the translation and
   make sure the segment inside them has the same meaning as in the original.
4. if a text include some variable within {} ( like the reported_so_far key for example)
   it means that it will be replaced with an appropriate replacement -
   keep the {} and their content un translated)
5. In case of doubt of any Q's please call Eyal Peleg : 054-4638212

*/
const ar = {
    $vuetify :{
      open:'افتح',
      close:'أغلق',
      noDataText:'ليست هناك معلومات'
    },
    general: {
      buttons: {
        understood:'فهمت',
        continue:'تابع',
        next:'التالي',
        previous:'السابق',
        close:'مغلق',
        sendReport:'أرسل تقريرًا'
      }
    },
    app: {
      title:'IntoMyZone.Com', // do not translate the title.
      menu:{
        choose_language:'اختَر لغة',
        continue_reporting:'تابع للتقرير',
        ongoing_feedback:'تغذية راجعة مرحلية',
        final_feedback:'تغذية راجعة ملخّصة',
        contact:'اتصل بنا',
      }
    },
    install: {
      please_install_to_continue:'يرجى اتباع تعليمات التثبيت أعلاه.',
      after_install:'في نهاية التثبيت، افتح التطبيق المثبت للمتابعة.',
      guidelines:'تعليمات التحميل',
      your_os_is:'بيئة عملك {operatingSystem}.',
      Android:{
        para_1:'على جهاز Android, تأكد من أن لديك نسخة محدثة من Google Chrome.',
        para_2:'استخدم/ي google chrome وشغل/ي  https://app.intomyzone.com',
        para_3:'في قائمة Google Chrome، ابحث عن خيار تثبيت التطبيق',
        para_4:'سيُطلب منك تأكيد تنفيذ التثبيت.',
        para_5:'بعد الموافقة يمكنك إضافة التطبيق من قائمة التطبيقات المثبتة على جهازك بالطريقة المعتادة.',
      },
      iOS:{
        para_1:'على iPhone أو iPad، افتح Safari.',
        para_2:'اذهب إلى  https://app.intomyzone.com',
        para_3:'في أسفل الشاشة انقر على الزر {share_icon}.',
        para_4:'ابحث عن "إضافة إلى الشاشة الرئيسية" وانقر عليها "Add to home Screen" {add_to_home_screen_icon}',
        para_5:'قم بتأكيد تفاصيل الموقع ثم انقر على "إضافة". "Add".',
        para_6:'ستتم إضافة رابط لتشغيل التطبيق إلى شاشتك الرئيسية.',
      },
      Windows: {
        para_1:'لم يتم تصميم هذا التطبيق ليعمل في بيئة  Microsoft Windows.',
      },
      Other: {
        para_1:'أنت مستخدم ل: "{userAgent}"',
        para_2:'هذه البيئة غير مألوفة بالنسبة لنا.',
        para_3:'يرجى التقاط لقطة شاشة لهذه الشاشة وإرسالها إلينا على {email} حتى نتمكن من مساعدتك.',
      }
    },
    view: {
      generalInfo: {
        title:'معلومات عامّة',
        head1:'IntoMyZone',
        para1:'IntoMyZone هو تطبيق يدعوك إلى الاستغراق في بيئتك بيقظة واستمتاع، والتكرّس لعملية قياس تجارب انطباعية لحظية في بيئات مدنية أو طبيعية',
      },
      contact: {
        can_contact_via_email:'يمكنك الاتصال بنا على {email}'
      },
      consentForm: {
        title:'استمارة موافقة واعية',
        para1:'طلب مشاركتكم في دراسة تبحث في العلاقة بين البيئة المادّية والصحّة العقلية في البيئات الحضرية والطبيعية – IntoMyZone',
        para2:'تحية وبعد, ',
        para3:'نحن نتواصل معك كجزء من دراسة تتناول دراسة أنظمة الروابط بين البيئة المادّية والصحّة العقلية في البيئات الحضرية والطبيعية. تم إجراء البحث في إسرائيل ويديره طاقم باحثين من كلّية التربية في جامعة حيفا. تمّت الموافقة على هذه الدراسة من قبل لجنة الأخلاقيات في جامعة حيفا.',
        para4:'<b>الغرض من الدراسة</b> هو فهم التأثير اللحظي والتراكمي الذي تحدثه البيئات المختلفة على الصحّة العقلية للشخص. ستساهم نتائج البحث في فهم أعمق للقيمة التأهيلية للظروف البيئية المختلفة سواء أكان من حيث تعزيز الصحة أم من وجهة نظر بيئية لعامّة الناس.',
        para5:'من أجل إعطاء موافقتك تجب قراءة شروط الاستخدام وكتاب الموافقة الذي سيُعرض فيما يلي، والإشارة إلى إمكانية "أنا أوافق على المشاركة في الدراسة". إنّك باختيار "أنا أوافق على المشاركة في الدراسة" تصدّق\\ين على أنّك توافق\\ين على المشاركة في الدراسة. سيكون بإمكانك الوصول إلى المعلومات المعروضة أعلاه ثانية وحتّى سحب موافقتك من خلال قائمة التعريفات الخاصّة بالتطبيق.',
        termsAndConditions: {
          buttonCaption:'اعرض شروط الاستخدام',
          title:'شروط الاستخدام',
          head1:'طريقة المشاركة.',
          para1:'من أجل المشاركة في الدراسة يجب ملء الاستبيانات عبر الإنترنت باستخدام تطبيق ‘IntoMyZone’, على مواقع الويب المختلفة التي تختارها. إذا قرّرت المشاركة في الدراسة فسيُطلب منك أوّلًا الإجابة عن استبيان أوّليّ يتعلّق بالعمر والجنس والمهنة ومكان الإقامة ونمط الحياة والرفاهية الشخصية ودرجة الانجذاب للطبيعة. بعد ذلك، سيطالبك التطبيق بالإبلاغ عن خصائص بيئتك ومشاعرك وأفكارك وسلوكك، 3 مرّات يوميًّا، خلال فترة أسبوعين. كما سيتم تسجيل موقعك الجغرافيّ باستخدام مستشعر الـ GPS الموجود على هاتفك المحمول.',
          head2:'',
          para2:'سنطلب موافقتك على تقديم الموقع الجغرافيّ عند استخدام التطبيق لأوّل مرّة. بالإضافة إلى ذلك، في نهاية كلّ تقرير، سيكون لديك خيار التقاط صورة لشيء ما في بيئتك يجعلك تشعر بالإيجابية أو السلبية، وتسجيل صوت البيئة التي أنت موجود فيها. يستغرق الاستبيان الأوّليّ حوالي 5 دقائق لاستكماله، وتستغرق التقارير المستمرّة حوالي دقيقتين لاستكمالها.',
          head3:'',
          para3:'خلال الأسبوعين سيتمّ إرسال الإشعارات إلى هاتفك المحمول في أوقات محدّدة مسبّقًا. بعد كل تنبيه ستتوافر لك نافذة زمنية مدّتها ساعتين لإكمال التقرير. إذا لم تتمكن من الإبلاغ خلال هذه الفترة يمكنك تخطّي هذا التقرير وانتظار الإشعار التالي. نطلب منك إكمال أكبر عدد ممكن من التقارير لأنّ ذلك سيسمح بالحصول على صورة أكثر موثوقية وشمولية للوضع.',
          head4:'السرّية.',
          para4:'المشاركة في الدراسة مجهولة تمامًا. لن يُطلب منك تقديم تفاصيل شخصية و/أو معرّفات من أيّ نوع في أيّ وقت. سيتمّ استخدام البيانات التي تمّ جمعها لأغراض البحث فقط، وسيتمّ الإبلاغ عنها في شكل إجماليّ (لن يتمّ الإبلاغ عن البيانات الفردية). سيتمّ تخزين إجاباتك عن الاستبيانات إلى أجل غير مسمًّى وتأمينها على أجهزة كمبيوتر محمية بكلمة مرور، أو على أقراص منفصلة. سيتمّ تحليل الصور والمقاطع الصوتية لأغراض البحث فقط، وقد يتمّ عرضها على وسائل التواصل الاجتماعيّ واستخدامها في المعارض الفنّية الرقمية للترويج للمشروع.',
          head5:'تحليل البيانات.',
          para5:'قد يتمّ تقديم البيانات التي تمّ جمعها كتقارير علمية ونشرها في المجلات العلمية، بالإضافة إلى ذلك، قد يتمّ توفير البيانات لباحثين آخرين بروح الحركة العلمية المفتوحة، وأيّ معلومات مقدّمة في التقارير أو المنشورات المحتملة ستكون مجهولة المصدر تمامًا وغير قابلة للتحديد.',
          head6:'فائدة المشاركة في الدراسة.',
          para6:'إنّ للمشاركة في الدراسة فائدة شخصية وعامّة. على الصعيد الشخصيّ المشارك\\ة الذي\\ التي سيملأ\\ستملأ أكثر من 20 تقريرًا على امتداد فترة المشاركة سيُتلقّى في نهاية الفترة تقرير شخصيّ يصف الطريقة التي فيها ترتبط بيئات مختلفة بحالات عاطفية مختلفة لديه\\ها. بإمكان هذا التقرير أن يُستخدم للتخطيط الشخصيّ لتطوير حالات تساهم في الرفاه النفسيّ. ستساهم نتائج البحث في فهم متعمّق للقيمة التأهيلية للظروف البيئية المختلفة لتعزيز الصحّة العقلية والجسدية، وبالتالي المساهمة في جميع السكان. بالإضافة إلى ذلك، وبناء على النتائج، سيكون من الممكن التوصية بالتخطيط الحضريّ المتوازن والمفيد للسكان في المستقبل.',
          head7:'معلومات إضافية.',
          para7:'إذا كنت مهتمًا\\ة بالحصول على مزيد من المعلومات حول البحث ندعوك للاتصال بطاقم البحث عبر عُنوان البريد الإلكترونيّ: intomyzone24@gmail.com',
          head8:'',
          para8:'سنكون ممتنّين جدًّا إذا وافقت على المشاركة في الدراسة. من المهمّ ملاحظة أنّ المشاركة لن تكون ممكنة إلّا إذا أعطيت موافقتك. هذه الموافقة ليست ملزمة ويمكنك إيقاف مشاركتك في الدراسة في أيّ وقت من دون أيّ ضرر لك. يمكنك، أيضًا، حذف البيانات التي سيتمّ جمعها من خلال قائمة إعدادات التطبيق من خلال الضغط على &apos;حذف كافة السجلات&apos;. بالإضافة إلى ذلك، يمكنك حذف التطبيق في أيّ وقت. ومع ذلك، بعد ذلك، لن تتمكّن من حذف السجلّات ولن تتمكّن من سحب موافقتك على المشاركة في الدراسة. وذلك لأنّنا لا نستطيع تحديد القوائم الفردية لأنّنا لا نجمع أيّ معلومات تعريفية. ',
          },
        bullet1:'أعلن بموجب هذا أنني أوافق على المشاركة في البحث حول هذا الموضوع في دراسة أنظمة الروابط بين البيئة المادّية والصحّة العقلية في البيئات الحضرية والطبيعية.',
        bullet2:'أعلم أنني حرّ في اختيار عدم المشاركة في البحث، كما أنني حرّ في إيقاف مشاركتي في أيّ وقت، من دون المساس بحقوقي، ومن دون إلحاق أيّ ضرر بي، ومن دون اتّخاذ أيّ عقوبة ضدّي.',
        bullet3:'أعلم أنني أضمن السرية فيما يتعلّق بهويّتي الشخصية في كلّ مرحلة من مراحل البحث بما في ذلك المنشورات العلمية. ',
        bullet4:'أقرّ بموجب هذا أنني أعطيت موافقتي بمحض إرادتي وأنني فهمت كلّ ما سبق.',
        bullet5:'لأيّ أسئلة بخصوص الدراسة يمكن التواصل مع طاقم الدراسة عبر عُنوان البريد الإلكنرونيّ: intomyzone24@gmail.com.',
        iagree:'أوافق على المشاركة في الدراسة'
      },
      sheelon1:{
        title:'معلومات إحصائية',
        intro:'من أجل التحليل الإحصائيّ نطلب منك الإجابة عن عدد من الأسئلة فيما يلي قبل البدء باستخدام التطبيق. جميع المعلومات لأغراض الدراسة، فقط. وفي جميع الأحوال، لن تُجمع معلومات شخصية من قبيل أسماء المشاركين.',
        NR_title:'إلى أيّ درجة توافق\\ين على المقولات التالية؟',
        PA_title:'أمامك مقولات تصف العلاقة بمكان السكن. يُرجى أن تجيب\\ي من خلال التطرّق إلى المكان الذي تسكن\\تسكنين فيه الآن. إلى أيّ درجة توافق\\ين على المقولات التالية ((1 = \'لا أوافق بشدّة\' حتّى 5 = \'أوافق بشدّة)?',
        WHO_title:'يُرجى أن تجيب\\ي بالنسبة إلى الأسبوعين الأخيرين، متى وافقت على كلّ واحدة من المقولات الخمس التالية:',
        agreeScaleText:'(1 = لا أوافق بشدّة حتّى 5 = أوافق بشدّة)',
        satisfactionTexts:[
          'لا أوافق إطلاقًا','لا أوافق','غير مبالٍ','أوافق','أوافق فعلًا'
        ],
        itemSets:{
          ProjectItems:[
            {value:0, title:'لا تنتمي إلى مشروع معين'},
            {value:1, title:'مشروع مدرسي'},
            {value:2, title:'مشروع مجتمعي'},
          ],
          YesNoItems:[
            {value:1, title:'نعم'},
            {value:0, title:'لا'},
          ],
          NoYesItems:[
            {value:0, title:'لا'},
            {value:1, title:'نعم'}
          ],
          residence:[
            {value:1, title:'في المدينة'},
            {value:2, title:'في بلدة حضرية مع أقلّ من 20,000 شخص'},
            {value:3, title:'في مِنطقة قروية (كيبوتس، مثلًا)'},
            {value:4, title:'في عدّة أماكن'},
            {value:5, title:'غيرها'},
          ],
          house:[
            {value:10 , title:'بيت خاصّ' ,hasFloors:false},
            {value:20 , title:'شقّة في عمارة' , hasFloors:true},
            {value:30 , title:'فندق' , hasFloors:true},
            {value:40 , title:'نُزُل طالب' , hasFloors:true },
            {value:90 , title:'لا شيء ممّا ذُكر أعلاه' ,hasFloors:false}
          ]
      },
      Qs: {
          //phase 1
          project:
            {
              label:'مشاركتك في البحث هي:',
              // changed to @:view.sheelon1.itemSets.ProjectItems
            },
          schoolCode:{
            label_1:'أدخل\\ي شيفرة المدرسة مكوّنة من 6 أرقام',
            label_2:'أدخل رمز المجتمع المكون من 6 أرقام',
            errors: {
              schoolCodeRuleOutOfRange_1:'يجب أن تكون شيفرة المدرسة مكوّنة من 6 أرقام',
              schoolCodeRuleOutOfRange_2:'يجب أن يتكون رمز المجتمع من 6 أرقام'
            }
          },
          //phase 2
          age: {
            label:'كم عمرك؟',
            errors:{
              ageRuleOutOfRange:'يجب أن يكون العمر بين 6 و99'
            }
          },
          gender:
          {
            label:'ما هو نوعك الاجتماعيّ؟',
            items:[
              {value:1, title:'أنثى'},
              {value:0, title:'ذكر'},
              {value:2, title:'ليس ثنائيًّا'},
              {value:3, title:'لا أريد القول'},
            ]
          },
          israel:{
            label: "هل أنت من مواليد البلاد؟",
            //items: @:view.sheelon1.itemSets.NoYesItems
          },

          evacuated:{
            label: "هل أدّت الحرب إلى تغيير مكان سكنك؟",
            //items: @:view.sheelon1.itemSets.NoYesItems

          },
          residenceChildhood:{
            label: "في أيّ نوع بلدة ترعرعرت؟",
          },

          residenceName:{
            label: "ما هو مكان سكنك الثابت الآن؟",
            label_evacuated: "ما هو مكان السكن الذي أقمت فيه قبل الإخلاء؟",
            //list of vilages is injected automatically
            errors:{
              noDataText:'ليست هناك بلدة كهذه'
            }
          },
          //phase 3
          residencePermanent:{
            label:'ما هو نوع البلدة التي تسكن\\ين فيها بشكل ثابت (بيتك الثابت)؟'
          },
          residenceBefore:{
            label:'ما هو نوع البلدة التي سكنتها قبل الإخلاء (بيتك الثابت)؟'
          },
          residenceAfter:{
            label:'ما هو نوع البدة التي تسكن\\ين فيها اليوم؟'
          },

          houseNow:{
            label: "في أيّ نوع سكن تقيم\\ين اليوم؟",
          },
          houseBefore:{
            label: "ما نوع البيت الذي سكنته فبل الانتقال؟",
          },
          houseAfter:{
            label: "ما نوع البيت الذي تسكن\\ين فيه اليوم؟",
          },
          floor: { // used for all house* Q's where the  hasFloors is true for the selected answer
            label: "في أيّ طابق؟"
          },

          educationLevel:{
            label: "مستوى التحصيل العلميّ",
            items:[
              {value:1, title:'مرحلة إعدادية'},
              {value:2, title:'مرحلة ثانوية بدون شهادة بجروت'},
              {value:3, title:'مرحلة ثانوية مع شهادة بجروت'},
              {value:4, title:'فوق ثانويّ مهْنيّ'},
              {value:5, title:'لقب أوّل'},
              {value:6, title:'لقب ثانٍ فما فوق'},
            ]
          },

          //phase 3
          NR_1:{label: "مكان العطلة الأمثل بالنسبة لي هو مكان بعيد وبرّيّ"},
          NR_2:{label:'أفكّر، دائمًا، في الطريقة التي تؤثّر بها نشاطاتي على البيئة',},
          NR_3:{label:'علاقتي بالطبيعة والبيئة جزء من روحانيّتي'},
          NR_4:{label:'أنتبه إلى الطبيعة في أيّ مكان أكون فيه'},
          NR_5:{label:'علاقتي بالطبيعة جزء مهمّ من كياني'},
          NR_6:{label:'أشعر بصلة وثيقة بجميع المخلوقات الحيّة وبالكرة الأرضية'},
          //phase 4

          PA_nature1:{label:'أنا مرتبط\\ة بالطبيعة الموجودة في هذا المكان'},
          PA_nature2:{label:'عندما أكون بعيدًا\\ة من هنا أشتاق إلى الطبيعة الموجودة هنا'},
          PA_nature3:{label:'الطبيعة الموجودة في هذا المكان تجعلني أشعر بالانتماء إلى هذا المكان'},
          PA_nature4:{label:'الطبيعة الموجودة في هذا المكان تعكس كينونتي'},
          PA_people1:{label:'أشعر بأنّني متعلّق\\ة بالناس في هذا المكان'},
          PA_people2:{label:'عندما أكون بعيدًا\\ة من هنا أشتاق إلى الناس الموجودين هنا'},
          PA_people3:{label:'يجعلني الناس الموجودون هنا أشعر بالانتماء إلى هذا المكان'},
          PA_people4:{label:'العلاقات التي تربطني بالأشخاص في هذا المكان تجعلني أشعر بالانتماء إليه'},
          PA_identity1:{label:'يعكس هذا المكان كينونتي'},
          PA_identity2:{label:'أشعر بارتباط عاطفيّ بهذا المكان'},
          PA_identity3:{label:'أشعر بأنّني أنتمي إلى هذا المكان'},
          PA_identity4:{label:'عندما أكون بعيدًا\\ة أشعر بالاشتياق إلى هذا المكان'},
          //phase 5

          WHO_1:{label:'شعرت بأنّني مسرور\\ة ومنطلق\\ة'},
          WHO_2:{label:'شعرت بأنّني مطمئنّ\\ة وهادئ\\ة'},
          WHO_3:{label:'شعرت بأنّني نشِط\\ة وحيويّ\\ة'},
          WHO_4:{label:'استيقظت بشعور منتعش'},
          WHO_5:{label:'حياتي اليومية مليئة بالأشياء التي تثير اهتمامي'},

          WHO_Headers_5: 'كلّ الوقت',
          WHO_Headers_4: 'أغلب الوقتן',
          WHO_Headers_3: 'أكثر من نصف الوقت',
          WHO_Headers_2: 'أقلّ من نصف الوقت',
          WHO_Headers_1: 'بعض الوقت',
          WHO_Headers_0: 'ولا في أيّ وقت كان'
        }
      },
      sheelon2:{
          phase0: {
            goodToSeeYouAgain:'تسرّني رؤيتك مجدّدًا.',
            readyLetsGo:'أمستعدّ\\ة لتعبئة تقرير جديد؟ هيّا بنا نبدأ!',
            thanksForJoining:'نشكرك على انضمامك إلينا.',
            thrilledYourWithUs:'متأثّرون بوجودك معنا.',
            youStartTommorow:'التقارير الخاصة بك سوف تبدأ غدا.',
            thanksForYouRecentReport:'شكرًا على التقرير السابق الذي قدّمته.',
            oopsYouMissed:'ووبس، فوّتّ نافذة التقرير السابقة',
            nextReportWillBeAvailable:'سيكون التقرير التالي مفتوحًا أمامك الساعة {hour}',
            thanksForParticipating:'انتهت فترة التقارير ، شكرًا على مشاركتك في المشروع,',
            //youCanSeeYourReports:'بإمكانك رؤية التقارير التي تصف تقاريرك هنا.',
            showReports:'اعرض التقارير',
            timeLeft:'الوقت المتبقّي: {time} دقائق',
            reminders:{
              display_reminder_notifications:'أوافق على عرض التذكيرات',
              explain_permissions:'سيطالبك اختيار إظهار التذكيرات بمنح الأذونات المناسبة',
              permission_denied:'لقد رفضت منح الإذن، لذلك لن يتم عرض أي تذكيرات. يجب إعطاء الأذونات المناسبة على جهازك.',
            }
          },

          agreeExtent:'إلى أيّ درجة توافق\\ين على  المقولات التالية؟',

          nearBy:'في المِنطقة القريبة منّي:',
          atTheDistance:'في المنظر الطبيعيّ البعيد (أكر من100 متر)',

          microphone_permissions_are_needed:'من أجل إتاحة تسجيل الصوت يجب إعطاء التصاريح الملائمة في جهازك',
          //recording_prompt:'سجّل\\ي من فضلك الصوت الذي تسمعه\\تسمعينه الآن.',
          recording_prompt_v2:'نطلب منك، الآن، تسجيل 30 ثانية من الأصوات حولك. (التوثيق الذي سيُحفظ هو لقوّة الصوت، فقط)',
          start_recording:'ابدأ التسجيل',
          stop_recording:'إنهاء التسجيل',
          recording_has_ended:'شكرًا، انتهى التسجيل',

          camera_permissions_are_needed:'من أجل إتاحة التقاط الصور يجب إعطاء التصاريح الملائمة في جهازك',
          photo_pos_prompt:'التقط\\ي من فضلك صورة لشيء تراه\\ترينه من حولك يجعلك تشعر\\ين بإحساس إيجابيّ. يُرجى عدم تصوير الأشخاص.',
          photo_neg_prompt:'التقط\\ي من فضلك صورة لشيء تراه\\ترينه من حولك يجعلك تشعر\\ين بإحساس سلبيّ. يُرجى عدم تصوير الأشخاص.',
          open_camera:'تشغيل الكاميرا',
          take_picture:'تصوير',
          dont_use_this_image:'لا ترسل\\ي هذه الصورة',


          form_filled_press_send:'أنهيت تعبئة الاستمارة. اضغط\\ي على الزرّ "أرسل التقرير" في الأسفل، لتقديم التقرير',

          Qscales: {
            stai_1_4:"والآن، كنت لأصف حالتي كـ (بين 1='إطلاقًا لا' و 4='جدًّا')",
            panas_1_5:"والآن، أنا أشعر (بين 1='إطلاقًا لا' و 5='جدًّا')"
          },
          Qs: {
            //phase 1
            in_out:
              {
                label:'الآن أنا موجود\\ة:',
                items:[{value:0, title:'في الداخل'},
                       {value:1, title:'في الخارج'},]
              },
            company:{
              label:'الآن أنا موجود\\ة مع:',
              items:[
                {value:1, title:'لا أحد'},
                {value:2, title:'العائلة'},
                {value:3, title:'حيوان أليف'},
                {value:4, title:'أصدقاء'},
                {value:5, title:'غيرها'},
              ]
            },
            //phase 2
            activity:
            {
              label:'أنا مشغول\\ة الآن في:',
              items:[
                {value:1, title:'العمل'},
                {value:2, title:'الدراسة'},
                {value:3, title:'المشتريات'},
                {value:4, title:'أعمال البيت'},
                {value:5, title:'نشاط أوقات فراغ'},
                {value:6, title:'الاستراحة'},
                {value:7, title:'في طريقي إلى مكان ما'},
                {value:9, title:'النشاط الرياضي'},
                {value:8, title:'غيرها'},
              ]
            },
            /// Those are for people Outside...
            location: {
              label:'أنا موجود\\ة الآن في:',
              items:[
                {value:1, title:'ساحة أو بستان بيت'},
                {value:2, title:'الشارع'},
                {value:3, title:'شاطئ البحر'},
                {value:4, title:'حديقة عامّة'},
                {value:9, title:'حديقة المجتمع'},
                {value:5, title:'مركَز مشتريات'},
                {value:6, title:'بستان'},
                {value:7, title:'مكان طبيعيّ'},
                {value:8, title:'غيرها'},
              ]
            },
            //nearBy
            trees:{
              label: "أرى أشجارًا:",
              items:[
                {value:1, title:'إطلاقًا لا'},
                {value:2, title:'بين 1-3'},
                {value:3, title:'بين 4-10'},
                {value:4, title:'بين 11-20'},
                {value:5, title:'فوق 20'},
              ]
            },
            bushes:{
              label: "أرى شجيرات:",
              items:[
                {value:1, title:'إطلاقًا لا'},
                {value:2, title:'بين 1-3'},
                {value:3, title:'بين 4-10'},
                {value:4, title:'بين 11-20'},
                {value:5, title:'فوق 20'},
              ]
            },
            carsp:{
              label: "أرى سيّارات مركونة:",
              items:[
                {value:1, title:'ليست هناك سيّارات مركونة'},
                {value:2, title:'بين 1-3'},
                {value:3, title:'بين 4-10'},
                {value:4, title:'بين 11-20'},
                {value:5, title:'فوق 20'},
              ]
            },
            carsd:{
              label: "أرى سيّارات مسافرة:",
              items:[
                {value:1, title:'ليست هناك سيّارات مسافرة'},
                {value:2, title:'بين 1-3'},
                {value:3, title:'بين 4-10'},
                {value:4, title:'بين 11-20'},
                {value:5, title:'فوق 20'},
              ]
            },
            grass:{
              label: "أرى عشبًا أخضر:",
              items:[
                {value:1, title:'نعم'},
                {value:2, title:'لا'},
                {value:3, title:'غير متأكّد\\ة'},
              ]
            },
            birds:{
              label: "أرى أو أسمع عصافير :",
              items:[
                {value:1, title:'نعم'},
                {value:2, title:'لا'},
                {value:3, title:'غير متأكّد\\ة'},
              ]
            },
            // phase
            // في المنظر الطبيعيّ البعيد (أكثر من نحو 100 متر)
            blue:{
              label: "أرى بحرًا أو بحيرة:",
              items:[
                {value:1, title:'نعم'},
                {value:2, title:'لا'},
                {value:3, title:'غير متأكّد\\ة'},
              ]
            },
            green:{
              label: "أرى منظرًا طبيعيًّا أخضر :",
              items:[
                {value:1, title:'نعم'},
                {value:2, title:'لا'},
                {value:3, title:'غير متأكّد\\ة'},
              ]
            },
            //phase 3
            stai_1:{label: "متوتّر\\ة"},
            stai_2:{label: "راضٍ\\ية"},
            stai_3:{label: "هادئ\\ة"},
            panas_1: {label: "مليء\\ئة بالإيحاء"},
            panas_2: {label: "معادٍ\\ية"},
            panas_3: {label: "قويّ\\ة"},
            panas_4: {label: "في ضائقة"},
            panas_5: {label: "مصرّ\\ة"},
            panas_6: {label: "عصبيّ\\ة"},

            //phase 6 - audio
            sound:{
              label:'ما هو المصدر الأساسيّ للصوت الذي سجّلته؟',
              items:[
                {value:1, title:'أشحاص يتحدّثون'},
                {value:2, title:'كلاب تنبح'},
                {value:3, title:'حركة'},
                {value:4, title:'موسيقى'},
                {value:6, title:'أصوات الطيور'},
                {value:5, title:'غيرها'},
              ]
            },

          },

          locationPermissions: {
            title: 'تصاريح موضع',
            para1:'من أجل بحث العلاقة بين الموضع المادّيّ والمشاعر العاطفية نريد أن نتوجّه إلى موضعك في أثناء استخدام التطبيق، وفي هذه الأوقات، فقط. ',
            para2:'بعد أن تضغط\\ي على "التالي" سنطلب منك إعطاء التصريح، يُرجى أن تصدّق\\ي على أنّك توافق\\ين على ذلك. من دون إعطاء هذا التصريح ليس من الممكن المشاركة في الفعّاليّة',
            denied:'رفضت إعطاء التصريح ولذلك ليس من الممكن المشاركة في الفعّاليّة. يجب إعطاء التصاريح الملائمة في جهازك.',
          },

      },
      get_feedback: {
        reported_so_far:'قدّمت حتّى الآن تقارير {n} مرّات.',
        where_were_you:'أين كنت عندما قدّمت تقريرًا:',
        outside:'في الخارج',
        inside:'في الداخل',
        sea_or_lake_view:'منظر طبيعيّ لبحر أوبحيرة',
        green_view:'منظر طبيعيّ أخضر',

        who_were_you_with:'مع من كنت عندما قدّمت التقرير:',
        alone:'وحدك',
        with_pet:'مع حيوان أليف',
        with_friends:'مع أصدقاء',
        with_family:'مع العائلة',
        other:'غيرها',

        trees_seen:'عدد الأشجار التي رأيتها:',
        carsd_seen:'عدد السيّارات المسافرة التي رأيتها:',
        range_none:'إطلاقًا لا',
        range_1_3:'بين 1-3',
        range_4_10:'بين 4-10',
        range_11_20:'بين 11-20',
        range_over_20:'فوق 20',
        number_of_times_you_reported_of:'عدد المرّات التي قدّمت فيها تقريرًا عن:',
        reported_feeling_avg:'معدّل المشاعر التي بلّغت عنها (في المدى بين 1-4) هو:',
        no_reports_yet:'هنا ستظهر تغذية راجعة مستمرّة عن تقاريرك. يجب التبليغ مرّة واحدة على الأقلّ، من أجل الحصول على التغذية الراجعة المستمرّة.',

        data_will_be_here:'بدءًا من تاريخ {end_date} (في نهاية أسبوعين من المشاركة) سيظهر هنا تقريرك الشخصي، والذي يلخص العلاقة التي ترتبط بها المواقع والتجارب المختلفة بمشاعرك.',
        data_N_required:'ولهذا الغرض، تحتاج الوصول إلى {n_req} تقريرًا على الأقل.',
        data_requirements:'ينقصك {n} ابلاغ لتلقي تقريرك الخاص.',
        your_reporting_progress:'تقدّمك في التقارير',

        did_N_reports:'حظًّا سعيدًا! استكملت {n_req} تقريرًا. سيخرج تقرير ملخّص بعد انقضاء أسبوعين من المشاركة.',
        did_N_continue_reporting: 'في جميع الأحوال، من أجل أن يكون التقرير دقيقًا قدْر الإمكان يوصى بمتابعة تعبئة التقارير.',

        ended_without_enough_reports:'شكرًا جزيلًا على مشاركتك. بما أنّه لم يتمّ تلقّي {n_req} تقريرًا لا يمكن إصدار تقرير شخصيّ ملخّص. لا يزال بإمكانك مشاهدة تقرير نشاطك ومعرفة ما هي النشاطات الأساسية التي تناولتها وما هي المناظر الأساسية التي رأيتها.',

        we_created:'من المعطيات التي عبّأتها بنينا مقاييس لثلاثة أنواع من المشاعر:',
        indicators:'مشاعر إيجابية، مشارعر سلبية، وشعور بالضغط.',
        averages:'فيما يلي معدّلات المشاعر التي تنتابك في حالات مختلفة.',
        averages_feelings:'تظهر معدّلات المشاعر الإيجابية والسلبية في المدى بين 1-5.',
        averages_stress:'تظهر معدّلات الضغط في المدى بين 1-4.',
        limits_note:'انتبه\\ي: تشمل الرسوم البيانية تشكيلة من المتغيّرات التي تمّ جمعها ولكن ليس كلّ متغيّرات الدراسة.',

        final:{
          positive_and_negative_feelings:'المشاعر الإيجابية والسلبية:',
          stress:'ضغط:',
          categories: {
            g_in_out_1:'في الخارج',
            g_in_out_0:'في الداخل',
            blue_1:'منظر طبيعيّ لبحر أو بحيرة',
            g_green_1:'منظر طبيعيّ أخضر',
            g_birds_1:'عصافير',
            g_company_1:'وحدك',
            g_company_2:'مع أصدقاء',
            g_company_3:'مع العائلة',
            g_company_4:'مع الكلب',
            g_trees_1:'حيث ليست هناك أشجار',
            g_trees_2:'1-3 أشجار',
            g_trees_3:'4-10 أشجار',
            g_trees_4:'11-20 أشجار',
            g_carsd_1:'حيث ليست هناك سيّارات مسافرة',
            g_carsd_2:'1-3 سيّارات مسافرة',
            g_carsd_3:'4-10 سيّارات مسافرة',
            g_carsd_4:'11-20 سيّارات مسافرة',
          }
        },
        no_information_word_1:'ليست هناك',
        no_information_word_2:'معلومات',


      }
    }

  };

export {ar};